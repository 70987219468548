import React from 'react';

import { ButtonSelect, Flex } from '@ubisend/pulse-components';

import { useButtons } from '../../hooks/index';

const AddButton = () => {
  const { handleButton, types } = useButtons();

  const handleClick = type => {
    handleButton({
      type: type.id,
      ...type.setInitialContent({})
    });
  };

  const handleTypeSelect = selectedOption => {
    const selectedType = types.find(type => type.id === selectedOption.value);
    handleClick(selectedType);
  };

  const formatOption = type => {
    return {
      value: type.id,
      label: type.name === 'Url' ? 'URL' : type.name
    };
  };

  const order = ['Postback', 'Url', 'File', 'Mailto', 'Callto', 'Content'];

  const sortedTypes = [...types].sort((a, b) => {
    return order.indexOf(a.name) - order.indexOf(b.name);
  });

  return (
    <Flex xSpaceSm wrap gap>
      <ButtonSelect
        aria-label="add-button"
        placeholder={'+ Add button'}
        defaultValue={null}
        options={sortedTypes.map(formatOption)}
        value={null}
        isStatic
        isSearchable={false}
        onChange={handleTypeSelect}
      />
    </Flex>
  );
};

export default AddButton;
