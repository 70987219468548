import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';

import { Spinner } from '../Loading/index';
import { useInfiniteScroll } from '../../hooks/index';

const SpinnerContainer = styled.div`
  ${tw`w-full flex justify-center items-center`}
`;

const InfiniteScroll = ({
  className,
  children,
  targetParent = false,
  handleBottomReached
}) => {
  const { ref, loading } = useInfiniteScroll(handleBottomReached, {
    targetParent
  });

  return (
    <div className={className} ref={ref}>
      <>
        {children}
        {loading && (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
      </>
    </div>
  );
};

InfiniteScroll.propTypes = {
  className: PropTypes.string,
  targetParent: PropTypes.bool,
  handleBottomReached: PropTypes.func.isRequired
};

export default InfiniteScroll;
