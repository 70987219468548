import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  TextInput,
  Flex,
  FormGroup,
  Label,
  Button,
  Divider
} from '@ubisend/pulse-components';
import {
  Match,
  useConditionalReducer,
  AddConditionalButton,
  Conditionals,
  ConditionalContext
} from '@ubisend/pulse-conditionals';

import subjects from '../../subjects/index';

const LookupDetails = ({
  handleSubmit,
  initialName = '',
  initialDescription = '',
  initialConditionals,
  sheet
}) => {
  const [name, setName] = useState(initialName);
  const [description, setDescription] = useState(initialDescription);
  const conditionals = useConditionalReducer({
    conditionals: initialConditionals,
    subjects: [subjects.conditionals.sheet(sheet)]
  });

  const onSubmit = event => {
    event.preventDefault();
    handleSubmit({ name, description, ...conditionals.form });
  };

  const handleNameChange = event => setName(event.target.value);

  const handleDescriptionChange = event => setDescription(event.target.value);

  return (
    <form onSubmit={onSubmit}>
      <FormGroup>
        <Label htmlFor="name">Name</Label>
        <TextInput
          id="name"
          placeholder="Check table row"
          onChange={handleNameChange}
          value={name}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="description">Description</Label>
        <TextInput
          id="description"
          placeholder="The table row contains data"
          onChange={handleDescriptionChange}
          value={description}
        />
      </FormGroup>
      <ConditionalContext.Provider value={conditionals}>
        <Match />
        <Divider />
        <Conditionals labelPrefix="lookup-conditionals" />
        <Flex right xSpace>
          <AddConditionalButton />
          <Button type="submit" variant="primary">
            Save
          </Button>
        </Flex>
      </ConditionalContext.Provider>
    </form>
  );
};

LookupDetails.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialName: PropTypes.string,
  initialDescription: PropTypes.string,
  initialConditionals: PropTypes.shape({
    match: PropTypes.oneOf(['all', 'any']),
    conditionals: PropTypes.array
  }),
  sheet: PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired
};

export default LookupDetails;
