import React from 'react';
import { Link } from 'react-router-dom';

import {
  Flex,
  LeftHalfLayout,
  Panel,
  Button,
  Box,
  TextInput
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { useImports } from '../hooks/index';
import { SectionActions } from '../Components/index';

const Faqs = () => {
  const { section } = useImports();

  return (
    <>
      <SectionActions />
      <Flex>
        <LeftHalfLayout>
          <Panel header="FAQs">
            {section.faqs.length > 0 &&
              section.faqs.map((faq, key) => (
                <Flex xSpace mb key={key}>
                  <TextInput value={faq.name} disabled readonly />
                  <Flex center>
                    <PermissionFilter can="edit faqs">
                      <Button
                        variant="secondary"
                        as={Link}
                        to={`/faqs/${faq.id}/edit`}
                        icon="pencilAlt">
                        Edit
                      </Button>
                    </PermissionFilter>
                  </Flex>
                </Flex>
              ))}
            <PermissionFilter can="create faqs">
              <Box border="dashed">
                <Flex middle center>
                  <Button
                    variant="primary"
                    icon="plus"
                    as={Link}
                    to={`/faqs/create`}>
                    New FAQ
                  </Button>
                </Flex>
              </Box>
            </PermissionFilter>
          </Panel>
        </LeftHalfLayout>
      </Flex>
    </>
  );
};

export default Faqs;
