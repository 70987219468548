import React from 'react';
import PropTypes from 'prop-types';
import { styled } from 'twin.macro';

import { Flex } from '@ubisend/pulse-components';

import Bubble from './Bubble';

const Link = styled.a`
  color: ${props => props.theme.bot[props.direction].text};
`;

const File = ({ content, direction, ...props }) => (
  <Flex fat right={direction === 'recievedMessage'}>
    <Bubble direction={direction} {...props}>
      <Link
        target="blank"
        rel="noreferrer"
        href={content.url}
        direction={direction}>
        {content.text}
      </Link>
    </Bubble>
  </Flex>
);

File.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired,
  direction: Bubble.propTypes.direction
};

export default File;
