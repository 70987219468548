import React from 'react';
import tw, { styled } from 'twin.macro';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import githubSanitizeSchema from 'hast-util-sanitize/lib/github';
import remarkGfm from 'remark-gfm';

import tags from './Tags/index';

const Container = styled.div`
  ${tw`w-full`}
`;

const sanitizeSchema = {
  ...githubSanitizeSchema,
  tagNames: [
    ...githubSanitizeSchema.tagNames,
    // Support underlines that githubs schema does not.
    'u'
  ],
  attributes: {
    ...githubSanitizeSchema.attributes,
    // Support inline `style` tags for every element.
    '*': [...githubSanitizeSchema.attributes['*'], 'style']
  }
};

const Markdown = ({ children, ...props }) => (
  <Container>
    <ReactMarkdown
      rehypePlugins={[
        // Support HTML.
        rehypeRaw,
        // Sanitize any HTML using schema.
        [rehypeSanitize, sanitizeSchema]
      ]}
      remarkPlugins={[
        // Support tables.
        remarkGfm
      ]}
      components={tags}
      {...props}>
      {children}
    </ReactMarkdown>
  </Container>
);

export default Markdown;
export { tags, sanitizeSchema };
