import React from 'react';
import tw, { styled } from 'twin.macro';
import { useTheme } from 'styled-components';

import { Flex, Span } from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';

import { useCanvas } from '../hooks';
import IconWrapper from './IconWrapper';

const Container = styled.div`
  ${tw`absolute p-1 flex items-center z-20 w-auto rounded top-4 left-16 pl-2 py-1 box-border`}
  color: ${props => props.colours.white};
  background-color: ${props => props.colours.positive};
  height: 34px;
`;

const ClearSelectedNodes = () => {
  const { selectedNodes, setSelectedNodes } = useCanvas();
  const theme = useTheme();

  if (selectedNodes.length === 0) {
    return null;
  }

  return (
    <Container colours={theme}>
      <Flex fat between center xSpace>
        <Flex center between xSpaceSm>
          <Span>
            {selectedNodes.length} block
            {selectedNodes.length > 1 ? 's' : null} selected
          </Span>
          <IconWrapper
            onClick={() => {
              setSelectedNodes([]);
            }}>
            <Icon
              size="1.5rem"
              width="1.5rem"
              height="1.5rem"
              type="x"
              colour={theme.white}
            />
          </IconWrapper>
        </Flex>
      </Flex>
    </Container>
  );
};

export default ClearSelectedNodes;
