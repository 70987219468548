import React from 'react';

import { useQuery } from '@ubisend/pulse-hooks';

import { TeamsSettingContext } from '../Contexts/index';

const TeamsSettingsProvider = ({ children }) => {
  const { data, isSuccess, isLoading, refetch } = useQuery('teams/settings');

  const context = {
    settings: isSuccess ? data.data : null,
    refetch,
    isLoading,
    isSuccess
  };

  return (
    <TeamsSettingContext.Provider value={context}>
      {children}
    </TeamsSettingContext.Provider>
  );
};

export default TeamsSettingsProvider;
