import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useTooltip, { POSITIONS } from '../../hooks/useTooltip';
import TooltipPopup from '../Tooltip/TooltipPopup';
import Button from '../Button/Button';
import Flex from '../Layout/Flex';
import Divider from '../Spacing/Divider';

const FilterMenu = ({
  children,
  position = POSITIONS.BOTTOM,
  buttonProps = {},
  status,
  STATUSES,
  handleApply: passedHandleApply,
  handleReset: passedHandleReset,
  isValid = true
}) => {
  const [visible, setVisible] = useState(false);

  const handleApply = () => {
    passedHandleApply();
    setVisible(false);
  };

  const handleReset = () => {
    passedHandleReset();
    setVisible(false);
  };

  const handleSubmit = event => {
    event.preventDefault();

    handleApply();
  };

  const tooltip = useTooltip({
    visible,
    position,
    tooltip: (
      <form onSubmit={handleSubmit}>
        <Flex col>
          <Flex pl pr pt>
            {children}
          </Flex>
          <Divider />
          <Flex right xSpace px pr pb>
            <Button
              disabled={status === STATUSES.CLEAN}
              variant="secondary"
              colour="danger"
              icon="x"
              onClick={handleReset}>
              Reset
            </Button>
            <Button
              variant="primary"
              icon="check"
              type="sumbit"
              data-testid="apply-filter"
              disabled={status !== STATUSES.DIRTY || !isValid}>
              Apply
            </Button>
          </Flex>
        </Flex>
      </form>
    )
  });

  const handleClick = () => {
    if (visible) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  };

  const colours = {
    [STATUSES.DIRTY]: 'primary',
    [STATUSES.CLEAN]: 'primary',
    [STATUSES.SUBMITTED]: 'positive'
  };

  const icons = {
    [STATUSES.DIRTY]: 'filter',
    [STATUSES.CLEAN]: 'filter',
    [STATUSES.SUBMITTED]: 'check'
  };

  return (
    <Flex relative left ref={tooltip.ref}>
      <Button
        colour={colours[status]}
        icon={icons[status]}
        variant="secondary"
        onClick={handleClick}
        aria-label="Toggle filter menu"
        {...buttonProps}>
        Filter
      </Button>
      <TooltipPopup {...tooltip.props} />
    </Flex>
  );
};

FilterMenu.propTypes = {
  position: PropTypes.oneOf(Object.values(POSITIONS)),
  status: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  STATUSES: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  buttonProps: PropTypes.object,
  handleReset: PropTypes.func.isRequired,
  handleApply: PropTypes.func.isRequired,
  isValid: PropTypes.bool
};
FilterMenu.POSITIONS = POSITIONS;

export default FilterMenu;
