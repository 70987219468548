import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  Span,
  Tag,
  Button,
  Label,
  InnerPanel
} from '@ubisend/pulse-components';
import { TemplatingTextInput } from '@ubisend/pulse-templating';

const TYPES = {
  ADD_ROW: 'ADD_ROW',
  DELETE_ROW: 'DELETE_ROW',
  UPDATE_TRIGGER_POSTBACK_DATETIME: 'UPDATE_TRIGGER_POSTBACK_DATETIME',
  UPDATE_TRIGGER_POSTBACK_CALLBACK: 'UPDATE_TRIGGER_POSTBACK_CALLBACK'
};

const reducer = (state, { type, ...params }) => {
  const updateTriggerPostback = callback => {
    const updateRow = {
      rows: state.rows.map(triggerPostback => {
        if (triggerPostback.id === params.id) {
          return {
            ...triggerPostback,
            ...callback(triggerPostback)
          };
        }

        return triggerPostback;
      })
    };
    params.onChange(updateRow);
    return updateRow;
  };

  switch (type) {
    case TYPES.ADD_ROW: {
      const addRow = {
        rows: state.rows.concat({
          id: Math.random(),
          triggerPostbackDateTime: '',
          triggerPostbackCallback: ''
        })
      };
      params.onChange(addRow);
      return addRow;
    }
    case TYPES.DELETE_ROW: {
      const deleteRow = {
        rows: state.rows.filter(rows => rows.id !== params.id)
      };
      params.onChange(deleteRow);
      return deleteRow;
    }
    case TYPES.UPDATE_TRIGGER_POSTBACK_DATETIME:
      return updateTriggerPostback(() => {
        return { triggerPostbackDateTime: params.triggerPostbackDateTime };
      });
    case TYPES.UPDATE_TRIGGER_POSTBACK_CALLBACK:
      return updateTriggerPostback(() => {
        return { triggerPostbackCallback: params.triggerPostbackCallback };
      });
    default:
      throw new Error(
        `No event defined in trigger postback content reducer for ${type}`
      );
  }
};

const TriggerPostbackContent = ({ content, onChange }) => {
  const [contents, dispatch] = useReducer(reducer, content);

  const handleTriggerPostbackCallbackChange = id => value => {
    dispatch({
      type: TYPES.UPDATE_TRIGGER_POSTBACK_CALLBACK,
      id,
      triggerPostbackCallback: value,
      onChange: onChange
    });
  };

  const handleTriggerPostbackDateTimeChange = id => value => {
    dispatch({
      type: TYPES.UPDATE_TRIGGER_POSTBACK_DATETIME,
      id,
      triggerPostbackDateTime: value,
      onChange: onChange
    });
  };

  const handleNewRow = () => {
    dispatch({ type: TYPES.ADD_ROW, onChange: onChange });
  };

  const handleRowDelete = id => () => {
    dispatch({ type: TYPES.DELETE_ROW, id, onChange: onChange });
  };

  return (
    <Flex xSpace fat top>
      <Flex ptSm>
        <Tag background="warning" colour="warning">
          Beta
        </Tag>
      </Flex>
      {contents.rows.length === 0 && (
        <Span light mb>
          No trigger postback set
        </Span>
      )}
      {contents.rows.length > 0 && (
        <InnerPanel>
          {contents.rows.map((rows, index) => (
            <Flex key={rows.id} mb xSpace bottom>
              <Flex col fat>
                {index === 0 && <Label>trigger</Label>}
                <TemplatingTextInput
                  aria-label="Trigger"
                  placeholder="Trigger"
                  value={rows.triggerPostbackCallback}
                  onChange={handleTriggerPostbackCallbackChange(rows.id)}
                />
              </Flex>
              <Flex col fat>
                {index === 0 && <Label>at date time</Label>}
                <TemplatingTextInput
                  aria-label="Date time scheduled"
                  placeholder="Date time scheduled"
                  value={rows.triggerPostbackDateTime}
                  onChange={handleTriggerPostbackDateTimeChange(rows.id)}
                />
              </Flex>
              <Flex bottom>
                <Button
                  aria-label="Delete rows"
                  icon="trash"
                  colour="danger"
                  onClick={handleRowDelete(rows.id)}
                  disabled={contents.rows.length === 1}
                />
              </Flex>
            </Flex>
          ))}
          <Flex left>
            <Button icon="plus" onClick={handleNewRow}>
              Add
            </Button>
          </Flex>
        </InnerPanel>
      )}
    </Flex>
  );
};

TriggerPostbackContent.propTypes = {
  onChange: PropTypes.func.isRequired,
  content: PropTypes.shape({
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        triggerPostbackDateTime: PropTypes.string.isRequired,
        triggerPostbackCallback: PropTypes.string.isRequired
      })
    )
  })
};

export default TriggerPostbackContent;
