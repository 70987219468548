import tw, { styled } from 'twin.macro';

import { spacing, text } from '../styles';

const Paragraph = styled.p`
  ${tw`m-0`}
  ${({ secondary = false, theme }) => secondary && `color: ${theme.grey};`}
  ${props => props.sm && tw`text-sm`}
  ${spacing}
  ${text}
  ${tw`whitespace-pre-line`}
`;

export default Paragraph;
