import tw, { styled } from 'twin.macro';

import { motion } from '@ubisend/framer-motion';

import { spacing, text } from '../styles';

const Heading3 = styled(motion.h3)`
  ${tw`m-0 font-semibold`}
  font-size:1.125rem;
  ${spacing}
  ${text}
`;

export default Heading3;
