import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, Flex } from '@ubisend/pulse-components';
import { TemplatingTextInput } from '@ubisend/pulse-templating';

import { useButtons } from '../../../../hooks/index';
import ExpiresTooltip from '../../../ExpiresTooltip';

const PostbackInput = ({ content, index }) => {
  const { handleButtonChange } = useButtons();

  return (
    <Flex col ySpace fat>
      <TemplatingTextInput
        aria-label={`postback-label-${index + 1}`}
        value={content.label}
        placeholder="Annual Leave"
        onChange={value => {
          handleButtonChange(index, 'label', value);
        }}
      />
      <TemplatingTextInput
        aria-label={`postback-action-${index + 1}`}
        value={content.action}
        placeholder="What is the annual leave policy?"
        onChange={value => {
          handleButtonChange(index, 'action', value);
        }}
      />
      <Flex center>
        <Checkbox
          aria-label={`postback-expires-${index + 1}`}
          checked={content.expires}
          onChange={() => {
            handleButtonChange(index, 'expires', !content.expires);
          }}
          label="Disable on reply"
        />
        <ExpiresTooltip />
      </Flex>
    </Flex>
  );
};

PostbackInput.propTypes = {
  content: PropTypes.shape({
    label: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    expires: PropTypes.bool
  }),
  index: PropTypes.number.isRequired
};

export default PostbackInput;
