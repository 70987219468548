import axios from 'axios';

import useDelivery from './useDelivery';
import useAuth from './useAuth';

const useAxios = (options = {}) => {
  const {
    baseUrl: passedBaseUrl,
    clientId: passedClientId,
    token: passedToken,
    browserUrl: passedBrowserUrl,
    setting: passedSetting,
    variables: passedVariables,
    channelDriverName: passedChannelDriverName,
    params = {},
    ...restOfOptions
  } = options;

  const {
    base_url: defaultBaseUrl,
    client_id: defaultClientId,
    browserUrl: defaultBrowserUrl,
    setting: defaultSetting,
    variables: defaultVariables,
    channelDriverName: defaultChannelDriverName
  } = useDelivery();
  const { token: defaultToken } = useAuth();

  const instance = axios.create({
    baseURL: passedBaseUrl || `${defaultBaseUrl}/bot`,
    params: {
      client_id: passedClientId || defaultClientId,
      token: passedToken || defaultToken,
      browser_url: passedBrowserUrl || defaultBrowserUrl,
      setting: passedSetting || defaultSetting,
      variables: passedVariables || defaultVariables,
      channel_driver_name: passedChannelDriverName || defaultChannelDriverName,
      ...params
    },
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    ...restOfOptions
  });

  return instance;
};

export default useAxios;
