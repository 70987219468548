import React from 'react';
import tw, { styled } from 'twin.macro';

import OneHalfLayout from './OneHalfLayout';

const Spacer = styled.div`
  ${tw`mr-4`}
`;

const LeftHalfLayout = ({ children }) => (
  <OneHalfLayout>
    <Spacer>{children}</Spacer>
  </OneHalfLayout>
);

export default LeftHalfLayout;
