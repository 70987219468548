import React from 'react';

import { useAuth } from '@ubisend/pulse-auth';
import { useModal, useNotification, Button } from '@ubisend/pulse-components';

import { exportFaqs } from '../../api/index';

const ExportFaqs = () => {
  const { user: loggedInUser } = useAuth();
  const { showModal, hideModal } = useModal();
  const { showSuccess } = useNotification();

  const handleExport = () => {
    showModal({
      header: 'Export FAQs',
      body: `Are you sure you want to export all of your FAQs? You will receive an email at "${loggedInUser.email}" with a link to download a spreadsheet containing your FAQs and Questions.`,
      handleConfirm: async () => {
        await exportFaqs();
        showSuccess(
          'Successfully requested an FAQ export. Keep an eye on your emails.'
        );
        hideModal();
      }
    });
  };

  return (
    <Button variant="secondary" onClick={handleExport} icon="arrowUpOnSquare">
      Export FAQs
    </Button>
  );
};

export default ExportFaqs;
