import tw, { styled } from 'twin.macro';

const BlurPanel = styled.div`
  ${tw`flex justify-between items-center h-16 md:h-24 p-4 md:p-8 `}
  background: rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  & > * {
    ${tw`m-0 bg-transparent`}
  }
  & > div {
    ${tw`w-full`}
  }
`;

export default BlurPanel;
