import tw, { styled } from 'twin.macro';
import chroma from 'chroma-js';

const SidePanel = styled.div`
  ${tw`w-64 p-6 flex flex-col items-center overflow-y-auto`}
  height: calc(100% - 130px);
  border-right: 1px solid ${props => chroma(props.theme.grey).alpha(0.25).css()};
  > * {
    ${tw`mb-2`}
  }
`;

export default SidePanel;
