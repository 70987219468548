import React from 'react';

import { Tooltip } from '@ubisend/pulse-components';

const ExpiresTooltip = () => (
  <Tooltip
    position={Tooltip.POSITIONS.RIGHT}
    tooltip={
      <Tooltip.Content>
        When ticked, this button will be disabled after a reply from the user.
      </Tooltip.Content>
    }>
    <Tooltip.Icon>?</Tooltip.Icon>
  </Tooltip>
);

export default ExpiresTooltip;
