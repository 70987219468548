import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';

import { CreatableSelect as Select } from '@ubisend/pulse-components';

const formatLink = link => ({
  label: link.name || link.url,
  value: `${link.tracked_url}?subscriber_id={{ subscriber.identifier }}`
});

const UrlSelect = ({ value, onChange, ...props }) => {
  const [options, setOptions] = useState(null);

  useQuery('links/all', {
    onSuccess: ({ data }) => {
      const trackedLinks = data.map(formatLink);

      // If the user has a manual link (not tracked) add it as a new option group.
      const regularLinks =
        value && !trackedLinks.find(option => option.value === value)
          ? [{ label: value, value }]
          : [];

      setOptions([
        { label: 'Tracked links', options: trackedLinks },
        { label: 'Regular links', options: regularLinks }
      ]);
    }
  });

  const formatCreateLabel = input => input;

  const onCreateOption = input => {
    const option = { label: input, value: input };
    setOptions(options => [
      options[0],
      {
        ...options[1],
        options: options[1].options.concat(option)
      }
    ]);
    onChange(option);
  };

  const formattedValue = useMemo(() => {
    if (!options || !value) {
      return null;
    }

    const option = options.find(group => {
      return group.options.find(option => option.value === value);
    });

    if (!option) {
      return null;
    }

    return option.options.find(option => option.value === value);
  }, [options, value]);

  return (
    <Select
      {...props}
      onChange={onChange}
      isLoading={!options}
      value={formattedValue}
      options={options ? options : []}
      isCreatable
      formatCreateLabel={formatCreateLabel}
      onCreateOption={onCreateOption}
    />
  );
};

UrlSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default UrlSelect;
