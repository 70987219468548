import tw, { styled } from 'twin.macro';

import Footer, { animations } from './Footer';

const FixedFooter = styled(Footer)`
  box-sizing: border-box;
  width: calc(100vw - 12rem);
  ${tw`fixed bottom-0 right-0 z-20`}
`;

FixedFooter.defaultProps = {
  ...animations
};

export default FixedFooter;
