import React from 'react';
import { styled } from 'twin.macro';
import dayjs from 'dayjs';

import { useQueryClient, useQuery, useMutation } from '@ubisend/pulse-hooks';
import {
  PageWrapper,
  StretchPanel,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableActions,
  useModal,
  useNotification,
  Tag,
  Flex,
  Pagination,
  usePaginationReducer,
  OrderableTableRow,
  useOrderableTableReducer,
  Button
} from '@ubisend/pulse-components';
import { useAuth, hasGlobalRole } from '@ubisend/pulse-auth';

import {
  deleteUser as deleteUserApi,
  promoteUser as promoteUserApi
} from '../api/index';

const TagTableCell = styled(TableCell)`
  max-width: 15vw;
  white-space: nowrap;
  overflow-y: auto;
`;

const Users = () => {
  const { user: loggedInUser } = useAuth();
  const { showModal, hideModal } = useModal();
  const { showSuccess } = useNotification();

  const pagination = usePaginationReducer({ id: 'all-users' });
  const order = useOrderableTableReducer({ id: 'all-users' });

  const queryClient = useQueryClient();
  const query = useQuery([
    'owner/users',
    { ...pagination.params, ...order.params }
  ]);
  const deleteUser = useMutation(deleteUserApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('owner/users');
      showSuccess(`User was successfully deleted.`);
    }
  });
  const promoteUser = useMutation(promoteUserApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('owner/users');
      showSuccess(`User was successfully promoted to an Owner.`);
    }
  });

  const handleDelete = user => {
    showModal({
      header: 'Delete User',
      body: `Are you sure you want to permanently delete ${user.first_name} ${user.last_name} (${user.email})?`,
      handleConfirm: async () => {
        try {
          await deleteUser.mutateAsync(user.id);
        } finally {
          hideModal();
        }
      }
    });
  };

  const handleOwnerPromotion = user => {
    promoteUser.mutate(user.id);
  };

  return (
    <PageWrapper header="Users" subheader="Manage users and permissions">
      <StretchPanel clip>
        <Table loading={query.isLoading} loadingColumns={5}>
          <TableHead>
            <OrderableTableRow
              rows={[
                { label: 'Name', sort: null },
                { label: 'Email', sort: 'email' },
                { label: 'Joined', sort: 'created_at' },
                { label: 'Global roles', sort: null },
                null
              ]}
              {...order.props}
            />
          </TableHead>
          {query.isSuccess && (
            <TableBody>
              {query.data.data.map((user, key) => (
                <TableRow key={key}>
                  <TableCell>{`${user.first_name} ${user.last_name}`}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{dayjs(user.created_at).from(dayjs())}</TableCell>
                  <TagTableCell>
                    <Flex xSpaceSm xScroll>
                      {user.global_roles.length > 0
                        ? user.global_roles.map(role => (
                            <Tag key={role.id}>{role.name}</Tag>
                          ))
                        : 'N/A'}
                    </Flex>
                  </TagTableCell>
                  <TableActions>
                    <Button
                      variant="secondary"
                      loading={promoteUser.isLoading}
                      disabled={hasGlobalRole('owner')({ user })}
                      onClick={() => handleOwnerPromotion(user)}>
                      Promote to owner
                    </Button>
                    <Button
                      colour="danger"
                      variant="secondary"
                      icon="trash"
                      disabled={
                        hasGlobalRole('owner')({ user }) ||
                        user.id === loggedInUser.id
                      }
                      onClick={() => handleDelete(user)}>
                      Delete
                    </Button>
                  </TableActions>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {query.showPagination && (
          <Pagination pagination={query.data.meta} {...pagination.props} />
        )}
      </StretchPanel>
    </PageWrapper>
  );
};

export default Users;
