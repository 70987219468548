import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  Heading2,
  Label,
  LeftHalfLayout,
  FormGroup,
  RightHalfLayout,
  Flex,
  Panel,
  TextInput,
  Button,
  FormHeading,
  Divider,
  Span
} from '@ubisend/pulse-components';
import { TopSection } from '@ubisend/pulse-tickets';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { getName } from '../LogName';
import LogLevel from '../LogLevel';
import LogDescription from '../LogDescription';

const AutomatedContent = ({
  subscriber,
  created_at,
  description,
  content,
  level,
  type
}) => (
  <Panel>
    <Flex col mb>
      <TopSection center>
        <LogLevel level={level} />
        <Heading2>{getName({ level, type })}</Heading2>
      </TopSection>
      <Label secondary>
        {dayjs(created_at).from(dayjs())} by{' '}
        {subscriber ? `Subscriber #${subscriber.id}` : 'N/A'}
      </Label>
    </Flex>
    <Flex>
      {!subscriber || !content ? (
        <LogDescription description={description} />
      ) : (
        <>
          <LeftHalfLayout>
            <LogDescription description={description} />
          </LeftHalfLayout>
          <RightHalfLayout>
            <FormGroup>
              <Label>Message</Label>
              {content.message ? (
                <Flex xSpace>
                  <TextInput value={content.message} disabled />
                  <PermissionFilter can="view messages">
                    <Button
                      as={Link}
                      variant="secondary"
                      icon="eye"
                      to={`/conversations/${subscriber.id}`}>
                      View
                    </Button>
                  </PermissionFilter>
                </Flex>
              ) : (
                <Span tinyText>No messsage sent</Span>
              )}
            </FormGroup>
            {content.arrival && (
              <>
                <Divider />
                <FormHeading>Arrival</FormHeading>
                <FormGroup>
                  <Label>Conversation</Label>
                  <Flex xSpace>
                    <TextInput value={content.arrival.title} disabled />
                    <PermissionFilter can="view conversations">
                      <Button
                        as={Link}
                        variant="secondary"
                        icon="eye"
                        to={`/builder/${content.arrival.id}`}>
                        View
                      </Button>
                    </PermissionFilter>
                  </Flex>
                </FormGroup>
                <Flex col mb={Boolean(content.destination)}>
                  <Label>Step</Label>
                  <Flex xSpace>
                    <TextInput value={content.arrival.step.title} disabled />
                    <PermissionFilter can="view conversations">
                      <Button
                        as={Link}
                        variant="secondary"
                        icon="eye"
                        to={`/builder/${content.arrival.id}`}>
                        View
                      </Button>
                    </PermissionFilter>
                  </Flex>
                </Flex>
              </>
            )}
            {content.destination && (
              <>
                <Divider />
                <FormHeading>Destination</FormHeading>
                <FormGroup>
                  <Label>Conversation</Label>
                  <Flex xSpace>
                    <TextInput value={content.destination.title} disabled />
                    <PermissionFilter can="view conversations">
                      <Button
                        as={Link}
                        variant="secondary"
                        icon="eye"
                        to={`/builder/${content.destination.id}`}>
                        View
                      </Button>
                    </PermissionFilter>
                  </Flex>
                </FormGroup>
                <FormGroup>
                  <Label>Step</Label>
                  <Flex xSpace>
                    <TextInput
                      value={content.destination.step.title}
                      disabled
                    />
                    <PermissionFilter can="view conversations">
                      <Button
                        as={Link}
                        variant="secondary"
                        icon="eye"
                        to={`/builder/${content.destination.id}`}>
                        View
                      </Button>
                    </PermissionFilter>
                  </Flex>
                </FormGroup>
                <div>
                  <Label>Transition</Label>
                  <Flex xSpace>
                    <TextInput
                      value={content.transition.description}
                      disabled
                    />
                  </Flex>
                </div>
              </>
            )}
          </RightHalfLayout>
        </>
      )}
    </Flex>
  </Panel>
);

const conversationType = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  step: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string
  })
});

AutomatedContent.propTypes = {
  subscriber: PropTypes.shape({
    id: PropTypes.number
  }),
  created_at: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  content: PropTypes.shape({
    message: PropTypes.string,
    arrival: conversationType,
    destination: conversationType,
    transition: PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string
    })
  })
};

export default AutomatedContent;
