import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { createFilter } from 'react-select';

import {
  Select,
  CreatableSelect,
  Button,
  Label,
  Flex,
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCancel,
  ModalBody
} from '@ubisend/pulse-components';

import { useTemplating } from '../../hooks/index';
import FilterInput from './FilterInput';
import GroupInput from './GroupInput';
import allGroups from './Groups/index';

const groups = [
  allGroups.integration,
  allGroups.lookup,
  allGroups.custom,
  allGroups.created
];

const VariableModal = ({ filters }) => {
  const [variables, setVariables] = useState(null);
  const [variable, setVariable] = useState({
    key: null,
    variable: null,
    content: null
  });
  const [filter, setFilter] = useState({
    name: null,
    content: null
  });

  const { dispatch, TYPES } = useTemplating();

  useQuery('template-variables', {
    onSuccess: data => {
      setVariables(
        data.concat({
          key: allGroups.created.key,
          name: 'Created variables',
          variables: []
        })
      );
    }
  });

  const getGroup = key => {
    return groups.find(group => group.key === key) || allGroups.default;
  };

  const getFilter = name => {
    return filters.find(filter => filter.name === name);
  };

  const isValid = () => {
    const passes = Boolean(
      variable.key &&
        variable.variable &&
        getGroup(variable.key).valid(variable.content)
    );

    if (filter.name) {
      return passes && getFilter(filter.name).valid(filter.content);
    }

    return passes;
  };

  const getFullVariable = () => {
    const value = getGroup(variable.key).format(variable);

    if (!filter.name) {
      return value;
    }

    return getFilter(filter.name).format(value, filter.content);
  };

  const handleOptionCreate = value => {
    setVariables(groups => {
      return groups.map(group => {
        if (group.key === allGroups.created.key) {
          return {
            ...group,
            variables: group.variables.concat(value)
          };
        }

        return group;
      });
    });
    setVariable({
      key: allGroups.created.key,
      variable: value,
      content: getGroup(allGroups.created.key).content
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    if (isValid()) {
      dispatch({ type: TYPES.ADD_VARIABLE, variable: getFullVariable() });
    }
  };

  const handleClose = () => {
    dispatch({ type: TYPES.HIDE_VARIABLES });
  };

  const handleVariableChange = option => {
    setVariable({
      key: option.value.key,
      variable: option.value.variable,
      content: getGroup(option.value.key).content
    });
  };

  const handleGroupContentChange = content => {
    setVariable(variable => ({
      key: variable.key,
      variable: variable.variable,
      content: { ...variable.content, ...content }
    }));
  };

  const handleFilterNameChange = option => {
    if (!option) {
      return setFilter({ name: null, content: null });
    }

    setFilter({ name: option.value, content: getFilter(option.value).content });
  };

  const handleFilterContentChange = content => {
    setFilter(filter => ({
      name: filter.name,
      content: { ...filter.content, ...content }
    }));
  };

  const formatFilter = filter => {
    return {
      label: filter.name,
      value: filter.name
    };
  };

  const formatVariableGroup = group => {
    return {
      label: group.name,
      options: group.variables.map(variable => {
        return {
          label: variable,
          value: { variable, key: group.key }
        };
      })
    };
  };

  return (
    <ModalPortal>
      <form onSubmit={handleSubmit}>
        <ModalContainer>
          <ModalContent style={{ width: '100%', maxWidth: '50rem' }}>
            <ModalHeader>Variables</ModalHeader>
            <ModalBody>
              <Flex col ySpace>
                <Flex col>
                  <Label htmlFor="variable">Variable</Label>
                  {/* eslint-disable jsx-a11y/no-autofocus */}
                  <CreatableSelect
                    id="variable"
                    isLoading={!variables}
                    autoFocus
                    defaultMenuIsOpen
                    placeholder="Start typing to filter options..."
                    options={
                      variables ? variables.map(formatVariableGroup) : []
                    }
                    value={
                      variable.variable
                        ? {
                            label: variable.variable,
                            value: {
                              variable: variable.variable,
                              key: variable.key
                            }
                          }
                        : null
                    }
                    onChange={handleVariableChange}
                    filterOption={createFilter({
                      stringify: option => {
                        if (option.label.slice(0, 8) === 'Create "') {
                          return `${option.value} ${option.label}`;
                        }

                        return `${option.value.key} ${option.value.variable}`;
                      }
                    })}
                    isOptionSelected={option => {
                      if (!variable.key) {
                        return false;
                      }

                      return (
                        variable.key === option.value.key &&
                        variable.variable === option.value.variable
                      );
                    }}
                    onCreateOption={handleOptionCreate}
                  />
                  {/* eslint-enable jsx-a11y/no-autofocus */}
                </Flex>
                {Boolean(variable.key) && (
                  <GroupInput
                    variable={variable}
                    groups={groups}
                    defaultGroup={allGroups.default}
                    onChange={handleGroupContentChange}
                  />
                )}
                {filters.length > 0 && (
                  <>
                    <Flex col>
                      <Label htmlFor="formatter">Formatter</Label>
                      <Select
                        isLoading={!variables}
                        id="formatter"
                        isClearable
                        options={filters.map(formatFilter)}
                        value={
                          filter.name
                            ? formatFilter(
                                filters.find(({ name }) => name === filter.name)
                              )
                            : null
                        }
                        onChange={handleFilterNameChange}
                      />
                    </Flex>
                    {filter.name && (
                      <FilterInput
                        filter={filter}
                        filters={filters}
                        onChange={handleFilterContentChange}
                      />
                    )}
                  </>
                )}
              </Flex>
            </ModalBody>
            <ModalFooter>
              <ModalCancel onClick={handleClose}>Close</ModalCancel>
              <Button type="submit" disabled={!isValid()}>
                Choose
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalContainer>
      </form>
    </ModalPortal>
  );
};

VariableModal.propTypes = {
  initialVariables: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired
        })
      ).isRequired
    })
  ).isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ).isRequired
};

export default VariableModal;
