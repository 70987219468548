import DateModifyInput from './DateModifyInput';

const date = {
  name: 'Date Modify',
  Input: DateModifyInput,
  content: {
    data: [
      {
        dateModifyType: '',
        dateModifyValue: '',
        dateModifyPeriod: '',
        dateModifyRange: ''
      }
    ]
  },
  format: (variable, content) => {
    const exactText = {
      year: 'Y',
      month: 'm',
      day: 'd',
      hour: 'H',
      minute: 'i'
    };
    let dynamicText = '';

    content.data.map(data => {
      const {
        dateModifyType,
        dateModifyValue,
        dateModifyPeriod,
        dateModifyRange
      } = data;
      if (parseInt(dateModifyValue) && dateModifyPeriod) {
        if (dateModifyType === 'dynamic') {
          dynamicText +=
            (dynamicText ? ` ` : ``) +
            `${dateModifyRange}${dateModifyValue} ${dateModifyPeriod}`;
        } else if (
          dateModifyType === 'exact' &&
          parseInt(dateModifyValue) > 0
        ) {
          exactText[dateModifyPeriod] = parseInt(dateModifyValue);
        }
      }
    });

    return (
      `${variable}` +
      (dynamicText ? `|date_modify("${dynamicText}")` : '') +
      `|date("${exactText['year']}-${exactText['month']}-${exactText['day']} ${exactText['hour']}:${exactText['minute']}")`
    );
  },
  valid: content => content.data.length > 0
};
export default date;
