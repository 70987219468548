import tw, { styled } from 'twin.macro';

import { spacing } from '../styles';

const SubHeader = styled.h2`
  ${tw`m-0`}
  ${spacing}
`;

export default SubHeader;
