import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useQuery } from '../../../../hooks/index';
import Content from './Content';

const Section = ({ sectionId }) => {
  const history = useHistory();

  const [content, setContent] = useState({
    title: null,
    markdown: null,
    isFailure: false
  });

  const { isLoading, isSuccess, refetch, isRefetching } = useQuery(
    `sections/${sectionId}`,
    {
      onSuccess: data =>
        setContent({ title: data.data.title, markdown: data.data.content })
    }
  );

  if (!isLoading && !isSuccess && !content.isFailure) {
    setContent({
      title: 'Uh oh...',
      markdown: 'Something went wrong, please try again later.',
      isFailure: true
    });
  }

  const handleHistory = () => history.goBack();

  useEffect(() => {
    if (isSuccess && !content.markdown) {
      refetch();
    }
  }, [isSuccess, content, refetch]);

  return (
    <Content
      content={{ content }}
      isLoading={isLoading || isRefetching}
      isLinkedFromAnotherSection
      backArrowCallback={handleHistory}
    />
  );
};

Section.propTypes = {
  sectionId: PropTypes.string.isRequired
};

export default Section;
