import ReplaceInput from './ReplaceInput';

const replace = {
  name: 'Replace',
  Input: ReplaceInput,
  content: {
    data: [
      {
        search: '',
        replace: ''
      }
    ]
  },
  format: (variable, content) => {
    let text = '';
    content.data.map((data, index) => {
      const { search, replace } = data;
      // skip if the search is empty (exclude single white space)
      search
        ? (text +=
            (index !== 0 && text ? `,` : ``) +
            `'` +
            search +
            `':'` +
            replace +
            `'`)
        : '';
    });
    return `${variable}|replace({${text}})`;
  },
  // first value of search should not empty
  valid: content => content.data[0].search.length > 0
};

export default replace;
