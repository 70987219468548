import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Panel, FormGroup, TextInput, Label } from '@ubisend/pulse-components';
import { WysiwygEditor } from '@ubisend/pulse-edit';

import EditorActions from '../../EditorActions';

const WebsiteImportEditor = ({ content: initialContent, imports }) => {
  const [name, setName] = useState(initialContent ? initialContent.name : '');
  const [content, setContent] = useState(
    initialContent ? initialContent.content : ''
  );

  const handleNameChange = event => {
    setName(event.target.value);
  };

  const handleContentChange = useCallback(content => {
    setContent(content);
  }, []);

  return (
    <Panel>
      <FormGroup>
        <Label htmlFor="name">Section name</Label>
        <TextInput id="name" value={name} onChange={handleNameChange} />
      </FormGroup>
      <FormGroup>
        <WysiwygEditor
          value={content}
          imports={imports}
          onChange={handleContentChange}
          aria-label="Section content"
        />
      </FormGroup>
      <EditorActions
        content={{ name, content }}
        valid={Boolean(name && content)}
      />
    </Panel>
  );
};

WebsiteImportEditor.propTypes = {
  content: PropTypes.shape({
    name: PropTypes.string,
    content: PropTypes.string
  }).isRequired,
  imports: PropTypes.array
};
export default WebsiteImportEditor;
