import React from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalClose,
  Label,
  FormGroup,
  Button,
  Accordion
} from '@ubisend/pulse-components';

import StepDetails from './StepDetails';
import AdvancedDetails from './AdvancedDetails';
import VariableDetails from './VariableDetails';
import { useTriggerStep } from '../../hooks/index';

const TriggerStepModal = ({ handleClose, handleSubmit, loading }) => {
  const { form, valid } = useTriggerStep();

  const onSubmit = event => {
    event.preventDefault();

    handleSubmit(form);
  };

  return (
    <ModalPortal>
      <ModalContainer>
        <ModalContent
          style={{
            maxHeight: '40rem',
            overflowY: 'auto'
          }}>
          <ModalHeader>
            <Label mb={false}>Trigger step</Label>
            <ModalClose onClick={handleClose} />
          </ModalHeader>
          <form onSubmit={onSubmit}>
            <ModalBody>
              <StepDetails />
              <FormGroup>
                <Accordion header="Advanced">
                  <AdvancedDetails />
                </Accordion>
              </FormGroup>
              <FormGroup>
                <Accordion header="Variables">
                  <VariableDetails />
                </Accordion>
              </FormGroup>
              <Flex right>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={!valid || loading}>
                  Trigger
                </Button>
              </Flex>
            </ModalBody>
          </form>
        </ModalContent>
      </ModalContainer>
    </ModalPortal>
  );
};

TriggerStepModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default TriggerStepModal;
