import React, { useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Select } from '@ubisend/pulse-components';

const SectionSelect = ({ value, onChange, useCachedImports, ...props }) => {
  const [options, setOptions] = useState(null);

  const { data, isLoading } = useQuery('imports', {
    enabled: !useCachedImports // If no imports handed down to component, grab them fresh.
  });

  const handleSetOptions = useCallback(() => {
    if (!data?.data || data?.data.length === 0) {
      return [];
    }

    setOptions(
      data.data.map(imprt => {
        return {
          label: imprt.name,
          options: imprt.sections.map(section => {
            return {
              label: section.content.name,
              value: section.unique_id
            };
          })
        };
      })
    );
  }, [data]);

  useEffect(() => {
    if (!isLoading && !options) {
      handleSetOptions();
    }
  }, [isLoading, options, handleSetOptions]);

  const formattedValue = useMemo(() => {
    if (!options || !value) {
      return null;
    }

    const option = options.find(group => {
      return group.options.find(option => option.value === value);
    });

    if (!option) {
      return null;
    }

    return option.options.find(option => option.value === value);
  }, [options, value]);

  return (
    <Select
      {...props}
      onChange={onChange}
      isLoading={isLoading}
      value={formattedValue}
      options={options}
    />
  );
};

SectionSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  useCachedImports: PropTypes.bool.isRequired
};

export default SectionSelect;
