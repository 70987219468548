import tw, { styled } from 'twin.macro';

const ArrowBall = styled.div`
  ${tw`z-30`}
  margin-top: -0.375rem;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #888888;
`;

export default ArrowBall;
