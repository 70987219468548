import React, { useMemo } from 'react';

import { message } from '../../../types/index';
import {
  Payment,
  ViewMessage,
  QuickReply,
  Standard,
  Action,
  General,
  Carousel,
  Progress,
  Image,
  Video,
  Event,
  Dynamic,
  File
} from './ResponseTypes/index';
import TypingIndicator from './TypingIndicator';

/**
 * The component to render for each type of Message.
 * The default is Standard.
 */
const types = {
  standard: Standard,
  action: Action,
  'quick-reply': QuickReply,
  general: General,
  carousel: Carousel,
  image: Image,
  video: Video,
  content: ViewMessage,
  embed: ViewMessage,
  payment: Payment,
  loading: TypingIndicator,
  progress: Progress,
  event: Event,
  dynamic: Dynamic,
  file: File
};

const defaultFilter = ({ message, handleButtonClick, label, hasHadReply }) => {
  return {
    'aria-label': label,
    role: 'section',
    type: message.type,
    composer: message.composer,
    content: message.content,
    handleButtonClick,
    direction:
      message.direction === 'toClient' ? 'sentMessage' : 'recievedMessage',
    hasHadReply: hasHadReply
  };
};

const filterQuickReply = props => {
  return {
    ...defaultFilter(props),
    // Only show quick replies if it's part of the last message group.
    showResponses: props.groupIndex === props.groups.length - 1
  };
};

const filterProgress = props => {
  return {
    ...defaultFilter(props),
    // Only show loading if it's part of the last message group.
    isLoading: props.groupIndex === props.groups.length - 1
  };
};

const filterEmbed = props => {
  return {
    ...defaultFilter(props),
    id: props.message.id
  };
};

const filterContent = props => {
  return {
    ...defaultFilter(props),
    id: props.message.id
  };
};

/**
 * Prop filters to handle passing unique props to specific
 * messages types.
 */
const filters = {
  'quick-reply': filterQuickReply,
  progress: filterProgress,
  embed: filterEmbed,
  content: filterContent
};

const filterProps = props => {
  const filter = filters[props.message.type] || defaultFilter;

  return filter(props);
};

const Message = props => {
  const Component = useMemo(() => types[props.message.type] || Standard, [
    props.message.type
  ]);

  return <Component {...filterProps(props)} />;
};

Message.propTypes = message;

export default Message;
