import React from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  Panel,
  Button,
  InnerPanel,
  Label,
  SubHeader
} from '@ubisend/pulse-components';

import LoginButton from './LoginButton';
import { TEAMS_APP_ID } from '../constants';

const ExistingSettings = ({ settings, toggleEdit }) => {
  return (
    <Panel header="Update Microsoft Teams settings">
      <Flex col ySpace mt>
        <InnerPanel>
          <Flex center>
            <Flex col ySpace>
              <Label>Connected with tenant ID: </Label>
              <SubHeader>{settings}</SubHeader>
            </Flex>
          </Flex>
        </InnerPanel>
        <Flex xSpace right>
          <Button onClick={toggleEdit}>Change Tenant ID</Button>
          <Button
            icon="download"
            variant="secondary"
            as="a"
            download
            href="https://umwucyquhfcpzkpmxqwsfkwu.s3.eu-west-2.amazonaws.com/ChatbotConnect.zip"
            target="_blank"
            rel="noopener noreferrer">
            Download Config
          </Button>
          <LoginButton
            href={`https://teams.microsoft.com/l/chat/0/0?users=${TEAMS_APP_ID}`}
          />
        </Flex>
      </Flex>
    </Panel>
  );
};

ExistingSettings.propTypes = {
  settings: PropTypes.string.isRequired,
  toggleEdit: PropTypes.func.isRequired
};

export default ExistingSettings;
