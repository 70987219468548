import React from 'react';
import PropTypes from 'prop-types';

import Icon from '@ubisend/pulse-icons';

import BlockContainer from '../../BlockContainer';
import TextPreview from '../../TextPreview';

const ActionBlock = ({ icon, colour, ...props }) => {
  return (
    <BlockContainer colour={colour} {...props} aria-label="Action block">
      <Icon outline type={icon} />
      <TextPreview>{props ? props.base.name : ''}</TextPreview>
    </BlockContainer>
  );
};

ActionBlock.propTypes = {
  icon: PropTypes.string.isRequired,
  colour: PropTypes.string.isRequired,
  base: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ).isRequired
};

export default ActionBlock;
