import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, Flex, Tooltip } from '@ubisend/pulse-components';
import { TemplatingTextInput } from '@ubisend/pulse-templating';

import { useButtons } from '../../../../hooks/index';
import SectionSelect from './SectionSelect';
import ExpiresTooltip from '../../../ExpiresTooltip';

const ContentInput = ({ content, index }) => {
  const { handleButtonChange } = useButtons();

  const handleSectionSelect = ({ value }) =>
    handleButtonChange(index, 'action', value);

  return (
    <Flex col ySpace fat>
      <TemplatingTextInput
        aria-label={`content-button-label-${index + 1}`}
        value={content.label}
        placeholder="View content"
        onChange={value => {
          handleButtonChange(index, 'label', value);
        }}
      />
      <SectionSelect
        value={content.action}
        onChange={handleSectionSelect}
        aria-label="content-button-action"
      />
      <Flex center>
        <TemplatingTextInput
          aria-label={`content-button-linkback-${index + 1}`}
          value={content.linkback}
          placeholder="The user clicked the content button"
          onChange={value => {
            handleButtonChange(index, 'linkback', value);
          }}
        />
        <Tooltip
          position={Tooltip.POSITIONS.TOP}
          tooltip={
            <Tooltip.Content>
              This optional postback text can be used to trigger a new step when
              the user clicks the button to perform an action.
            </Tooltip.Content>
          }>
          <Tooltip.Icon>?</Tooltip.Icon>
        </Tooltip>
      </Flex>
      <Flex>
        <Checkbox
          aria-label={`content-button-expires-${index + 1}`}
          checked={content.linkback ? content.expires : false}
          disabled={!content.linkback}
          onChange={() => {
            handleButtonChange(index, 'expires', !content.expires);
          }}
          label="Disable on reply"
        />
        <ExpiresTooltip />
      </Flex>
    </Flex>
  );
};

ContentInput.propTypes = {
  content: {
    label: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    linkback: PropTypes.string.isRequired,
    expires: PropTypes.bool
  },
  index: PropTypes.number.isRequired
};

export default ContentInput;
