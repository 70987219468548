import axios from 'axios';

const createFaqTest = ({ name, fileId }) => {
  return axios.post('faqs/regression-testing', {
    name,
    file_id: fileId
  });
};

const invokeFaqTest = id => axios.put(`faqs/regression-testing/${id}`);

const deleteFaqTest = id => axios.delete(`faqs/regression-testing/${id}`);

export { createFaqTest, deleteFaqTest, invokeFaqTest };
