import tw, { styled } from 'twin.macro';

import Flex from '../Layout/Flex';

const ActionBar = styled(Flex)`
  & > * {
    ${tw`flex-1 rounded-none`}
  }
  & > *:not(:last-child) {
    content: '';
    width: 1px;
    ${tw`h-full border-0 border-r border-gray-200`}
  }
`;

export default ActionBar;
