import React from 'react';
import tw, { styled, css } from 'twin.macro';

import { motion } from '@ubisend/framer-motion';

const colour = ({ active = false, type = 'client' }) => {
  if (type === 'owner') {
    return 'black';
  }

  return `rgba(255, 255, 255, ${active ? 1 : 0.5})`;
};

const hoverColour = ({ active = false, type = 'client' }) => {
  if (active) {
    return '';
  }

  if (type === 'owner') {
    return `rgba(0,0,0,0.5);`;
  }

  return `rgba(255, 255, 255, 0.75)`;
};

const backgroundColour = ({ active = false, type = 'client' }) => {
  if (!active) {
    return '';
  }

  if (type === 'owner') {
    return 'rgba(0,0,0,0.1)';
  }

  return `rgba(0,0,0,0.25)`;
};

const styles = css`
  ${tw`no-underline text-xs box-border p-0 list-none flex flex-wrap items-center flex flex-row px-4 py-2 rounded w-full`};
  transition: color ease-in-out 0.1s, background-color ease-in-out 0.1s;
  color: ${colour};
`;

const hoverStyles = css`
  &:hover {
    color: ${hoverColour};
  }
`;

const activeStyles = css`
  background-color: ${backgroundColour};
`;

const SidebarItemContainer = styled(motion.div)`
  ${tw`w-full`}

  & > a {
    ${tw`uppercase cursor-pointer`}
    ${styles}
    ${activeStyles}
    ${hoverStyles}
  }

  & > button {
    ${tw`uppercase border-0 m-0 outline-none bg-transparent cursor-pointer`}
    ${styles}
    ${activeStyles}
    ${hoverStyles}
  }

  & > span {
    ${tw`uppercase border-0 m-0 outline-none bg-transparent`}
    ${styles}
  }
`;

const itemAnim = {
  hide: {
    opacity: 0,
    y: 16
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 200,
      damping: 40
    }
  }
};

const SidebarItem = ({ children, ...props }) => {
  return (
    <SidebarItemContainer
      variants={itemAnim}
      data-testid={'sidebar-item-container'}
      {...props}>
      {children}
    </SidebarItemContainer>
  );
};

export default SidebarItem;
export { styles, activeStyles, hoverStyles };
