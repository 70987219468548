import React from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  Button,
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Explainer
} from '@ubisend/pulse-components';

const AuthModal = ({ handleClose, handleSubmit, name }) => {
  return (
    <ModalPortal>
      <ModalContainer>
        <ModalContent>
          <ModalHeader>Confirm disabling of {name}</ModalHeader>
          <ModalBody>
            <Explainer type="danger">
              You are about to disable Email & Password login for all users
              across all your accounts. This may prevent users from logging in.
              Are you sure you want to continue?
            </Explainer>
          </ModalBody>
          <ModalFooter>
            <Flex right>
              <Flex xSpace>
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  colour="danger"
                  onClick={handleSubmit}>
                  Yes, I want to disable email & password
                </Button>
              </Flex>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalContainer>
    </ModalPortal>
  );
};

AuthModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired
};

export default AuthModal;
