import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Panel, useOrderableTableReducer } from '@ubisend/pulse-components';

import { MetricContext } from '../../../Contexts/index';
import DragableMetric from './DragableMetric';
import types from '../../../Components/Types/index';

const Metric = ({ metric, filters, ...context }) => {
  const [page, setPage] = useState(1);
  const [params, setParams] = useState([]);

  const order = useOrderableTableReducer({ id: metric.key });

  const query = useQuery({
    queryKey: [
      `metrics/${metric.id}`,
      { page, ...filters, ...params, ...order.params }
    ],
    ...types[metric.type].query
  });

  const { Component: MetricType, columns, rows } = useMemo(() => {
    return types[metric.type];
  }, [metric.type]);

  if (!query.data) {
    return (
      <Panel
        loading
        loadingItems={1}
        style={{ gridColumn: `span ${columns}`, gridRow: `auto/ span ${rows}` }}
      />
    );
  }

  return (
    <MetricContext.Provider
      value={{
        metric: query.data,
        page,
        setPage,
        isLoading: query.isFetching,
        params,
        setParams,
        order,
        ...filters,
        ...context
      }}>
      <DragableMetric>
        <MetricType />
      </DragableMetric>
    </MetricContext.Provider>
  );
};

Metric.propTypes = {
  metric: PropTypes.shape({
    id: PropTypes.number.isRequired,
    key: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.keys(types))
  }).isRequired,
  filters: PropTypes.shape({
    start: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ]).isRequired,
    end: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ]).isRequired,
    compare: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  }).isRequired
};

export default Metric;
export { types };
