import React, { useState } from 'react';

import { Flex } from '@ubisend/pulse-components';
import { ConditionalTypeProvider } from '@ubisend/pulse-conditionals';
import { useQuery } from '@ubisend/pulse-hooks';

import {
  Conversation,
  LayoutControls,
  Bank,
  BuilderLoadingAnimation,
  BuilderQueryCache,
  KeyboardControls,
  WalkThrough,
  ShowWalkThroughButton
} from './Components/index';
import {
  BuilderProvider,
  CanvasProvider,
  BankProvider,
  LanguageProvider
} from './Providers/index';
import Demo from './Components/Bank/Demo';
import { useCanvas } from './hooks/index';
import ReturnButton from './Components/ReturnButton';
import ClearSelectedNodes from './Components/ClearSelectedNodes';

const BuilderContainer = ({ children }) => {
  const { handleWheel } = useCanvas();

  return (
    <Flex col fat relative xHidden yHidden onWheel={handleWheel}>
      {children}
    </Flex>
  );
};

const Builder = props => {
  const [showWalkThrough, setShowWalkThrough] = useState(false);

  const query = useQuery(`conversations/builder/${props.match.params.id}`);

  const handleShowWalkThrough = () => {
    setShowWalkThrough(true);
  };

  const handleHideWalkThrough = () => {
    setShowWalkThrough(false);
  };

  const demo = () => {
    return <Demo />;
  };

  return (
    <>
      {showWalkThrough && <WalkThrough handleClose={handleHideWalkThrough} />}
      <ShowWalkThroughButton onClick={handleShowWalkThrough} />
      <ConditionalTypeProvider>
        {query.isLoading && <BuilderLoadingAnimation />}
        {query.isSuccess && (
          <LanguageProvider>
            <BuilderProvider initialConversation={query.data.data}>
              <BuilderQueryCache />
              <CanvasProvider>
                <BankProvider>
                  <BuilderContainer>
                    <ReturnButton />
                    <ClearSelectedNodes />
                    <Conversation />
                    <LayoutControls />
                    <Bank demo={demo()} />
                    <KeyboardControls />
                  </BuilderContainer>
                </BankProvider>
              </CanvasProvider>
            </BuilderProvider>
          </LanguageProvider>
        )}
      </ConditionalTypeProvider>
    </>
  );
};

export default Builder;
