import tw, { styled } from 'twin.macro';

import BlurPanel from './BlurPanel';

const Header = styled(BlurPanel)`
  ${tw`z-20 text-white`}
  background: linear-gradient(
  to right bottom,
  ${props => props.theme.bot.gradient.from} 0%,
  ${props => props.theme.bot.gradient.to} 100%
);
  & svg {
    ${tw`h-8 w-8`}
  }
  & p {
    ${tw`text-2xl`}
  }
`;

export default Header;
