import RegexInput from './RegexInput';

const regex = {
  name: 'Regex',
  Input: RegexInput,
  content: {
    regex: ''
  },
  format: (variable, content) => {
    return `${variable}|regex("${content.regex}")`;
  },
  valid: content => content.regex.length > 0
};

export default regex;
