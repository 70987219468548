import React from 'react';
import { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';
import tw, { styled } from 'twin.macro';

import { Flex } from '@ubisend/pulse-components';

import Bubble from './ResponseTypes/Bubble';

const loading = keyframes`
  0%{
    opacity : 1;
  }
  25%{
    opacity: 0.25;
  }
  75%{
    opacity: 0.25;
  }
  100%{
    opacity : 1;
  }
`;

const Dot = styled.div`
  ${tw`bg-white my-1`}
  border-radius: 999px;
  width: 10px;
  height: 10px;
  animation: ${loading} infinite linear 1s;
  &:nth-child(2) {
    ${tw`mx-1`}
    animation-delay: 0.1s;
  }
  &:nth-child(3) {
    animation-delay: 0.2s;
  }
`;

const TypingIndicator = props => {
  const { t } = useTranslation('bots');

  return (
    <Bubble
      direction="sentMessage"
      aria-label={t('typing_indicator_label')}
      role="alert"
      aria-busy="true"
      transition={{ type: 'spring', stiffness: 400, damping: 50 }}
      {...props}>
      <Flex center>
        {[0, 1, 2].map(i => (
          <Dot key={i} />
        ))}
      </Flex>
    </Bubble>
  );
};

export default TypingIndicator;
