import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { useMutation } from '@ubisend/pulse-hooks';
import {
  Flex,
  Placeholder,
  SecondarySidebarContent,
  SecondarySidebarContainer,
  Heading2
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

import NewImportButton from './NewImportButton';
import SortableRootSidebarItem from './SortableRootSidebarItem';
import SaveSidebarOrder, { orderByIndex } from './SaveSidebarOrder';
import { useImports } from '../hooks/index';
import { ExportAllImports } from './Exports/index';
import { updateImportOrderApi } from '../api/imports';

const HeadingContainer = styled(Flex)`
  ${tw`border-gray-300 border-solid border-0 border-b border-r`}
`;

const TopButtonContainer = styled(Flex)`
  ${tw`border-gray-300 border-solid border-0 border-b`}
  height: 3rem;
`;

const BottomButtonContainer = styled(Flex)`
  ${tw`border-gray-300 border-solid border-0 border-b`}
  height: 3rem;
`;

const SortableSidebar = () => {
  const { loading, imports, open, setOpen } = useImports();

  const { importId } = useParams();
  const updateImportOrder = useMutation(updateImportOrderApi);

  const handleImportClick = id => () => {
    setOpen(id === open ? null : id);
  };

  useEffect(() => {
    if (importId) {
      setOpen(parseInt(importId));
    }
  }, [importId, setOpen]);

  const handleReorder = payload => {
    updateImportOrder.mutate({
      import_ids: payload
    });
  };

  return (
    <SecondarySidebarContainer>
      <Flex fat>
        <Flex between fat>
          <HeadingContainer
            borderRight
            grow
            col
            tall
            ySpaceSm
            fat
            center
            middle>
            <Heading2>Knowledge Bases</Heading2>
          </HeadingContainer>
          <Flex col>
            <PermissionFilter can="create knowledge bases">
              <TopButtonContainer>
                <NewImportButton fill icon="plus" variant="primary">
                  New KB
                </NewImportButton>
              </TopButtonContainer>
            </PermissionFilter>
            <BottomButtonContainer ptSm pbSm pr pl>
              <ExportAllImports />
            </BottomButtonContainer>
          </Flex>
        </Flex>
      </Flex>
      <SecondarySidebarContent>
        {loading && <Placeholder ySpace />}
        {!loading && (
          <Flex col>
            <SaveSidebarOrder payload={imports} handleReorder={handleReorder} />
            {imports.sort(orderByIndex).map(item => (
              <SortableRootSidebarItem
                importItem={item}
                key={item.id}
                open={item.id === open}
                handleOpen={handleImportClick(item.id)}
              />
            ))}
          </Flex>
        )}
      </SecondarySidebarContent>
    </SecondarySidebarContainer>
  );
};

export default SortableSidebar;
