import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useQueryClient, useMutation } from '@ubisend/pulse-hooks';
import { AnimatePresence } from '@ubisend/framer-motion';
import {
  Button,
  PanelSlider,
  useNotification
} from '@ubisend/pulse-components';
import {
  ConditionalProvider,
  ConditionalTypeProvider
} from '@ubisend/pulse-conditionals';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { conditionals } from '../../../subjects/index';
import { createConversation } from '../api/index';
import ConversationDetails from '../../../Components/ConversationDetails';

const CreateConversation = () => {
  const [showCreate, setShowCreate] = useState(false);

  const history = useHistory();
  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const mutation = useMutation(createConversation, {
    onSuccess: async ({ data }) => {
      await queryClient.invalidateQueries('conversations/builder');
      showSuccess('Successfully created your new workflow!');
      history.push(`/builder/${data.data.id}`);
    }
  });

  const handleSubmit = conversation => {
    mutation.mutate(conversation);
  };

  return (
    <>
      <AnimatePresence>
        {showCreate && (
          <PanelSlider
            width="56rem"
            header={`Create New Workflow`}
            handleHide={() => setShowCreate(false)}>
            <ConditionalTypeProvider>
              <ConditionalProvider subjects={conditionals}>
                <ConversationDetails
                  handleSubmit={handleSubmit}
                  loading={mutation.isLoading}
                />
              </ConditionalProvider>
            </ConditionalTypeProvider>
          </PanelSlider>
        )}
      </AnimatePresence>
      <PermissionFilter can="create conversations">
        <Button
          variant="primary"
          icon="plus"
          onClick={() => setShowCreate(true)}
          aria-label="Create workflow">
          New Workflow
        </Button>
      </PermissionFilter>
    </>
  );
};

export default CreateConversation;
