import React from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import {
  Flex,
  Button,
  Divider,
  useNotification
} from '@ubisend/pulse-components';
import {
  useConditionalReducer,
  Conditionals,
  AddConditionalButton,
  ConditionalContext,
  Match
} from '@ubisend/pulse-conditionals';

import Modal from '../../../Modal';
import { conditionals } from '../../../../../../subjects/index';
import { useBuilder } from '../../../../hooks/index';
import { createStepTransition } from '../../../../api/index';
import TransitionPreview from './TransitionPreview';

const AddTransitionNode = ({ handleClose, link }) => {
  const { dispatch } = useBuilder();
  const conditional = useConditionalReducer({
    subjects: conditionals
  });
  const { showSuccess } = useNotification();

  const { isLoading, mutate } = useMutation(createStepTransition, {
    onSuccess: ({ data }) => {
      showSuccess('Successfully added transition');
      dispatch({
        type: 'ADD_TRANSITION',
        stepId: link.from.id,
        transitionDetails: data.data
      });
      handleClose();
    }
  });

  const handleSubmit = event => {
    event.preventDefault();

    if (conditional.valid && !isLoading) {
      mutate({
        stepId: link.from.id,
        transition: {
          description: `Transition to step #${link.to.id}`,
          previous_transition: null,
          next_transition: {
            id: link.to.id,
            ...conditional.form,
            x: link.to.base.x,
            y: link.to.base.y - 100,
            variables: [],
            metrics: []
          }
        }
      });
    }
  };

  const startNode = {
    id: link.from.id,
    blocks: [],
    base: link.from.base
  };

  const destinationNode = {
    id: link.to.id,
    blocks: [],
    base: link.to.base
  };

  return (
    <Modal
      header="Add transition"
      handleClose={handleClose}
      style={{ maxWidth: '100%', width: '80rem' }}>
      <Flex>
        <Flex pad fat col borderRight>
          <Flex as="form" col tall onSubmit={handleSubmit}>
            <ConditionalContext.Provider value={conditional}>
              <Match />
              <Divider />
              <Conditionals labelPrefix="transition-conditionals" />
              <Flex right>
                <AddConditionalButton />
              </Flex>
              <Divider />
              <Flex center xSpace bottom>
                <Flex xSpace fat right bottom>
                  <Button
                    variant="primary"
                    disabled={!conditional.valid || isLoading}
                    type="submit">
                    Save
                  </Button>
                </Flex>
              </Flex>
            </ConditionalContext.Provider>
          </Flex>
        </Flex>
        <TransitionPreview
          startNode={startNode}
          destinationNode={destinationNode}
        />
      </Flex>
    </Modal>
  );
};

AddTransitionNode.propTypes = {
  handleClose: PropTypes.func.isRequired,
  link: PropTypes.shape({
    from: PropTypes.shape({
      id: PropTypes.number.isRequired,
      base: PropTypes.object.isRequired
    }).isRequired,
    to: PropTypes.shape({
      id: PropTypes.number.isRequired,
      base: PropTypes.object.isRequired
    }).isRequired
  })
};

export default AddTransitionNode;
