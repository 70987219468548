import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Flex, Panel, WizardProgress } from '@ubisend/pulse-components';

import FacebookLogin from './FacebookLogin';
import SelectPage from './SelectPage';

const SetupFacebookComponent = ({ handlePageSelect }) => {
  const [user, setUser] = useState();

  const handleNewUser = newUser => setUser(newUser);

  return (
    <Panel
      divider
      header="Connect your Facebook account"
      actions={<WizardProgress steps={3} current={user ? 1 : 0} />}>
      <Flex col ySpace mt>
        {!user && <FacebookLogin user={user} handleNewUser={handleNewUser} />}
        {user && <SelectPage user={user} handlePageSelect={handlePageSelect} />}
      </Flex>
    </Panel>
  );
};

SetupFacebookComponent.propTypes = {
  handlePageSelect: PropTypes.func.isRequired
};

export default SetupFacebookComponent;
