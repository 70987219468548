import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

const Container = styled.div`
  ${tw`md:flex lg:flex w-full flex items-center`};
`;

const Inliner = styled.div`
  ${tw`sm:w-1/2 p-4 pt-8 lg:pl-16 flex justify-center`};
`;

const ErrorMessage = styled.div`
  ${tw`sm:w-1/2 flex-1 justify-center text-center inline-flex py-4 md:pt-24 lg:pt-24 lg:pr-8 md:pr-8 leading-tight`};
`;

const ErrorContainer = ({ children, code, message }) => (
  <Container>
    <Inliner>{children}</Inliner>
    <ErrorMessage>
      <div>
        <h1
          style={{
            fontSize: '150px',
            margin: '0',
            color: '#1a1a1a'
          }}>
          {code}
        </h1>
        <p
          style={{
            fontSize: '50px',
            fontWeight: 'bold',
            maxWidth: '70%',
            margin: 'auto',
            color: '#1a1a1a'
          }}>
          {message}
        </p>
      </div>
    </ErrorMessage>
  </Container>
);

ErrorContainer.propTypes = {
  code: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired
};

export default ErrorContainer;
