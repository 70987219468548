import { useMemo, useState } from 'react';
import {
  forceSimulation,
  forceY,
  forceManyBody,
  forceCollide,
  forceX
} from 'd3-force';
import { path } from 'd3-path';

const ARC_RADIUS = 30;
const LINK_SPACING = 20;
const SIMULATION_TICKS = 1000;
const NODE_WIDTH = 288;
const COLLISION_FORCE = (NODE_WIDTH + LINK_SPACING) / 2;
const Y_FORCE = 320;

// Used for distance calculation
const calculateArcLength = () => {
  return (2 * Math.PI * ARC_RADIUS) / 4;
};

const getMidpoint = (x1, y1, x2, y2) => {
  return {
    x: (x1 + x2) / 2,
    y: (y1 + y2) / 2
  };
};

const TYPES = {
  BELOW_ARC: 'BELOW_ARC',
  ABOVE_ARC: 'ABOVE_ARC'
};

const getPathType = (x1, y1, x2, y2) => {
  if (y1 >= y2) {
    return TYPES.ABOVE_ARC;
  }
  return TYPES.BELOW_ARC;
};

const stepBlockTypes = {
  endpoints: 'integration',
  emails: 'email',
  actions: 'action'
};

const transitionBlockTypes = { variables: 'variable', metrics: 'metric' };

/**
 * Node structure:
 * type: The type of node.
 * style: The sub-class of the type of node for when we have multiple types of one node that we wish to behave diffrently.
 * id: The per-type id of the node.
 * links: An array of objects with type and id that represent the link destination.
 * meta: Node type specific information.
 * base: The node type in the original API resource format.
 * blocks: Block blocks that can be added to the node.
 */
const formatSteps = step => {
  return {
    type: 'step',
    style: step.is_automated_step ? 'automatedStep' : 'messageStep',
    id: step.id,
    links: [
      ...step.transitions.map(transition => ({
        type: 'transition',
        id: transition.id
      })),
      ...step.validation_transitions.map(transition => ({
        type: 'validation',
        id: transition.id
      }))
    ],
    meta: {},
    base: step,
    blocks: Object.keys(stepBlockTypes)
      .map(type => {
        return step[type].map(block => ({
          type: stepBlockTypes[type],
          base: block
        }));
      })
      .reduce((a, b) => a.concat(b), [])
  };
};

const formatTransitions = step => {
  return step.transitions.map(transition => ({
    type: 'transition',
    style: 'transition',
    id: transition.id,
    links: [{ id: transition.destination.id, type: 'step' }],
    meta: { stepId: step.id },
    base: transition,
    blocks: Object.keys(transitionBlockTypes)
      .map(type => {
        return transition[type].map(block => ({
          type: transitionBlockTypes[type],
          base: block
        }));
      })
      .reduce((a, b) => a.concat(b), [])
  }));
};

const formatValidation = step => {
  return step.validation_transitions.map(transition => ({
    type: 'validation',
    style: 'validation',
    id: transition.id,
    links: [{ id: transition.destination.id, type: 'step' }],
    meta: { stepId: step.id },
    base: transition,
    blocks: Object.keys(transitionBlockTypes)
      .map(type => {
        return transition[type].map(block => ({
          type: transitionBlockTypes[type],
          base: block
        }));
      })
      .reduce((a, b) => a.concat(b), [])
  }));
};

const formatTriggers = step => {
  return step.incoming_triggers.map(trigger => ({
    type: 'trigger',
    style: 'trigger',
    id: trigger.id,
    links: [{ id: trigger.entry_step.id, type: 'step' }],
    meta: { stepId: step.id },
    base: trigger,
    blocks: Object.keys(transitionBlockTypes)
      .map(type => {
        return trigger[type].map(block => ({
          type: transitionBlockTypes[type],
          base: block
        }));
      })
      .reduce((a, b) => a.concat(b), [])
  }));
};

const useNodePositions = conversation => {
  // Sizes have to be stored seperately as they rely on blocks being rendered first
  const [nodeSizes, setNodeSizes] = useState([]);

  // conversation steps oobject with d3 data appended
  const nodes = useMemo(() => {
    const steps = conversation.steps.map(formatSteps);
    const transitions = conversation.steps
      .map(formatTransitions)
      .reduce((a, b) => a.concat(b), []);

    let nodes = [...steps, ...transitions];

    const calculateNodesNumberOfParents = node => {
      let numberOfParents = 0;
      let currentNode = node;
      let traversed = [];

      while (
        nodes.find(node => {
          const hasNotBeenTraversed = !traversed.find(traversed => {
            return traversed.id === node.id && traversed.type === node.type;
          });

          return (
            hasNotBeenTraversed &&
            node.links.find(link => {
              return (
                link.type === currentNode.type && link.id === currentNode.id
              );
            })
          );
        })
      ) {
        numberOfParents++;
        currentNode = nodes.find(node => {
          return node.links.find(link => {
            return link.type === currentNode.type && link.id === currentNode.id;
          });
        });
        traversed = traversed.concat({
          id: currentNode.id,
          type: currentNode.type
        });
      }

      return { numberOfParents, numberOfAddons: 0, ...node };
    };

    // FIX TODO: Infinitely loops for conversations that loop back on itself.
    // E.g. step 1 -> step 2 -> step 3 -> step 1.
    nodes = nodes.map(calculateNodesNumberOfParents);

    const calculateSecondaryNodesNumberOfParents = node => {
      if (node.links.length === 0) {
        return { numberOfParents: 0, ...node };
      }
      const linkedNode = nodes.find(
        ({ id, type }) => id === node.links[0].id && type === node.links[0].type
      );

      linkedNode.numberOfAddons++;
      return { numberOfParents: linkedNode.numberOfParents - 1, ...node };
    };

    const triggers = conversation.steps
      .map(formatTriggers)
      .reduce((a, b) => a.concat(b), []);
    const validation = conversation.steps
      .map(formatValidation)
      .reduce((a, b) => a.concat(b), []);

    const addons = [...triggers, ...validation].map(
      calculateSecondaryNodesNumberOfParents
    );

    nodes = [...nodes, ...addons];

    const calculateNumberOfSiblings = node => {
      const siblings = nodes
        .filter(
          ({ numberOfParents }) => node.numberOfParents === numberOfParents
        )
        .sort((a, b) => a.x - b.x);
      node.numberOfSiblings = siblings.length;
      node.siblingIndex = siblings.findIndex(sibling => sibling.id === node.id);
      return node;
    };

    const sim = forceSimulation(nodes.map(calculateNumberOfSiblings))
      .force(
        'x',
        forceX(d => {
          const xPosition = d.siblingIndex - d.numberOfSiblings / 2;
          return xPosition * 200;
        }).strength(10)
      )
      .force(
        'y',
        forceY(d => {
          if (d.base.x && d.base.y) {
            d.fy = d.base.y;
            d.fx = d.base.x;
            return 0;
          }

          d.fy = (d.numberOfParents + 1) * Y_FORCE;
          return 0;
        })
      )
      .force('collide', forceCollide(COLLISION_FORCE))
      .force('charge', forceManyBody())
      .stop()
      .tick(SIMULATION_TICKS);
    return sim.nodes();
  }, [conversation]);

  // Calculates corresponding x and y values associated with transitions
  const links = useMemo(() => {
    const arcLength = calculateArcLength();

    return nodes
      .map(node => {
        const fromSize = nodeSizes.find(
          position => position.id === node.id && node.type === position.type
        );
        return node.links.map(link => {
          const linkedNode = nodes.find(
            position => position.id === link.id && link.type === position.type
          );

          if (!linkedNode) {
            return null;
          }
          const toSize = nodeSizes.find(
            position => position.id === link.id && link.type === position.type
          );
          if (!fromSize || !toSize) {
            return null;
          }
          const xDistance = Math.abs(node.x - linkedNode.x);
          const yDistance = Math.abs(
            node.y + fromSize.height / 2 - linkedNode.y + toSize.height / 2
          );
          return {
            from: {
              id: node.id,
              type: node.type,
              x: node.x,
              y: node.y + fromSize.height / 2
            },
            to: {
              id: linkedNode.id,
              type: linkedNode.type,
              x: linkedNode.x,
              y: linkedNode.y - toSize.height / 2
            },
            meta: {
              distance: xDistance - ARC_RADIUS * 4 + yDistance + arcLength * 2
            }
          };
        });
      })
      .flat()
      .filter(link => link !== null);
  }, [nodes, nodeSizes]);

  // Get a links horizontal offset from its original start point to make lines and connectors spaced apart
  const getLinkOffset = link => {
    if (!link) {
      return;
    }

    const siblings = links
      .filter(l => link.from.id === l.from.id)
      .sort((a, b) => a.to.x - b.to.x);

    const index = siblings.findIndex(sibling => sibling.to.id === link.to.id);

    return index * LINK_SPACING - (siblings.length * LINK_SPACING) / 2;
  };

  const getLinkDestinationOffset = link => {
    if (!link) {
      return;
    }

    const siblings = links
      .filter(l => link.to.id === l.to.id)
      .sort((a, b) => a.from.x - b.from.x + a.to.x - b.to.x);

    const index = siblings.findIndex(
      sibling => sibling.from.id === link.from.id
    );

    return index * LINK_SPACING - (siblings.length * LINK_SPACING) / 2;
  };

  // Get offset of a block's empty connector
  const newLinkOffset = node => {
    const children = links.filter(link => link.from.id === node.id);
    return (children.length * LINK_SPACING) / 2;
  };

  // Get offset of a block's empty destination connector
  const newLinkDestinationOffset = node => {
    const children = links.filter(link => link.to.id === node.id);
    return (children.length * LINK_SPACING) / 2;
  };

  // Generates path attribute using d3-path
  const calculatePath = (x1, y1, x2, y2, midPoint) => {
    const newPath = path();
    // Move to the start point of the path
    newPath.moveTo(x1, y1);

    // if the x distance is smaller than the defined arc radius, shrink to fit
    const adjustedRadius = Math.min(
      ARC_RADIUS,
      Math.abs(x1 - x2) / 2,
      Math.abs(y1 - y2)
    );

    switch (getPathType(x1, y1, x2, y2)) {
      case TYPES.BELOW_ARC:
        newPath.arcTo(x1, midPoint.y, midPoint.x, midPoint.y, adjustedRadius);
        newPath.arcTo(x2, midPoint.y, x2, y2, adjustedRadius);
        newPath.lineTo(x2, y2);
        break;
      case TYPES.ABOVE_ARC:
        newPath.arcTo(
          x1,
          y1 + adjustedRadius,
          midPoint.x - adjustedRadius,
          y1 + adjustedRadius,
          adjustedRadius
        );
        newPath.arcTo(
          midPoint.x,
          y1 + adjustedRadius,
          midPoint.x,
          midPoint.y,
          adjustedRadius
        );
        newPath.arcTo(
          midPoint.x,
          y2 - adjustedRadius,
          x2,
          y2 - adjustedRadius,
          adjustedRadius
        );
        newPath.arcTo(x2, y2 - adjustedRadius, x2, y2, adjustedRadius);
        break;
      default:
        throw new Error();
    }

    return newPath;
  };

  // Generate path d attribute using link information
  const getCurve = (link, xOffset, yOffset) => {
    const { from, to } = link;
    const x1 = from.x + getLinkOffset(link);
    const x2 = to.x + getLinkDestinationOffset(link);
    const y1 = from.y;
    const y2 = to.y;
    const midPoint = getMidpoint(
      x1 + xOffset,
      y1 + yOffset,
      x2 + xOffset,
      y2 + yOffset
    );

    return calculatePath(
      x1 + xOffset,
      y1 + yOffset,
      x2 + xOffset,
      y2 + yOffset,
      midPoint
    );
  };

  return {
    calculatePath,
    setNodeSizes,
    nodeSizes,
    links,
    nodes,
    getCurve,
    getLinkOffset,
    getMidpoint,
    getLinkDestinationOffset,
    newLinkOffset,
    newLinkDestinationOffset
  };
};

export default useNodePositions;
