import React from 'react';
import tw, { styled } from 'twin.macro';

const Container = styled.div`
  ${tw`flex`}
`;

const TabularContainer = ({ children }) => {
  return <Container>{children}</Container>;
};

export default TabularContainer;
