import tw, { styled } from 'twin.macro';

const MessageGroupContainer = styled.div`
  ${tw`inline-flex mb-4 w-full`}
  & > div {
    ${tw`flex-col`}
    width: ${({ hasAvatar }) => (hasAvatar ? 'calc(100% - 40px)' : '100%')}
  }
`;

export default MessageGroupContainer;
