import React, { useState, useEffect } from 'react';

import {
  PageWrapper,
  Label,
  TextInput,
  FormGroup,
  Flex,
  Button,
  useNotification,
  useModal,
  Panel
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { updateImport, deleteImport } from '../api/index';
import { useImports } from '../hooks/index';
import { drivers } from '../Components/Drivers/index';
import { ExportImport } from './Exports/index';
import PollingIntervalSelect from './PollingIntervalSelect';
import NlpExportSettings from './NlpExportSettings';

const ImportSettings = () => {
  const { item, dispatch } = useImports();
  const { showSuccess } = useNotification();
  const { showModal, hideModal } = useModal();

  const [name, setName] = useState(item.name);
  const [interval, setInterval] = useState(item.polling_interval);

  const showPollingInterval = drivers[item.type].show_polling_interval;

  const handleNameChange = event => {
    setName(event.target.value);
  };

  const handleIntervalChange = ({ value }) => {
    setInterval(value);
  };

  const handleSubmit = async event => {
    event.preventDefault();

    const { data } = await updateImport(item.id, {
      name,
      polling_interval: interval
    });

    dispatch({ type: 'UPDATE_IMPORT', newImport: data.data });
    showSuccess('Successfully updated content import');
  };

  const handleDelete = () => {
    showModal({
      header: `Delete content import`,
      body: `Are you sure you want to delete this content import? You will lose all content associated with it.`,
      handleConfirm: async () => {
        try {
          await deleteImport(item.id);
          dispatch({ type: 'REMOVE_IMPORT', oldImport: item });
          showSuccess(`Successfully deleted content import`);
        } finally {
          hideModal();
        }
      }
    });
  };

  useEffect(() => {
    if (item) {
      setName(item.name);
    }
  }, [item, setName]);

  return (
    <PageWrapper
      header={`${item.name} Settings`}
      actions={
        <PermissionFilter can="delete knowledge bases">
          <Flex xSpace center>
            <ExportImport item={item} />
            <Button
              variant="secondary"
              icon="trash"
              colour="danger"
              onClick={handleDelete}>
              Delete
            </Button>
          </Flex>
        </PermissionFilter>
      }>
      <Panel>
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Label htmlFor="name">Name</Label>
            <TextInput id="name" value={name} onChange={handleNameChange} />
          </FormGroup>
          {showPollingInterval && (
            <FormGroup>
              <Label htmlFor="polling-interval">When to refresh content</Label>
              <PollingIntervalSelect
                id="polling-interval"
                value={interval}
                onChange={handleIntervalChange}
              />
            </FormGroup>
          )}
          <Flex right>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </Flex>
        </form>
      </Panel>

      {item.has_semantic_driver_enabled && item.sections.length > 0 && (
        <NlpExportSettings item={item} />
      )}
    </PageWrapper>
  );
};

export default ImportSettings;
