import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

import { useMutation } from '@ubisend/pulse-hooks';
import {
  Flex,
  Span,
  SecondarySidebarRoot as RootLink,
  SecondarySidebarLink as SectionLink,
  Button
} from '@ubisend/pulse-components';

import { updateSectionOrder as updateSectionOrderApi } from '../api/index';
import SortableSidebarItem from './SortableSidebarItem';
import { useImports } from '../hooks/index';
import SaveSidebarOrder, { orderByIndex } from './SaveSidebarOrder';

const LinkContainer = styled.div`
  ${tw`flex p-2 justify-between items-center`}
  & svg,
   & i {
    width: 20px;
    height: 20px;
  }
`;

const RootSidebarItem = ({ open, handleOpen, ...importItem }) => {
  const { dispatch } = useImports();

  const updateSectionOrder = useMutation(updateSectionOrderApi);

  const buildLink = (path = '') => {
    return `/knowledge-bases/${importItem.id}/sections/${path}`;
  };

  const checkActive = path => {
    return location.pathname.includes(buildLink(path));
  };

  const hasSections = importItem?.sections && importItem.sections.length > 0;
  const isManualImport = importItem.type === 'Manual Import';

  const saveOrder = payload => {
    updateSectionOrder.mutate({
      importId: importItem.id,
      section_ids: payload
    });
  };

  const handleReorder = ({ from, to }) => {
    dispatch({ type: 'REORDER_SECTION', from, to });
  };

  return (
    <>
      <LinkContainer>
        {hasSections && (
          <SaveSidebarOrder
            payload={importItem.sections}
            handleReorder={saveOrder}
          />
        )}
        <RootLink
          active
          to={`/knowledge-bases/${importItem.id}`}
          onClick={handleOpen}>
          {importItem.name}
        </RootLink>
        <Button
          aria-label={
            open ? `Close ${importItem.name}` : `Open ${importItem.name}`
          }
          variant="inline"
          onClick={handleOpen}
          icon={open ? 'cheveronUp' : 'cheveronDown'}
        />
      </LinkContainer>
      {open && (
        <Flex ySpace col>
          {hasSections &&
            importItem.sections
              .sort(orderByIndex)
              .map(section => (
                <SortableSidebarItem
                  key={section.id}
                  section={section}
                  importItem={importItem}
                  handleReorder={handleReorder}
                  checkActive={checkActive}
                />
              ))}
          {isManualImport && (
            <SectionLink
              active={false}
              to={`/knowledge-bases/${importItem.id}/sections/new`}>
              + Create new section
            </SectionLink>
          )}
          {!isManualImport && !hasSections && (
            <Span tinyText>
              Content is being imported. Check back later to see the results.
            </Span>
          )}
        </Flex>
      )}
    </>
  );
};

RootSidebarItem.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired
};

export default RootSidebarItem;
