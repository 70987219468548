import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@ubisend/pulse-components';

const options = [
  { label: 'Before', value: '-' },
  { label: 'After', value: '+' }
];

const DateModifyRangeSelect = ({ value, ...props }) => {
  return (
    <Select
      options={options}
      value={value && options.find(option => option.value === value)}
      {...props}
    />
  );
};

DateModifyRangeSelect.propTypes = {
  value: PropTypes.string
};

export default DateModifyRangeSelect;
