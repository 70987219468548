import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@ubisend/pulse-components';

const options = [
  {
    label: 'Append date',
    value: 'exact'
  },
  {
    label: 'Date math',
    value: 'dynamic'
  }
];

const DateModifyTypeSelect = ({ value, ...props }) => {
  return (
    <Select
      options={options}
      value={value && options.find(option => option.value === value)}
      {...props}
    />
  );
};

DateModifyTypeSelect.propTypes = {
  value: PropTypes.string
};

export default DateModifyTypeSelect;
