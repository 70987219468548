import React from 'react';
import PropTypes from 'prop-types';

import { CreatableSelect } from '@ubisend/pulse-components';

const options = [
  'Cache-Control',
  'Host',
  'User-Agent',
  'Accept',
  'Accept-Encoding',
  'Connection',
  'Content-Type'
].map(option => ({ value: option, label: option }));

const formatOptions = value => {
  const findOption = options.find(option => option.value === value);

  if (value) {
    return findOption ?? { value: value, label: value };
  }
};

const HeaderSelect = ({ value, ...props }) => (
  <CreatableSelect
    {...props}
    closeMenuOnSelect
    isClearable
    value={formatOptions(value)}
    options={options}
  />
);

HeaderSelect.propTypes = {
  value: PropTypes.string
};

export default HeaderSelect;
