import React from 'react';
import PropTypes from 'prop-types';

import ComposerButton from './ComposerButton';

const SendButton = props => {
  return (
    <ComposerButton
      {...props}
      style={{ border: '1px #fff', paddingRight: '0.75rem' }}>
      <svg
        viewBox="1.991 1.981 20.02 20.011"
        xmlns="http://www.w3.org/2000/svg">
        <path
          className={'primary'}
          style={props.disabled ? { opacity: 0.25 } : null}
          d="M 12.005 20.105 L 3.405 21.905 C 2.701 22.217 1.924 21.649 2.006 20.884 C 2.019 20.766 2.052 20.651 2.105 20.545 L 11.105 2.545 C 11.441 1.852 12.4 1.782 12.832 2.419 C 12.86 2.459 12.884 2.501 12.905 2.545 L 21.905 20.545 C 22.248 21.234 21.716 22.036 20.948 21.989 C 20.83 21.981 20.713 21.953 20.605 21.905 L 12.005 20.105 Z"
          transform="matrix(0, 1, -1, 0, 23.992027, -0.019255)"
        />
        <path
          className={'secondary'}
          style={props.disabled ? { opacity: 0.25 } : null}
          d="M 6.986 6.99 C 7.346 6.99 7.696 7.17 7.886 7.54 L 16.886 25.54 C 17.229 26.229 16.697 27.032 15.929 26.984 C 15.81 26.977 15.694 26.948 15.586 26.9 L 6.986 25.09 L 6.986 6.99 Z"
          transform="matrix(0, 1, -1, 0, 28.977156, 4.998897)"
        />
      </svg>
    </ComposerButton>
  );
};

SendButton.propTypes = {
  disabled: PropTypes.bool
};

export default SendButton;
