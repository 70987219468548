import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import { AnimateSharedLayout } from '@ubisend/framer-motion';
import {
  Flex,
  Label,
  Button,
  useNotification,
  TabButton,
  Divider,
  LeftHalfLayout,
  RightHalfLayout
} from '@ubisend/pulse-components';
import {
  MultiComposer,
  useMultiComposerReducer,
  MultiComposerSettings,
  MultiComposerWidget,
  MultiComposerSubmitButton,
  MultiComposerLanguageSelect,
  ComposerContext
} from '@ubisend/pulse-composer';
import {
  useConditionalReducer,
  Conditionals,
  AddConditionalButton,
  ConditionalContext,
  Match
} from '@ubisend/pulse-conditionals';

import Modal from '../../../Modal';
import ComposerWidget from '../../../ComposerWidget';
import { useBuilder } from '../../../../hooks/index';
import { createStepTransition } from '../../../../api/index';
import { composers, conditionals } from '../../../../../../subjects/index';
import TransitionPreview from '../Transition/TransitionPreview';

const AddValidationNode = ({ handleClose, node }) => {
  const [step, setStep] = useState('VALIDATION');

  const { showSuccess } = useNotification();
  const { dispatch } = useBuilder();
  const composer = useMultiComposerReducer({
    responses: [],
    subjects: composers
  });
  const conditional = useConditionalReducer({
    subjects: conditionals
  });

  const { isLoading, mutate } = useMutation(createStepTransition, {
    onSuccess: ({ data }) => {
      dispatch({
        type: 'ADD_TRANSITION',
        stepId: node.id,
        transitionDetails: data.data,
        transitionType: 'validation'
      });
      showSuccess('Successfully added validation');
      handleClose();
    }
  });

  const valid = useMemo(() => {
    return composer.valid && conditional.valid;
  }, [composer.valid, conditional.valid]);

  const handleSubmit = event => {
    event.preventDefault();

    if (valid && !isLoading) {
      mutate({
        stepId: node.id,
        transition: {
          description: `Validation for step #${node.id}`,
          previous_transition: null,
          ...composer.form,
          next_transition: {
            id: node.id,
            ...conditional.form,
            x: node.x - 300,
            y: node.y,
            variables: [],
            metrics: []
          }
        }
      });
    }
  };

  const startNode = {
    id: node.base.id,
    blocks: [],
    base: node.base
  };

  const destinationNode = {
    id: node.base.id,
    blocks: [],
    base: { ...node.base, responses: composer.responses }
  };

  return (
    <Modal
      header="Add validation"
      handleClose={handleClose}
      style={{ maxWidth: '100%', width: '80rem' }}>
      <Flex>
        <Flex fat col borderRight>
          <Flex center xSpace mt ml mr>
            <AnimateSharedLayout>
              <TabButton
                active={step === 'VALIDATION'}
                onClick={() => setStep('VALIDATION')}>
                Validation
              </TabButton>
              <TabButton
                active={step === 'RESPONSE'}
                onClick={() => setStep('RESPONSE')}>
                Response
              </TabButton>
            </AnimateSharedLayout>
          </Flex>
          <Divider />
          <Flex as="form" col tall onSubmit={handleSubmit} pad>
            {step === 'VALIDATION' && (
              <ConditionalContext.Provider value={conditional}>
                <Match />
                <Divider />
                <Conditionals labelPrefix="validation-conditionals" />
                <Flex right>
                  <AddConditionalButton />
                </Flex>
                <Divider />
                <Flex center xSpace bottom>
                  <Flex xSpace fat right bottom>
                    <Button onClick={() => setStep('RESPONSE')}>
                      Continue
                    </Button>
                  </Flex>
                </Flex>
              </ConditionalContext.Provider>
            )}
            {step === 'RESPONSE' && (
              <Flex>
                <ComposerContext.Provider value={composer}>
                  <LeftHalfLayout>
                    <Flex between center mb>
                      <Label mb={false}>Language</Label>
                      <Flex>
                        <MultiComposerLanguageSelect />
                      </Flex>
                    </Flex>
                    <MultiComposer />
                    <Flex xSpace>
                      <MultiComposerSettings />
                      <MultiComposerSubmitButton
                        type="submit"
                        disabled={isLoading || !valid}>
                        Save
                      </MultiComposerSubmitButton>
                    </Flex>
                  </LeftHalfLayout>
                  <RightHalfLayout>
                    <Flex fat middle>
                      <MultiComposerWidget as={ComposerWidget} />
                    </Flex>
                  </RightHalfLayout>
                </ComposerContext.Provider>
              </Flex>
            )}
          </Flex>
        </Flex>
        <TransitionPreview
          startNode={startNode}
          destinationNode={destinationNode}
        />
      </Flex>
    </Modal>
  );
};

AddValidationNode.propTypes = {
  handleClose: PropTypes.func.isRequired,
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
    base: PropTypes.object.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired
  }).isRequired
};

export default AddValidationNode;
