import React from 'react';

import { hasPermission } from '@ubisend/pulse-auth';
import { FacebookProvider } from '@ubisend/pulse-facebook';

import { InstagramLink } from './Pages/index';

const routes = [
  {
    name: 'Instagram',
    path: '/instagram-link',
    component: () => (
      <FacebookProvider>
        <InstagramLink />
      </FacebookProvider>
    ),
    canAccess: hasPermission('view instagram settings'),
    actions: [
      {
        title: 'Manage your Instagram channel',
        description: 'Connect, disconnect, or edit your Instagram integration.',
        icon: 'lightningBolt'
      }
    ]
  }
];

export default routes;
