import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Select } from '@ubisend/pulse-components';

const getMethodName = ({ name, endpoint, method }) => {
  if (name) {
    return name;
  }

  return `${method.toUpperCase()} ${endpoint}`;
};

const format = method => ({
  value: method.id,
  label: getMethodName(method)
});

const EndpointSelect = ({ value, integration, ...props }) => {
  const { data, isLoading, isSuccess } = useQuery('integrations');

  const disabled = !integration;

  const endpoints = useMemo(() => {
    if (!integration || !isSuccess) {
      return null;
    }

    return data.data.find(({ id }) => id === integration).endpoints;
  }, [data, isSuccess, integration]);

  const endpoint = () => {
    if (!endpoints || !value) {
      return null;
    }

    const endpoint = endpoints.find(endpoint => endpoint.id === value);

    if (!endpoint) {
      return null;
    }

    return format(endpoint);
  };

  return (
    <Select
      isLoading={isLoading}
      isDisabled={disabled}
      placeholder={'/users'}
      options={endpoints ? endpoints.map(format) : []}
      value={endpoint()}
      {...props}
    />
  );
};

EndpointSelect.propTypes = {
  value: PropTypes.number,
  integration: PropTypes.number
};

export default EndpointSelect;
