import React, { useCallback } from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import {
  ConverseMessages,
  ComposerProvider,
  EmbedBody,
  BotProvider,
  RecordTyping
} from '@ubisend/pulse-volt';

import {
  LiveChatDeliveryProvider,
  AgentTypingProvider
} from '../Providers/index';
import { flipDirection } from '../../../../../utils/index';
import {
  startTyping as startTypingApi,
  stopTyping as stopTypingApi
} from '../../../../../api/index';
import AgentComposer from './AgentComposer';

const Container = styled.div`
  ${tw`relative flex flex-col flex-grow`}
  height: 100%;
`;

const Fixed = styled.div`
  ${tw`sticky w-full bottom-0 z-50`}
  > div:first-child {
    ${tw`py-3`}
  }
`;

const Conversation = ({ ticket }) => {
  const subscriberId = ticket.subscriber.id;

  const { mutate: startTypingMutation } = useMutation(startTypingApi);
  const { mutate: stopTypingMutation } = useMutation(stopTypingApi);

  const startTyping = useCallback(() => {
    startTypingMutation(subscriberId);
  }, [startTypingMutation, subscriberId]);

  const stopTyping = useCallback(() => {
    stopTypingMutation(subscriberId);
  }, [stopTypingMutation, subscriberId]);

  const compareMessages = useCallback(() => {
    return false;
  }, []);

  return (
    <BotProvider
      name={`User #${subscriberId}`}
      initialMessages={ticket.subscriber.messages.map(flipDirection)}
      compareMessages={compareMessages}>
      <LiveChatDeliveryProvider ticket={ticket}>
        <AgentTypingProvider subscriber={ticket.subscriber}>
          <Container>
            <EmbedBody
              style={{
                height:
                  ticket.is_active && ticket.status === 'in_progress'
                    ? 'calc(100vh - 17.6rem)'
                    : 'calc(100vh - 6.6rem)'
              }}>
              <ConverseMessages />
            </EmbedBody>
            {ticket.is_active && ticket.status === 'in_progress' && (
              <Fixed>
                <ComposerProvider>
                  <RecordTyping
                    enabled
                    startTyping={startTyping}
                    stopTyping={stopTyping}
                  />
                  <AgentComposer
                    subscriberId={subscriberId}
                    ticketId={ticket.id}
                  />
                </ComposerProvider>
              </Fixed>
            )}
          </Container>
        </AgentTypingProvider>
      </LiveChatDeliveryProvider>
    </BotProvider>
  );
};

Conversation.propTypes = {
  ticket: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    is_active: PropTypes.bool.isRequired,
    subscriber: PropTypes.shape({
      id: PropTypes.number.isRequired,
      messages: PropTypes.array.isRequired
    }).isRequired
  }).isRequired
};

export default Conversation;
