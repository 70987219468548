import { styled } from 'twin.macro';

const DateWrapper = styled.div`
  .MuiPickersBasePicker-pickerView {
    max-width: 100%;
  }
  .MuiDialogActions-root {
    background: black;
  }
`;

export default DateWrapper;
