import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@ubisend/pulse-components';

const DateModifyPeriodSelect = ({
  value,
  options,
  selectedOption,
  ...props
}) => {
  return <Select options={options} value={selectedOption} {...props} />;
};

DateModifyPeriodSelect.propTypes = {
  value: PropTypes.string,
  options: PropTypes.string,
  selectedOption: PropTypes.string
};

export default DateModifyPeriodSelect;
