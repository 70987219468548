import { styled } from 'twin.macro';

import { motion } from '@ubisend/framer-motion';

const SettingContainer = styled(motion.div)``;

SettingContainer.defaultProps = {
  'data-testid': 'setting',
  variants: {
    hidden: { opacity: 0, y: 50 },
    show: {
      opacity: 1,
      y: 0,
      transition: { duration: 1, ease: [0.16, 1, 0.3, 1] }
    }
  }
};

export default SettingContainer;
