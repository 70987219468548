import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { BotContext } from '../Contexts/index';
import { useExternallyChangableState } from '../hooks/index';

const DefaultMessageWrapper = ({ children }) => (
  <React.Fragment>{children}</React.Fragment>
);

const DefaultGroupWrapper = ({ children }) => (
  <React.Fragment>{children}</React.Fragment>
);

const noop = () => undefined;

const BotProvider = ({
  children,
  // Global
  resetOnClose = false,
  isDemo = false,
  // Header
  name = 'ubibot',
  logo,
  showName = true,
  showLogo = true,
  showDateTime = true,
  logoLink,
  showClose = true,
  title = "Hey I'm ubibot!",
  subtitle = 'You talk to me about stuff. To start, why not ask me a question?',
  // CTA
  initialShowCta = false,
  ctaText: initialCtaText,
  // Banner
  hasSeenBanner = false,
  showBanner = true,
  // Body
  initialMessages = [],
  compareMessages: passedCompareMessages,
  MessageWrapper = DefaultMessageWrapper,
  GroupWrapper = DefaultGroupWrapper,
  showPoweredByUbisend = true,
  footerImageUrl = null,
  footerUrl = null,
  // Burger
  buttons = [],
  showBurgerIcon = false,
  initialShowBurgerMenu = false,
  //Launcher
  showlauncher = false,
  launcherLogoUrl,
  // Avatars
  showAvatars = false,
  humanAvatarUrl,
  botAvatarUrl,
  // Settings Page
  showReset = true,
  showChangeLanguage = false,
  showExportTranscript = false,
  showExportSubscriber = false,
  showDeleteSubscriber = false,
  hasActiveSettings = true,
  // Event Hooks
  onWidgetShow = noop,
  onWidgetOpen = noop,
  onWidgetClose = noop,
  onCtaHide = noop,
  onCtaShow = noop,
  onIframeModalOpen = noop,
  onIframeModalClose = noop,
  onResetBot = noop
}) => {
  const defaultCompareMessages = useCallback((state, ref) => {
    return !(
      state.length === ref.length &&
      state.every((message, index) => {
        return message.id === ref[index].id;
      })
    );
  }, []);

  const compareMessages = passedCompareMessages || defaultCompareMessages;

  const [messages, setMessages] = useExternallyChangableState(
    initialMessages,
    compareMessages
  );
  const [showCta, setShowCta] = useExternallyChangableState(initialShowCta);
  const [ctaText, setCtaText] = useExternallyChangableState(initialCtaText);
  const [showBurgerMenu, setShowBurgerMenu] = useState(initialShowBurgerMenu);
  const [iframeModal, setIframeModal] = useState('');

  const autofocusComposer = messages.find(
    ({ direction }) => direction === 'toServer'
  );

  const context = {
    // Global
    resetOnClose,
    autofocusComposer,
    isDemo,
    // Header
    name,
    logo,
    showName,
    showLogo,
    showDateTime,
    logoLink,
    showClose,
    title,
    subtitle,
    // CTA
    showCta,
    setShowCta,
    ctaText,
    setCtaText,
    // Banner
    hasSeenBanner,
    showBanner,
    // Body
    messages,
    setMessages,
    MessageWrapper,
    GroupWrapper,
    showPoweredByUbisend,
    footerImageUrl,
    footerUrl,
    //Launcher
    showlauncher,
    launcherLogoUrl,
    // Avatars
    showAvatars,
    botAvatarUrl,
    humanAvatarUrl,
    // Burger
    showBurgerIcon,
    showBurgerMenu,
    setShowBurgerMenu,
    buttons,
    // Lightbox
    iframeModal,
    setIframeModal,
    // Settings Page
    showReset,
    showChangeLanguage,
    showExportTranscript,
    showExportSubscriber,
    showDeleteSubscriber,
    hasActiveSettings,
    // Event Hooks.
    onWidgetShow,
    onWidgetOpen,
    onWidgetClose,
    onCtaHide,
    onCtaShow,
    onIframeModalOpen,
    onIframeModalClose,
    onResetBot
  };

  return <BotContext.Provider value={context}>{children}</BotContext.Provider>;
};

BotProvider.propTypes = {
  // Global
  resetOnClose: PropTypes.bool,
  isDemo: PropTypes.bool,
  // Header
  name: PropTypes.string,
  logo: PropTypes.string,
  showName: PropTypes.bool,
  showLogo: PropTypes.bool,
  showDateTime: PropTypes.bool,
  logoLink: PropTypes.string,
  showClose: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  // CTA
  initialShowCta: PropTypes.bool,
  ctaText: PropTypes.string,
  // Banner
  hasSeenBanner: PropTypes.bool,
  showBanner: PropTypes.bool,
  // Body
  initialMessages: PropTypes.array,
  compareMessages: PropTypes.func,
  MessageWrapper: PropTypes.element,
  GroupWrapper: PropTypes.element,
  showPoweredByUbisend: PropTypes.bool,
  footerImageUrl: PropTypes.string,
  footerUrl: PropTypes.string,
  // Burger
  buttons: PropTypes.array,
  showBurgerIcon: PropTypes.bool,
  initialShowBurgerMenu: PropTypes.bool,
  //Launcher
  showlauncher: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  launcherLogoUrl: PropTypes.string,
  // Avatars
  showAvatars: PropTypes.bool,
  humanAvatarUrl: PropTypes.string,
  botAvatarUrl: PropTypes.string,
  // Settings Page
  showReset: PropTypes.bool,
  showChangeLanguage: PropTypes.bool,
  showExportTranscript: PropTypes.bool,
  showExportSubscriber: PropTypes.bool,
  showDeleteSubscriber: PropTypes.bool,
  hasActiveSettings: PropTypes.bool,
  // Event Hooks
  onWidgetShow: PropTypes.func,
  onWidgetOpen: PropTypes.func,
  onWidgetClose: PropTypes.func,
  onCtaHide: PropTypes.func,
  onCtaShow: PropTypes.func,
  onIframeModalOpen: PropTypes.func,
  onIframeModalClose: PropTypes.func,
  onResetBot: PropTypes.func
};

export default BotProvider;
export { DefaultMessageWrapper, DefaultGroupWrapper };
