import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import chroma from 'chroma-js';

import { Tooltip, useTheme } from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';

const ColourContrast = ({ backgrounds, foreground, contrastExempt }) => {
  const theme = useTheme();

  const invalidBackgrounds = useMemo(() => {
    return backgrounds.filter(
      background => chroma.contrast(foreground, background) < 4.5
    );
  }, [backgrounds, foreground]);

  if (contrastExempt) {
    return (
      <Tooltip
        tooltip={
          <Tooltip.Content>Contrast check not applicable.</Tooltip.Content>
        }>
        <Icon
          stroke={theme.grey}
          size="1.5rem"
          width="1.5rem"
          height="1.5rem"
          type="checkMinus"
          outline
          style={{ cursor: 'auto', marginTop: '-0.5rem' }}
        />
      </Tooltip>
    );
  }

  if (invalidBackgrounds.length === 0) {
    return (
      <Tooltip
        tooltip={
          <Tooltip.Content>
            Meets WCAG AA standard for colour contrasts.{' '}
            {foreground.toUpperCase()} does not clash with{' '}
            {backgrounds.map(background => background.toUpperCase()).join(', ')}
          </Tooltip.Content>
        }>
        <Icon
          stroke={theme.positive}
          size="1.5rem"
          width="1.5rem"
          height="1.5rem"
          type="checkCircle"
          outline
          style={{ cursor: 'auto', marginTop: '-0.5rem' }}
        />
      </Tooltip>
    );
  }

  return (
    <Tooltip
      tooltip={
        <Tooltip.Content>
          Does not meet WCAG AA standard for colour contrasts.{' '}
          {foreground.toUpperCase()} clashes with{' '}
          {invalidBackgrounds.map((background, index) => {
            if (index !== invalidBackgrounds.length - 1) {
              return background.toUpperCase() + ' and ';
            }
            return background.toUpperCase();
          })}
        </Tooltip.Content>
      }>
      <Icon
        stroke={theme.warning}
        size="1.5rem"
        width="1.5rem"
        height="1.5rem"
        type="exclamation"
        outline
        style={{ cursor: 'auto', marginTop: '-0.5rem' }}
      />
    </Tooltip>
  );
};

ColourContrast.propTypes = {
  backgrounds: PropTypes.arrayOf(PropTypes.string),
  foreground: PropTypes.string,
  contrastExempt: PropTypes.bool
};

export default ColourContrast;
