import React from 'react';
import tw, { styled } from 'twin.macro';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';

import { motion } from '@ubisend/framer-motion';

const Bubble = styled(motion.button)`
  ${tw`z-20 flex items-center px-3 py-2 rounded-lg text-xs leading-tight uppercase font-poppins whitespace-nowrap border-0 shadow-lg cursor-pointer`}
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.bot.textColour};
  pointer-events: auto;
  border: 1px solid ${({ theme }) => chroma(theme.bot.textColour).alpha(0.3)};
  & > span {
    ${tw`font-poppins`}
  }
`;

const Arrow = () => (
  <svg
    style={{ width: '0.75rem', height: '0.75rem', marginRight: '0.5rem' }}
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
      clipRule="evenodd"
    />
  </svg>
);

const Container = styled(motion.div)`
  ${tw`flex justify-center sticky`}
  top: 0;
  pointer-events: none;
  margin-top: -2rem;
`;

const JumpToBottom = props => {
  const { t } = useTranslation('bots');

  return (
    <Container initial={{ y: -64 }} animate={{ y: 0 }} exit={{ y: -64 }}>
      <Bubble
        whileTap={{ scale: 0.95 }}
        whileHover={{ scale: 1.05 }}
        {...props}>
        <Arrow />
        <span>{t('jump_to_bottom_button')}</span>
      </Bubble>
    </Container>
  );
};

export default JumpToBottom;
