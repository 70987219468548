import tw, { styled } from 'twin.macro';

const PreviewContainer = styled.div`
  width: 24rem;
  ${tw`mb-2`}
  &:not(:first-child) {
    ${tw`ml-2`}
  }
  &:not(:last-child) {
    ${tw`mr-2`}
  }
  & > div {
    ${tw`flex justify-center mx-auto`}
  }
`;

export default PreviewContainer;
