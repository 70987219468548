import React from 'react';
import { styled } from 'twin.macro';

import { motion } from '@ubisend/framer-motion';

const fixedContainerAnimations = {
  initial: { marginTop: 0 },
  animate: { marginTop: '3rem' },
  transition: { delay: 0.1 },
  exit: { marginTop: 0 }
};

const StyledFixedContainer = styled(motion.div)`
  box-sizing: border-box;
`;

const FixedFooterContainer = ({ children }) => (
  <StyledFixedContainer {...fixedContainerAnimations}>
    {children}
  </StyledFixedContainer>
);

export { fixedContainerAnimations };
export default FixedFooterContainer;
