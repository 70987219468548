import React from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  ColourPicker,
  Label,
  FormGroup,
  OneQuarterLayout,
  InfoSection,
  Panel,
  Divider,
  Accordion,
  Button
} from '@ubisend/pulse-components';
import { defaultTheme } from '@ubisend/pulse-volt';

import { useBotSettings } from '../../hooks/index';
import ColourContrast from '../ColourContrast';

// Get default colours from theme.
const {
  textColour,
  launcherBackgroundColour,
  gradient,
  sentMessage,
  recievedMessage
} = defaultTheme;

const ConverseColourSettings = props => {
  const {
    settings,
    setSettings,
    focusBanner,
    focusBot,
    focusLauncher,
    handleColourChange,
    handleColourChangeNested
  } = useBotSettings();

  const handleReset = () => {
    setSettings(settings => ({
      ...settings,
      styles: {
        ...settings.styles,
        textColour: textColour,
        launcherBackgroundColour: launcherBackgroundColour,
        gradient: {
          to: gradient.to,
          from: gradient.from
        },
        sentMessage: {
          text: sentMessage.text,
          background: sentMessage.background
        },
        recievedMessage: {
          text: recievedMessage.text,
          background: recievedMessage.background
        }
      }
    }));
  };

  const isSettingDefault = () => {
    const { styles } = settings;

    return (
      styles.textColour === textColour &&
      styles.launcherBackgroundColour === launcherBackgroundColour &&
      styles.gradient.to === gradient.to &&
      styles.gradient.from === gradient.from &&
      styles.sentMessage.text === sentMessage.text &&
      styles.sentMessage.background === sentMessage.background &&
      styles.recievedMessage.text === recievedMessage.text &&
      styles.recievedMessage.background === recievedMessage.background
    );
  };

  return (
    <Flex>
      {props.titlePosition === 'left' && (
        <>
          <OneQuarterLayout>
            <InfoSection
              title="Colours"
              info="Edit the colour scheme of your bot."
            />
          </OneQuarterLayout>
          <Divider />
        </>
      )}
      <Panel>
        {props.titlePosition === 'top' && (
          <>
            <InfoSection
              mtNone
              title="Colours"
              info="Edit the colour scheme of your bot."
            />
            <Divider />
          </>
        )}
        <Flex col ySpace>
          <Accordion mb primary header="General colours">
            <Flex growChildren>
              <Flex border rounded pad mr growChildren>
                <FormGroup>
                  <Flex mbSm rounded between bottom>
                    <Label mb={false}>Text colour</Label>
                    <ColourContrast
                      backgrounds={[
                        settings.styles.gradient.from,
                        settings.styles.gradient.to
                      ]}
                      foreground={settings.styles.textColour}
                    />
                  </Flex>
                  <ColourPicker
                    testId="text-colour-picker"
                    onChange={focusBanner}
                    color={settings.styles.textColour}
                    onChangeComplete={handleColourChange('textColour')}
                  />
                  <Label>Launcher background colour</Label>
                  <ColourPicker
                    testId="launcher-background-colour-picker"
                    onChange={focusLauncher}
                    color={settings.styles.launcherBackgroundColour}
                    onChangeComplete={handleColourChange(
                      'launcherBackgroundColour'
                    )}
                  />
                </FormGroup>
              </Flex>
              <Flex border rounded pad growChildren>
                <FormGroup>
                  <Flex mbSm rounded between bottom>
                    <Label mb={false}>Gradient start colour</Label>
                    <ColourContrast
                      contrastExempt
                      backgrounds={[
                        settings.styles.gradient.from,
                        settings.styles.gradient.to
                      ]}
                      foreground={settings.styles.textColour}
                    />
                  </Flex>
                  <ColourPicker
                    testId="gradient-start-colour-picker"
                    onChange={focusBanner}
                    color={settings.styles.gradient.from}
                    onChangeComplete={handleColourChangeNested(
                      'gradient',
                      'from'
                    )}
                  />
                  <Label>Gradient end colour</Label>
                  <ColourPicker
                    testId="gradient-end-colour-picker"
                    onChange={focusBanner}
                    color={settings.styles.gradient.to}
                    onChangeComplete={handleColourChangeNested(
                      'gradient',
                      'to'
                    )}
                  />
                </FormGroup>
              </Flex>
            </Flex>
          </Accordion>
          <Accordion header="Message colours" primary>
            <Flex growChildren>
              <Flex border rounded pad mr growChildren>
                <FormGroup>
                  <Flex mbSm between middle>
                    <Label mb={false}>Sent message text colour</Label>
                    <ColourContrast
                      backgrounds={[settings.styles.sentMessage.background]}
                      foreground={settings.styles.sentMessage.text}
                    />
                  </Flex>
                  <ColourPicker
                    testId="sent-message-text-colour-picker"
                    onChange={focusBot}
                    color={settings.styles.sentMessage.text}
                    onChangeComplete={handleColourChangeNested(
                      'sentMessage',
                      'text'
                    )}
                  />
                  <Label>Sent message background colour</Label>
                  <ColourPicker
                    testId="sent-message-background-colour-picker"
                    onChange={focusBot}
                    color={settings.styles.sentMessage.background}
                    onChangeComplete={handleColourChangeNested(
                      'sentMessage',
                      'background'
                    )}
                  />
                </FormGroup>
              </Flex>
              <Flex border rounded pad growChildren>
                <FormGroup>
                  <Flex mbSm between middle>
                    <Label mb={false}>Received message text colour</Label>
                    <ColourContrast
                      backgrounds={[settings.styles.recievedMessage.background]}
                      foreground={settings.styles.recievedMessage.text}
                    />
                  </Flex>
                  <ColourPicker
                    testId="received-message-text-colour-picker"
                    onChange={focusBot}
                    color={settings.styles.recievedMessage.text}
                    onChangeComplete={handleColourChangeNested(
                      'recievedMessage',
                      'text'
                    )}
                  />
                  <Label>Received message background colour</Label>
                  <ColourPicker
                    testId="received-message-background-colour-picker"
                    onChange={focusBot}
                    color={settings.styles.recievedMessage.background}
                    onChangeComplete={handleColourChangeNested(
                      'recievedMessage',
                      'background'
                    )}
                  />
                </FormGroup>
              </Flex>
            </Flex>
          </Accordion>
          <Flex grow right>
            <Button
              selfBottom
              data-testId="colour-defaults"
              data-testid="bot-colour-defaults"
              variant="secondary"
              colour="danger"
              onClick={handleReset}
              disabled={isSettingDefault()}>
              Reset to default
            </Button>
          </Flex>
        </Flex>
      </Panel>
    </Flex>
  );
};

ConverseColourSettings.propTypes = {
  titlePosition: PropTypes.string
};

ConverseColourSettings.defaultProps = {
  titlePosition: 'left'
};

export default ConverseColourSettings;
