import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { useTranslation } from 'react-i18next';

import { motion } from '@ubisend/framer-motion';
import { Flex, Heading1 } from '@ubisend/pulse-components';

import ResetBot from './ResetBot';
import ChangeLanguage from './ChangeLanguage';
import RequestTranscript from './RequestTranscript';
import RequestData from './RequestData';
import DeleteData from './DeleteData';
import Version from './Version';
import { useBot } from '../../hooks/index';

const animation = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.075
    }
  }
};

const Container = styled(motion.div)`
  ${tw`h-full max-w-lg mx-auto overflow-y-auto`}
  & > *:not(:last-child) {
    ${tw`mb-4`}
  }
  max-height: 100%;
`;

const OuterContainer = styled.div`
  ${tw`relative overflow-y-hidden h-full`}

  ${props => props.pad && tw`p-4`}
  max-height: 100%;
`;

const Settings = ({ pad = true }) => {
  const bot = useBot();

  const { t } = useTranslation('bots');

  return (
    <OuterContainer pad={pad}>
      <Container
        data-testid="settings"
        initial="hidden"
        animate="show"
        variants={animation}>
        <Flex between middle center>
          <Flex col ySpaceSm>
            <Heading1 initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              {t('settings_header')}
            </Heading1>
            <Version />
          </Flex>
          <Flex>{bot.showChangeLanguage && <ChangeLanguage />}</Flex>
        </Flex>
        {bot.showReset && <ResetBot />}
        {bot.showExportTranscript && <RequestTranscript />}
        {bot.showExportSubscriber && <RequestData />}
        {bot.showDeleteSubscriber && <DeleteData />}
      </Container>
    </OuterContainer>
  );
};

Settings.propTypes = {
  pad: PropTypes.bool
};

export default Settings;
