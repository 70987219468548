import React from 'react';
import PropTypes from 'prop-types';

import { AnimateSharedLayout } from '@ubisend/framer-motion';
import {
  Panel,
  InfoSection,
  Flex,
  OneQuarterLayout,
  Accordion,
  Divider,
  Label,
  TextInput,
  ProgressArc,
  TextArea,
  TabButton
} from '@ubisend/pulse-components';
import { useLanguages, LanguagesConsumer } from '@ubisend/pulse-hooks';

import { useBotSettings } from '../../hooks/index';

const ConverseBannerSettings = props => {
  const {
    settings,
    setSettings,
    language,
    setLanguage,
    bannerTitle,
    bannerSubtitle,
    focusBanner,
    focusBot
  } = useBotSettings();
  const { languages } = useLanguages();

  const handleShowBannerToggle = () => {
    settings.banner_enabled ? focusBot() : focusBanner();

    setSettings(settings => ({
      ...settings,
      banner_enabled: !settings.banner_enabled
    }));
  };

  const handleBannerTitleChange = event => {
    const newTitle = event.target.value;

    setSettings(settings => ({
      ...settings,
      banner_titles: settings.banner_titles.map(title => {
        if (title.language_id === language.id) {
          return {
            language_id: language.id,
            content: { banner_title: newTitle }
          };
        }

        return title;
      })
    }));
  };

  const handleBannerSubtitleChange = event => {
    const newSubtitle = event.target.value;

    setSettings(settings => ({
      ...settings,
      banner_subtitles: settings.banner_subtitles.map(subtitle => {
        if (subtitle.language_id === language.id) {
          return {
            language_id: language.id,
            content: { banner_subtitle: newSubtitle }
          };
        }

        return subtitle;
      })
    }));
  };

  return (
    <Flex>
      {props.titlePosition === 'left' && (
        <OneQuarterLayout>
          <InfoSection
            title="Banner"
            info="Show or hide banner, set title and subtitle."
          />
        </OneQuarterLayout>
      )}
      <Panel>
        {props.titlePosition === 'top' && (
          <>
            <InfoSection
              mtNone
              title="Banner"
              info="Show or hide banner, set title and subtitle."
            />
            <Divider />
          </>
        )}
        <Flex col ySpace>
          <LanguagesConsumer>
            <Flex>
              <AnimateSharedLayout>
                {languages.map(languageTab => (
                  <TabButton
                    onClick={() => setLanguage(languageTab)}
                    active={languageTab.id === language.id}
                    key={languageTab.id}>
                    {languageTab.name}
                  </TabButton>
                ))}
              </AnimateSharedLayout>
            </Flex>
            <Divider fullWidth mtNone />
          </LanguagesConsumer>
          <Flex col ySpace>
            <Accordion
              header="Show banner"
              primary
              hasToggle
              checked={Boolean(settings.banner_enabled)}
              onChange={handleShowBannerToggle}>
              <Flex col mb>
                <Label htmlFor="banner_title">Banner title</Label>
                <TextInput
                  id="banner_title"
                  aria-label="bot-banner-title"
                  value={bannerTitle}
                  onChange={handleBannerTitleChange}
                  onClick={focusBanner}
                  maxLength="255"
                />
              </Flex>
              <Flex col ySpaceSm>
                <Flex xSpace between>
                  <Label htmlFor="banner_subtitle">Banner subtitle</Label>
                  <Flex xSpaceSm center>
                    <ProgressArc progress={bannerSubtitle.length / 500} />
                    <Label mb={false} secondary>
                      {bannerSubtitle.length} / 500
                    </Label>
                  </Flex>
                </Flex>
                <TextArea
                  id="banner_subtitle"
                  rows={8}
                  aria-label="bot-banner-subtitle"
                  value={bannerSubtitle}
                  onChange={handleBannerSubtitleChange}
                  onClick={focusBanner}
                  maxLength="500"
                />
              </Flex>
            </Accordion>
          </Flex>
        </Flex>
      </Panel>
    </Flex>
  );
};

ConverseBannerSettings.propTypes = {
  titlePosition: PropTypes.string
};

ConverseBannerSettings.defaultProps = {
  titlePosition: 'left'
};

export default ConverseBannerSettings;
