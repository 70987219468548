const connectionPath = (
  ctx,
  link,
  botTheme,
  panX,
  panY,
  zoomAmount,
  getCurve
) => {
  if (process.env.NODE_ENV === 'test') {
    return;
  }

  ctx.save();
  ctx.fillStyle = 'transparent';
  ctx.strokeStyle =
    link.to.type === 'validation' ? botTheme.black : botTheme.grey;
  ctx.lineWidth = 3;

  const curve = getCurve(
    link,
    panX.get() / zoomAmount.get(),
    panY.get() / zoomAmount.get()
  );
  const path = new Path2D(curve);

  ctx.fill(path);
  ctx.stroke(path);

  ctx.restore();
};

export default connectionPath;
