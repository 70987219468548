import React from 'react';
import { Switch } from 'react-router-dom';

import { Route } from '@ubisend/pulse-auth';

import { License } from './Pages/index';

const routes = [
  {
    name: 'Manage User',
    path: '/owner/licenses/:id',
    component: License,
    canAccess: () => true
  }
];

const LicenseRouter = () => {
  return (
    <Switch>
      {routes.map((route, key) => (
        <Route
          exact
          public={false}
          key={key}
          path={route.path}
          canAccess={() => true}
          render={props => <route.component {...props} />}
        />
      ))}
    </Switch>
  );
};

export default LicenseRouter;
