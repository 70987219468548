import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  Button,
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormGroup,
  Label,
  TextInput
} from '@ubisend/pulse-components';

import { WebhookActionSelect, WebhookVariableSelect } from './index';

const defaultWebhook = {
  name: '',
  referring_domain: '',
  webhook_action_type_id: null,
  variable_id: null
};

const WebhookModal = ({
  handleClose,
  handleSubmit,
  initialWebhook = defaultWebhook,
  loading
}) => {
  const [webhook, setWebhook] = useState(initialWebhook);

  const isValid = () => {
    if (webhook.webhook_action_type_id) {
      return Boolean(
        webhook.name &&
          webhook.referring_domain &&
          webhook.webhook_action_type_id &&
          webhook.variable_id
      );
    }

    return Boolean(webhook.name && webhook.referring_domain);
  };

  const valid = isValid();

  const onSubmit = event => {
    event.preventDefault();

    if (valid && !loading) {
      handleSubmit(webhook);
    }
  };

  const handleNameChange = event => {
    const value = event.target.value;

    setWebhook({ ...webhook, name: value });
  };

  const handleUrlChange = event => {
    const value = event.target.value;

    setWebhook({ ...webhook, referring_domain: value });
  };

  const handleActionChange = event => {
    // If user clears action select, set both variables to null.
    if (!event) {
      return setWebhook({
        ...webhook,
        webhook_action_type_id: null,
        variable_id: null
      });
    }

    const value = event.value;

    setWebhook({ ...webhook, webhook_action_type_id: value });
  };

  const handleVariableChange = event => {
    if (!event) {
      return setWebhook({ ...webhook, variable_id: null });
    }

    const value = event.value;

    setWebhook({ ...webhook, variable_id: value });
  };

  return (
    <ModalPortal>
      <ModalContainer>
        <ModalContent>
          <ModalHeader>Manage webhook</ModalHeader>
          <form onSubmit={onSubmit}>
            <ModalBody>
              <Flex ySpace col>
                <FormGroup>
                  <Label htmlFor="name">Name</Label>
                  <TextInput
                    id="name"
                    placeholder="Example webhook"
                    value={webhook.name}
                    onChange={handleNameChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="name">Refering URL</Label>
                  <TextInput
                    id="url"
                    placeholder="https://example.com/webhook"
                    value={webhook.referring_domain}
                    onChange={handleUrlChange}
                  />
                </FormGroup>
                <WebhookActionSelect
                  handleActionChange={handleActionChange}
                  value={webhook.webhook_action_type_id}
                />
                {Boolean(webhook.webhook_action_type_id) && (
                  <WebhookVariableSelect
                    handleVariableChange={handleVariableChange}
                    value={webhook.variable_id}
                  />
                )}
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Flex right>
                <Flex xSpace>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={!valid || loading}
                    loading={loading}>
                    Save
                  </Button>
                </Flex>
              </Flex>
            </ModalFooter>
          </form>
        </ModalContent>
      </ModalContainer>
    </ModalPortal>
  );
};

WebhookModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialWebhook: PropTypes.shape({
    name: PropTypes.string.isRequired,
    referring_domain: PropTypes.string.isRequired
  }),
  loading: PropTypes.bool.isLoading
};

export default WebhookModal;
