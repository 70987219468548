import React from 'react';
import tw, { styled } from 'twin.macro';

import { motion } from '@ubisend/framer-motion';

import { useBot } from '../../../../hooks/index';

const StyledAvatarName = styled(motion.span)`
  ${tw`text-sm mb-1 text-black`}
`;

const AvatarName = ({ children }) => {
  const { showName } = useBot();

  if (!showName) {
    return null;
  }

  return (
    <StyledAvatarName
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.3 }}>
      {children}
    </StyledAvatarName>
  );
};

export default AvatarName;
