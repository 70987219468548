import React from 'react';

import { useQueryClient, useMutation } from '@ubisend/pulse-hooks';
import {
  Flex,
  useNotification,
  Panel,
  Button,
  Explainer
} from '@ubisend/pulse-components';

import { removeTelegramSettings } from '../api/index';

const UpdateTelegramSettings = () => {
  const { showSuccess, showError } = useNotification();

  const queryClient = useQueryClient();
  const { mutate: deleteSettings } = useMutation(removeTelegramSettings, {
    onSuccess: () => {
      showSuccess(`Successfully unlinked Telegram integration`);
      queryClient.invalidateQueries('telegram/settings');
    },
    onError: () => {
      showError(`Something went wrong whilst unlinking Telegram integration`);
    }
  });

  const handleDeleteSettings = () => deleteSettings();

  return (
    <Panel header="Update Telegram settings">
      <Explainer mb>
        You have successfully connected to Telegram. If you would like to update
        your connection, please remove your existing connection first.
      </Explainer>
      <Flex mt>
        <Button colour="danger" onClick={handleDeleteSettings}>
          Remove connection
        </Button>
      </Flex>
    </Panel>
  );
};

export default UpdateTelegramSettings;
