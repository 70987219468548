import tw, { styled } from 'twin.macro';

const Header = styled.div`
  ${tw`p-4 shadow z-20 relative`};
  color: ${props => props.theme.bot.textColour};
  background: linear-gradient(
    to right bottom,
    ${props => props.theme.bot.gradient.from} 0%,
    ${props => props.theme.bot.gradient.to} 100%
  );
  @media only screen and (min-width: 420px) {
    ${tw`bot:rounded bot:rounded-bl-none bot:rounded-br-none `}
  }
`;

const TallHeader = styled(Header)`
  ${tw`flex-grow break-words block overflow-y-auto`}
  max-height: 576px;
`;

export default Header;
export { TallHeader };
