import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import {
  Flex,
  Label,
  Button,
  useNotification
} from '@ubisend/pulse-components';
import { EmailSelect } from '@ubisend/pulse-integrations';

import Modal from '../../../Modal';
import { syncStepEmails } from '../../../../api/index';
import { useBuilder } from '../../../../hooks/index';

const AddEmailBlock = ({ handleClose, node }) => {
  const [email, setEmail] = useState(null);

  const { showSuccess } = useNotification();
  const { dispatch } = useBuilder();

  const { mutate, isLoading } = useMutation(syncStepEmails, {
    onSuccess: ({ data }) => {
      showSuccess('Successfully added a new email');
      dispatch({
        type: 'SYNC_STEP_EMAILS',
        stepId: node.id,
        newEmails: data.data
      });
      handleClose();
    }
  });

  const handleEmailChange = email => {
    setEmail(email ? email.value : null);
  };

  const valid = useMemo(() => {
    return Boolean(email);
  }, [email]);

  const handleSubmit = event => {
    event.preventDefault();

    if (!valid || isLoading) {
      return;
    }

    mutate({
      stepId: node.id,
      emails: node.base.emails.map(email => email.id).concat(email)
    });
  };

  return (
    <Modal header="Add email" handleClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <Flex pad col>
          <Flex mb col>
            <Label htmlFor="email">Email</Label>
            <EmailSelect
              id="email"
              value={email}
              onChange={handleEmailChange}
            />
          </Flex>
          <Flex right>
            <Button
              variant="primary"
              type="submit"
              disabled={!valid || isLoading}>
              Save
            </Button>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};

AddEmailBlock.propTypes = {
  handleClose: PropTypes.func.isRequired,
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
    base: PropTypes.shape({
      emails: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired
      ).isRequired
    }).isRequired
  }).isRequired
};

export default AddEmailBlock;
