import React from 'react';
import PropTypes from 'prop-types';

import {
  TextInput,
  Flex,
  Label,
  Button,
  FormGroup
} from '@ubisend/pulse-components';

import VariableSettingsModal from './VariableSettingsModal';
import { useVariableReducer } from '../../reducers/index';
import { VariableContext } from '../../Contexts/index';
import subjects from '../../subjects/variables/index';
import types from './Types/index';
import scopes from './Scopes/index';

const VariableDetails = ({
  initialVariable,
  onSubmit,
  canEditScope,
  canEditType
}) => {
  const variable = useVariableReducer({
    types: types.default,
    scopes: scopes.default,
    variable: initialVariable,
    subjects: [subjects.integration, subjects.variable, subjects.url],
    canEditScope,
    canEditType
  });

  const handleSubmit = event => {
    event.preventDefault();
    onSubmit(variable.form);
  };

  const handleNameChange = event => {
    const name = event.target.value;
    variable.dispatch({ type: variable.TYPES.UPDATE_NAME, name });
  };

  const handleSubjectOpen = () => {
    variable.dispatch({ type: variable.TYPES.OPEN_SETTINGS });
  };

  return (
    <>
      {variable.variable.showSettings && (
        <VariableContext.Provider value={variable}>
          <VariableSettingsModal />
        </VariableContext.Provider>
      )}
      <form onSubmit={handleSubmit}>
        <Flex ySpace col>
          <FormGroup>
            <Label htmlFor="name">Variable Name</Label>
            <TextInput
              placeholder="my_variable"
              id="name"
              value={variable.variable.name}
              onChange={handleNameChange}
            />
          </FormGroup>
          <Flex right xSpace>
            <Button variant="secondary" onClick={handleSubjectOpen}>
              Advanced settings
            </Button>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </Flex>
        </Flex>
      </form>
    </>
  );
};

VariableDetails.propTypes = {
  canEditScope: PropTypes.bool,
  canEditType: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  initialVariable: PropTypes.shape({
    name: PropTypes.string.isRequired,
    subject: PropTypes.shape({
      type: PropTypes.string
    })
  })
};
export default VariableDetails;
