import axios from 'axios';

const createLink = params => axios.post('links', params);

const updateLink = ({ id, ...params }) => axios.put(`links/${id}`, params);

const deleteLink = id => axios.delete(`links/${id}`);

const enablement = ({ id, ...params }) => {
  return axios.put(`links/${id}/enable`, params);
};

const linkUsage = id => {
  return axios.get(`links/${id}/usage`);
};

export { createLink, updateLink, deleteLink, enablement, linkUsage };
