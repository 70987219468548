import React from 'react';

import { Flex, EmptyStatePlaceholder } from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { useImports } from '../hooks/index';
import { NewImportButton } from '../Components/index';

const NoSelection = () => {
  const { imports } = useImports();

  return (
    <Flex middle center fat ySpace col>
      <EmptyStatePlaceholder
        type="knowledgebase"
        customHeight="500px"
        heading={
          imports.length > 0
            ? 'Select or add a knowledge base to get started'
            : 'Upload a knowledge base to get started'
        }
        text="Create, add, or import knowledge into your automation. "
        actions={
          <PermissionFilter can="create knowledge bases">
            <NewImportButton icon="plus" variant="primary">
              New Knowledge base
            </NewImportButton>
          </PermissionFilter>
        }
        helpLink="/docs/2151317744/2153218105"
        helpText="Learn more about knowledge bases."
      />
    </Flex>
  );
};

export default NoSelection;
