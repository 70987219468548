import React from 'react';

import {
  PageWrapper,
  OneHalfLayout,
  Flex,
  Panel,
  Placeholder
} from '@ubisend/pulse-components';
import { useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import { PolicyAccept, BackButton } from '@ubisend/pulse-channels';

import TeamsSettings from './TeamsSettings';
import { acceptPolicy as acceptPolicyApi } from '../api';
import { useTeamsSettings } from '../hooks/index';

const TeamsLanding = () => {
  const { settings, isSuccess, isLoading } = useTeamsSettings();

  const queryClient = useQueryClient();

  const { mutate: acceptPolicy } = useMutation(acceptPolicyApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('teams/settings');
    }
  });

  const PolicyCheck = () => {
    if (!settings.has_given_data_consent) {
      return (
        <PolicyAccept
          handlePolicyAccept={acceptPolicy}
          policyUrl="https://learn.microsoft.com/en-us/legal/microsoft-apis/terms-of-use"
        />
      );
    }

    return <TeamsSettings data-testid="teams-settings" />;
  };

  return (
    <PageWrapper
      center
      half
      header="Microsoft Teams"
      prevAction={<BackButton to="/channels" />}>
      <Flex col center>
        <OneHalfLayout>
          {isLoading && (
            <Panel>
              <Placeholder items={1} subitems={3} />
            </Panel>
          )}
          {isSuccess && <PolicyCheck />}
        </OneHalfLayout>
      </Flex>
    </PageWrapper>
  );
};

export default TeamsLanding;
