import React from 'react';
import { useTranslation } from 'react-i18next';

import { Span } from '@ubisend/pulse-components';

import { useDelivery, useQuery } from '../../hooks/index';
import { VERSION } from '../../constants';

const Version = () => {
  const { base_url } = useDelivery();

  const { t } = useTranslation('bots');

  const { data, isSuccess } = useQuery('pulse/version', {
    baseUrl: `${base_url}/api`
  });

  if (!isSuccess) {
    return null;
  }

  return (
    <Span tinyText>
      {t('setting_version_label')} {VERSION} ({data.version})
    </Span>
  );
};

export default Version;
