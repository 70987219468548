import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  useComposer,
  useDelivery,
  useUploadFiles,
  useSendFiles,
  useDelayedBot,
  useTyping
} from '../../../../../hooks/index';
import { useNotificationReducer } from '../../../../../reducers/index';
import {
  isValidMessage,
  formatClientMessage,
  formatServerMessage,
  isTemporaryFileId,
  getTemporaryFileIndex
} from '../../../../../utilities/index';
import {
  MAX_MESSAGE_LENGTH,
  MIN_MESSAGE_LIMIT,
  MAX_FILE_COUNT,
  COMBINED_MAX_FILE_BYTES,
  UPLOADED_FILE_ID_PREFIX
} from '../../../../../constants';
import BaseComposer from '../BaseComposer';

const isValid = message => {
  return isValidMessage(message, {
    min: MIN_MESSAGE_LIMIT,
    max: MAX_MESSAGE_LENGTH
  });
};

const MessageComposer = ({ content: passedContent = {} }) => {
  const { reply, setReply } = useComposer();
  const { sendMessage } = useDelivery();
  const {
    setMessages,
    setQueuedMessages,
    showBurgerIcon,
    setShowBurgerMenu
  } = useDelayedBot();
  const typing = useTyping();
  const notification = useNotificationReducer();

  const { t } = useTranslation('bots');

  const sendFileMutation = useSendFiles({
    onSuccess: ({ data }) => {
      setMessages(messages => {
        return messages.map(message => {
          if (isTemporaryFileId(message.id)) {
            const file = data.files[getTemporaryFileIndex(message.id)];

            return formatClientMessage({
              type: 'file',
              content: {
                text: message.content.text,
                url: file.link
              }
            });
          }

          return message;
        });
      });

      if (data.messages.length > 0) {
        setQueuedMessages(messages => {
          return messages.concat(data.messages.map(formatServerMessage));
        });
      } else {
        typing.dispatch({ type: typing.TYPES.STOP_TYPING });
      }
    },
    onError: () => {
      typing.dispatch({ type: typing.TYPES.STOP_TYPING });

      notification.dispatch({
        type: notification.TYPES.SHOW_ERROR,
        message: t('processing_file_error_message')
      });
    }
  });
  const uploadFileMutation = useUploadFiles({
    onSuccess: files => {
      setMessages(messages => {
        return messages.concat(
          files.map((file, key) => {
            return formatClientMessage({
              id: `${UPLOADED_FILE_ID_PREFIX}_${key}`,
              type: 'file',
              content: { text: file.file.name }
            });
          })
        );
      });

      sendFileMutation.mutate({
        files: files.map(file => file.path)
      });
    },
    onError: () => {
      typing.dispatch({ type: typing.TYPES.STOP_TYPING });

      notification.dispatch({
        type: notification.TYPES.SHOW_ERROR,
        message: t('upload_file_error_message')
      });
    }
  });

  const content = passedContent || {
    type: 'text',
    placeholder: t('message_composer_label'),
    disabled: false,
    show_file_upload: false
  };

  const handleOnChange = event => {
    const value = event.target.value;

    if (value.length >= MAX_MESSAGE_LENGTH) {
      return;
    }

    setReply(value);
  };

  const handleSubmit = () => {
    const validatedMessage = isValid(reply);

    if (!validatedMessage) {
      return;
    }

    if (content.type === 'password') {
      sendMessage(
        // Replace message with asterisks.
        new Array(validatedMessage.length).fill('*').join(''),
        validatedMessage
      );
    } else {
      sendMessage(validatedMessage);
    }

    setReply('');
  };

  const handleFileSelect = event => {
    if (event.target.files.length > MAX_FILE_COUNT) {
      return notification.dispatch({
        type: notification.TYPES.SHOW_WARNING,
        message: t('file_count_exceeded_error_message')
      });
    }

    const combinedFileSize = [...event.target.files].reduce((size, file) => {
      return size + file.size;
    }, 0);

    if (combinedFileSize > COMBINED_MAX_FILE_BYTES) {
      return notification.dispatch({
        type: notification.TYPES.SHOW_WARNING,
        message: t('file_size_exceeded_error_message')
      });
    }

    typing.dispatch({
      type: typing.TYPES.START_TYPING,
      message: t('uploading_files_label')
    });

    uploadFileMutation.mutate(event.target.files);
  };

  const toggleBurgerMenu = () => {
    setShowBurgerMenu(open => !open);
  };

  const handleNotificationClose = () => {
    notification.dispatch({ type: notification.TYPES.HIDE_NOTIFICATION });
  };
  return (
    <BaseComposer
      type={content.type}
      value={reply}
      onChange={handleOnChange}
      handleSubmit={handleSubmit}
      showBurgerIcon={showBurgerIcon}
      toggleBurgerMenu={toggleBurgerMenu}
      showFileUpload={content.show_file_upload}
      disableFileUpload={
        uploadFileMutation.isDisabled ||
        sendFileMutation.isLoading ||
        uploadFileMutation.isLoading
      }
      handleFileSelect={handleFileSelect}
      aria-label={content.placeholder}
      placeholder={content.placeholder}
      disabled={content.disabled}
      notification={notification}
      handleNotificationClose={handleNotificationClose}
    />
  );
};

MessageComposer.propTypes = {
  content: PropTypes.shape({
    placeholder: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    show_file_upload: PropTypes.bool.isRequired
  })
};

export default MessageComposer;
