import React from 'react';

const Robo = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}>
    <path
      stroke="currentColor"
      strokeWidth={2}
      d="M20.5 6a1 1 0 0 0-1-1h-15a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h15a1 1 0 0 0 1-1V6Z"
    />
    <path
      stroke="currentColor"
      d="M13.863 10a2 2 0 1 0 4 0 2 2 0 0 0-4 0ZM6.137 10a2 2 0 1 0 4 0 2 2 0 0 0-4 0ZM20.5 11.5a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      d="m7.874 14.965.727.678h6.941l.583-.678"
    />
    <path
      stroke="currentColor"
      d="M3.5 11.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
    />
  </svg>
);

export default Robo;
