import EmailBlock from './EmailBlock';
import AddEmailBlock from './AddEmailBlock';
import EditEmailBlock from './EditEmailBlock';

const email = {
  id: 'email',
  name: 'Email',
  icon: 'mail',
  permission: 'emails',
  Block: EmailBlock,
  AddBlock: AddEmailBlock,
  EditBlock: EditEmailBlock
};

export default email;
