import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

import Icon from '@ubisend/pulse-icons';

import { Flex } from '../Layout/index';
import Panel from './Panel';

const IconImage = styled(Icon)`
  ${tw`w-12 h-12 mr-4`}
`;
const Title = styled.p`
  ${tw`m-0`}
`;
const Info = styled.p`
  ${tw`opacity-50 m-0 text-xs mt-2`}
`;
const SubTitle = styled.p`
  ${tw`m-0 text-xs mt-2`}
`;

const IconPanel = ({ actions, icon = 'document', title, subtitle, status }) => {
  return (
    <Panel>
      <Flex between center>
        <Flex center>
          {icon && <IconImage alt="panel-icon" type={icon} />}
          <Flex col middle>
            {title && <Title>{title}</Title>}
            {status && <Info>{status}</Info>}
            {subtitle && <SubTitle>{subtitle}</SubTitle>}
          </Flex>
        </Flex>
        {actions}
      </Flex>
    </Panel>
  );
};

IconPanel.propTypes = {
  actions: PropTypes.element,
  icon: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  status: PropTypes.string
};

export default IconPanel;
