import tw, { styled } from 'twin.macro';
import { Link } from 'react-router-dom';

const SecondarySidebarHeader = styled.div`
  ${tw`border-gray-300 border-0 border-b border-solid p-4`}
  & h1 {
    ${tw`m-0`}
  }
`;

const SecondarySidebarContainer = styled.div`
  ${tw`w-full flex flex-col bg-white shadow flex-shrink-0`}
  width: 20rem;
`;

const SecondarySidebarContent = styled.div`
  ${tw`overflow-y-auto p-4`}
`;

const SecondarySidebarRoot = styled(Link)`
  ${tw`uppercase no-underline font-poppins tracking-wide text-xs text-black`};
  transition: opacity ease-in-out 0.1s;
  &:hover {
    ${props => !props.active && `opacity: 0.75;`}
  }
  ${props => {
    return props.active ? tw`opacity-100` : tw`opacity-50`;
  }}
`;

const SecondarySidebarLink = styled(Link)`
  ${tw`py-1 rounded-sm my-1 no-underline hover:opacity-100 text-xs text-black px-3 py-2`}
  ${props => props.active && 'background: rgba(104,75,153,12.5%);'}
  ${props => {
    return props.active ? tw`opacity-100 text-core-primary` : tw`opacity-50`;
  }}
`;

export {
  SecondarySidebarHeader,
  SecondarySidebarContainer,
  SecondarySidebarContent,
  SecondarySidebarRoot,
  SecondarySidebarLink
};
