import axios from 'axios';

const getFaq = faqId => axios.get(`faqs/${faqId}`);

const createFaq = ({ name, randomResponse, utterances, locationId = null }) => {
  return axios.post('faqs', {
    name,
    should_use_random_response: randomResponse,
    ...(utterances?.length > 0 && { utterances }),
    ...(locationId && { location_id: locationId })
  });
};

const deleteFaq = faqId => axios.delete(`faqs/${faqId}`);

const deleteFaqs = faqIds => axios.put(`faqs/delete`, faqIds);

const updateFaq = ({
  faqId,
  randomResponse,
  newName,
  locationId = null,
  sharedFaqId = null
}) => {
  return axios.put(`faqs/${faqId}`, {
    name: newName,
    should_use_random_response: randomResponse,
    ...(locationId && { location_id: locationId }),
    ...(sharedFaqId && { faq_id: sharedFaqId })
  });
};

const duplicateFaq = ({
  faqId,
  name,
  randomResponse,
  utterances,
  locationId = null,
  sharedFaqId = null
}) => {
  return axios.post(`faqs/${faqId}/duplicate-responses`, {
    name,
    should_use_random_response: randomResponse,
    ...(utterances?.length > 0 && { utterances }),
    ...(locationId && { location_id: locationId }),
    ...(sharedFaqId && { faq_id: sharedFaqId })
  });
};

const exportFaqs = () => axios.get('/faqs/export');

const importFaqs = ({ fileId }) => {
  return axios.post('faqs/import', { file_id: fileId });
};

export {
  getFaq,
  createFaq,
  deleteFaq,
  deleteFaqs,
  updateFaq,
  duplicateFaq,
  exportFaqs,
  importFaqs
};
