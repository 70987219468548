import React from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Select, formatSelectOption } from '@ubisend/pulse-components';

const UnderstoodAsSelect = ({ value, ...props }) => {
  const { data, isLoading, isSuccess } = useQuery([
    'intents',
    { used_for_training: true }
  ]);

  const handleData = () => {
    if (!data?.data) {
      return [];
    }

    return data.data
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
      .map(formatSelectOption);
  };

  return (
    <Select
      aria-label="understood_as"
      closeMenuOnSelect
      isClearable
      isLoading={isLoading}
      options={isSuccess && handleData(data)}
      value={
        isSuccess &&
        value &&
        formatSelectOption(
          data.data.find(intent => {
            return intent.id === value;
          })
        )
      }
      {...props}
    />
  );
};

UnderstoodAsSelect.propTypes = {
  value: PropTypes.string
};

export default UnderstoodAsSelect;
