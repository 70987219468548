import axios from 'axios';

const approveSectionContent = (importId, sectionId, contentId) => {
  return axios.put(
    `imports/${importId}/sections/${sectionId}/content/${contentId}`,
    {
      approved: true
    }
  );
};

const deleteSectionContent = (importId, sectionId, contentId) => {
  return axios.delete(
    `imports/${importId}/sections/${sectionId}/content/${contentId}`
  );
};

export { approveSectionContent, deleteSectionContent };
