import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Label } from '@ubisend/pulse-components';
import { TemplatingTextInput } from '@ubisend/pulse-templating';

import { useIntegrations } from '../../hooks/index';
import MethodSelect from './MethodSelect';

const Request = ({
  method,
  setMethod,
  endpoint,
  setEndpoint,
  soapFunction,
  setSoapFunction
}) => {
  const { viewEndpoints: integration } = useIntegrations();

  const handleEndpointChange = value => setEndpoint(value);

  const handleMethodChange = ({ value }) => {
    setMethod(value);
  };

  const handleSoapFunctionChange = value => setSoapFunction(value);

  return (
    <Flex center top xSpace fat>
      {integration.is_soap ? (
        <Flex col fat>
          <Label htmlFor="soap-call-function">Call</Label>
          <TemplatingTextInput
            id="soap-call-function"
            placeholder="getUser"
            value={soapFunction}
            onChange={handleSoapFunctionChange}
          />
        </Flex>
      ) : (
        <Flex col fat>
          <Label htmlFor="soap-request-type">Make</Label>
          <MethodSelect
            id="soap-request-type"
            value={method}
            onChange={handleMethodChange}
          />
        </Flex>
      )}
      <Flex selfStretch col fat>
        <Label htmlFor="integration-name" noWrap>
          {integration.is_soap ? 'on' : 'request to'}
        </Label>
        <Flex grow center>
          <span id="integration-name">{integration.name}</span>
        </Flex>
      </Flex>
      <Flex col fat>
        <Label htmlFor="soap-endpoint">at</Label>
        <Flex fat>
          <TemplatingTextInput
            id="soap-endpoint"
            placeholder="/users"
            value={endpoint}
            onChange={handleEndpointChange}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

Request.propTypes = {
  method: PropTypes.string,
  setMethod: PropTypes.func.isRequired,
  endpoint: PropTypes.string,
  setEndpoint: PropTypes.func,
  soapFunction: PropTypes.string,
  setSoapFunction: PropTypes.func
};

export default Request;
