import { hasFeature, hasPermission } from '@ubisend/pulse-auth';

import {
  Faqs,
  DuplicateFaq,
  CreateFaq,
  Responses,
  FollowUp,
  Locations,
  Questions
} from './Pages/index';
import FaqRegressionTesting from './Pages/FaqRegressionTesting';

const routes = [
  {
    path: '/faqs',
    component: Faqs,
    name: 'FAQs',
    canAccess: hasPermission('view faqs'),
    actions: [
      {
        title: 'Manage current FAQs',
        description: 'Create, update and delete FAQs.',
        icon: 'questionMarkCircle'
      }
    ]
  },
  {
    path: '/faqs/follow-up',
    component: FollowUp,
    name: 'Follow Up',
    canAccess: hasPermission('edit faq settings'),
    actions: [
      {
        title: 'Enable / disable FAQ follow up',
        description: "Gather user feedback on your chatbot's performance.",
        icon: 'questionMarkCircle'
      }
    ]
  },
  {
    path: '/faqs/:faqId/questions',
    component: Questions,
    name: 'Questions',
    canAccess: hasPermission('edit faqs')
  },

  {
    path: '/faqs/:faqId/duplicate',
    component: DuplicateFaq,
    name: 'Duplicate FAQ',
    canAccess: hasPermission('create faqs')
  },
  {
    path: '/faqs/create',
    component: CreateFaq,
    name: 'Create FAQ',
    canAccess: hasPermission('create faqs')
  },
  {
    path: '/faqs/:faqId/responses',
    component: Responses,
    name: 'Answers',
    canAccess: hasPermission('view faqs')
  },
  {
    path: '/locations',
    component: Locations,
    name: 'Faq Locations',
    canAccess: hasPermission('edit locations')
  },
  {
    path: '/regression-testing',
    component: FaqRegressionTesting,
    name: 'Regression Testing',
    canAccess: hasFeature('faq regression testing')
  }
];

export default routes;
