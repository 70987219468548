import React, { useMemo } from 'react';
import tw, { styled } from 'twin.macro';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Flex,
  Heading2,
  Heading3,
  Panel,
  Grid,
  Paragraph,
  useTheme,
  Divider
} from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';
import { useAuth } from '@ubisend/pulse-auth';

import routes from '../routes';

const StyledIcon = styled(Icon)`
  ${tw`opacity-75`}
`;

const ActionPanel = styled(Panel)`
  ${tw`p-0 flex flex-col opacity-100 hover:opacity-75 cursor-pointer`}
  box-sizing: border-box;
  a {
    ${tw`no-underline text-black`}
  }
`;

const ActionDetails = styled(Flex)`
  ${tw`px-4 pb-4`}
`;

const Action = ({ title, description, icon, path }) => {
  const { primary } = useTheme();

  return (
    <ActionPanel>
      <Link to={path.replace('*', '')}>
        <Flex middle center pad>
          <StyledIcon
            colour={primary}
            type={icon}
            height="8rem"
            width="8rem"
            size="8rem"
          />
        </Flex>
        <Divider />
        <ActionDetails fat col>
          <Heading3>{title}</Heading3>
          <Paragraph secondary>{description}</Paragraph>
        </ActionDetails>
      </Link>
    </ActionPanel>
  );
};

Action.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired
};

const defaultCanAccess = () => true;

const Actions = () => {
  const auth = useAuth();

  const actions = useMemo(() => {
    return routes
      .reduce(
        (allActions, { actions = [], canAccess = defaultCanAccess, path }) => {
          if (!canAccess(auth)) {
            return allActions;
          }

          return allActions.concat(
            actions.map(action => ({ ...action, path }))
          );
        },
        []
      )
      .map(a => ({ sort: Math.random(), value: a }))
      .sort((a, b) => a.sort - b.sort)
      .map(a => a.value)
      .slice(1, 9);
  }, [auth]);

  if (actions.length === 0) {
    return null;
  }

  return (
    <Flex col ySpace>
      <Flex>
        <Heading2>Pick up where you left off</Heading2>
      </Flex>
      <Grid columns={4}>
        {actions.map((action, key) => (
          <Action key={key} {...action} />
        ))}
      </Grid>
    </Flex>
  );
};

export default Actions;
