import axios from 'axios';

const updateTelegramSettings = settings => {
  return axios.put('telegram/settings', settings);
};

const removeTelegramSettings = () => axios.delete('telegram/settings');

const acceptPolicy = () => axios.put('telegram/settings/accept');

export { updateTelegramSettings, removeTelegramSettings, acceptPolicy };
