import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Panel,
  Flex,
  OneQuarterLayout,
  InfoSection,
  Button,
  Divider,
  Accordion,
  Radio,
  Label,
  Paragraph
} from '@ubisend/pulse-components';
import { ImageSelect } from '@ubisend/pulse-files';

import { useBotSettings } from '../../hooks/index';

const AvatarSettings = props => {
  const { settings, setSettings, focusBot } = useBotSettings();

  const [botImage, setBotImage] = useState(false);
  const [humanImage, setHumanImage] = useState(false);
  const [useDefaultAvatars, setUseDefaultAvatars] = useState(
    !(settings.bot_avatar_url && settings.human_avatar_url)
  );

  const [customBotAvatar, setCustomBotAvatar] = useState(
    settings.bot_avatar_url
  );
  const [customHumanAvatar, setCustomHumanAvatar] = useState(
    settings.human_avatar_url
  );

  const toggleBotImage = () => setBotImage(open => !open);

  const toggleHumanImage = () => setHumanImage(open => !open);

  const handleBotImage = ({ link }) => {
    setSettings({ ...settings, bot_avatar_url: link });
    setCustomBotAvatar(link);
    toggleBotImage();
  };

  const handleHumanImage = ({ link }) => {
    setSettings({ ...settings, human_avatar_url: link });
    setCustomHumanAvatar(link);
    toggleHumanImage();
  };

  const handleDefaultAvatarChange = useDefault => {
    focusBot();

    if (useDefault) {
      setSettings(prevSettings => ({
        ...prevSettings,
        bot_avatar_url: null,
        human_avatar_url: null
      }));
    } else {
      setSettings(prevSettings => ({
        ...prevSettings,
        bot_avatar_url: customBotAvatar,
        human_avatar_url: customHumanAvatar
      }));
    }

    setUseDefaultAvatars(useDefault);
  };

  const handleAvatarEnabledChange = () => {
    focusBot();
    setSettings(settings => ({
      ...settings,
      avatars_enabled: !settings.avatars_enabled
    }));
  };

  return (
    <Flex>
      {props.titlePosition === 'left' && (
        <OneQuarterLayout>
          <InfoSection
            title="Avatars"
            info="Show or hide avatar icons, and upload custom replacements."
          />
        </OneQuarterLayout>
      )}
      <Panel>
        {props.titlePosition === 'top' && (
          <>
            <InfoSection
              mtNone
              title="Avatars"
              info="Show or hide avatar icons, and upload custom replacements."
            />
            <Divider />
          </>
        )}
        <Accordion
          header="Show avatars"
          primary
          hasToggle
          checked={settings.avatars_enabled}
          onChange={handleAvatarEnabledChange}>
          <Flex col ySpace role="radiogroup">
            <Radio
              checked={useDefaultAvatars}
              value="default"
              onChange={() => handleDefaultAvatarChange(true)}
              label="Use default avatars"
              name="avatar"
            />
            <Radio
              checked={!useDefaultAvatars}
              value="custom"
              onChange={() => handleDefaultAvatarChange(false)}
              label="Use custom avatars"
              name="avatar"
            />
            {!useDefaultAvatars && (
              <Flex ml block col>
                <Flex>
                  <Flex border rounded pad col center mr>
                    <Label>Chatbot image</Label>
                    <Paragraph mb smallText colour="grey">
                      {settings.bot_avatar_url === null
                        ? 'No image selected'
                        : 'Current image:'}
                    </Paragraph>
                    {settings.bot_avatar_url && (
                      <img
                        alt="Customisable bot avatar"
                        style={{ width: '2rem', marginBottom: '1rem' }}
                        src={settings.bot_avatar_url}
                      />
                    )}
                    <Button
                      mtAuto
                      variant="secondary"
                      onClick={toggleBotImage}
                      data-testid={'choose-bot-avatar'}>
                      Choose image
                    </Button>
                  </Flex>
                  <Flex border rounded pad col center>
                    <Label>Human image</Label>
                    <Paragraph mb smallText colour="grey">
                      {settings.human_avatar_url === null
                        ? 'No image selected'
                        : 'Current image:'}
                    </Paragraph>
                    {settings.human_avatar_url && (
                      <img
                        alt="Customisable human avatar"
                        style={{ width: '2rem', marginBottom: '1rem' }}
                        src={settings.human_avatar_url}
                      />
                    )}
                    <Button
                      mtAuto
                      variant="secondary"
                      onClick={toggleHumanImage}
                      data-testid={'choose-human-avatar'}>
                      Choose image
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            )}
            {botImage && (
              <ImageSelect
                handleCancel={toggleBotImage}
                handleFileSelect={handleBotImage}
              />
            )}
            {humanImage && (
              <ImageSelect
                handleCancel={toggleHumanImage}
                handleFileSelect={handleHumanImage}
              />
            )}
          </Flex>
        </Accordion>
      </Panel>
    </Flex>
  );
};

AvatarSettings.propTypes = {
  titlePosition: PropTypes.string
};

AvatarSettings.defaultProps = {
  titlePosition: 'left'
};

export default AvatarSettings;
