import chroma from 'chroma-js';

import roundRect from '../roundRect';
import {
  MESSAGE_PREVIEW_PADDING,
  NODE_WIDTH,
  PARAGRAPH_FONT_SIZE
} from '../constants';
import standard from './standard';

const QUICKREPLY_BUTTON_PADDING_X = 13.6;
const QUICKREPLY_BUTTON_PADDING_Y = 6.4;
const QUICKREPLY_BUTTON_LINE_HEIGHT = 22;
const QUICKREPLY_BUTTON_SPACING = MESSAGE_PREVIEW_PADDING / 2;
const QUICKREPLY_BORDER_RADIUS = 17;
const QUICKREPLY_BUTTON_HEIGHT =
  QUICKREPLY_BUTTON_LINE_HEIGHT + QUICKREPLY_BUTTON_PADDING_Y * 2;
// Maximum width of text to stop overflow
const MAX_BUTTON_TEXT_WIDTH =
  NODE_WIDTH - MESSAGE_PREVIEW_PADDING * 2 - QUICKREPLY_BUTTON_PADDING_X * 2;
/**
 * Function to draw a Quick Reply message based on on a node object
 *
 * @param   {CanvasRenderingContext2D}  ctx current canvas rendering context
 * @param   {number}  x     x position of node
 * @param   {number}  y     y position of node
 * @param   {string}  fill  hex, rgba, or string preset colour value to fill the background
 * @param   {object}  response  response object
 *
 * @return  {number}        height of resulting draw object
 */
const quickReply = (ctx, x, y, fill, response) => {
  let height = standard(ctx, x, y, fill, response);
  // If we have no responses, return early as we only need the standard
  if (response.content.responses.length === 0) {
    return height;
  }
  ctx.save();
  ctx.font = `${PARAGRAPH_FONT_SIZE}px Open Sans`;
  ctx.fillStyle = fill;
  // Add space between bubble and buttons
  height += QUICKREPLY_BUTTON_SPACING;
  // Track how far we have traversed horizontally to calulcate wrapping
  let xOffset = 0;
  response.content.responses.forEach(button => {
    // If the text width is wider than container, shrink it to fit
    const textWidth = Math.min(
      MAX_BUTTON_TEXT_WIDTH,
      ctx.measureText(button.label).width
    );

    if (xOffset + textWidth >= MAX_BUTTON_TEXT_WIDTH) {
      // Move onto a new line if the current button overflows horizontally
      height += QUICKREPLY_BUTTON_HEIGHT + QUICKREPLY_BUTTON_SPACING;
      xOffset = 0;
    }

    roundRect(
      ctx,
      x + MESSAGE_PREVIEW_PADDING + xOffset,
      y + MESSAGE_PREVIEW_PADDING + height,
      textWidth + QUICKREPLY_BUTTON_PADDING_X * 2,
      QUICKREPLY_BUTTON_HEIGHT,
      chroma.mix(fill, 'white', 0.5),
      'white',
      QUICKREPLY_BORDER_RADIUS
    );

    ctx.fillText(
      button.label,
      x + xOffset + MESSAGE_PREVIEW_PADDING + QUICKREPLY_BUTTON_PADDING_X,
      y + height + QUICKREPLY_BUTTON_LINE_HEIGHT + MESSAGE_PREVIEW_PADDING,
      MAX_BUTTON_TEXT_WIDTH
    );
    xOffset +=
      textWidth + QUICKREPLY_BUTTON_PADDING_X * 2 + QUICKREPLY_BUTTON_SPACING;
  });
  // Add padding to the bottom of the container
  height +=
    MESSAGE_PREVIEW_PADDING +
    QUICKREPLY_BUTTON_PADDING_Y +
    MESSAGE_PREVIEW_PADDING;
  ctx.restore();
  return height;
};

export default quickReply;
