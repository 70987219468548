import React from 'react';
import { Link } from 'react-router-dom';

import {
  PageWrapper,
  Flex,
  FilterMenu,
  Button
} from '@ubisend/pulse-components';

import { Messages, Filter } from '../Components/index';
import { useFilters } from '../hooks/index';

const Training = () => {
  const filters = useFilters({ id: 'training' });

  return (
    <PageWrapper
      header="Training"
      subheader="Improve your chatbot's knowledge"
      actions={
        <Flex>
          <Flex mr>
            <FilterMenu {...filters.filterMenuProps}>
              <Filter {...filters.filterProps} />
            </FilterMenu>
          </Flex>
          <Flex>
            <Button
              variant="secondary"
              as={Link}
              icon="clock"
              to="/training/history">
              History
            </Button>
          </Flex>
        </Flex>
      }>
      <Messages
        query="training"
        filters={filters.filters}
        pagination={filters.pagination}
        order={filters.order}
      />
    </PageWrapper>
  );
};

export default Training;
