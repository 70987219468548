import React, { useState } from 'react';

import {
  useQuery,
  useMutation,
  useQueryClient,
  useDelete
} from '@ubisend/pulse-hooks';
import {
  PageWrapper,
  StretchPanel as Panel,
  EmptyStatePlaceholder,
  PanelSlider,
  TableActions,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableHeadCell,
  Table,
  Button,
  useNotification,
  Flex,
  LeftHalfLayout,
  RightHalfLayout
} from '@ubisend/pulse-components';
import {
  createComposer as createComposerApi,
  updateComposer as updateComposerApi,
  deleteComposer as deleteComposerApi
} from '@ubisend/pulse-composer';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { ComposerDetails } from '../Components/index';
import { updateResponseSettings as updateResponseSettingsApi } from '../api/index';

const Composers = () => {
  const [create, setCreate] = useState(false);
  const [edit, setEdit] = useState(false);

  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();

  const query = useQuery('composers');
  const createComposer = useMutation(createComposerApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('composers');
      setCreate(false);
      showSuccess('Successfully created a new custom composer');
    }
  });
  const updateComposer = useMutation(updateComposerApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('composers');
      setEdit(false);
      showSuccess('Successfully updated a new custom composer');
    }
  });
  const deleteComposer = useDelete(deleteComposerApi, {
    queries: ['composers']
  });
  const updateResponseSettings = useMutation(updateResponseSettingsApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('composers');
      showSuccess('Successfully updated response settings');
    }
  });

  const handleCreateSave = details => {
    createComposer.mutate(details);
  };

  const handleEditSave = details => {
    updateComposer.mutate([edit.id, details]);
  };

  const handleDelete = details => {
    deleteComposer.handleDelete(details.id);
  };

  const handleDefaultComposer = details => {
    updateResponseSettings.mutate({
      default_composer_id: details.id
    });
  };

  const customComposers = query.data?.data.filter(
    composer => composer.is_custom
  );
  const builtInComposers = query.data?.data.filter(
    composer => !composer.is_custom
  );

  return (
    <PageWrapper
      header="Message composers"
      subheader="Explore and create composers">
      {create && (
        <PanelSlider
          header={`Create Composer`}
          handleHide={() => setCreate(null)}
          width="640px">
          <ComposerDetails handleSubmit={handleCreateSave} />
        </PanelSlider>
      )}
      {edit && (
        <PanelSlider
          header={`Edit Composer`}
          handleHide={() => setEdit(null)}
          width="640px">
          <ComposerDetails
            initialComposer={{
              name: edit.name,
              type: edit.type,
              content: edit.content,
              subject: edit.subject
            }}
            handleSubmit={handleEditSave}
          />
        </PanelSlider>
      )}
      <Flex>
        <LeftHalfLayout>
          <Panel header="Built-in composers">
            <Table loading={query.isLoading} loadingColumns={3}>
              <TableHead>
                <TableRow>
                  <TableHeadCell>Name</TableHeadCell>
                  <TableHeadCell>Type</TableHeadCell>
                  <TableHeadCell />
                </TableRow>
              </TableHead>
              {builtInComposers && (
                <TableBody>
                  {builtInComposers.map((composer, key) => (
                    <TableRow key={key}>
                      <TableCell>{composer.name}</TableCell>
                      <TableCell>{composer.type}</TableCell>
                      <TableActions>
                        <Button
                          center
                          disabled={composer.is_client_default}
                          onClick={() => handleDefaultComposer(composer)}>
                          {composer.is_client_default
                            ? 'Is Default'
                            : 'Make Default'}
                        </Button>
                      </TableActions>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </Panel>
        </LeftHalfLayout>
        <RightHalfLayout>
          <Panel
            header="Custom composers"
            actions={
              customComposers?.length !== 0 && (
                <PermissionFilter can="create composers">
                  <Button
                    center
                    icon="plus"
                    variant="primary"
                    onClick={() => setCreate(true)}>
                    New Composer
                  </Button>
                </PermissionFilter>
              )
            }>
            {customComposers?.length === 0 && (
              <EmptyStatePlaceholder
                type="builder"
                heading="Create your first custom composer"
                text="Need something specific?  Build custom composers tailored for your users. "
                actions={
                  <PermissionFilter can="create composers">
                    <Button
                      center
                      icon="plus"
                      variant="primary"
                      onClick={() => setCreate(true)}>
                      New Composer
                    </Button>
                  </PermissionFilter>
                }
                helpLink="/docs/2151317744/2153283859"
                helpText="Learn more about custom composers."
              />
            )}
            {(customComposers?.length > 0 || query.isLoading) && (
              <Table loading={query.isLoading} loadingColumns={3}>
                <TableHead>
                  <TableRow>
                    <TableHeadCell>Name</TableHeadCell>
                    <TableHeadCell>Type</TableHeadCell>
                    <TableHeadCell />
                  </TableRow>
                </TableHead>
                {customComposers && (
                  <TableBody>
                    {customComposers.map((composer, key) => (
                      <TableRow key={key}>
                        <TableCell>{composer.name}</TableCell>
                        <TableCell>{composer.type}</TableCell>
                        <TableActions>
                          <PermissionFilter can="edit composers">
                            <Button
                              variant="secondary"
                              center
                              icon="pencilAlt"
                              onClick={() => setEdit(composer)}>
                              Edit
                            </Button>
                          </PermissionFilter>
                          <PermissionFilter can="delete composers">
                            <Button
                              variant="secondary"
                              colour="danger"
                              icon="trash"
                              onClick={() => handleDelete(composer)}>
                              Delete
                            </Button>
                          </PermissionFilter>
                          <PermissionFilter can="edit composers">
                            <Button
                              center
                              disabled={composer.is_client_default}
                              onClick={() => handleDefaultComposer(composer)}>
                              {composer.is_client_default
                                ? 'Is Default'
                                : 'Make Default'}
                            </Button>
                          </PermissionFilter>
                        </TableActions>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            )}
          </Panel>
        </RightHalfLayout>
      </Flex>
    </PageWrapper>
  );
};

export default Composers;
