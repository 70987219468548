import { styled } from 'twin.macro';

import Flex from '../../Layout/Flex';

const ValidatorConnector = styled(Flex)`
  & > *:first-child {
    transform: rotate(90deg);
    margin-right: 1rem;
    ${props => props.arrow && 'margin-bottom: -1rem;'}
  }
  & > *:last-child {
    margin-left: -1px;
  }
`;

export default ValidatorConnector;
