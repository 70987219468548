import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  TextInput,
  Button,
  Label,
  Flex,
  FormGroup
} from '@ubisend/pulse-components';

const GroupDetails = ({ onSubmit, initialName = '', loading }) => {
  const [name, setName] = useState(initialName);

  const handleNameChange = event => setName(event.target.value);

  const valid = Boolean(name);

  const handleSubmit = event => {
    event.preventDefault();

    if (!valid || loading) {
      return;
    }

    onSubmit({ name });
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup>
        <Label htmlFor="name">Name</Label>
        <TextInput
          id="name"
          placeholder="Tag name"
          value={name}
          onChange={handleNameChange}
        />
      </FormGroup>
      <Flex right>
        <Button disabled={!valid || loading} type="submit" variant="primary">
          Save
        </Button>
      </Flex>
    </form>
  );
};

GroupDetails.propTypes = {
  initialName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default GroupDetails;
