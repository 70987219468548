import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { motion } from '@ubisend/framer-motion';
import { Label, Flex } from '@ubisend/pulse-components';

import { useBot } from '../../../../hooks/index';
import { Button } from '../ResponseTypes/QuickReply';

const Container = styled(motion.div)`
  ${tw`bg-white p-4 border border-gray-300 border-solid`};
`;

const containerAnim = {
  initial: { y: 60, opacity: 0 },
  exit: {
    y: 60,
    opacity: 0,
    transition: { duration: 0 }
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: { duration: 0 }
  }
};

const BurgerMenu = ({ handleButtonClick = () => {} }) => {
  const { t } = useTranslation('bots');

  const { setShowBurgerMenu, buttons } = useBot();

  const handleClick = (label, action) => {
    handleButtonClick(label, action);
    setShowBurgerMenu(false);
  };

  return (
    <Container
      initial="initial"
      animate="animate"
      exit="exit"
      variants={containerAnim}>
      <Label>{t('hamburger_quick_actions_label')}</Label>
      <Flex xSpace xScroll>
        {buttons.map(({ label, action }, key) => (
          <Button
            key={key}
            type="button"
            direction="sentMessage"
            onClick={() => handleClick(label, action)}>
            {label}
          </Button>
        ))}
      </Flex>
    </Container>
  );
};

BurgerMenu.propTypes = {
  handleButtonClick: PropTypes.func.isRequired
};

export default BurgerMenu;
