import tw, { styled } from 'twin.macro';

import { size } from '../styles';

const Fixed = styled.div`
  ${tw`fixed`}
  ${props => props.right && tw`right-0`}
  ${props => props.left && tw`left-0`}
  ${props => props.top && tw`top-0`}
  ${props => props.bottom && tw`bottom-0`}
  ${size}
`;

export default Fixed;
