import React from 'react';
import PropTypes from 'prop-types';

import DefaultLink from './DefaultLink';
import YoutubeLink from './YoutubeLink';
import SectionLink from './SectionLink';

const links = [
  {
    Component: SectionLink,
    matches: href => href.includes('window/section/')
  },
  {
    Component: YoutubeLink,
    matches: href => href.includes('www.youtube.com')
  },
  { Component: DefaultLink, matches: () => true }
];

const MarkdownLink = props => {
  const { Component } = links.find(({ matches }) => matches(props.href));

  return <Component {...props} />;
};

MarkdownLink.propTypes = {
  href: PropTypes.string.isRequired
};

export default MarkdownLink;
