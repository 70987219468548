import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCancel,
  ModalBody,
  Label,
  Divider,
  TextInput,
  Flex,
  Button,
  FormHeading
} from '@ubisend/pulse-components';
import {
  useConditionalReducer,
  ConditionalContext,
  Match,
  AddConditionalButton,
  Conditionals
} from '@ubisend/pulse-conditionals';

import subjects from '../../subjects/index';

const defaultConditionals = {
  match: 'any',
  conditionals: [
    {
      type: {
        name: 'All the time',
        type: 'event'
      },
      condition: null,
      flipped: false,
      case_sensitive: false,
      target: '',
      subject: {
        target: null,
        type: 'message'
      }
    }
  ]
};

const ConditionalModal = ({
  handleSubmit,
  handleCancel,
  initialName = '',
  initialConditionals = defaultConditionals,
  header = 'Configure when Converse is shown',
  subHeading = 'When to show Converse'
}) => {
  const [name, setName] = useState(initialName);
  const conditionals = useConditionalReducer({
    conditionals: initialConditionals,
    subjects: subjects.conditionals.subjects
  });

  const handleNameChange = event => {
    setName(event.target.value);
  };

  const onSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    handleSubmit({ name, ...conditionals.form });
  };

  return (
    <ModalPortal>
      <ModalContainer>
        <ModalContent
          style={{
            width: '60rem',
            maxWidth: '60rem',
            maxHeight: '40rem',
            overflowY: 'auto'
          }}>
          <ModalHeader>{header}</ModalHeader>
          <form onSubmit={onSubmit}>
            <ModalBody>
              <Label htmlFor="name">Name</Label>
              <TextInput
                id="name"
                placeholder="Show on website Home Page"
                onChange={handleNameChange}
                value={name}
              />
              <Divider tall />
              <FormHeading>{subHeading}</FormHeading>
              <ConditionalContext.Provider value={conditionals}>
                <Match />
                <Divider />
                <Conditionals labelPrefix="conditionals" />
                <Flex right xSpace>
                  <AddConditionalButton />
                </Flex>
              </ConditionalContext.Provider>
            </ModalBody>
            <ModalFooter>
              <Flex right center xSpace>
                <ModalCancel type="button" onClick={handleCancel}>
                  Cancel
                </ModalCancel>
                <Button variant="primary" type="submit">
                  Save
                </Button>
              </Flex>
            </ModalFooter>
          </form>
        </ModalContent>
      </ModalContainer>
    </ModalPortal>
  );
};

ConditionalModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  initialName: PropTypes.string,
  initialConditionals: PropTypes.shape({
    match: PropTypes.oneOf(['any', 'all']),
    conditionals: PropTypes.array
  }),
  header: PropTypes.string,
  subHeading: PropTypes.string
};

export default ConditionalModal;
