import React from 'react';

import { useQuery } from '@ubisend/pulse-hooks';
import { Span } from '@ubisend/pulse-components';

const LicenseTotal = () => {
  const query = useQuery('owner/licenses/summary');

  if (!query.isSuccess) {
    return null;
  }

  return (
    <Span light tinyText style={{ textAlign: 'right' }}>
      {query.data.in_use_licenses} of {query.data.active_licenses} active
      licenses in use
    </Span>
  );
};

export default LicenseTotal;
