import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Icon from '@ubisend/pulse-icons';

const StyledLink = styled(Link)`
  ${tw`flex border pb-3 outline-none flex items-center underline cursor-pointer`}

  ${({ theme }) => `color: ${theme.black};`}
  margin-right: auto;
  margin-left: -0.35rem;

  &:hover {
    opacity: 0.75;
  }
  &:active {
    opacity: 0.5;
  }
`;

const BackButton = ({ to }) => {
  return (
    <StyledLink to={to} aria-label="Back to channels">
      <Icon size="1.2rem" width="1.2rem" height="1.2rem" type="cheveronLeft" />
      Back
    </StyledLink>
  );
};

BackButton.propTypes = {
  to: PropTypes.string
};

export default BackButton;
