import React from 'react';

import { hasPermission } from '@ubisend/pulse-auth';

import { TeamsLanding } from './Pages/index';
import { TeamsSettingsProvider } from './Providers/index';

const routes = [
  {
    name: 'Microsoft Teams',
    path: '/teams-link',
    component: () => (
      <TeamsSettingsProvider>
        <TeamsLanding />
      </TeamsSettingsProvider>
    ),
    canAccess: hasPermission('view microsoft teams settings'),
    actions: [
      {
        title: 'Manage your Teams channel',
        description:
          'Connect, disconnect, or edit your Microsoft Teams integration.',
        icon: 'lightningBolt'
      }
    ]
  }
];

export default routes;
