import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Select, formatSelectOption } from '@ubisend/pulse-components';

const FaqSelect = ({
  value,
  selectedGroups = [],
  selectedLocations = [],
  exclude = false,
  ...props
}) => {
  const { data, isLoading, isSuccess } = useQuery([
    'faqs/all',
    { groupIds: selectedGroups, locations: selectedLocations }
  ]);

  // Memoize the options to prevent re-rendering when data changes
  const options = useMemo(() => {
    if (!isSuccess || !data?.data) {
      return [];
    }

    return data.data
      .filter(item => item && item.id !== exclude)
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
      .map(formatSelectOption);
  }, [isSuccess, data, exclude]);

  const selectedOption = useMemo(() => {
    if (!isSuccess || !data?.data || !value) {
      return null;
    }

    return data.data.find(
      faq => faq && (faq.id === value || faq.name === value)
    );
  }, [isSuccess, data, value]);
  return (
    <Select
      aria-label="faq-names"
      closeMenuOnSelect
      isClearable
      isLoading={isLoading}
      options={options}
      value={selectedOption ? formatSelectOption(selectedOption) : null}
      {...props}
    />
  );
};

FaqSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  exclude: PropTypes.number,
  selectedGroups: PropTypes.arrayOf(PropTypes.number.isRequired),
  selectedLocations: PropTypes.array
};

export default FaqSelect;
