import { styled } from 'twin.macro';

const ValidatorContainer = styled.div`
  margin-left: -1px;
  & > div:nth-child(2) {
    transform: translateX(0.8rem);
  }
`;

export default ValidatorContainer;
