import React from 'react';
import tw, { styled } from 'twin.macro';

import { Flex, Panel, Label } from '@ubisend/pulse-components';
import { useAuth } from '@ubisend/pulse-auth';

const ClientDetails = styled(Flex)`
  flex: 1.5;
`;

const Logo = styled.img`
  ${tw`w-full`}
  flex: 1;
  max-width: 7.5rem;
`;

const Client = () => {
  const { user, client } = useAuth();

  return (
    <Panel divider header="Current client">
      <Flex xSpace middle center fat top>
        <ClientDetails col>
          <Label secondary>Name: {client.name}</Label>
          <Label secondary>
            Your roles:{' '}
            {user.roles.length > 0
              ? user.roles.map(role => role.name).join(',')
              : 'None'}
          </Label>
        </ClientDetails>
        <Logo src={client.logo} />
      </Flex>
    </Panel>
  );
};

export default Client;
