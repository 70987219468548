import tw, { styled } from 'twin.macro';

import Explainer from '../../Explainer';

const MarkdownBlockQuote = styled(Explainer)`
  ${tw`my-4`}
  & strong:first-child {
    ${tw`mb-2 uppercase text-xs tracking-wide`}
  }
`;

export default MarkdownBlockQuote;
