import React from 'react';

import { Flex, EmptyStatePlaceholder } from '@ubisend/pulse-components';

const NoFile = () => {
  return (
    <Flex middle fat>
      <EmptyStatePlaceholder
        type="files"
        heading="Select or upload a file"
        text="Select a file to see a detailed breakdown."
        helpLink="/docs/2151317744/2153316415"
        helpText="Learn more about file storages."
      />
    </Flex>
  );
};

export default NoFile;
