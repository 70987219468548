import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { Flex, Heading2, Span } from '@ubisend/pulse-components';

import { LicenseLink } from '../../../Components/index';
import LicenseStatus from './LicenseStatus';

const ActiveSummary = ({ id, period }) => (
  <Flex col mb>
    <Flex between mb>
      <Heading2>License #{id} Status</Heading2>
      <LicenseStatus status="active" />
    </Flex>
    <Flex col ySpaceSm>
      {period.end ? (
        <Span
          aria-label={`Current period started on ${dayjs(period.start).format(
            'DD/MM/YYYY'
          )} and expires on ${dayjs(period.end).format('DD/MM/YYYY')} (${dayjs(
            period.end
          ).from(dayjs())})`}>
          Current period{' '}
          <Span bold>
            started on {dayjs(period.start).format('DD/MM/YYYY')} (
          </Span>
          {dayjs(period.start).from(dayjs())}) and{' '}
          <Span bold>expires on {dayjs(period.end).format('DD/MM/YYYY')}</Span>{' '}
          ({dayjs(period.end).from(dayjs())})
        </Span>
      ) : (
        <Span
          aria-label={`Current period started on ${dayjs(period.start).format(
            'DD/MM/YYYY'
          )} (${dayjs(period.start).from(dayjs())}) and never expires`}>
          Current period{' '}
          <Span bold>
            started on {dayjs(period.start).format('DD/MM/YYYY')}
          </Span>{' '}
          ({dayjs(period.start).from(dayjs())}) and{' '}
          <Span bold>never expires</Span>
        </Span>
      )}
      <LicenseLink>Request license extension</LicenseLink>
    </Flex>
  </Flex>
);

ActiveSummary.propTypes = {
  id: PropTypes.number.isRequired,
  period: PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string
  }).isRequired
};

const ExpiringSummary = ({ id, period }) => (
  <Flex col mb>
    <Flex between mb>
      <Heading2>License #{id} Status</Heading2>
      <LicenseStatus status="expiring" />
    </Flex>
    <Flex col ySpaceSm>
      <Span
        aria-label={`Current period started on ${dayjs(period.start).format(
          'DD/MM/YYYY'
        )} and expires on ${dayjs(period.end).format('DD/MM/YYYY')} (${dayjs(
          period.end
        ).from(dayjs())})`}>
        Current period{' '}
        <Span bold>started on {dayjs(period.start).format('DD/MM/YYYY')}</Span>{' '}
        ({dayjs(period.start).from(dayjs())}) and{' '}
        <Span bold>expires on {dayjs(period.end).format('DD/MM/YYYY')}</Span> (
        {dayjs(period.end).from(dayjs())})
      </Span>
      <LicenseLink>Request license extension</LicenseLink>
    </Flex>
  </Flex>
);

ExpiringSummary.propTypes = {
  id: PropTypes.number.isRequired,
  period: PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired
  }).isRequired
};

const ExpiredSummary = ({ id, period }) => (
  <Flex col mb>
    <Flex between mb>
      <Heading2>License #{id} Status</Heading2>
      <LicenseStatus status="expired" />
    </Flex>
    <Flex col ySpaceSm>
      <Span
        aria-label={`Last active period started on ${dayjs(period.start).format(
          'DD/MM/YYYY'
        )} and expired on ${dayjs(period.end).format('DD/MM/YYYY')} (${dayjs(
          period.end
        ).from(dayjs())})`}>
        Last active period{' '}
        <Span bold>started on {dayjs(period.start).format('DD/MM/YYYY')}</Span>{' '}
        and{' '}
        <Span bold>expired on {dayjs(period.end).format('DD/MM/YYYY')}</Span> (
        {dayjs(period.end).from(dayjs())})
      </Span>
      <LicenseLink>Request license activation</LicenseLink>
    </Flex>
  </Flex>
);

ExpiredSummary.propTypes = {
  id: PropTypes.number.isRequired,
  period: PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired
  }).isRequired
};

const summaries = {
  active: ActiveSummary,
  expiring: ExpiringSummary,
  expired: ExpiredSummary
};

const LicenseSummary = props => {
  const Component = useMemo(() => summaries[props.status], [props.status]);

  return <Component {...props} />;
};

LicenseSummary.propTypes = {
  status: PropTypes.oneOf(Object.keys(summaries)).isRequired
};

export default LicenseSummary;
