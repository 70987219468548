import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

import { motion } from '@ubisend/framer-motion';
import Icon from '@ubisend/pulse-icons';

import { useTheme } from '../../hooks/index';
import Flex from '../Layout/Flex';
import Span from '../Text/Span';

const StyledIcon = styled(Icon)`
  ${tw`flex`}
`;

const InlineNotification = ({ description, type }) => {
  const theme = useTheme();

  const types = {
    success: {
      colour: 'positive',
      icon: 'checkCircle'
    },
    error: {
      colour: 'danger',
      icon: 'xCircle'
    },
    information: {
      colour: 'secondary',
      icon: 'informationCircle'
    }
  };

  const roles = {
    success: 'status',
    information: 'status',
    error: 'alert'
  };

  return (
    <motion.div
      role={roles[type]}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}>
      <Flex xSpaceSm center>
        <StyledIcon
          stroke={theme[types[type].colour]}
          type={types[type].icon}
          outline
          height={'20px'}
          width={'20px'}
        />
        <Span tinyText colour={types[type].colour}>
          {description}
        </Span>
      </Flex>
    </motion.div>
  );
};

InlineNotification.propTypes = {
  description: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'error', 'information'])
};

export default InlineNotification;
