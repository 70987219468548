import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import { AnimatePresence } from '@ubisend/framer-motion';
import {
  ActionMenu,
  Divider,
  Flex,
  useModal,
  useNotification
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

import {
  useBuilder,
  useAllowedBlocks,
  useCanvas
} from '../../../../hooks/index';
import { deleteStepTransition } from '../../../../api/index';
import { Block, types } from '../../../Blocks/index';
import EditValidationNode from './EditValidationNode';
import EditResponse from './EditResponse';
import NodeButton from '../../NodeButton';

const Wrapper = styled.div`
  ${tw`overflow-y-auto flex flex-col h-full justify-end`}

  & p {
    ${tw`m-0 mb-1 text-sm mr-4`}
    &:last-of-type {
      ${tw`mb-0`}
    }
  }
`;

const ValidationNode = ({ node, handleEditBlock }) => {
  const [edit, setEdit] = useState(false);
  const [editResponse, setEditResponse] = useState(false);

  const { dispatch } = useBuilder();
  const blocks = useAllowedBlocks(node.blocks, types);
  const { showModal, hideModal } = useModal();
  const { showSuccess } = useNotification();
  const { handleCanvasRender } = useCanvas();

  const { mutateAsync } = useMutation(deleteStepTransition, {
    onSuccess: () => {
      dispatch({
        type: 'DELETE_TRANSITION',
        stepId: node.meta.stepId,
        transitionId: node.id,
        transitionType: 'validation'
      });
      showSuccess('Successfully deleted step validation');
    }
  });

  const handleDelete = () => {
    showModal({
      header: 'Delete step validation',
      body: `Are you sure you want to delete this steps validation?`,
      handleConfirm: async () => {
        try {
          await mutateAsync({
            stepId: node.meta.stepId,
            transitionId: node.id
          });
          handleCanvasRender();
        } finally {
          hideModal();
        }
      }
    });
  };

  return (
    <>
      <AnimatePresence>
        {edit && (
          <EditValidationNode handleClose={() => setEdit(false)} node={node} />
        )}
        {editResponse && (
          <EditResponse
            handleClose={() => setEditResponse(false)}
            node={node}
          />
        )}
      </AnimatePresence>
      <Wrapper>
        <Flex absolute style={{ top: '0', right: '0' }}>
          <ActionMenu
            buttonProps={{
              'aria-label': 'Toggle validation menu',
              onMouseDown: event => event.stopPropagation(),
              onMouseUp: event => event.stopPropagation()
            }}>
            <PermissionFilter can="edit conversations">
              <>
                <NodeButton
                  variant="inline"
                  icon="pencilAlt"
                  onClick={() => {
                    setEdit(true);
                  }}>
                  <Flex xSpace between>
                    Edit validation
                  </Flex>
                </NodeButton>
                <Divider mtNone mbNone />
                <NodeButton
                  icon="pencilAlt"
                  variant="inline"
                  onClick={() => {
                    setEditResponse(true);
                  }}>
                  Edit response
                </NodeButton>
                <Divider mtNone mbNone />
              </>
            </PermissionFilter>
            <PermissionFilter can="delete conversations">
              <NodeButton
                icon="trash"
                variant="inline"
                colour="danger"
                onClick={handleDelete}>
                Delete validation
              </NodeButton>
            </PermissionFilter>
          </ActionMenu>
        </Flex>
        {blocks.length > 0 && (
          <Flex col yScroll ySpaceSm fat plSm pbSm prSm>
            {blocks.map((block, key) => (
              <Block
                key={key}
                onClick={() => handleEditBlock(block)}
                {...block}
              />
            ))}
          </Flex>
        )}
      </Wrapper>
    </>
  );
};

ValidationNode.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
    blocks: PropTypes.arrayOf(PropTypes.object).isRequired,
    meta: PropTypes.shape({
      stepId: PropTypes.number.isRequired
    }).isRequired,
    base: PropTypes.object.isRequired
  }),
  handleEditBlock: PropTypes.func.isRequired
};

export default ValidationNode;
