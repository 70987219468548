import React from 'react';
import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';
import { createGlobalStyle } from 'styled-components';
import tw, { styled } from 'twin.macro';

import 'flatpickr/dist/themes/dark.css';

const OveriddenFlatpickrStyles = createGlobalStyle`
  .flatpickr-input[readonly] {
    ${tw`block px-3 py-2 bg-gray-300 border-none text-sm rounded-sm`}
    box-sizing: border-box;
    font-size: 0.875rem;
    text-align: center;
  }
`;

/**
 * Wrapper for flatpickr to show time.
 *
 * Flatpickr: https://github.com/flatpickr/flatpickr
 * Flatpickr React: https://github.com/haoxins/react-flatpickr
 */
const TimePicker = ({
  time,
  defaultTime = '00:00',
  disabled = false,
  className,
  options = {},
  ...props
}) => (
  <>
    <OveriddenFlatpickrStyles />
    <Flatpickr
      value={time}
      disabled={disabled}
      className={className}
      options={{
        enableTime: true,
        noCalendar: true,
        defaultDate: defaultTime,
        time_24hr: true,
        ...options
      }}
      {...props}
    />
  </>
);

TimePicker.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  time: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  defaultTime: PropTypes.any,
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.object
};

// appearance none fixes datepicker on ios
const StyledTimePicker = styled(TimePicker)`
  ${tw`w-full block px-3 py-2 bg-gray-300 border-none text-sm rounded-sm`}
  box-sizing: border-box;
  appearance: none;
`;

export default StyledTimePicker;
