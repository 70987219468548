import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';

import { motion, AnimatePresence } from '@ubisend/framer-motion';

const StyledButton = styled.button`
  ${tw`px-3 py-2 text-center cursor-pointer rounded-sm uppercase tracking-wide outline-none border-solid`}
  min-width: 3rem;
  border-width: 1px;
  background: white;
  ${({ on }) => !on && 'opacity: 0.25;'}

  border-color: ${({ on, ...props }) =>
    on ? props.theme.secondary : props.theme.grey};

  color: ${({ on, ...props }) =>
    on ? props.theme.secondary : props.theme.grey};
`;

const AnimatedContainer = styled(motion.div)`
  ${tw`inline-flex rounded-sm`}
`;

const ToggleButton = ({ onChange, on, children, ...props }) => (
  <AnimatePresence>
    <AnimatedContainer whileTap={{ scale: 0.9 }}>
      <StyledButton
        on={on ? 1 : 0}
        onClick={onChange}
        {...props}
        type={'button'}>
        {children}
      </StyledButton>
    </AnimatedContainer>
  </AnimatePresence>
);

ToggleButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  on: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  children: PropTypes.node
};

export default ToggleButton;
