import { useContext } from 'react';

import { FacebookSettingContext } from '../Contexts/index';

const useFacebookSettings = () => {
  const context = useContext(FacebookSettingContext);

  return context;
};

export default useFacebookSettings;
