import React, { useEffect, useCallback } from 'react';

import { useMutation } from '@ubisend/pulse-hooks';
import { Flex, Heading1, Paragraph } from '@ubisend/pulse-components';

import { logout } from '../api/auth';
import { useAuth } from '../hooks/index';
import { LoggedOutRedirect } from '../Components/index';

const Logout = () => {
  const { authenticated, logoutUser } = useAuth();

  const { isLoading, isSuccess, mutate } = useMutation(logout, {
    onSuccess: ({ data }) => {
      return logoutUser(data.redirect);
    }
  });

  const handleLogout = useCallback(() => {
    !isLoading && !isSuccess && mutate();
  }, [isLoading, isSuccess, mutate]);

  useEffect(() => {
    if (authenticated) {
      handleLogout();
    }
  }, [authenticated, handleLogout]);

  return (
    <Flex col fat yScreen center middle pad>
      {!authenticated && <LoggedOutRedirect />}
      <Heading1 style={{ fontSize: '2rem' }} mb textCenter>
        Logging you out
      </Heading1>
      <Paragraph secondary textCenter>
        Please wait and you will be logged out
      </Paragraph>
    </Flex>
  );
};

export default Logout;
