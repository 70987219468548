import React from 'react';
import { styled } from 'twin.macro';

import {
  Converse,
  StickyWidget,
  DeactivatedQueryProvider
} from '@ubisend/pulse-volt';

import { useBotSettings } from '../../hooks/index';
import { BotProvider } from '../../Providers/index';

const CtaPreviewSizing = styled.div`
  /* to simulate the iframe css for the cta widget in the converse settings */
  /* min-width based on right padding of 12px and close button width of 28px  */
  #open-widget-cta {
    min-width: 186px;
  }
`;

const WidgetPreview = () => {
  const { path } = useBotSettings();

  return (
    <StickyWidget>
      <DeactivatedQueryProvider>
        <BotProvider>
          <CtaPreviewSizing>
            <Converse path={path} />
          </CtaPreviewSizing>
        </BotProvider>
      </DeactivatedQueryProvider>
    </StickyWidget>
  );
};

export default WidgetPreview;
