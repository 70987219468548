import React, { useMemo, useState } from 'react';

import {
  Drivers,
  useQuery,
  useMutation,
  useQueryClient
} from '@ubisend/pulse-hooks';
import {
  OneThirdLayout,
  TwoThirdsLayout,
  PageWrapper,
  Placeholder,
  Grid,
  Flex,
  Panel,
  useNotification
} from '@ubisend/pulse-components';

import { AuthModal } from '../Components';
import {
  enableDriver as enableDriverApi,
  disableDriver as disableDriverApi
} from '../api/index';
import methods from '../Components/LoginMethods/index';

const LoginMethods = () => {
  const { showSuccess } = useNotification();
  const [showAuthModal, setShowAuthModal] = useState(false);

  const driversQuery = useQuery('owner/auth');
  const allDriversQuery = useQuery('owner/auth/all');
  const queryClient = useQueryClient();
  const { mutate: enableDriver } = useMutation(enableDriverApi, {
    onSuccess: ({ data }) => {
      showSuccess(`Successfully enabled ${data.data.name}`);
      queryClient.invalidateQueries('owner/auth');
    }
  });
  const { mutate: disableDriver } = useMutation(disableDriverApi, {
    onSuccess: ({ data }) => {
      showSuccess(`Successfully disabled ${data.data.name}`);
      queryClient.invalidateQueries('owner/auth');
    }
  });

  const drivers = useMemo(() => {
    if (driversQuery.isLoading) {
      return;
    }
    return driversQuery.data.data.map(driver => driver.id);
  }, [driversQuery]);

  const handleToggleDriver = updatedDriver => {
    if (drivers.find(id => id === updatedDriver.id)) {
      if (updatedDriver.name === 'Email & Password') {
        setShowAuthModal(updatedDriver);
      } else {
        disableDriver(updatedDriver.id);
      }
    } else {
      enableDriver(updatedDriver.id);
    }
  };

  const handleConfirmation = updatedDriver => {
    disableDriver(updatedDriver.id);
    setShowAuthModal(false);
  };

  const copy = {
    available: {
      title: 'Available login methods',
      info: 'Enable one or multiple login methods.'
    },
    other: {
      title: 'Other login methods',
      info: ''
    }
  };

  return (
    <PageWrapper header="Login Methods" subheader="Manage your login methods">
      {showAuthModal && (
        <AuthModal
          name={showAuthModal.name}
          handleSubmit={() => handleConfirmation(showAuthModal)}
          handleClose={() => setShowAuthModal(false)}
        />
      )}
      {driversQuery.isSuccess && allDriversQuery.isSuccess ? (
        <Drivers
          copy={copy}
          type="multi"
          required={true}
          value={drivers}
          drivers={allDriversQuery.data.data.map(driver => {
            if (driver.preview) {
              return driver;
            }

            const method = methods[driver.name];

            return {
              ...driver,
              link:
                method && method.Settings
                  ? `/owner/logins/${method.name}`
                  : null
            };
          })}
          handleClick={handleToggleDriver}
          canEditDriver={() => true}
          canEnableDriver={() => true}
          canDisableDriver={() => true}
        />
      ) : (
        <Flex fat>
          <OneThirdLayout>
            <Placeholder items={1} />
          </OneThirdLayout>
          <TwoThirdsLayout>
            <Grid columns={3}>
              {[0, 1, 2, 3, 4, 5].map(i => (
                <Panel key={i} loading loadingItems={1} />
              ))}
            </Grid>
          </TwoThirdsLayout>
        </Flex>
      )}
    </PageWrapper>
  );
};

export default LoginMethods;
