import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

import { breakpoints } from '@ubisend/pulse-components';

import { Content, Embed, Section } from './ResponseTypes/index';

const types = {
  content: Content,
  embed: Embed
};

const MessagePanel = styled.div`
  ${tw`relative w-full bg-white lg:h-full flex flex-col text-black lg:overflow-y-auto w-full`}
  box-sizing: border-box;
  height: 25vh;

  @media (min-width: ${breakpoints.xl}px) {
    width: 40rem;
  }
`;

const ContentMessage = ({ content, sectionFromApi }) => {
  const Component = useMemo(() => types[content?.type], [content?.type]);

  if (sectionFromApi) {
    return (
      <MessagePanel layout layoutId="panel">
        <Section sectionId={sectionFromApi} />
      </MessagePanel>
    );
  }

  return (
    <MessagePanel layout layoutId="panel">
      <Component content={content} />
    </MessagePanel>
  );
};

ContentMessage.propTypes = {
  sectionFromApi: PropTypes.string,
  content: PropTypes.shape({
    type: PropTypes.string.isRequired,
    content: PropTypes.object.isRequired
  })
};

export default ContentMessage;
