import React from 'react';
import PropTypes from 'prop-types';

import { Label, Paragraph } from '@ubisend/pulse-components';

const LogDescription = ({ description }) => (
  <div>
    <Label>Description</Label>
    <Paragraph>{description}</Paragraph>
  </div>
);

LogDescription.propTypes = {
  description: PropTypes.string.isRequired
};

export default LogDescription;
