import axios from 'axios';

const createUtterance = ({ faqId, utterance }) =>
  axios.post(`faqs/${faqId}/utterances`, utterance);

const updateUtterance = ({ faqId, utteranceId, utterance }) =>
  axios.put(`faqs/${faqId}/utterances/${utteranceId}`, utterance);

const deleteUtterance = ({ faqId, utteranceId }) =>
  axios.delete(`faqs/${faqId}/utterances/${utteranceId}`);

const deleteUtterances = ({ faqId, utteranceIds }) =>
  axios.put(`faqs/${faqId}/utterances/delete`, utteranceIds);

export { createUtterance, updateUtterance, deleteUtterance, deleteUtterances };
