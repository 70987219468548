import React, { useState, useCallback } from 'react';

import { Screen, LoadingContainer } from '@ubisend/pulse-components';
import { usePulse, useQueryClient, useQuery } from '@ubisend/pulse-hooks';

import { AuthContext } from '../Context/index';
import {
  hasPermission as hasPermissionCallback,
  hasSomePermissions as hasSomePermissionsCallback,
  hasAllPermissions as hasAllPermissionsCallback,
  hasGlobalRole as hasGlobalRoleCallback,
  hasSomeGlobalRoles as hasSomeGlobalRolesCallback,
  hasAllGlobalRoles as hasAllGlobalRolesCallback,
  hasFeature as hasFeatureCallback
} from '../callbacks/index';
import { CLIENT_ID_SESSION_KEY } from '../constants';

const defaultAuth = {
  user: null,
  client: null
};

const AuthProvider = ({ children }) => {
  const pulse = usePulse();

  const [auth, setAuth] = useState(
    localStorage.getItem('token') ? null : defaultAuth
  );

  const queryClient = useQueryClient();
  const { refetch: refetchAuth } = useQuery('auth/check', {
    enabled: !auth,
    onSuccess: ({ data: { client, ...user } }) => {
      sessionStorage.clear();
      sessionStorage.setItem(CLIENT_ID_SESSION_KEY, client.id);

      setAuth({ user, client });
    },
    onError: async () => {
      localStorage.removeItem('token');
      sessionStorage.clear();

      setAuth(defaultAuth);

      await queryClient.resetQueries();
    }
  });

  const loginUser = useCallback(({ token, user, client }) => {
    localStorage.setItem('token', token);
    sessionStorage.clear();
    sessionStorage.setItem(CLIENT_ID_SESSION_KEY, client.id);

    setAuth({ user, client });
  }, []);

  const logoutUser = useCallback(
    async redirect => {
      await queryClient.resetQueries();
      sessionStorage.clear();
      localStorage.removeItem('token');
      setAuth(defaultAuth);

      if (redirect) {
        window.location.replace(redirect);
      }
    },
    [queryClient]
  );

  const hasPermission = useCallback(
    permission => {
      return hasPermissionCallback(permission)(auth);
    },
    [auth]
  );

  const hasAllPermissions = useCallback(
    (...permissions) => {
      return hasAllPermissionsCallback(...permissions)(auth);
    },
    [auth]
  );

  const hasSomePermissions = useCallback(
    (...permissions) => {
      return hasSomePermissionsCallback(...permissions)(auth);
    },
    [auth]
  );

  const hasGlobalRole = useCallback(
    role => {
      return hasGlobalRoleCallback(role)(auth);
    },
    [auth]
  );

  const hasAllGlobalRoles = useCallback(
    (...roles) => {
      return hasAllGlobalRolesCallback(...roles)(auth);
    },
    [auth]
  );

  const hasSomeGlobalRoles = useCallback(
    (...roles) => {
      return hasSomeGlobalRolesCallback(...roles)(auth);
    },
    [auth]
  );

  const hasFeature = useCallback(
    feature => {
      return hasFeatureCallback(feature)(auth);
    },
    [auth]
  );

  if (!auth) {
    return (
      <Screen flex center>
        <LoadingContainer />
      </Screen>
    );
  }

  const authContext = {
    // Details
    authenticated: Boolean(auth.user),
    // User
    ...auth,
    setAuth,
    // Roles and permissions
    hasPermission,
    hasAllPermissions,
    hasSomePermissions,
    hasGlobalRole,
    hasAllGlobalRoles,
    hasSomeGlobalRoles,
    // Features
    hasFeature,
    // Callbacks
    loginUser,
    logoutUser,
    refetchAuth,
    // Pulse
    owner: pulse.owner,
    platform: pulse.platform
  };

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
export { defaultAuth };
