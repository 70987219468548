import React from 'react';

import {
  PageWrapper,
  LoadingContainer,
  useNotification
} from '@ubisend/pulse-components';
import {
  Drivers,
  useQuery,
  useQueryClient,
  useMutation
} from '@ubisend/pulse-hooks';
import { useAuth } from '@ubisend/pulse-auth';

import { updateDriver } from '../api/index';

const TicketDrivers = () => {
  const { showSuccess } = useNotification();
  const { hasPermission } = useAuth();

  const queryClient = useQueryClient();
  const { data: drivers, isLoading: driversLoading } = useQuery(
    'tickets/drivers/all'
  );
  const { data: driver, isLoading: driverLoading } = useQuery(
    'tickets/drivers'
  );
  const { mutate } = useMutation(updateDriver, {
    onSuccess: () => {
      showSuccess(`Successfully updated ticket driver`);
      queryClient.invalidateQueries('tickets/drivers');
    }
  });

  const loading = driverLoading || driversLoading;

  const handleDriver = driver => {
    mutate({ id: driver.id });
  };

  const copy = {
    available: {
      title: 'Available ticket providers',
      info:
        'Choose a currently supported Ticket Provider to power your chatbot.'
    },
    other: {
      title: 'Other ticket providers',
      info: 'Let us know which Ticket Provider you want to use next.'
    }
  };

  return (
    <PageWrapper
      header="Ticket Provider"
      subheader="Configure your chosen Ticket Provider">
      {loading && <LoadingContainer />}
      {!loading && (
        <Drivers
          copy={copy}
          type="single"
          required
          value={driver.data.id}
          drivers={drivers.data}
          handleClick={handleDriver}
          canEditDriver={() => false}
          canEnableDriver={() => hasPermission('edit ticket providers')}
          canDisableDriver={() => hasPermission('edit ticket providers')}
        />
      )}
    </PageWrapper>
  );
};

export default TicketDrivers;
