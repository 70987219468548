import React from 'react';

import {
  Converse,
  BotContext,
  ThemeProvider,
  DeactivatedQueryProvider
} from '@ubisend/pulse-volt';

const AppWidget = props => (
  <DeactivatedQueryProvider>
    <ThemeProvider>
      <BotContext.Provider
        value={{
          showClose: false,
          open: true,
          showName: true,
          showLogo: false,
          showDateTime: true,
          ...props
        }}>
        <Converse start="/window/body/messages" />
      </BotContext.Provider>
    </ThemeProvider>
  </DeactivatedQueryProvider>
);

export default AppWidget;
