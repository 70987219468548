import React from 'react';
import PropTypes from 'prop-types';

import { useQueryClient, useMutation } from '@ubisend/pulse-hooks';
import {
  Flex,
  ModalClose,
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalBody,
  useNotification
} from '@ubisend/pulse-components';

import { updateWidget } from '../../../api/index';
import WidgetDetails from './WidgetDetails';
import { useBotSettings } from '../../../hooks';

const UpdateWidget = ({ handleClose }) => {
  const { showSuccess } = useNotification();
  const { handleSubmit, settings } = useBotSettings();

  const queryClient = useQueryClient();

  const mutation = useMutation(updateWidget, {
    onSuccess: () => {
      queryClient.invalidateQueries('widgets');
      showSuccess('Successfully updated the Widget.');
      handleClose();
    }
  });

  const handleFormSubmit = async form => {
    await handleSubmit(form.name);
    handleClose();
  };

  return (
    <ModalPortal>
      <ModalContainer>
        <ModalContent>
          <ModalHeader>
            Rename Your Widget
            <Flex between>
              <ModalClose onClick={handleClose} />
            </Flex>
          </ModalHeader>
          <ModalBody>
            <WidgetDetails
              settings={settings}
              handleSubmit={handleFormSubmit}
              loading={mutation.isLoading}
            />
          </ModalBody>
        </ModalContent>
      </ModalContainer>
    </ModalPortal>
  );
};

UpdateWidget.propTypes = {
  widget: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    isPrimary: PropTypes.bool.isRequired
  }),
  handleClose: PropTypes.func.isRequired
};

export default UpdateWidget;
