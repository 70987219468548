import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';

import { Panel, Flex, Button } from '@ubisend/pulse-components';

import { useCanvas } from '../hooks/index';
import { MAX_ZOOM, MIN_ZOOM } from '../hooks/useCanvasTransform';

const Container = styled(Panel)`
  ${tw`absolute flex w-40 items-center z-20 p-3 pl-4`}
  box-sizing: border-box;
  bottom: 1rem;
  left: 1rem;
  & i {
    ${tw`flex items-center`}
  }
`;

const ZoomPercentage = styled.span`
  ${tw`font-poppins font-semibold text-sm w-10`}
`;

const LayoutControls = () => {
  const {
    percentageRef,
    handleZoomInClick,
    handleZoomOutClick,
    zoomAmount
  } = useCanvas();

  const zoomInDisabled = zoomAmount.get() >= MAX_ZOOM;
  const zoomOutDisabled = zoomAmount.get() <= MIN_ZOOM;

  return (
    <Container xSpaceSm>
      <Flex xSpaceSm center>
        <ZoomPercentage ref={percentageRef}>100%</ZoomPercentage>
        <Button
          aria-label={zoomOutDisabled ? 'Zoom out disabled' : 'Zoom out'}
          data-testid="zoom-out"
          variant="inline"
          icon="minus"
          onClick={handleZoomOutClick}
          disabled={zoomOutDisabled}
          aria-disabled={zoomOutDisabled}
        />
        <Button
          aria-label={zoomInDisabled ? 'Zoom in disabled' : 'Zoom in'}
          data-testid="zoom-in"
          variant="inline"
          icon="plus"
          onClick={handleZoomInClick}
          disabled={zoomInDisabled}
          aria-disabled={zoomInDisabled}
        />
      </Flex>
    </Container>
  );
};

LayoutControls.propTypes = {
  percentageBackup: PropTypes.string
};

export default LayoutControls;
