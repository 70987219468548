import React from 'react';
import tw, { styled } from 'twin.macro';

import {
  Flex,
  Label,
  useTheme,
  NumberFormatter,
  Heading2,
  Span
} from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';

import { useMetric } from '../../../hooks/index';

const IconContainer = styled.div`
  ${tw`absolute opacity-25`}
  right: 2.5rem;
  bottom: 1rem;
`;

const ValueMetric = () => {
  const { metric } = useMetric();
  const { primary } = useTheme();

  return (
    <Flex center relative yHidden xHidden tall pl pr>
      <IconContainer>
        <Icon
          data-testid="metric-icon"
          type={metric.data.data.icon}
          colour={primary}
          stroke={primary}
          width="5rem"
          height="5rem"
        />
      </IconContainer>
      <Flex col ml>
        <Heading2 style={{ fontSize: '1.875rem' }}>
          <NumberFormatter>{metric.data.data.value}</NumberFormatter>
          <Span
            light
            tinyText
            style={{ paddingLeft: '0.25rem' }}
            data-testid="span-metric">
            {metric.data.data.past_value !== null
              ? `(${metric.data.data.past_value})`
              : ''}
          </Span>
        </Heading2>
        <Label mb={false}>{metric.data.label}</Label>
      </Flex>
    </Flex>
  );
};

export default ValueMetric;
