import tw, { styled } from 'twin.macro';

const MarkdownTableRow = styled.tr`
  ${tw`py-2`}
  &:not(:last-child) {
    ${tw`border-0 border-b  border-solid`}
  }
`;

export default MarkdownTableRow;
