import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { motion } from '@ubisend/framer-motion';

const BotLogo = ({ showLogo, logoLink = null, logo, ...props }) => {
  const [hideOnError, setHideOnError] = useState(false);

  const { t } = useTranslation('bots');

  const handleError = () => {
    setHideOnError(true);
  };

  if (!showLogo || hideOnError) {
    return <span {...props} />;
  }

  if (showLogo && logoLink) {
    return (
      <a href={logoLink} target="_blank" rel="noopener noreferrer">
        <motion.img
          layout
          alt={t('bot_logo_description')}
          src={logo}
          onError={handleError}
          {...props}
        />
      </a>
    );
  }

  return (
    <motion.img layout alt="" src={logo} onError={handleError} {...props} />
  );
};

BotLogo.propTypes = {
  showLogo: PropTypes.bool,
  logoLink: PropTypes.string,
  logo: PropTypes.string
};

export default BotLogo;
