import React from 'react';

import {
  Flex,
  ColourPicker,
  Label,
  FormGroup,
  OneQuarterLayout,
  InfoSection,
  Panel,
  Divider
} from '@ubisend/pulse-components';

import { useBotSettings } from '../../hooks/index';
import ColourContrast from '../ColourContrast';

const EmbedColourSettings = () => {
  const {
    settings,
    focusBanner,
    focusBot,
    handleColourChange,
    handleColourChangeNested
  } = useBotSettings();

  return (
    <Flex>
      <OneQuarterLayout>
        <InfoSection
          title="Colours"
          info="Edit the colour scheme of your bot."
        />
      </OneQuarterLayout>
      <Panel>
        <FormGroup>
          <Flex mbSm between bottom>
            <Label mb={false}>Text colour</Label>
            <ColourContrast
              backgrounds={[
                settings.styles.gradient.from,
                settings.styles.gradient.to
              ]}
              foreground={settings.styles.textColour}
            />
          </Flex>
          <ColourPicker
            testId="text-colour-picker"
            onChange={focusBanner}
            color={settings.styles.textColour}
            onChangeComplete={handleColourChange('textColour')}
          />
        </FormGroup>
        <Divider mtNone />
        <FormGroup>
          <Label>Gradient start colour</Label>
          <ColourPicker
            testId="gradient-start-colour-picker"
            onChange={focusBanner}
            color={settings.styles.gradient.from}
            onChangeComplete={handleColourChangeNested('gradient', 'from')}
          />
          <Label>Gradient end colour</Label>
          <ColourPicker
            testId="gradient-end-colour-picker"
            onChange={focusBanner}
            color={settings.styles.gradient.to}
            onChangeComplete={handleColourChangeNested('gradient', 'to')}
          />
        </FormGroup>
        <Divider mtNone />
        <FormGroup>
          <Flex mbSm between middle>
            <Label mb={false}>Sent message text colour</Label>
            <ColourContrast
              backgrounds={[settings.styles.sentMessage.background]}
              foreground={settings.styles.sentMessage.text}
            />
          </Flex>
          <ColourPicker
            testId="sent-message-text-colour-picker"
            onChange={focusBot}
            color={settings.styles.sentMessage.text}
            onChangeComplete={handleColourChangeNested('sentMessage', 'text')}
          />
          <Label>Sent message background colour</Label>
          <ColourPicker
            testId="sent-message-background-colour-picker"
            onChange={focusBot}
            color={settings.styles.sentMessage.background}
            onChangeComplete={handleColourChangeNested(
              'sentMessage',
              'background'
            )}
          />
        </FormGroup>
        <Divider mtNone />
        <FormGroup>
          <Flex mbSm between middle>
            <Label mb={false}>Received message text colour</Label>
            <ColourContrast
              backgrounds={[settings.styles.recievedMessage.background]}
              foreground={settings.styles.recievedMessage.text}
            />
          </Flex>
          <ColourPicker
            testId="received-message-text-colour-picker"
            onChange={focusBot}
            color={settings.styles.recievedMessage.text}
            onChangeComplete={handleColourChangeNested(
              'recievedMessage',
              'text'
            )}
          />
          <Label>Received message background colour</Label>
          <ColourPicker
            testId="received-message-background-colour-picker"
            onChange={focusBot}
            color={settings.styles.recievedMessage.background}
            onChangeComplete={handleColourChangeNested(
              'recievedMessage',
              'background'
            )}
          />
        </FormGroup>
      </Panel>
    </Flex>
  );
};

export default EmbedColourSettings;
