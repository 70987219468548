import React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import {
  LeftHalfLayout,
  StretchPanel,
  TableActions,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableHeadCell,
  Table,
  Button,
  useModal,
  useNotification
} from '@ubisend/pulse-components';

import { useImports } from '../hooks/index';
import { deleteSectionContent } from '../api/index';

const Versions = () => {
  const { section, item, dispatch } = useImports();

  const { showModal, hideModal } = useModal();

  const { showSuccess } = useNotification();

  const handleDelete = (item, section, content) => {
    showModal({
      header: 'Remove Version',
      body: `Are you sure you want to delete the '${content.name}' version?`,
      handleConfirm: async () => {
        try {
          // must put before deleteSectionContent
          dispatch({
            type: 'REMOVE_SECTION_CONTENT',
            import: item,
            section,
            content
          });
          await deleteSectionContent(item.id, section.id, content.id);
          showSuccess('Version was deleted successfully.');
        } finally {
          hideModal();
        }
      }
    });
  };

  return (
    <LeftHalfLayout>
      <StretchPanel>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell>Name</TableHeadCell>
              <TableHeadCell>Created By</TableHeadCell>
              <TableHeadCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {section.other_content.map((content, key) => (
              <TableRow key={key}>
                <TableCell>{content.name}</TableCell>
                <TableCell>
                  {content.user.id
                    ? content.user.name
                    : 'Automatically Created'}{' '}
                  ({dayjs(content.created_at).from()})
                </TableCell>
                <TableActions>
                  <Button
                    data-testid={`kbv-button-delete-${content.id}`}
                    colour="danger"
                    variant="secondary"
                    icon="trash"
                    onClick={() => handleDelete(item, section, content)}>
                    Delete
                  </Button>
                  <Button
                    data-testid={`kbv-button-view-${content.id}`}
                    variant="secondary"
                    as={Link}
                    to={`/knowledge-bases/${item.id}/sections/${section.id}/versions/${content.id}`}>
                    View
                  </Button>
                </TableActions>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StretchPanel>
    </LeftHalfLayout>
  );
};

export default Versions;
