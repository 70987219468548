import React from 'react';
import PropTypes from 'prop-types';

import {
  Panel,
  Flex,
  InfoSection,
  OneQuarterLayout,
  Label,
  TextInput,
  Div,
  Explainer,
  Divider,
  Button,
  Grid
} from '@ubisend/pulse-components';
import { defaultTheme } from '@ubisend/pulse-volt';

import { useBotSettings } from '../../hooks';

// Get default font height and width from theme.
const { size } = defaultTheme;

const ConverseWidgetSizeSettings = props => {
  const { settings, setSettings } = useBotSettings();

  const handleDimensionChange = key => event => {
    const value = event.target.value;
    setSettings(settings => ({
      ...settings,
      styles: {
        ...settings.styles,
        size: {
          ...settings.styles.size,
          [key]: value
        }
      }
    }));
  };

  const handleReset = () => {
    setSettings(settings => ({
      ...settings,
      styles: {
        ...settings.styles,
        size: {
          width: defaultTheme.size.width,
          height: defaultTheme.size.height
        }
      }
    }));
  };

  const isSettingDefault = () => {
    const { styles } = settings;
    return (
      styles.size.width === size.width && styles.size.height === size.height
    );
  };

  return (
    <Flex>
      {props.titlePosition === 'left' && (
        <OneQuarterLayout mtNone>
          <InfoSection
            title="Bot size"
            info="Set the height and width of your chatbot"
          />
          <Divider />
        </OneQuarterLayout>
      )}
      <Panel>
        {props.titlePosition === 'top' && (
          <>
            <InfoSection
              mtNone
              title="Bot size"
              info="Set the height and width of your chatbot"
            />
            <Divider />
          </>
        )}
        <Explainer type="warning" hideLabel mb>
          The bot will remain full width and height on mobile. Default values
          are width 24rem, height 640px.
        </Explainer>
        <Grid columns={2}>
          <Div>
            <Label>Width</Label>
            <TextInput
              aria-label="converse-width"
              value={settings.styles.size ? settings.styles.size.width : ''}
              onChange={handleDimensionChange('width')}
            />
          </Div>
          <Div>
            <Label>Height</Label>
            <TextInput
              aria-label="converse-height"
              value={settings.styles.size ? settings.styles.size.height : ''}
              onChange={handleDimensionChange('height')}
            />
          </Div>
        </Grid>
        <Flex mt grow right>
          <Button
            selfBottom
            data-testid="bot-size-defaults"
            variant="secondary"
            colour="danger"
            onClick={handleReset}
            disabled={isSettingDefault()}>
            Reset to default
          </Button>
        </Flex>
      </Panel>
    </Flex>
  );
};

ConverseWidgetSizeSettings.propTypes = {
  titlePosition: PropTypes.string
};

ConverseWidgetSizeSettings.defaultProps = {
  titlePosition: 'left'
};

export default ConverseWidgetSizeSettings;
