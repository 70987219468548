import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

import { motion } from '@ubisend/framer-motion';

import { useTheme } from '../hooks/index';

const NotificationSymbol = styled(motion.div)`
  ${tw`w-4 h-4 absolute rounded-full top-0 right-0 bg-white`}
  & > span {
    ${tw`font-bold font-poppins`}
    color: ${props => props.gradient.from}
  }
  margin-top: -0.75rem;
  margin-right: -0.5rem;
`;

const Number = styled(motion.span)`
  ${tw`flex justify-center items-center text-xs`};
  line-height: 1rem;
`;

const Pulse = styled(motion.div)`
  ${tw`w-2 h-2 absolute rounded-full -ml-1 -mt-2 bg-white`}
`;

const PulsingNotification = props => (
  <Pulse
    transition={{
      duration: 1,
      repeat: Infinity,
      repeatType: 'loop',
      repeatDelay: 1,
      delay: 0.5
    }}
    initial={{ opacity: 0 }}
    animate={{ scale: [1, 2.5], opacity: [0.75, 0] }}
    {...props}
  />
);

const UnreadNotifications = ({ showNumber, children, scale }) => {
  const theme = useTheme();

  return (
    <div style={{ transform: `scale(${scale})`, transformOrigin: 'left' }}>
      {!showNumber && <PulsingNotification {...theme} />}
      <NotificationSymbol
        showNumber={showNumber}
        {...theme}
        initial={{ scale: 0.375, opacity: 0 }}
        animate={
          showNumber ? { opacity: 1, scale: 1 } : { scale: 0.375, opacity: 0 }
        }>
        <Number>{children}</Number>
      </NotificationSymbol>
    </div>
  );
};

UnreadNotifications.propTypes = {
  showNumber: PropTypes.bool,
  scale: PropTypes.bool
};

export default UnreadNotifications;
