import UrlEncodeInput from './UrlEncodeInput';

const urlEncode = {
  name: 'URL Encode',
  Input: UrlEncodeInput,
  content: {},
  format: variable => {
    return `${variable}|url_encode`;
  },
  valid: () => true
};

export default urlEncode;
