import React, { useState } from 'react';

import {
  useQuery,
  useMutation,
  useQueryClient,
  useDelete
} from '@ubisend/pulse-hooks';
import {
  PageWrapper,
  Pagination,
  EmptyStatePlaceholder,
  StretchPanel as Panel,
  TableActions,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Table,
  PanelSlider,
  Button,
  useNotification,
  usePaginationReducer,
  OrderableTableRow,
  useOrderableTableReducer,
  Flex,
  Span
} from '@ubisend/pulse-components';
import { PermissionFilter, PermissionsFilter } from '@ubisend/pulse-auth';

import {
  createVariable as createVariableApi,
  updateVariable as updateVariableApi,
  deleteVariable as deleteVariableApi
} from '../api/index';
import { VariableDetails } from '../Components/index';
import VariablePreview from '../Components/Variables/VariablePreview';
import scopes from '../Components/Variables/Scopes/index';
import types from '../Components/Variables/Types/index';

const getScope = scope => {
  return scopes.all.find(({ name }) => name === scope);
};

const getType = type => {
  return types.all.find(({ key }) => key === type);
};

const Variables = () => {
  const order = useOrderableTableReducer({ id: 'variables' });
  const pagination = usePaginationReducer({ id: 'variables' });

  const [editing, setEditing] = useState(null);
  const [creating, setCreating] = useState(false);

  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const query = useQuery([
    'variables',
    { ...pagination.params, ...order.params }
  ]);
  const { mutate: createVariable } = useMutation(createVariableApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('variables');
      showSuccess(`Successfully created variable.`);
      setCreating(false);
    }
  });
  const { mutate: updateVariable } = useMutation(updateVariableApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('variables');
      showSuccess(`Successfully updated variable.`);
      setEditing(false);
    }
  });
  const { handleDelete: deleteVariable } = useDelete(deleteVariableApi, {
    queries: ['variables'],
    notification: 'Successfully deleted variable.'
  });

  const handleVariableDelete = id => () => {
    deleteVariable(id);
  };

  const handleEditSave = params => {
    updateVariable({ id: editing.id, ...params });
  };

  const handleCreateSave = params => {
    createVariable(params);
  };

  return (
    <PageWrapper
      header="Variables"
      subheader="Manage your variables"
      actions={
        query.isSuccess &&
        !query.showNoResultsMessage && (
          <PermissionFilter can="create variables">
            <Button
              icon="plus"
              variant="primary"
              onClick={() => setCreating(true)}>
              New Variable
            </Button>
          </PermissionFilter>
        )
      }>
      {editing && (
        <PanelSlider
          header={`Edit Variable`}
          handleHide={() => setEditing(null)}
          width="40vw">
          <VariableDetails
            initialVariable={{
              name: editing.name,
              type: editing.type.name,
              scope: editing.scope.name,
              subject: editing.subject,
              default_value: editing.default_value
            }}
            onSubmit={handleEditSave}
            canEditScope={false}
            canEditType={false}
          />
        </PanelSlider>
      )}
      {creating && (
        <PanelSlider
          header={`Create Variable`}
          handleHide={() => setCreating(null)}
          width="40vw">
          <VariableDetails
            onSubmit={handleCreateSave}
            canEditScope
            canEditType
          />
        </PanelSlider>
      )}
      <Panel>
        {query.showNoResultsMessage && (
          <EmptyStatePlaceholder
            type="variables"
            heading="Create your first variable"
            text="Capture data from your automation as users or processes progress through workflows. "
            actions={
              <PermissionFilter can="create variables">
                <Button variant="primary" onClick={() => setCreating(true)}>
                  + New Variable
                </Button>
              </PermissionFilter>
            }
            helpLink="/docs/2151317744/2152562788"
            helpText="Learn more about variables."
          />
        )}
        {query.showTable && (
          <>
            <Table loading={query.isLoading} loadingColumns={5}>
              <TableHead>
                <OrderableTableRow
                  rows={[
                    { label: 'Id', sort: 'id' },
                    { label: 'Name', sort: 'name' },
                    { label: 'Scope', sort: null },
                    { label: 'Default value', sort: null },
                    null
                  ]}
                  {...order.props}
                />
              </TableHead>
              {query.isSuccess && (
                <TableBody>
                  {query.data.data.map((variable, key) => (
                    <TableRow key={key}>
                      <TableCell>{variable.id}</TableCell>
                      <TableCell>
                        <Flex col middle>
                          <Span>{variable.name}</Span>
                          <Span light tinyText>
                            {getType(variable.type.name).name}
                          </Span>
                        </Flex>
                      </TableCell>
                      <TableCell>{variable.scope.name}</TableCell>
                      <TableCell>
                        <VariablePreview variable={variable} />
                      </TableCell>
                      <TableActions>
                        <PermissionsFilter
                          can={[
                            'edit variables',
                            `edit ${getScope(variable.scope.name).permission}`
                          ]}>
                          <Button
                            varaint="secondary"
                            icon="pencilAlt"
                            onClick={() => setEditing(variable)}>
                            Edit
                          </Button>
                        </PermissionsFilter>
                        <PermissionsFilter
                          can={[
                            'delete variables',
                            `delete ${getScope(variable.scope.name).permission}`
                          ]}>
                          <Button
                            variant="secondary"
                            colour="danger"
                            icon="trash"
                            onClick={handleVariableDelete(variable.id)}>
                            Delete
                          </Button>
                        </PermissionsFilter>
                      </TableActions>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            {query.showPagination && (
              <Pagination pagination={query.data.meta} {...pagination.props} />
            )}
          </>
        )}
      </Panel>
    </PageWrapper>
  );
};

export default Variables;
