import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

import {
  ArrowHead,
  VerticalConnector,
  ArrowBall
} from './StepConnectors/index';

// Top value is 50% minus half the height of the add button
const Child = styled.div`
  ${tw`absolute z-20`}
  top: calc(50% - 1.25rem);
`;

const Container = styled.div`
  ${tw`flex flex-col items-center relative z-0`}
`;

const BuilderConnector = ({
  children,
  ball = false,
  arrow = false,
  ...rest
}) => (
  <Container>
    {ball && <ArrowBall />}
    <VerticalConnector tall={arrow} {...rest} />
    <Child>{children}</Child>
    {arrow && <ArrowHead />}
  </Container>
);

BuilderConnector.propTypes = {
  arrow: PropTypes.bool,
  ball: PropTypes.bool
};

export default BuilderConnector;
