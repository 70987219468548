import React, { useState } from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import { AnimatePresence } from '@ubisend/framer-motion';
import {
  EmptyStatePlaceholder,
  PageWrapper,
  StretchPanel as Panel,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Pagination,
  Flex,
  TableActions,
  Divider,
  useModal,
  useNotification,
  Span,
  Tag,
  Indicator,
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalClose,
  Heading2,
  Button,
  ActionMenu,
  FilterMenu,
  usePaginationReducer,
  OrderableTableRow,
  useOrderableTableReducer,
  useFilterReducer,
  Select,
  TextInput,
  Label,
  PanelSlider,
  InnerPanel,
  Explainer
} from '@ubisend/pulse-components';
import { PermissionFilter, useAuth } from '@ubisend/pulse-auth';
import { GroupSelect } from '@ubisend/pulse-groups';

import {
  deleteLink as deleteLinkApi,
  enablement,
  linkUsage as linkUsageApi
} from '../api';

const LinkStatus = ({ link }) => {
  if (!link.expires_at && !link.disabled_at) {
    return (
      <Flex xSpaceSm center>
        <Indicator colour="positive" />
        <Span colour="positive">Active forever</Span>
      </Flex>
    );
  }

  if (link.disabled_at) {
    return (
      <Flex xSpaceSm center>
        <Indicator colour="danger" />
        <Span colour="danger">
          Disabled {dayjs(link.disabled_at).from(dayjs())}
        </Span>
      </Flex>
    );
  }

  if (dayjs().isAfter(dayjs(link.expires_at))) {
    return (
      <Flex xSpaceSm center>
        <Indicator colour="warning" />
        <Span colour="warning">
          Expired {dayjs(link.expires_at).from(dayjs())}
        </Span>
      </Flex>
    );
  }

  return (
    <Flex xSpaceSm center>
      <Indicator colour="positive" />
      <Span colour="positive">
        Active until {dayjs(link.expires_at).format('DD/MM/YY')}
      </Span>
    </Flex>
  );
};

LinkStatus.propTypes = {
  link: PropTypes.shape({
    expires_at: PropTypes.string,
    disabled_at: PropTypes.string
  }).isRequired
};

const Img = styled.img`
  ${tw`w-full`}
`;

const QrCodeButton = ({ link }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      {show && (
        <ModalPortal>
          <ModalContainer>
            <ModalContent>
              <Flex between fat pad center middle>
                <Heading2>QR Code</Heading2>
                <ModalClose onClick={() => setShow(false)} />
              </Flex>
              <Flex pad col fat ySpace>
                <Flex fat>
                  <Img src={link.qr_code.link} />
                </Flex>
                <Flex right>
                  <Button
                    variant="secondary"
                    as="a"
                    download
                    href={link.qr_code.link}
                    target="_blank"
                    rel="noopener noreferrer">
                    Download
                  </Button>
                </Flex>
              </Flex>
            </ModalContent>
          </ModalContainer>
        </ModalPortal>
      )}
      <Button variant="inline" icon="qrCode" onClick={() => setShow(true)}>
        QR Code
      </Button>
    </>
  );
};

QrCodeButton.propTypes = {
  link: PropTypes.shape({
    qr_code: PropTypes.shape({
      link: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

const defaultFilters = {
  search: '',
  groups: [],
  status: null
};

const StatusSelect = ({ value, ...props }) => {
  const options = [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' }
  ];

  return (
    <Select
      {...props}
      isClearable
      options={options}
      value={value ? options.find(option => option.value === value) : null}
    />
  );
};

StatusSelect.propTypes = {
  value: PropTypes.string
};

const Links = () => {
  const pagination = usePaginationReducer({ id: 'links' });
  const order = useOrderableTableReducer({ id: 'links' });
  const filters = useFilterReducer({
    id: 'links',
    initialFilters: defaultFilters,
    options: { pagination }
  });

  const [showUsage, setShowUsage] = useState();

  const { showModal, hideModal } = useModal();
  const { showSuccess } = useNotification();

  const { hasSomePermissions } = useAuth();

  const queryClient = useQueryClient();
  const query = useQuery([
    'links',
    { ...pagination.params, ...order.params, ...filters.form }
  ]);
  const deleteLink = useMutation(deleteLinkApi, {
    onSuccess: () => {
      showSuccess(`Link was successfully deleted.`);
      queryClient.invalidateQueries('links');
    }
  });

  const linkUsage = useMutation(linkUsageApi);

  const mutateEnablement = useMutation(enablement, {
    onSuccess: ({ data }) => {
      showSuccess(
        `Link was successfully ${
          data.data.disabled_at ? 'disabled' : 'enabled'
        }.`
      );
      queryClient.invalidateQueries('links');
    }
  });

  const mapTypeToUrl = (type, payload) => {
    const protocol = window.location.protocol; // e.g., "http:" or "https:"
    const hostname = window.location.hostname; // e.g., "localhost"
    const port = window.location.port;
    const localhostUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
    let path;

    switch (type) {
      case 'Broadcast':
        path = `/broadcast/scheduled`;
        break;
      case 'Conversation Step':
        path = `/builder/${payload.conversation_id}`;
        break;
      case 'FAQ':
        path = `/faqs/${payload.id}/responses`;
        break;
      case 'Small Talk':
        path = `/smalltalk/${payload.id}/edit`;
        break;
      default:
        path = '/';
        break;
    }
    return localhostUrl + path;
  };

  const handleLinkDelete = ({ id, name, url }) => {
    showModal({
      header: 'Delete Tracked Link',
      body: `Are you sure you want to delete "${name || url}"?`,
      handleConfirm: async () => {
        await deleteLink.mutateAsync(id);

        hideModal();
      }
    });
  };

  const handleLinkUsage = async id => {
    try {
      const result = await linkUsage.mutateAsync(id);
      setShowUsage(result);
    } catch (error) {
      return;
    }
  };

  const handleEnablement = (id, state) => {
    let disabledAt = null;

    if (!state) {
      disabledAt = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss');
    }

    mutateEnablement.mutate({ id, disabled_at: disabledAt });
  };

  const handleSearchChange = event => {
    const search = event.target.value;

    filters.updateFilters({ search });
  };

  const handleGroupsChange = groups => {
    filters.updateFilters({
      groups: groups ? groups.map(group => group.value) : []
    });
  };

  const handleStatusChange = status => {
    filters.updateFilters({
      status: status ? status.value : null
    });
  };

  const renderUsedInTitle = (label, value) => {
    if (value) {
      return (
        <>
          <Flex mbSm>
            <Span mbSm tinyText>
              {label}:
            </Span>
            <Span mlSm mbSm tinyText light>
              {value}
            </Span>
          </Flex>
        </>
      );
    }
    return null;
  };

  return (
    <PageWrapper
      header="Link Tracking"
      subheader="View and manage tracked links"
      actions={
        <Flex xSpace>
          <FilterMenu
            position={FilterMenu.POSITIONS.LEFT}
            buttonProps={{ loading: query.isLoading }}
            {...filters.props}>
            <Flex col style={{ width: '20rem' }}>
              <Flex fat col mb>
                <Label htmlFor="name">Search</Label>
                <TextInput
                  id="Search"
                  placeholder="Search..."
                  onChange={handleSearchChange}
                  value={filters.filters.search}
                />
              </Flex>
              <Flex fat col mb>
                <Label htmlFor="tags">Tags</Label>
                <GroupSelect
                  for="links"
                  id="tags"
                  onChange={handleGroupsChange}
                  value={filters.filters.groups}
                />
              </Flex>
              <Flex fat col>
                <Label htmlFor="status">Status</Label>
                <StatusSelect
                  id="status"
                  onChange={handleStatusChange}
                  value={filters.filters.status}
                />
              </Flex>
            </Flex>
          </FilterMenu>
          {query.isSuccess && !query.showNoResultsMessage && (
            <PermissionFilter can="create links">
              <Button
                variant="primary"
                as={Link}
                to="/links/create"
                icon="plus"
                aria-label="Create link">
                New Link
              </Button>
            </PermissionFilter>
          )}
        </Flex>
      }>
      <Panel style={{ overflow: 'visible' }} mt>
        {query.showNoResultsMessage && (
          <EmptyStatePlaceholder
            type="links"
            heading="Create your first link"
            text="Monitor click activity with custom short links. "
            actions={
              <PermissionFilter can="create links">
                <Button
                  variant="primary"
                  as={Link}
                  to="/links/create"
                  icon="plus"
                  aria-label="Create link">
                  New Link
                </Button>
              </PermissionFilter>
            }
            helpLink="/docs/2151317744/2153054679"
            helpText="Learn more about trackable links."
          />
        )}
        {query.showTable && (
          <>
            <Flex
              col
              xScroll
              style={{ paddingBottom: 250, marginBottom: -250 }}>
              <Table loading={query.isLoading} loadingColumns={6}>
                <TableHead>
                  <OrderableTableRow
                    rows={[
                      { label: 'Link', sort: 'url' },
                      { label: 'Tracked link', sort: null },
                      { label: 'Tags', sort: null },
                      { label: 'Status', sort: null },
                      { label: 'Clicks', sort: 'clicks' },
                      null
                    ]}
                    {...order.props}
                  />
                </TableHead>
                {query.isSuccess && (
                  <TableBody>
                    {query.data.data.map((link, key) => (
                      <TableRow key={key}>
                        <TableCell
                          style={{
                            maxWidth: '16vw',
                            overflowX: 'auto',
                            WebkitMaskImage:
                              'linear-gradient(90deg, black 80%, transparent 100%)'
                          }}>
                          <Flex col>
                            {link.name && <Span>{link.name}</Span>}
                            <Flex>
                              <Button
                                style={{ paddingLeft: '0', paddingRight: '0' }}
                                variant="inline"
                                as="a"
                                href={link.url}
                                target="_blank"
                                rel="noreferrer">
                                {link.url}
                              </Button>
                            </Flex>
                          </Flex>
                        </TableCell>
                        <TableCell
                          style={{
                            maxWidth: '16vw',
                            overflowX: 'auto',
                            WebkitMaskImage:
                              'linear-gradient(90deg, black 80%, transparent 100%)'
                          }}>
                          <Button
                            variant="inline"
                            as="a"
                            href={link.tracked_url}
                            target="_blank"
                            rel="noreferrer">
                            {link.tracked_url}
                          </Button>
                        </TableCell>
                        <TableCell
                          style={{
                            maxWidth: '12vw',
                            overflowX: 'auto',
                            WebkitMaskImage:
                              'linear-gradient(90deg, black 80%, transparent 100%)'
                          }}>
                          {link.groups.length > 0 ? (
                            <Flex xSpaceSm>
                              {link.groups.map((group, key) => (
                                <Tag key={key}>{group.name}</Tag>
                              ))}
                            </Flex>
                          ) : (
                            'N/A'
                          )}
                        </TableCell>
                        <TableCell>
                          <LinkStatus link={link} />
                        </TableCell>
                        <TableCell style={{ width: '2rem' }}>
                          <Span>{link.clicks}</Span>
                        </TableCell>
                        <TableActions style={{ width: '2rem' }}>
                          {hasSomePermissions('delete links', 'edit links') && (
                            <ActionMenu
                              position={ActionMenu.POSITIONS.LEFT}
                              buttonProps={{
                                'aria-label': 'Toggle link menu'
                              }}>
                              {link.qr_code && <QrCodeButton link={link} />}
                              <Divider mtNone mbNone />
                              <PermissionFilter can="view links usage">
                                <Button
                                  variant="inline"
                                  title={`link-usage-${key}`}
                                  icon="eye"
                                  colour={'primary'}
                                  onClick={() => {
                                    handleLinkUsage(link.id);
                                  }}>
                                  Used in
                                </Button>
                                <Divider mtNone mbNone />
                              </PermissionFilter>
                              <PermissionFilter can="edit links">
                                <Button
                                  variant="inline"
                                  as={Link}
                                  to={`/links/${link.id}/edit`}
                                  icon="pencilAlt">
                                  Edit
                                </Button>
                                <Divider mtNone mbNone />
                                <Button
                                  variant="inline"
                                  icon={link.disabled_at ? 'check' : 'x'}
                                  loading={mutateEnablement.isLoading}
                                  onClick={() => {
                                    handleEnablement(link.id, link.disabled_at);
                                  }}>
                                  {link.disabled_at ? 'Enable' : 'Disable'}
                                </Button>
                                <PermissionFilter can="delete links">
                                  <Divider mtNone mbNone />
                                </PermissionFilter>
                              </PermissionFilter>
                              <PermissionFilter can="delete links">
                                <Button
                                  variant="inline"
                                  title={`delete-link-${key}`}
                                  icon="trash"
                                  colour={'danger'}
                                  onClick={() => {
                                    handleLinkDelete({ ...link });
                                  }}>
                                  Delete
                                </Button>
                              </PermissionFilter>
                            </ActionMenu>
                          )}
                        </TableActions>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </Flex>
            {query.showPagination && (
              <Pagination pagination={query.data.meta} {...pagination.props} />
            )}
            <AnimatePresence>
              {showUsage && (
                <PanelSlider
                  width={'640px'}
                  header={`This link is used in ...`}
                  handleHide={() => setShowUsage()}>
                  <div>
                    {showUsage.data.length === 0 && (
                      <Flex middle center fat ySpace col>
                        <EmptyStatePlaceholder
                          type="links"
                          heading="This link isn't being used yet"
                          text="Measure the performance of your chatbot using trackable links."
                          helpLink="/docs/2151317744/2152366116"
                          helpText="Learn more about tracked links."
                        />
                      </Flex>
                    )}
                    {showUsage.data.length > 0 && (
                      <Explainer mbSm>
                        Only links used in buttons will appear in this list. Raw
                        links used inside a message (including buttons inside
                        dynamic messages) are not listed.
                      </Explainer>
                    )}
                    {showUsage.data.map((item, index) => (
                      <InnerPanel mbSm key={index}>
                        <h2>{item.type}</h2>
                        {renderUsedInTitle('Name', item.payload.name)}
                        {renderUsedInTitle('Title', item.payload.title)}
                        {renderUsedInTitle(
                          'Scheduled',
                          item.payload.scheduled_at
                            ? dayjs(item.payload.scheduled_at).format(
                                'YYYY-MM-DD HH:mm:ss'
                              )
                            : null
                        )}

                        <Button
                          inline
                          variant="secondary"
                          as="a"
                          href={mapTypeToUrl(item.type, item.payload)}
                          target="_blank"
                          rel="noopener noreferrer">
                          View
                        </Button>
                      </InnerPanel>
                    ))}
                  </div>
                </PanelSlider>
              )}
            </AnimatePresence>
          </>
        )}
      </Panel>
    </PageWrapper>
  );
};

export default Links;
