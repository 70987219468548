import React from 'react';
import PropTypes from 'prop-types';

import { useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import { useNotification } from '@ubisend/pulse-components';

import { createClient } from '../../../api/index';
import ClientDetails from './ClientDetails';

const CreateClient = ({ onClientCreated }) => {
  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const mutation = useMutation(createClient, {
    onSuccess: ({ data: { data } }) => {
      queryClient.invalidateQueries('clients');
      queryClient.invalidateQueries('owner/clients');
      onClientCreated(data);
      showSuccess(`Successfully created client "${data.name}"`);
    }
  });

  const handleSubmit = form => {
    mutation.mutate(form);
  };

  return <ClientDetails onSubmit={handleSubmit} loading={mutation.isLoading} />;
};

CreateClient.propTypes = {
  onClientCreated: PropTypes.func.isRequired
};

export default CreateClient;
