import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  FormGroup,
  Label,
  Button,
  TextInput
} from '@ubisend/pulse-components';

import PermissionFilter from '../../PermissionFilter';
import Roles from '../../Roles';
import Permissions from '../../Permissions';
import { useRolesAndPermissions } from '../../../hooks/index';

const defaultState = {
  email: '',
  firstName: '',
  lastName: '',
  identifier: ''
};

const OAuthInvite = ({ handleSubmit, loading }) => {
  const [invite, setInvite] = useState(defaultState);

  const {
    roles,
    setRoles,
    permissions,
    setPermissions,
    formattedPermissions
  } = useRolesAndPermissions();

  const handleRolesChange = roles => {
    setRoles(roles);
  };

  const handlePermissionsChange = permissions => {
    setPermissions(permissions);
  };

  const handleEmailChange = event => {
    const value = event.target.value;

    setInvite({ ...invite, email: value });
  };

  const handleFirstNameChange = event => {
    const value = event.target.value;

    setInvite({ ...invite, firstName: value });
  };

  const handleLastNameChange = event => {
    const value = event.target.value;

    setInvite({ ...invite, lastName: value });
  };

  const handleIdentifierChange = event => {
    const value = event.target.value;

    setInvite({ ...invite, identifier: value });
  };

  const onSubmit = event => {
    event.preventDefault();

    handleSubmit({
      email: invite.email,
      first_name: invite.firstName,
      last_name: invite.lastName,
      identifier: invite.identifier,
      roles: roles.map(role => role.id),
      permissions: permissions.map(permission => permission.id)
    });
  };

  const valid = useMemo(() => {
    return invite.email.length > 0;
  }, [invite.email]);

  return (
    <form onSubmit={onSubmit}>
      <FormGroup>
        <Label htmlFor="first_name">First name</Label>
        <TextInput
          id="first_name"
          value={invite.firstName}
          onChange={handleFirstNameChange}
          placeholder="John"
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="last_name">Last name</Label>
        <TextInput
          id="last_name"
          value={invite.lastName}
          onChange={handleLastNameChange}
          placeholder="Smith"
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="emails">Email</Label>
        <TextInput
          id="emails"
          value={invite.email}
          onChange={handleEmailChange}
          placeholder="john@smith.com"
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="identifier">Identifier</Label>
        <TextInput
          id="identifier"
          value={invite.identifier}
          onChange={handleIdentifierChange}
        />
      </FormGroup>
      <PermissionFilter can="view roles">
        <FormGroup>
          <Label htmlFor="roles">Roles</Label>
          <Roles onChange={handleRolesChange} value={roles} />
        </FormGroup>
      </PermissionFilter>
      <PermissionFilter can="view permissions">
        <FormGroup>
          <Label htmlFor="roles">Extra Permissions</Label>
          <Permissions
            onChange={handlePermissionsChange}
            value={formattedPermissions}
          />
        </FormGroup>
      </PermissionFilter>
      <Flex right>
        <Button
          variant="primary"
          type="submit"
          loading={loading}
          disabled={!valid}>
          Invite
        </Button>
      </Flex>
    </form>
  );
};

OAuthInvite.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default OAuthInvite;
