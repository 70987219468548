import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import { AnimatePresence } from '@ubisend/framer-motion';
import {
  Flex,
  OneThirdLayout,
  TwoThirdsLayout,
  InfoSection,
  Button,
  IconPanel,
  PanelSlider,
  useNotification,
  EmptyStatePlaceholder
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

import GroupDetails from './GroupDetails';
import {
  createGroup as createGroupApi,
  updateGroup as updateGroupApi,
  deleteGroup as deleteGroupApi
} from '../api/index';

const scopes = {
  faqs: 'FAQ',
  tickets: 'Ticket',
  conversations: 'Conversation',
  links: 'Link'
};

const getCount = ({ scoped_to, scope_count }) => {
  if (scope_count === 1) {
    return `1 ${scoped_to.slice(0, -1)}`;
  }

  return `${scope_count} ${scoped_to}`;
};

const Scope = ({ groups, scope }) => {
  const [create, setCreate] = useState(false);
  const [edit, setEdit] = useState(null);

  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const createGroup = useMutation(createGroupApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('groups');
      showSuccess('Successfully created new tag.');
    }
  });
  const editGroup = useMutation(updateGroupApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('groups');
      showSuccess('Successfully updated tag.');
    }
  });
  const deleteGroup = useMutation(deleteGroupApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('groups');
      showSuccess('Successfully deleted tag.');
    }
  });

  const handleAddNew = () => setCreate(true);

  const handleEdit = group => () => setEdit(group);

  const handleDelete = group => {
    deleteGroup.mutate(group.id);
  };

  const handleCreateGroup = details => {
    createGroup.mutate({ ...details, scoped_to: scope });
  };

  const handleEditGroup = details => {
    editGroup.mutate({ id: edit.id, ...details });
  };

  return (
    <Flex fat>
      <AnimatePresence>
        {create && (
          <PanelSlider
            width="40vw"
            header={`Create ${scopes[scope]} Tag`}
            handleHide={() => setCreate(false)}>
            <GroupDetails
              onSubmit={handleCreateGroup}
              loading={createGroup.isLoading}
            />
          </PanelSlider>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {edit && (
          <PanelSlider
            width="40vw"
            header={`Edit ${scopes[scope]} Tag`}
            handleHide={() => setEdit(null)}>
            <GroupDetails
              onSubmit={handleEditGroup}
              initialName={edit.name}
              loading={editGroup.isLoading}
            />
          </PanelSlider>
        )}
      </AnimatePresence>
      <OneThirdLayout>
        <InfoSection
          title={`${scopes[scope]} Tags`}
          info={`Categorise your ${scopes[scope]}s for better reporting and searching.`}
          actions={
            groups.length !== 0 && (
              <PermissionFilter can="create tags">
                <Button
                  icon="plus"
                  variant="primary"
                  onClick={handleAddNew}
                  aria-label="Create tag">
                  New {scopes[scope]} Tag
                </Button>
              </PermissionFilter>
            )
          }
        />
      </OneThirdLayout>
      <TwoThirdsLayout>
        <Flex col ySpace>
          {groups.length === 0 && (
            <EmptyStatePlaceholder
              type="generic"
              heading={`No ${
                scope === 'faqs' ? scopes[scope] : scopes[scope].toLowerCase()
              } tags created `}
              text="Tags are your chatbot’s filing and storage system. "
              actions={
                <PermissionFilter can="create tags">
                  <Button
                    icon="plus"
                    variant="primary"
                    onClick={handleAddNew}
                    aria-label="Create tag">
                    New {scopes[scope]} Tag
                  </Button>
                </PermissionFilter>
              }
              helpLink="/docs/2151317744/2153152924"
              helpText="Learn more about tags."
            />
          )}
          {groups.map((group, key) => (
            <IconPanel
              key={key}
              icon={'tag'}
              title={group.name}
              status={group.scope_count ? getCount(group) : 'Not used'}
              actions={
                <Flex xSpace>
                  <PermissionFilter can="edit tags">
                    <Button
                      onClick={handleEdit(group)}
                      variant="secondary"
                      icon="pencilAlt">
                      Edit
                    </Button>
                  </PermissionFilter>
                  <PermissionFilter can="delete tags">
                    <Button
                      variant="secondary"
                      colour="danger"
                      icon="trash"
                      loading={deleteGroup.isLoading}
                      onClick={() => handleDelete(group)}>
                      Delete
                    </Button>
                  </PermissionFilter>
                </Flex>
              }
            />
          ))}
        </Flex>
      </TwoThirdsLayout>
    </Flex>
  );
};

Scope.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      scope_count: PropTypes.number,
      scope_to: PropTypes.oneOf(Object.keys(scopes))
    })
  ),
  scope: PropTypes.oneOf(Object.keys(scopes))
};

export default Scope;
