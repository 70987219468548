import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import { AnimateSharedLayout } from '@ubisend/framer-motion';
import {
  Flex,
  FormGroup,
  Label,
  TextInput,
  Button,
  Checkbox,
  Tooltip,
  useNotification,
  TabButton,
  Divider,
  LeftHalfLayout,
  RightHalfLayout
} from '@ubisend/pulse-components';
import {
  MultiComposer,
  useMultiComposerReducer,
  MultiComposerSettings,
  MultiComposerWidget,
  MultiComposerSubmitButton,
  MultiComposerLanguageSelect,
  ComposerContext
} from '@ubisend/pulse-composer';

import Modal from '../../../Modal';
import ComposerWidget from '../../../ComposerWidget';
import { useBuilder, useCanvas } from '../../../../hooks/index';
import { createStep } from '../../../../api/index';
import { composers } from '../../../../../../subjects/index';

const AddStepNode = ({ handleClose, onSave, type = 'messageStep' }) => {
  const [tab, setTab] = useState('DETAILS');
  const [name, setName] = useState('');
  const [sensitive, setSensitive] = useState(false);

  const { mousePositionX, mousePositionY } = useCanvas();

  // as opposed to automatedStep
  const isMessageStep = type === 'messageStep';

  const xValue = mousePositionX.get();
  const yValue = mousePositionY.get();

  const { showSuccess } = useNotification();
  const { conversation, dispatch } = useBuilder();
  const composer = useMultiComposerReducer({
    responses: [],
    subjects: composers
  });
  const { isLoading, mutate } = useMutation(createStep, {
    onSuccess: ({ data }) => {
      dispatch({
        type: 'ADD_STEP',
        stepDetails: data.data
      });
      onSave(data.data);
      showSuccess('Successfully created a step');
    }
  });

  const handleNameChange = event => {
    setName(event.target.value);
  };

  const handleSensitiveChange = () => {
    setSensitive(sensitive => !sensitive);
  };

  const detailsTabIsValid = useMemo(() => {
    return name.length > 0;
  }, [name]);

  const responseTabIsValid = useMemo(() => {
    return composer.valid;
  }, [composer.valid]);

  const handleDetailsSubmit = event => {
    event.preventDefault();

    if (detailsTabIsValid && !isLoading) {
      mutate({
        conversationId: conversation.id,
        step: {
          title: name,
          sensitive,
          previous_transition: null,
          next_transition: null,
          x: xValue,
          y: yValue,
          type: 'automatedStep'
        }
      });
    }
  };

  const handleResponseSubmit = event => {
    event.preventDefault();

    if (detailsTabIsValid && responseTabIsValid && !isLoading) {
      mutate({
        conversationId: conversation.id,
        step: {
          title: name,
          sensitive,
          ...composer.form,
          previous_transition: null,
          next_transition: null,
          x: xValue,
          y: yValue,
          type: 'messageStep'
        }
      });
    }
  };

  return (
    <Modal
      header="Add step"
      handleClose={handleClose}
      style={{ maxWidth: '100%', width: '80rem' }}>
      <Flex center xSpace mt ml mr>
        <AnimateSharedLayout>
          <TabButton
            active={tab === 'DETAILS'}
            onClick={() => setTab('DETAILS')}>
            Details
          </TabButton>
          {isMessageStep && (
            <TabButton
              active={tab === 'RESPONSE'}
              onClick={() => setTab('RESPONSE')}>
              Response
            </TabButton>
          )}
        </AnimateSharedLayout>
      </Flex>
      <Divider />
      <form onSubmit={handleResponseSubmit}>
        <Flex pl pr pb fat col>
          {tab === 'DETAILS' && (
            <>
              <FormGroup>
                <Label htmlFor="name">Name</Label>
                <TextInput
                  id="name"
                  value={name}
                  placeholder="My steps name"
                  onChange={handleNameChange}
                />
              </FormGroup>
              <FormGroup>
                <Flex center>
                  <Checkbox
                    checked={sensitive}
                    onChange={handleSensitiveChange}
                    label="Sensitive information at this step"
                  />
                  <Tooltip
                    position={Tooltip.POSITIONS.TOP}
                    tooltip={
                      <Tooltip.Content>
                        If a step is marked as sensitive, no responses from the
                        subscriber will be stored.
                      </Tooltip.Content>
                    }>
                    <Tooltip.Icon>?</Tooltip.Icon>
                  </Tooltip>
                </Flex>
              </FormGroup>
              <Flex right>
                {isMessageStep ? (
                  <Button variant="primary" onClick={() => setTab('RESPONSE')}>
                    Continue
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    disabled={isLoading || !detailsTabIsValid}
                    onClick={handleDetailsSubmit}>
                    Save
                  </Button>
                )}
              </Flex>
            </>
          )}
          {isMessageStep && tab === 'RESPONSE' && (
            <Flex>
              <ComposerContext.Provider value={composer}>
                <LeftHalfLayout>
                  <Flex between center mb>
                    <Label mb={false}>Language</Label>
                    <Flex>
                      <MultiComposerLanguageSelect />
                    </Flex>
                  </Flex>
                  <MultiComposer />
                  <Flex xSpace>
                    <MultiComposerSettings />
                    <MultiComposerSubmitButton
                      type="submit"
                      disabled={isLoading || !responseTabIsValid}>
                      Save
                    </MultiComposerSubmitButton>
                  </Flex>
                </LeftHalfLayout>
                <RightHalfLayout>
                  <Flex fat middle>
                    <MultiComposerWidget as={ComposerWidget} />
                  </Flex>
                </RightHalfLayout>
              </ComposerContext.Provider>
            </Flex>
          )}
        </Flex>
      </form>
    </Modal>
  );
};

AddStepNode.propTypes = {
  handleClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default AddStepNode;
