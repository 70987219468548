import React from 'react';
import { styled } from 'twin.macro';
import { Link } from 'react-router-dom';

import { motion } from '@ubisend/framer-motion';
import {
  Sidebar as PulseSideBar,
  SidebarItem as PulseSideBarItem,
  useTheme,
  Flex,
  Span,
  Tag,
  Heading2
} from '@ubisend/pulse-components';
import { useAuth, canWhiteLabel } from '@ubisend/pulse-auth';
import Icon from '@ubisend/pulse-icons';
import { usePulse } from '@ubisend/pulse-hooks';

import SidebarItem from './SidebarItem';
import { useSidebar } from '../../hooks/index';

const Image = styled.img`
  width: 75%;
  height: 100%;
`;

const containerAnim = {
  show: {
    transition: {
      staggerChildren: 0.05
    }
  }
};

const links = [
  {
    to: '/owner/accounts',
    label: 'Accounts',
    icon: 'userGroup'
  },
  {
    to: '/owner/licenses',
    label: 'Licenses',
    icon: 'key'
  },
  {
    to: '/owner/users',
    label: 'Users',
    icon: 'users'
  },
  {
    to: '/owner/white-label',
    label: 'White Label',
    icon: 'colorSwatch',
    canSee: canWhiteLabel
  },
  {
    to: '/owner/docs',
    label: 'Documentation',
    icon: 'bookOpen'
  },
  {
    to: '/owner/logins',
    label: 'Login Methods',
    icon: 'viewGridAdd',
    disableBreadCrumbs: true
  }
];

const OwnerSidebar = () => {
  const pulse = usePulse();
  const auth = useAuth();
  const { secondary } = useTheme();
  const { filteredLinks, openLinkIndex, handleOpen } = useSidebar(links);

  return (
    <>
      <PulseSideBar type="owner">
        <Flex middle fat mb>
          <Image src={pulse.owner.logo_url} />
        </Flex>
        <Flex fat>
          <Flex fat mrSm mlSm growChildren>
            <Tag>
              <Flex middle>
                <Heading2>Owner</Heading2>
              </Flex>
            </Tag>
          </Flex>
        </Flex>
        <Flex grow fat xHidden col yScroll>
          <Flex padSm col between grow ySpaceSm>
            <motion.div variants={containerAnim} initial="hide" animate="show">
              {filteredLinks.map((link, key) => (
                <SidebarItem
                  key={`${key}-${link.label}`}
                  type="owner"
                  open={Boolean(openLinkIndex && openLinkIndex === key)}
                  handleOpen={handleOpen(key)}
                  {...link}
                />
              ))}
            </motion.div>
            <Flex col fat>
              <PulseSideBarItem type="owner">
                <Link to="/">
                  <Flex center xSpaceSm>
                    <Icon
                      type="switchHorizontal"
                      colour={secondary}
                      width="1.25rem"
                      height="1.25rem"
                    />
                    <Span>{auth.client.name}</Span>
                  </Flex>
                </Link>
              </PulseSideBarItem>
              <PulseSideBarItem type="owner">
                <Link to="/owner/logout">
                  <Flex center xSpaceSm>
                    <Icon
                      type="logout"
                      colour={secondary}
                      width="1.25rem"
                      height="1.25rem"
                    />
                    <Span>Logout</Span>
                  </Flex>
                </Link>
              </PulseSideBarItem>
            </Flex>
          </Flex>
        </Flex>
      </PulseSideBar>
    </>
  );
};

export default OwnerSidebar;
