import tw, { styled } from 'twin.macro';

// Offset the height to prevent content in the RightPanelSlider
// from falling out of the container.
const height = 'calc(100% - 130px)';

const PanelContent = styled.div`
  ${tw`p-6 overflow-y-scroll w-full`}
  height: ${height};
`;

export default PanelContent;
