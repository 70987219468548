import React, { useState, useEffect } from 'react';

import { PageWrapper, LoadingContainer } from '@ubisend/pulse-components';
import { Drivers } from '@ubisend/pulse-hooks';
import { useAuth } from '@ubisend/pulse-auth';

import { getAllDrivers } from '../api/index';

const PaymentDrivers = () => {
  const [drivers, setDrivers] = useState();
  const [activeDrivers, setActiveDrivers] = useState();

  const { hasPermission } = useAuth();

  const fetchDrivers = async () => {
    const { data } = await getAllDrivers();
    setDrivers(
      data.data.map(driver => ({
        ...driver,
        logo: 'creditCard'
      }))
    );

    // All drivers are active.
    setActiveDrivers(data.data.map(driver => driver.id));
  };

  useEffect(() => {
    fetchDrivers();
  }, []);

  const loaded = Boolean(drivers && activeDrivers);

  const handleDriverSelect = () => {
    // TODO
  };

  const copy = {
    available: {
      title: 'Available payment processors',
      info: 'Select a payment processor.'
    },
    other: {
      title: 'Other payment processors',
      info:
        'Contact us for a custom implementation of these payment processors.'
    }
  };

  return (
    <PageWrapper header="Payments" subheader="Configure your payment processor">
      {!loaded && <LoadingContainer />}
      {loaded && (
        <Drivers
          copy={copy}
          type="multi"
          required
          value={activeDrivers}
          drivers={drivers}
          handleClick={handleDriverSelect}
          canEditDriver={driver => {
            return hasPermission(`edit ${driver.resource_name}`);
          }}
          canEnableDriver={() => false}
          canDisableDriver={() => false}
        />
      )}
    </PageWrapper>
  );
};

export default PaymentDrivers;
