import React, { useEffect } from 'react';
import tw, { styled } from 'twin.macro';

import { TextArea as BaseTextArea } from '@ubisend/pulse-components';

import { useTemplating } from '../../../hooks/index';
import { LINE_HEIGHT } from '../../../constants';

const StyledBaseTextArea = styled(BaseTextArea)`
  ${tw`rounded-none m-0`}
  white-space: pre;
  line-height: ${LINE_HEIGHT};
  tab-size: 4;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
`;

const CodeEditor = props => {
  const {
    value,
    dispatch,
    lineCount,
    input,
    cursor,
    justAddedSnippet,
    justClosedVariables,
    TYPES
  } = useTemplating();

  useEffect(() => {
    if (justAddedSnippet.current || justClosedVariables.current) {
      justAddedSnippet.current = false;
      justClosedVariables.current = false;
      input.current.selectionStart = cursor.current;
      input.current.selectionEnd = cursor.current;
      input.current.focus();
    }
  });

  const handleValueChange = event => {
    const { value, selectionStart } = event.target;

    dispatch({
      type: TYPES.UPDATE_VALUE,
      value,
      cursor: selectionStart
    });
  };

  const handleOnClick = event => {
    const { selectionStart } = event.target;

    dispatch({ type: TYPES.UPDATE_CURSOR, cursor: selectionStart });
  };

  const handleOnKeyDown = event => {
    if (event.key === 'Tab') {
      event.preventDefault();
      dispatch({ type: TYPES.ADD_SNIPPET, snippet: '\t' });
    }
  };

  const handleOnKeyUp = event => {
    const selectionStart = event.target.selectionStart;

    dispatch({ type: TYPES.UPDATE_CURSOR, cursor: selectionStart });
  };

  return (
    <StyledBaseTextArea
      {...props}
      value={value}
      ref={input}
      onChange={handleValueChange}
      onClick={handleOnClick}
      onKeyDown={handleOnKeyDown}
      onKeyUp={handleOnKeyUp}
      rows={lineCount}
    />
  );
};

export default CodeEditor;
