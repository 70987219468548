import React from 'react';

import { useMutation } from '@ubisend/pulse-hooks';
import { Button, useNotification, useModal } from '@ubisend/pulse-components';
import { useAuth } from '@ubisend/pulse-auth';

import { useImports } from '../../hooks/index';
import { exportAllImports } from '../../api/index';

const ExportAllImports = () => {
  const { loading, imports } = useImports();
  const { showSuccess } = useNotification();
  const { showModal, hideModal } = useModal();
  const { user } = useAuth();

  const mutation = useMutation(exportAllImports, {
    onSuccess: () => {
      showSuccess(
        'You will receive an email shortly with details on accessing your export.'
      );
    }
  });

  const handleSubmit = () => {
    showModal({
      header: 'Export all knowledge bases',
      body: `After clicking confirm you will receive an email at "${user.email}" with a link to download every import.`,
      handleConfirm: async () => {
        try {
          await mutation.mutateAsync();
        } finally {
          hideModal();
        }
      }
    });
  };

  return (
    <Button
      disabled={loading || (!loading && imports.length <= 0)}
      variant="inline"
      onClick={handleSubmit}
      icon={'download'}>
      Export
    </Button>
  );
};

export default ExportAllImports;
