import TriggerPostbackCancelContent from './TriggerPostbackCancelContent';

const triggerPostbackCancel = {
  name: 'Trigger postback cancel',
  Content: TriggerPostbackCancelContent,
  setInitialContent: () => {
    return {};
  },
  valid: () => {
    return true;
  }
};

export default triggerPostbackCancel;
