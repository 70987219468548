import React, { useState, useEffect } from 'react';
import { useLocation, Redirect } from 'react-router-dom';

import { AnimateSharedLayout } from '@ubisend/framer-motion';
import { Flex, TabButton } from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { useImports } from '../hooks/index';

const SectionNav = () => {
  const { importId, sectionId, section } = useImports();
  const location = useLocation();

  const [redirectPath, setRedirectPath] = useState();

  const buildLink = (path = '') => {
    return `/knowledge-bases/${importId}/sections/${sectionId}${path}`;
  };

  const checkActive = path => {
    return location.pathname.includes(buildLink(path));
  };

  const handleRedirect = path => setRedirectPath(buildLink(path));

  useEffect(() => {
    setRedirectPath();
  }, [redirectPath]);

  return (
    <Flex xSpace mb shiftUp>
      {redirectPath && <Redirect to={redirectPath} />}
      <AnimateSharedLayout>
        <TabButton
          active={checkActive('/content')}
          onClick={() => handleRedirect('/content')}>
          Content
        </TabButton>
        <TabButton
          active={checkActive('/preview')}
          onClick={() => handleRedirect('/preview')}>
          Preview
        </TabButton>
        {section?.other_content?.length > 0 && (
          <TabButton
            active={checkActive('/versions')}
            onClick={() => handleRedirect('/versions')}>
            Versions
          </TabButton>
        )}
        <TabButton
          active={checkActive('/resources')}
          onClick={() => handleRedirect('/resources')}>
          Resources
        </TabButton>
        <TabButton
          active={checkActive('/metadata')}
          onClick={() => handleRedirect('/metadata')}>
          Metadata
        </TabButton>
        <PermissionFilter can="view faqs">
          <TabButton
            active={checkActive('/faqs')}
            onClick={() => handleRedirect('/faqs')}>
            FAQs
          </TabButton>
        </PermissionFilter>
      </AnimateSharedLayout>
    </Flex>
  );
};

export default SectionNav;
