import React from 'react';
import chroma from 'chroma-js';

import Graphic from '../Graphic';
import { useTheme } from '../../../hooks/index';

const Builder = props => {
  const { primary } = useTheme();

  return (
    <Graphic
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 140 145"
      preserveAspectRatio="xMidYMid meet"
      fill="none"
      {...props}>
      <ellipse cx="69.997" cy="140.781" fill="#F3F3F4" rx="51" ry="3.5" />
      <ellipse cx="70.155" cy="78.107" fill="#D9D9D9" rx="61.155" ry="61.107" />
      <path
        fill="#D9D9D9"
        d="M109.989 125.281c-10.741 9.343-24.777 15-40.136 15-10.842 0-21.026-2.819-29.856-7.764v-7.236h69.992Z"
      />
      <path
        fill={primary}
        fillOpacity=".75"
        d="M109.989 125.281c-10.741 9.343-24.777 15-40.136 15-10.842 0-21.026-2.819-29.856-7.764v-7.236h69.992Z"
      />
      <path
        fill="#D9D9D9"
        d="M40.406 132.281a61.4 61.4 0 0 1-19.362-16h48.953a5 5 0 0 1 5 5v6a5 5 0 0 1-5 5H40.406Z"
      />
      <path
        fill={primary}
        fillOpacity=".5"
        d="M40.406 132.281a61.4 61.4 0 0 1-19.362-16h48.953a5 5 0 0 1 5 5v6a5 5 0 0 1-5 5H40.406Z"
      />
      <path
        fill={primary}
        fillOpacity=".75"
        d="M110.816 124.849c.28-.247.556-.496.83-.747a.5.5 0 0 0-.676-.737 57.535 57.535 0 0 1-1.646 1.456.5.5 0 0 0 .649.761c.283-.241.564-.486.843-.733ZM114.09 120.293a.5.5 0 0 0 .726.687 61.42 61.42 0 0 0 4.316-5.088.5.5 0 0 0-.797-.605 60.69 60.69 0 0 1-4.245 5.006ZM104.673 129.636a.499.499 0 1 0-.564-.825 60.637 60.637 0 0 1-3.7 2.336.5.5 0 1 0 .503.864 60.886 60.886 0 0 0 3.761-2.375ZM98.967 133.096a.5.5 0 1 0-.471-.882c-18.15 9.696-39.99 9.416-57.775-.37a.5.5 0 0 0-.482.876c18.079 9.948 40.277 10.233 58.728.376ZM123.105 107.951a.5.5 0 0 0 .876.482c9.968-18.084 10.275-40.282.434-58.726a.5.5 0 0 0-.882.47c9.679 18.143 9.379 39.983-.428 57.774ZM122.466 48.263a.5.5 0 0 0 .865-.502 61.048 61.048 0 0 0-2.374-3.762.499.499 0 1 0-.825.563 60.718 60.718 0 0 1 2.334 3.701ZM32.777 127.876a.5.5 0 0 0 .605-.797 60.613 60.613 0 0 1-5.008-4.244.5.5 0 1 0-.687.727 61.65 61.65 0 0 0 5.09 4.314ZM24.565 120.392a.5.5 0 0 0 .737-.676 55.11 55.11 0 0 1-.7-.777C2.461 93.869 4.83 55.582 29.906 33.397c25.076-22.186 63.367-19.87 85.551 5.16.232.262.46.526.686.79a.5.5 0 0 0 .761-.65 70.487 70.487 0 0 0-.662-.762l-.036-.04a62.292 62.292 0 0 0-3.84-3.966.537.537 0 0 0-.064-.074 61.471 61.471 0 0 0-5.091-4.314.494.494 0 0 0-.128-.07 61.102 61.102 0 0 0-7.187-4.653.497.497 0 0 0-.146-.121c-18.079-9.948-40.277-10.233-58.728-.376a.497.497 0 0 0-.22.236c-.472.255-.94.517-1.407.785a.498.498 0 0 0-.318.064 61.499 61.499 0 0 0-3.761 2.374.498.498 0 0 0-.195.263 62.21 62.21 0 0 0-4.8 3.673.498.498 0 0 0-.306.12 62.812 62.812 0 0 0-1.672 1.48.498.498 0 0 0-.156.288 62.456 62.456 0 0 0-2.724 2.681.498.498 0 0 0-.29.152 61.658 61.658 0 0 0-4.316 5.088.498.498 0 0 0-.102.308 61.233 61.233 0 0 0-4.513 6.935.498.498 0 0 0-.234.216C6.04 67.068 5.733 89.266 15.574 107.71c.026.048.058.09.095.126.302.565.613 1.126.935 1.684a.5.5 0 0 0 .054.136 61.214 61.214 0 0 0 2.373 3.762.508.508 0 0 0 .076.088 62.21 62.21 0 0 0 4.71 6.056l.036.04.036.04.007.008c.221.249.444.497.67.742Z"
      />
      <path fill="#fff" stroke={primary} d="M89 16H1v85h88V16Z" />
      <path
        fill="#D9D9D9"
        d="M19.5 20.5h-13a1 1 0 0 0-1 1v74a1 1 0 0 0 1 1h13a1 1 0 0 0 1-1v-74a1 1 0 0 0-1-1ZM53.423 23.726h-16.97a1 1 0 0 0-1 1v13.058a1 1 0 0 0 1 1h16.97a1 1 0 0 0 1-1V24.726a1 1 0 0 0-1-1ZM45.435 47.819h-16.97a1 1 0 0 0-1 1v13.057a1 1 0 0 0 1 1h16.97a1 1 0 0 0 1-1V48.82a1 1 0 0 0-1-1ZM63.407 71.911h-16.97a1 1 0 0 0-1 1V85.97a1 1 0 0 0 1 1h16.97a1 1 0 0 0 1-1V72.911a1 1 0 0 0-1-1ZM83.374 23.726H69.4a1 1 0 0 0-1 1v30.123a1 1 0 0 0 1 1h13.975a1 1 0 0 0 1-1V24.726a1 1 0 0 0-1-1ZM28.458 20.714h-1.986a1.004 1.004 0 0 0 0 2.008h1.986a1.004 1.004 0 0 0 0-2.008Z"
      />
      <path
        fill={primary}
        fillRule="evenodd"
        d="M43.614 36.828a2.667 2.667 0 1 1 .387 4.092l1.853-1.853-.284-.283h-.847l-1.43 1.428a2.667 2.667 0 0 1 .321-3.384ZM38 45.508l5.295-5.296a2.678 2.678 0 0 0 .707.708l-5.295 5.295a2.668 2.668 0 1 1-.707-.708Z"
        clipRule="evenodd"
      />
      <path
        fill={primary}
        d="m44.001 40.92 1.853-1.853-.284-.283h-.847l-1.43 1.428a2.678 2.678 0 0 0 .685.691l.023.017Z"
      />
      <path
        fill={primary}
        fillRule="evenodd"
        d="M35.307 65.099a2.667 2.667 0 1 1 3.76-1.661l-1.123-.562h-1.526l-.142.285 2.344 1.171a2.667 2.667 0 0 1-3.313.767Zm16.626 5.89L38.62 64.332a2.688 2.688 0 0 0 .447-.894l13.313 6.657a2.668 2.668 0 1 1-.447.894Z"
        clipRule="evenodd"
      />
      <path
        fill={primary}
        d="m39.067 63.438-1.123-.562h-1.526l-.142.285 2.344 1.171a2.688 2.688 0 0 0 .439-.867l.008-.027Z"
      />
      <ellipse cx="89.114" cy="99.823" fill="#fff" rx="13.404" ry="13.393" />
      <path
        fill="url(#a)"
        fillRule="evenodd"
        d="M89.114 113.217c7.402 0 13.403-5.997 13.403-13.394s-6-13.393-13.403-13.393c-7.403 0-13.404 5.996-13.404 13.393s6.001 13.394 13.404 13.394Zm1.675-18.416a1.675 1.675 0 0 0-3.35 0v3.348h-3.352a1.675 1.675 0 1 0 0 3.348h3.351v3.349a1.675 1.675 0 0 0 3.351 0v-3.349h3.351a1.674 1.674 0 1 0 0-3.348h-3.35v-3.348Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        stroke={primary}
        d="M136.118 70h-12.236A1.882 1.882 0 0 0 122 71.882v12.236c0 1.04.843 1.882 1.882 1.882h12.236A1.882 1.882 0 0 0 138 84.118V71.882c0-1.04-.843-1.882-1.882-1.882Z"
      />
      <path
        fill={primary}
        d="M128.179 72H124v.5h4.179V72ZM128.179 73H124v.5h4.179V73Z"
      />
      <path
        fill="#fff"
        stroke={primary}
        d="M130.5 71a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      />
      <path stroke={primary} d="M121 75.5h16" />
      <path fill={primary} d="M126 78.25h9v2h-9v-2ZM126 82.25h9v2h-9v-2Z" />
      <path
        fill="#fff"
        stroke={primary}
        d="M130.5 88a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      />
      <path
        fill="#fff"
        d="M112.66 1.281H85.438c-3.007 0-5.444 2.388-5.444 5.334v16c0 2.945 2.437 5.333 5.444 5.333h5.444v10.667l1.595-1.562 9.294-9.105h10.889c3.007 0 5.445-2.388 5.445-5.333v-16c0-2.946-2.438-5.334-5.445-5.334Z"
      />
      <path
        fill={primary}
        fillOpacity=".25"
        d="M112.66 1.281H85.438c-3.007 0-5.444 2.388-5.444 5.334v16c0 2.945 2.437 5.333 5.444 5.333h5.444v10.667l1.595-1.562 9.294-9.105h10.889c3.007 0 5.445-2.388 5.445-5.333v-16c0-2.946-2.438-5.334-5.445-5.334Z"
      />
      <path
        stroke={primary}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M118.105 22.615v-16m0 16c0 2.945-2.438 5.333-5.445 5.333h-10.889l-9.294 9.105-1.595 1.562V27.948h-5.444c-3.007 0-5.444-2.388-5.444-5.333v-16c0-2.946 2.437-5.334 5.444-5.334h27.222c3.007 0 5.445 2.388 5.445 5.334m0 16v-16"
      />
      <path
        fill={primary}
        d="M118.105 11.948v10.667c0 2.945-2.438 5.333-5.445 5.333h-10.888l-9.295 9.105a5.485 5.485 0 0 0 3.85 1.562h10.889l10.889 10.666V38.615h5.444c3.007 0 5.445-2.388 5.445-5.334v-16c0-2.945-2.438-5.333-5.445-5.333h-5.444Z"
      />
      <path
        stroke={primary}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M118.105 11.948v10.667c0 2.945-2.438 5.333-5.445 5.333h-10.888l-9.295 9.105a5.485 5.485 0 0 0 3.85 1.562h10.889l10.889 10.666V38.615h5.444c3.007 0 5.445-2.388 5.445-5.334v-16c0-2.945-2.438-5.333-5.445-5.333h-5.444Z"
      />
      <defs>
        <linearGradient
          id="a"
          x1="89.114"
          x2="89.114"
          y1="86.43"
          y2="113.217"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={chroma(primary).brighten(0.5 - 0)} />
          <stop offset="1" stopColor={primary} />
        </linearGradient>
      </defs>
    </Graphic>
  );
};
export default Builder;
