import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';

import { Span } from '../Text/index';

const Container = styled.div`
  ${tw`flex px-4 py-4 cursor-pointer`}
  ${props =>
    props.isSelected &&
    !props.disabled &&
    `border-bottom-style: solid;
     border-bottom-color: ${props.theme.primary};
     color: ${props.theme.primary};
     font-weight: bold;
  `}
  ${props =>
    props.disabled
      ? `&:hover {
        cursor: not-allowed;
      }`
      : `&:hover {
    > span {
      ${tw`opacity-50`}
    }
    border-bottom-style: solid;
    border-bottom-color: ${props => props.theme.primary};
    color: ${props => props.theme.primary};
  }
`}
  ${props => props.disabled && `color: grey;`}
`;

const Tab = ({ title, isSelected = true, disabled = false, ...rest }) => {
  return (
    <Container isSelected={isSelected} disabled={disabled} {...rest}>
      <Span>{title}</Span>
    </Container>
  );
};

Tab.propTypes = {
  title: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  disabled: PropTypes.bool
};

export default Tab;
