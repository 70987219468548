import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import tw, { styled } from 'twin.macro';

import { motion } from '@ubisend/framer-motion';
import { Flex } from '@ubisend/pulse-components';

import { TallHeader, CloseWidgetButton } from '../Components/Header/index';
import { BannerComposer, WidgetFooter } from '../Components/index';
import { useBot, useTheme } from '../../../hooks/index';

const Title = styled(motion.h1)`
  ${tw`text-4xl font-bold font-poppins mt-6 flex items-center m-0 block`};
`;

Title.defaultProps = {
  initial: { opacity: 0, x: -20 },
  animate: { opacity: 1, x: 0 },
  transition: { delay: 0.2, ease: [0.16, 1, 0.3, 1], duration: 1 }
};

const SubTitle = styled(motion.p)`
  ${tw`mt-4 whitespace-pre-wrap`};
`;

const StyledFlex = styled(Flex)`
  padding-right: 1.75rem;
`;

SubTitle.defaultProps = {
  initial: { opacity: 0, x: -20 },
  animate: { opacity: 1, x: 0 },
  transition: { delay: 0.4, ease: [0.16, 1, 0.3, 1], duration: 1 }
};

const Banner = () => {
  const {
    showPoweredByUbisend,
    title,
    subtitle,
    showClose,
    onWidgetClose
  } = useBot();
  const theme = useTheme();

  const { t } = useTranslation('converse');

  const history = useHistory();

  const closeWidget = () => {
    history.push('/', { closed: true });
    onWidgetClose();
  };

  return (
    <>
      <TallHeader {...theme}>
        <Flex right>
          {showClose && (
            <CloseWidgetButton
              aria-label={t('close_widget_button_label')}
              onClick={closeWidget}
            />
          )}
        </Flex>
        <Title>{title}</Title>
        <SubTitle>{subtitle}</SubTitle>
      </TallHeader>
      <BannerComposer />
      <StyledFlex between background="white" pl pr pbSm={showPoweredByUbisend}>
        <div />
        {showPoweredByUbisend && <WidgetFooter />}
      </StyledFlex>
    </>
  );
};

export default Banner;
