import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';
import chroma from 'chroma-js';

import { motion } from '@ubisend/framer-motion';

import Button from './Button';

const styles = ({ active, theme }) => {
  return active
    ? `color: ${theme.primary};`
    : tw`opacity-50 cursor-pointer text-black`;
};

const Tab = styled(Button)`
  ${tw`px-3 h-8 font-semibold bg-transparent border-0 font-poppins text-xs uppercase`}
  &,&:not(:disabled):hover ,&:not(:disabled):active {
    background-image: none;
  }
  a {
    ${tw`no-underline`}
    ${styles}
  }
  ${styles}
  ${tw`items-center`}
`;

const TabBackground = styled(motion.div)`
  ${tw`h-8 w-full rounded-sm`}
  margin-top: -2rem;
  background: ${({ theme }) => chroma(theme.primary).alpha(0.25)};
`;

const TabButton = ({ children, active, onClick, ...props }) => {
  return (
    <div>
      <Tab active={active} onClick={onClick} {...props}>
        {children}
      </Tab>
      {active && (
        <TabBackground
          layoutId="outline"
          transition={{
            ease: [0.22, 1, 0.36, 1],
            duration: 0.4
          }}
        />
      )}
    </div>
  );
};

TabButton.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func
};

Tab.defaultProps = {
  type: 'button'
};

export default TabButton;
