import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

import ComposerButton from './ComposerButton';
import { UploadFileInput } from '../../../../Components/index';

const UploadButton = ({ onChange, disabled, ...props }) => {
  const theme = useTheme();

  return (
    <>
      <UploadFileInput id="file" disabled={disabled} onChange={onChange} />
      <ComposerButton as="label" htmlFor="file" disabled={disabled} {...props}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          stroke={theme.primary}>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            fill="none"
            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
          />
        </svg>
      </ComposerButton>
    </>
  );
};

UploadButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default UploadButton;
