import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';

import { ButtonSelect, Flex, Heading2 } from '@ubisend/pulse-components';

import { useChartColours, useMetric } from '../../../hooks/index';

const options = [
  {
    label: 'Daily',
    value: 'days'
  },
  {
    label: 'Weekly',
    value: 'weeks'
  },
  {
    label: 'Monthly',
    value: 'months'
  },
  {
    label: 'Quarterly',
    value: 'quarters'
  }
];

const LineGraphMultiAxisMetric = () => {
  const [axisInterval, setAxisInterval] = useState('days');

  const { editing, metric } = useMetric();
  const colours = useChartColours();

  const handleChange = ({ value }) => {
    setAxisInterval(value);
  };

  const selected = metric.data.data.selected[axisInterval].datasets.map(
    (set, key) => {
      return {
        ...set,
        borderColor: colours(
          metric.data.data.selected[axisInterval].length,
          metric.data.data.selected[axisInterval].datasets.length
        )[key]
      };
    }
  );

  const comparedData =
    metric.data?.data?.compared?.[axisInterval]?.datasets || [];

  const compared =
    comparedData.length > 0
      ? comparedData.map(set => {
          return {
            ...set,
            borderColor: '#CF9FFF'
          };
        })
      : [];

  const datasets = selected.concat(compared);

  return (
    <Flex col pad tall>
      <Flex between fat>
        <Heading2>{metric.data.label}</Heading2>
        {!editing && (
          <ButtonSelect
            defaultValue={{ ...options[0] }}
            onChange={handleChange}
            options={options}
          />
        )}
      </Flex>
      <Flex tall>
        <Line
          options={{
            responsive: true,
            stacked: false,
            animations: false,
            maintainAspectRatio: false
          }}
          data={{
            ...metric.data.data.selected[axisInterval],
            datasets: datasets
          }}
        />
      </Flex>
    </Flex>
  );
};

export default LineGraphMultiAxisMetric;
