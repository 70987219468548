import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';

const defaultTheme = {
  logo:
    'https://ubisend-bots-assets.s3.eu-west-2.amazonaws.com/images/7vH7oq6YhgTSwgeNBPmEG1EbE9n8Cw61I3pBa6v8.png',

  showPoweredByUbisend: true,
  // Colours
  textColour: '#FFFFFF',
  background: '#FFFFFF',
  gradient: { from: '#b15199', to: '#d97eb3' },
  // Messages
  sentMessage: { text: '#FFFFFF', background: '#b15199' },
  recievedMessage: { text: '#000000', background: '#dadadd' },
  // Call-to-action.
  ctaBackgroundColour: '#B15199',
  ctaTextColour: '#FFFFFF',
  // Launcher
  launcherBackgroundColour: '#B15199',
  // Font
  fontSrc:
    'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Poppins:wght@700&display=swap',
  headerFont: 'Poppins',
  bodyFont: 'Open Sans',
  // Dimensions
  size: {
    width: '24rem',
    height: '640px'
  }
};

const ThemeProvider = ({ value = defaultTheme, children }) => (
  <StyledComponentsThemeProvider
    theme={{
      // Bot specific theme.
      bot: value,

      // Generic theme.
      white: '#ffffff',
      black: '#1a1a1a',
      grey: '#6f6f6f',
      primary: value.gradient.from,
      secondary: value.gradient.from,
      positive: '#3bb6a1',
      danger: '#e62851',
      warning: '#ffc25d'
    }}>
    {children}
  </StyledComponentsThemeProvider>
);

ThemeProvider.propTypes = {
  value: PropTypes.object
};

export default ThemeProvider;
export { defaultTheme };
