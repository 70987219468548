import React from 'react';
import { Link } from 'react-router-dom';

import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import {
  PageWrapper,
  Button,
  StretchPanel,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableActions,
  useNotification,
  Pagination,
  EmptyStatePlaceholder,
  useModal,
  usePaginationReducer,
  OrderableTableRow,
  useOrderableTableReducer
} from '@ubisend/pulse-components';
import { SubscriberLink, TicketCreatedAt } from '@ubisend/pulse-tickets';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { deleteFeedback as deleteFeedbackApi } from '../api';

const AllFeedback = () => {
  const pagination = usePaginationReducer({ id: 'feedback' });
  const order = useOrderableTableReducer({ id: 'feedback' });

  const { showModal, hideModal } = useModal();
  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const query = useQuery([
    'feedback',
    { ...pagination.params, ...order.params }
  ]);
  const { mutateAsync: deleteFeedback } = useMutation(deleteFeedbackApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('feedback');
      showSuccess('Feedback deleted sucessfully');
    }
  });

  const handleDelete = ({ id }) => () => {
    showModal({
      header: 'Delete Feedback',
      body: `Are you sure you want to delete this piece of feedback?`,
      handleConfirm: async () => {
        try {
          await deleteFeedback(id);
        } finally {
          hideModal();
        }
      }
    });
  };

  return (
    <PageWrapper
      header="Feedback"
      subheader="View subscriber submitted feedback">
      <StretchPanel>
        {query.showNoResultsMessage && (
          <EmptyStatePlaceholder
            type="feedback"
            heading="Nothing to show yet"
            text="When users start leaving feedback, their messages will appear here. "
            helpLink="/docs/2151317744/2153414707"
            helpText="Learn more about feedback."
          />
        )}
        {query.showTable && (
          <>
            <Table loading={query.isLoading} loadingColumns={3}>
              <TableHead>
                <OrderableTableRow
                  rows={[
                    { label: 'Subscriber', sort: null },
                    { label: 'Date', sort: 'created_at' },
                    null
                  ]}
                  {...order.props}
                />
              </TableHead>
              {query.isSuccess && (
                <TableBody>
                  {query.data.data.map((feedback, key) => (
                    <TableRow key={key}>
                      <TableCell>
                        <SubscriberLink subscriber={feedback.subscriber} />
                      </TableCell>
                      <TableCell>
                        <TicketCreatedAt date={feedback.created_at} />
                      </TableCell>
                      <TableActions>
                        <Button
                          as={Link}
                          variant="secondary"
                          to={`/feedback/${feedback.id}`}
                          icon="eye">
                          View
                        </Button>
                        <PermissionFilter can="delete feedback">
                          <Button
                            variant="secondary"
                            colour="danger"
                            icon="trash"
                            onClick={handleDelete(feedback)}>
                            Delete
                          </Button>
                        </PermissionFilter>
                      </TableActions>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            {query.showPagination && (
              <Pagination pagination={query.data.meta} {...pagination.props} />
            )}
          </>
        )}
      </StretchPanel>
    </PageWrapper>
  );
};

export default AllFeedback;
