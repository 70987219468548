import React from 'react';

import { Flex, useNotification } from '@ubisend/pulse-components';
import { useMutation } from '@ubisend/pulse-hooks';

import { FacebookSettings, SetupFacebookComponent } from '../Components/index';
import { updateSettings as updateSettingsApi } from '../api/settings';
import { useFacebookSettings } from '../hooks/index';

const FacebookLink = () => {
  const { settings } = useFacebookSettings();
  const { showSuccess, showError } = useNotification();

  const { mutate: updateSettings } = useMutation(updateSettingsApi, {
    onSuccess: ({ data: { label } }) => {
      showSuccess(`Linked Facebook page ${label}`);
    },
    onError: () =>
      showError(`Something went wrong whilst selecting a Facebook page`)
  });

  const handlePageSelect = page => {
    const {
      value: { id, access_token },
      label
    } = page;

    updateSettings({
      page_id: id,
      auth_token: access_token,
      page_name: label
    });
  };

  return (
    <>
      <Flex>
        {settings.page_id && <FacebookSettings settings={settings} />}

        {!settings.page_id && (
          <SetupFacebookComponent handlePageSelect={handlePageSelect} />
        )}
      </Flex>
    </>
  );
};

export default FacebookLink;
