import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';

import Spinner from './Spinner';
import Pulse from './Pulse';
import { position } from '../styles';

const Container = styled.div`
  ${tw`flex justify-center h-full items-center flex-col`}
  ${position}
`;

const LoadingContainer = ({ spinner = 'Spinner', ...rest }) => (
  <Container {...rest}>
    {spinner === 'Spinner' ? <Spinner /> : <Pulse />}
  </Container>
);

LoadingContainer.propTypes = {
  spinner: PropTypes.string
};

export default LoadingContainer;
