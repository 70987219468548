import axios from 'axios';

const removeSettings = () => axios.delete('instagram/settings');

const updateSettings = params => axios.put(`instagram/settings`, params);

const getPages = params => axios.get(`facebook/pages`, { params });

const acceptPolicy = () => axios.put('instagram/settings/accept');

export { updateSettings, removeSettings, getPages, acceptPolicy };
