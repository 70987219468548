import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Flex,
  Button,
  Label,
  TextInput
} from '@ubisend/pulse-components';

const CreateUtterance = ({ handleCancel, handleSubmit }) => {
  const [utterance, setUtterance] = useState('');

  return (
    <ModalPortal>
      <form onSubmit={event => handleSubmit(event, utterance)}>
        <ModalContainer>
          <ModalContent>
            <ModalHeader>Create Question</ModalHeader>
            <ModalBody>
              <Flex col>
                <Label htmlFor="name">Question text</Label>
                <TextInput
                  name="question"
                  placeholder="How much annual leave do I have left"
                  value={utterance.text}
                  onChange={event => setUtterance(event.target.value)}
                  id="name"
                />
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Flex xSpace>
                <Button variant="secondary" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button type="submit" variant="primary">
                  Save
                </Button>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </ModalContainer>
      </form>
    </ModalPortal>
  );
};

CreateUtterance.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default CreateUtterance;
