import React from 'react';

import { useQuery } from '@ubisend/pulse-hooks';
import {
  PageWrapper,
  Placeholder,
  Flex,
  Divider
} from '@ubisend/pulse-components';

import { Scope } from './Components/index';

const groupByScope = (groups, group) => {
  return {
    ...groups,
    [group.scoped_to]: groups[group.scoped_to]
      ? groups[group.scoped_to].concat(group)
      : [group]
  };
};

const Groups = () => {
  const { data, isSuccess, isLoading } = useQuery('groups');

  const groupedTags =
    isSuccess &&
    data.data.reduce(groupByScope, {
      faqs: [],
      tickets: [],
      conversations: [],
      links: []
    });

  return (
    <PageWrapper header="Tags">
      {isLoading && <Placeholder ySpace />}
      {isSuccess && (
        <Flex fat ySpace col>
          <Scope scope="conversations" groups={groupedTags.conversations} />
          <Divider tall />
          <Scope scope="faqs" groups={groupedTags.faqs} />
          <Divider tall />
          <Scope scope="tickets" groups={groupedTags.tickets} />
          <Divider tall />
          <Scope scope="links" groups={groupedTags.links} />
        </Flex>
      )}
    </PageWrapper>
  );
};

export default Groups;
