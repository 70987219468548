import React from 'react';
import tw, { styled } from 'twin.macro';

import { AnimatePresence } from '@ubisend/framer-motion';
import { Flex } from '@ubisend/pulse-components';

import {
  Composer,
  WidgetFooter,
  BurgerMenu,
  Messages as MessagesComponent
} from '../../Converse/Components/index';
import { RecordSubscriberTyping } from '../../../Components/index';
import { Body } from '../Components/index';
import { ComposerProvider } from '../../../Providers/index';
import { useDelivery, useBot, useJumpToBottom } from '../../../hooks/index';

const FooterDivider = styled.hr`
  ${tw`m-0 border-grey-medium border-0 border-t w-full border-solid opacity-25`}
`;

const StyledFlex = styled(Flex)`
  #chatbot-footer > button {
    margin: 0.5rem 0;
  }
`;
const StyledContainer = styled(Flex)`
  padding-right: 1.75rem;
`;

const Messages = () => {
  const { sendMessage } = useDelivery();
  const { showPoweredByUbisend, showBurgerMenu } = useBot();
  const { showJump, handleScroll } = useJumpToBottom();

  return (
    <>
      <Body onScroll={handleScroll}>
        <MessagesComponent showJump={showJump} />
      </Body>
      <AnimatePresence>
        {showBurgerMenu && <BurgerMenu handleButtonClick={sendMessage} />}
      </AnimatePresence>
      <ComposerProvider>
        <RecordSubscriberTyping />
        <Composer channel="embed" />
      </ComposerProvider>
      {showPoweredByUbisend ? (
        <>
          <FooterDivider mbNone mtNone />
          <StyledContainer between center background="white" ptSm pbSm pl>
            <div id="chatbot-footer" />
            <WidgetFooter />
          </StyledContainer>
        </>
      ) : (
        <>
          <StyledFlex col>
            <Flex between background="white" center pl pr>
              <div id="chatbot-footer" />
            </Flex>
          </StyledFlex>
        </>
      )}
    </>
  );
};

export default Messages;
