import React from 'react';
import PropTypes from 'prop-types';
import { styled } from 'twin.macro';

import Label from '../Forms/Label';
import InnerPanel from './InnerPanel';
import { spacing } from '../styles';

const colours = {
  warning: '255, 194, 93',
  danger: '230, 40, 81',
  note: '0,0,0'
};

const ExplainerPanel = styled(InnerPanel)`
  ${spacing}
  box-sizing: border-box;
  background-color: rgba(
    ${props => colours[props.type]},
    ${props => (props.type !== 'note' ? 0.1 : 0.033)}
  );
  border: 1px solid
    rgba(
      ${props => colours[props.type]},
      ${props => (props.type !== 'note' ? 0.5 : 0.25)}
    );
`;

const Explainer = ({
  children,
  type = 'note',
  hideLabel = false,
  ...props
}) => {
  return (
    <ExplainerPanel type={type} {...props}>
      {!hideLabel && <Label mb={false}>{type}</Label>}
      {children}
    </ExplainerPanel>
  );
};

Explainer.propTypes = {
  type: PropTypes.oneOf(['note', 'danger', 'warning']),
  hideLabel: PropTypes.bool
};

export default Explainer;
