import React from 'react';
import PropTypes from 'prop-types';

import types from './Types/index';

const VariablePreview = ({ variable }) => {
  const { Preview } = types.all.find(type => type.key === variable.type.name);

  if (variable.variable_format === 'email') {
    return (
      <a
        rel="noreferrer"
        target="_blank"
        href={`mailto:${variable.default_value}`}>
        <Preview value={variable.default_value} />
      </a>
    );
  } else if (variable.variable_format === 'link') {
    let link = variable.default_value;

    if (!link.startsWith('http')) {
      link = 'https://' + link;
    }

    return (
      <a
        rel="noreferrer"
        target="_blank"
        href={link}
        data-testid="ticket-var-link">
        <Preview value={variable.default_value} />
      </a>
    );
  }
  return <Preview value={variable.default_value} />;
};

VariablePreview.propTypes = {
  variable: PropTypes.shape({
    type: PropTypes.shape({
      name: PropTypes.oneOf(types.all.map(type => type.key)).isRequired
    }).isRequired,
    variable_format: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    default_value: PropTypes.any
  }).isRequired
};
export default VariablePreview;
