import React from 'react';

import { Flex } from '@ubisend/pulse-components';

import { useIntegrations } from '../../hooks/index';
import EditEndpoint from './EditEndpoint';
import CreateEndpoint from './CreateEndpoint';

const Endpoints = () => {
  const { viewEndpoints: integration } = useIntegrations();

  if (!integration) {
    return null;
  }

  // todo: set this higher up
  const endpoints = integration.endpoints.map(endpoint => {
    endpoint.soapFunction = endpoint.soap_function;
    endpoint.isSoap = integration.is_soap;
    return endpoint;
  });

  return (
    <Flex col ySpace>
      {endpoints.map((endpoint, key) => (
        <EditEndpoint open={key === 0} key={key} {...endpoint} />
      ))}
      <CreateEndpoint />
    </Flex>
  );
};

export default Endpoints;
