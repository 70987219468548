import React from 'react';

import { Flex, EmptyStatePlaceholder } from '@ubisend/pulse-components';

const NoLiveChat = () => {
  return (
    <Flex middle center fat ySpace col>
      <EmptyStatePlaceholder
        type="livechat"
        customHeight="500px"
        heading="Choose a live chat ticket to get started"
        text="The ticketing system is where you can manage live chat tickets and the live chat agents that operate on your platform.   "
        helpLink="/docs/2151317744/2153382239"
        helpText="Learn more about the ticketing system."
      />
    </Flex>
  );
};

export default NoLiveChat;
