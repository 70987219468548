import React from 'react';
import tw, { styled } from 'twin.macro';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { Flex, Heading1, Heading2 } from '@ubisend/pulse-components';
import { motion } from '@ubisend/framer-motion';

import { useReadNotifications, useNotifications } from '../../../hooks/index';
import { ScreenContainer } from '../Components/index';

const Clock = props => (
  <svg
    {...props}
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

const StyledClock = styled(Clock)`
  ${tw`w-4 h-4 mr-2`}
`;

const Notification = styled(motion.a)`
  ${tw`md:px-8 md:py-6 px-4 py-3 md:mt-6 mt-4 block text-white no-underline`}
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 0.125);
  border: 2px solid rgba(255, 255, 255, 0.125);
  backdrop-filter: blur(8px);
`;

const Date = styled.span`
  ${tw`flex items-center font-semibold font-poppins text-xs uppercase tracking-wide whitespace-nowrap`}
`;

const Body = styled.p`
  ${tw`m-0 mt-2 md:mt-4 opacity-75`}
`;

Notification.defaultProps = {
  variants: {
    initial: { opacity: 0, y: 50 },
    show: {
      opacity: 1,
      y: 0,
      transition: { duration: 1, ease: [0.16, 1, 0.3, 1] }
    }
  }
};

const Notifications = () => {
  const { t } = useTranslation(['bots', 'full_page']);

  const { notifications } = useNotifications();

  useReadNotifications();

  return (
    <ScreenContainer data-testid="notifications">
      <Flex col>
        <Heading1 initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          {t('notifications_header', { ns: 'full_page' })}
        </Heading1>
        {notifications.length === 0 && (
          <Flex mtSm>
            <Heading2 initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              {t('no_notifications_message', { ns: 'bots' })}
            </Heading2>
          </Flex>
        )}
      </Flex>
      {notifications.map(notification => (
        <Notification
          key={notification.id}
          data-testid="notification"
          href={notification.link || '#'}>
          <Flex>
            <StyledClock />
            <Date>{dayjs().to(dayjs(notification.created_at))}</Date>
          </Flex>
          <Body>{notification.body}</Body>
        </Notification>
      ))}
    </ScreenContainer>
  );
};

Notifications.Notification = Notification;
Notifications.Clock = StyledClock;
Notifications.Body = Body;
Notifications.Date = Date;

export default Notifications;
