import AddStepNode from '../MessageStep/AddStepNode';
import StepNode from '../MessageStep/StepNode';

const blocks = ['action', 'email', 'integration', 'trigger', 'validation'];

const automatedStep = {
  id: 'automatedStep',
  name: 'Automated',
  icon: 'robo',
  colour: 'grey',
  permission: 'conversations',
  Node: StepNode,
  AddNode: AddStepNode,
  blocks,
  canDrop: block => {
    return blocks.includes(block.id);
  }
};

export default automatedStep;
