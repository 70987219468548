import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import {
  PageWrapper,
  StretchPanel as Panel,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableActions,
  Button,
  EmptyStatePlaceholder,
  useModal,
  useNotification,
  Flex,
  useOrderableTableReducer,
  OrderableTableRow,
  usePaginationReducer,
  Pagination,
  Span
} from '@ubisend/pulse-components';
import { useQueryClient, useMutation, useQuery } from '@ubisend/pulse-hooks';
import { PermissionFilter } from '@ubisend/pulse-auth';
import { AnimatePresence } from '@ubisend/framer-motion';

import { deleteFaqTest as deleteTest, invokeFaqTest } from '../api/index';
import AddFaqTest from '../Components/FaqRegressionTest/AddFaqTest';
import ResultWindow from '../Components/FaqRegressionTest/ResultWindow';
import StateLevel from '../Components/FaqRegressionTest/StateLevel';

const FaqRegressionTesting = () => {
  const [showAddTest, setShowAddTest] = useState(false);
  const [chosenTestId, setChosenTestId] = useState();
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const order = useOrderableTableReducer({ id: 'faq-tests' });
  const pagination = usePaginationReducer({ id: 'faq-tests' });

  const { showModal, hideModal } = useModal();
  const { showNotification } = useNotification();

  const queryClient = useQueryClient();

  const query = useQuery(
    ['faqs/regression-testing', { ...pagination.params, ...order.params }],
    {
      refetchInterval: shouldRefetch ? 5000 : false
    }
  );

  useEffect(() => {
    const pendingTests = query.data?.data.filter(
      test => test.state === 'Pending'
    );
    if (pendingTests?.length) {
      setShouldRefetch(true);
    } else {
      setShouldRefetch(false);
    }
  }, [query]);

  const deleteFaqTest = useMutation(deleteTest, {
    onSuccess: () => {
      showNotification({ message: `FAQ Test was successfully deleted.` });
      queryClient.invalidateQueries(['faqs/regression-testing']);
    }
  });

  const runFaqTest = useMutation(invokeFaqTest, {
    onSuccess: () => {
      showNotification({ message: `Successfully running test case.` });
      queryClient.invalidateQueries(['faqs/regression-testing']);
    }
  });

  const handleFaqTestDelete = (id, name) => {
    showModal({
      header: 'Delete FAQ Test',
      body: `Are you sure you want to delete "${name}"?`,
      handleConfirm: () => {
        deleteFaqTest.mutate(id);
        hideModal();
      }
    });
  };

  const handleRunFaqTest = id => {
    runFaqTest.mutate(id);
  };

  return (
    <>
      {showAddTest && (
        <AddFaqTest handleCancel={() => setShowAddTest(!showAddTest)} />
      )}
      <PageWrapper
        header="FAQ testing"
        subheader="Bulk test FAQs"
        actions={
          <Flex xSpace>
            {query.isSuccess && !query.showNoResultsMessage && (
              <PermissionFilter can="create faqs">
                <Button
                  variant="primary"
                  data-testid="create-faq-test"
                  onClick={() => {
                    setShowAddTest(true);
                  }}>
                  + New Test
                </Button>
              </PermissionFilter>
            )}
          </Flex>
        }>
        <Panel mt>
          {query.showNoResultsMessage && (
            <EmptyStatePlaceholder
              type="faqs"
              heading="Create an FAQ test"
              actions={
                <PermissionFilter can="edit faq settings">
                  <Button
                    variant="primary"
                    onClick={() => {
                      setShowAddTest(true);
                    }}>
                    + New Test
                  </Button>
                </PermissionFilter>
              }
            />
          )}
          {query.showTable && (
            <Table loading={query.isLoading} loadingColumns={5}>
              <TableHead>
                <OrderableTableRow
                  rows={[
                    { label: 'Test Name', sort: 'name' },
                    { label: 'Status', sort: 'state' },
                    { label: 'Last Tested', sort: 'last_run_at' },
                    null
                  ]}
                  {...order.props}
                />
              </TableHead>
              {query.isSuccess && (
                <TableBody>
                  {query.data.data.map((faqTest, key) => (
                    <TableRow key={key}>
                      <TableCell>
                        <Flex center>
                          <span>{faqTest.name}</span>
                        </Flex>
                      </TableCell>
                      <TableCell>
                        <Flex center>
                          {faqTest.state !== 'Created' && (
                            <StateLevel level={faqTest.state} />
                          )}
                          <span>{faqTest.state}</span>
                        </Flex>
                      </TableCell>
                      <TableCell>
                        <Flex col>
                          {faqTest.last_run_at ? (
                            <>
                              <Span>
                                {dayjs(faqTest.last_run_at).format('HH:mm')}
                              </Span>
                              <Span light>
                                {dayjs(faqTest.last_run_at).format(
                                  'DD/MM/YYYY'
                                )}
                              </Span>
                            </>
                          ) : (
                            'N/A'
                          )}
                        </Flex>
                      </TableCell>
                      <TableActions>
                        <Button
                          variant="secondary"
                          loading={faqTest.state === 'Pending'}
                          data-testid="run-faq-test"
                          onClick={() => handleRunFaqTest(faqTest.id)}>
                          {faqTest.state === 'Pending' ? 'Running' : 'Run'}
                        </Button>
                        <Button
                          variant="secondary"
                          data-testid="view-faq-test"
                          onClick={() => {
                            setChosenTestId(faqTest.id);
                          }}>
                          View Results
                        </Button>
                        <Button
                          variant="secondary"
                          colour="danger"
                          icon="trash"
                          data-testid="delete-faq-test"
                          onClick={() =>
                            handleFaqTestDelete(faqTest.id, faqTest.name)
                          }>
                          Delete
                        </Button>
                      </TableActions>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          )}
          {query.showPagination && (
            <Pagination pagination={query.data.meta} {...pagination.props} />
          )}
        </Panel>
        <AnimatePresence>
          {chosenTestId && (
            <ResultWindow
              chosenTestId={chosenTestId}
              handleHide={() => {
                setChosenTestId();
              }}
            />
          )}
        </AnimatePresence>
      </PageWrapper>
    </>
  );
};

export default FaqRegressionTesting;
