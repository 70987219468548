import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Flex, Button, Tooltip, Checkbox } from '@ubisend/pulse-components';
import { FileSelect } from '@ubisend/pulse-files';
import { TemplatingTextInput } from '@ubisend/pulse-templating';

import { useButtons } from '../../../../hooks/index';
import ExpiresTooltip from '../../../ExpiresTooltip';

const FileInput = ({ content, index }) => {
  const [showFiles, setShowFiles] = useState(false);

  const { handleButtonChange } = useButtons();

  const handleFileSelect = ({ link }) => {
    handleButtonChange(index, 'action', link);
    setShowFiles(false);
  };

  const handleLabelChange = value => {
    handleButtonChange(index, 'label', value);
  };

  const handleActionChange = value => {
    handleButtonChange(index, 'action', value);
  };

  return (
    <>
      <Flex col ySpace fat>
        <TemplatingTextInput
          aria-label={`file-label-${index + 1}`}
          value={content.label}
          placeholder="Download PDF"
          onChange={handleLabelChange}
        />
        <Flex xSpace>
          <Flex col fat>
            <TemplatingTextInput
              aria-label={`file-action-${index + 1}`}
              value={content.action}
              placeholder="http://website.com/document.pdf"
              onChange={handleActionChange}
            />
          </Flex>
          <Button
            variant="secondary"
            onClick={() => setShowFiles(true)}
            icon="document">
            Files
          </Button>
        </Flex>
        <Flex center>
          <TemplatingTextInput
            aria-label={`file-linkback-${index + 1}`}
            value={content.linkback}
            placeholder="The link was clicked"
            onChange={value => {
              handleButtonChange(index, 'linkback', value);
            }}
          />
          <Tooltip
            position={Tooltip.POSITIONS.TOP}
            tooltip={
              <Tooltip.Content>
                This optional postback text can be used to trigger a new step
                when the user downloads the file attached.
              </Tooltip.Content>
            }>
            <Tooltip.Icon>?</Tooltip.Icon>
          </Tooltip>
        </Flex>
        <Flex>
          <Checkbox
            aria-label={`file-expires-${index + 1}`}
            checked={content.linkback ? content.expires : false}
            disabled={!content.linkback}
            onChange={() => {
              handleButtonChange(index, 'expires', !content.expires);
            }}
            label="Disable on reply"
          />
          <ExpiresTooltip />
        </Flex>
      </Flex>
      {showFiles && (
        <FileSelect
          handleCancel={() => setShowFiles(false)}
          handleFileSelect={handleFileSelect}
        />
      )}
    </>
  );
};

FileInput.propTypes = {
  content: PropTypes.shape({
    label: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    linkback: PropTypes.string,
    expires: PropTypes.bool
  }),
  index: PropTypes.number.isRequired
};

export default FileInput;
