import tw, { styled } from 'twin.macro';

const RightDownConnector = styled.div`
  ${tw`h-4 w-3`}
  border-top-right-radius: 2rem;
  border-top: 2px solid #888888;
  border-right: 2px solid #888888;
  margin-top: 16px;
`;

export default RightDownConnector;
