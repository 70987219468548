import React from 'react';
import tw, { styled } from 'twin.macro';
import { Link } from 'react-router-dom';

import { Flex, Tooltip, Panel } from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';

const StyledLink = styled(Link)`
  ${tw`flex bg-transparent border-0 p-0 outline-none flex items-center justify-center cursor-pointer`}
  color: black;
  &:hover {
    opacity: 0.75;
  }
  &:active {
    opacity: 0.5;
  }
`;

const Container = styled(Panel)`
  ${tw`absolute p-1 flex items-center z-20`}
  box-sizing: border-box;
  top: 1rem;
  left: 1rem;
  width: 34px;
`;

const ReturnButton = () => {
  return (
    <Container>
      <Flex fat between center xSpace>
        <Flex center xSpaceSm>
          <Tooltip
            position={Tooltip.POSITIONS.RIGHT}
            tooltip={<Tooltip.Content>Back to workflows</Tooltip.Content>}>
            <StyledLink to="/builder" aria-label="Back to workflows">
              <Icon
                size="1.5rem"
                width="1.5rem"
                height="1.5rem"
                type="cheveronLeft"
              />
            </StyledLink>
          </Tooltip>
        </Flex>
      </Flex>
    </Container>
  );
};

export default ReturnButton;
