import roundRect from '../roundRect';
import textBox from '../textBox';
import {
  MESSAGE_PREVIEW_PADDING,
  NODE_WIDTH,
  BUBBLE_BORDER_RADIUS,
  BUBBLE_PADDING_Y,
  PARAGRAPH_LINE_HEIGHT,
  BUBBLE_PADDING_X,
  PARAGRAPH_FONT_SIZE,
  BUBBLE_INNER_WIDTH
} from '../constants';
import { getLines } from '../util/index';

/**
 * Function to draw a standard message based on on a node object
 *
 * @param   {CanvasRenderingContext2D}  ctx current canvas rendering context
 * @param   {number}  x     x position of node
 * @param   {number}  y     y position of node
 * @param   {string}  fill  hex, rgba, or string preset colour value to fill the background
 * @param   {object}  response  response object
 *
 * @return  {number}        height of resulting drawn object
 */
const standard = (ctx, x, y, fill, response) => {
  const text = response.content.text;
  let textLines = [];
  if (text) {
    textLines = getLines(ctx, text, BUBBLE_INNER_WIDTH);
  }

  let containerHeight = textLines.length * PARAGRAPH_LINE_HEIGHT;

  // only add padding if we need to show text, otherwise we want the box to be flush with the image
  if (text) {
    containerHeight += BUBBLE_PADDING_Y * 2;
  }

  roundRect(
    ctx,
    x + MESSAGE_PREVIEW_PADDING,
    y + MESSAGE_PREVIEW_PADDING,
    NODE_WIDTH - MESSAGE_PREVIEW_PADDING * 2,
    containerHeight,
    'transparent',
    fill,
    BUBBLE_BORDER_RADIUS
  );
  ctx.save();
  ctx.clip();

  textBox(
    ctx,
    x + MESSAGE_PREVIEW_PADDING + BUBBLE_PADDING_X,
    y + MESSAGE_PREVIEW_PADDING + PARAGRAPH_FONT_SIZE + BUBBLE_PADDING_Y,
    textLines
  );
  ctx.restore();
  return containerHeight;
};

export default standard;
