import { useTheme } from '../../../hooks/index';

const CustomCss = () => {
  const { fontSrc } = useTheme();

  if (fontSrc) {
    const googleFonts = document.querySelector(
      '[title="converse-google-fonts"]'
    );
    if (googleFonts) {
      if (googleFonts.href === fontSrc) {
        return null;
      }

      googleFonts.href = fontSrc;
    } else {
      const googleFonts = document.createElement('link');
      googleFonts.title = 'converse-google-fonts';
      googleFonts.href = fontSrc;
      googleFonts.rel = 'stylesheet';
      document.getElementsByTagName('head')[0].appendChild(googleFonts);
    }
  }

  return null;
};

export default CustomCss;
