import axios from 'axios';

const getConverseSettings = () => {
  return axios.get('converse/settings');
};

const createConverseSetting = tabName => {
  return axios.post('converse/settings', tabName);
};

const updateConverseSettings = settings => {
  return axios.put(`converse/settings/${settings.id}`, settings);
};

const deleteConverseSetting = id => {
  return axios.delete(`converse/settings/${id}`);
};

const updateConverseLogo = logo => {
  return axios.put('converse/settings/images', {
    logo_id: logo.id,
    source_id: logo.source.id,
    bot_setting_id: logo.botSettingId
  });
};

export {
  getConverseSettings,
  updateConverseSettings,
  deleteConverseSetting,
  createConverseSetting,
  updateConverseLogo
};
