import { styled } from 'twin.macro';

import { Flex } from '@ubisend/pulse-components';

const MainSection = styled(Flex)`
  width: calc(100% - 30rem);
  @media (max-width: 992px) {
    width: 100%;
  }
`;

export default MainSection;
