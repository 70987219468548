import React from 'react';

import {
  Button,
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Flex,
  Label,
  Divider
} from '@ubisend/pulse-components';

import VariableSubjectSelect from './VariableSubjectSelect';
import VariableSubject from './VariableSubject';
import VariableTypeSelect from './VariableTypeSelect';
import VariableScopeSelect from './VariableScopeSelect';
import VariableDefaultValueInput from './VariableDefaultValueInput';
import { useVariable } from '../../hooks/index';

const VariableSettingsModal = () => {
  const {
    dispatch,
    variable,
    TYPES,
    canEditScope,
    canEditType
  } = useVariable();

  const handleSubjectChange = ({ value }) => {
    dispatch({ type: TYPES.CHANGE_SUBJECT_TYPE, newSubjectType: value });
  };

  const handleScopeChange = ({ value }) => {
    dispatch({ type: TYPES.CHANGE_SCOPE, newScope: value });
  };

  const handleTypeChange = option => {
    dispatch({
      type: TYPES.UPDATE_VARIABLE_TYPE,
      variableType: option.value
    });
  };

  const handleReset = () => {
    dispatch({ type: TYPES.RESET_SETTINGS });
  };

  const handleClose = () => {
    dispatch({ type: TYPES.CLOSE_SETTINGS });
  };

  const handleSave = () => {
    dispatch({ type: TYPES.SAVE_SETTINGS });
  };

  return (
    <ModalPortal>
      <ModalContainer>
        <ModalContent>
          <ModalHeader>Advanced Settings</ModalHeader>
          <Flex col>
            <Flex pl pt pr col ySpace>
              <Flex col>
                <Label htmlFor="variable_type">Variable type</Label>
                <VariableTypeSelect
                  id="variable_type"
                  value={variable.type}
                  placeholder="Type"
                  onChange={handleTypeChange}
                  isDisabled={!canEditType}
                />
              </Flex>
              <VariableDefaultValueInput />
            </Flex>
            <Divider />
            <Flex col pl pr>
              <Label htmlFor="scopes">Variable scope</Label>
              <VariableScopeSelect
                id="scopes"
                value={variable.scope}
                placeholder="Scope"
                onChange={handleScopeChange}
                isDisabled={!canEditScope}
              />
            </Flex>
            <Divider />
            <Flex col pl pr>
              <Label htmlFor="subjects">Data Type</Label>
              <VariableSubjectSelect
                id="subjects"
                value={variable.subject ? variable.subject.type : null}
                onChange={handleSubjectChange}
              />
            </Flex>
            <VariableSubject />
          </Flex>
          <ModalFooter>
            <Flex xSpace>
              <Button
                variant="secondary"
                icon="trash"
                colour="danger"
                onClick={handleReset}>
                Reset
              </Button>
              <Button variant="secondary" icon="x" onClick={handleClose}>
                Close
              </Button>
              <Button onClick={handleSave} variant="primary">
                Save
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalContainer>
    </ModalPortal>
  );
};

export default VariableSettingsModal;
