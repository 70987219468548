import React, { useMemo } from 'react';

import { useImports } from '../hooks/index';
import { drivers } from '../Components/Drivers/index';
import { SectionActions } from '../Components/index';

const Content = () => {
  const { item, section, imports } = useImports();

  const Component = useMemo(() => drivers[item.type].content.Editor, [
    item.type
  ]);

  return (
    <>
      <SectionActions />
      <Component imports={imports} {...section} />
    </>
  );
};

export default Content;
