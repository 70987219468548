import React from 'react';
import { Link } from 'react-router-dom';

import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import {
  PageWrapper,
  Flex,
  Button,
  StretchPanel,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableActions,
  useNotification,
  Pagination,
  Tag,
  OrderableTableRow,
  FilterMenu,
  EmptyStatePlaceholder
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

import {
  TicketStatus,
  SubscriberLink,
  TicketCreatedAt,
  TicketGroupTableCell,
  TicketNameTableCell,
  TicketFilters
} from '../Components/index';
import {
  restoreTicket as restoreTicketApi,
  deleteTicket as deleteTicketApi
} from '../api/index';
import { useTicketFilters } from '../hooks/index';

const ArchivedTickets = () => {
  const filters = useTicketFilters({
    id: 'archived-tickets',
    initialFilters: {
      ticket_types: [],
      statuses: [],
      assignee_ids: []
    }
  });

  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const query = useQuery(['tickets/ubidesk/archived', filters.form]);
  const { mutate: restoreTicket } = useMutation(restoreTicketApi, {
    onSuccess: () => {
      showSuccess('Successfully restored ticket');
      queryClient.invalidateQueries('tickets/ubidesk/archived');
      queryClient.invalidateQueries('tickets/ubidesk');
    }
  });

  const { mutate: deleteTicket } = useMutation(deleteTicketApi, {
    onSuccess: () => {
      showSuccess('Successfully deleted ticket');
      queryClient.invalidateQueries('tickets/ubidesk/archived');
    }
  });

  const handleRestore = ticket => () => {
    restoreTicket(ticket.id);
  };

  const handleDelete = ticket => () => {
    deleteTicket(ticket.id);
  };

  return (
    <PageWrapper
      header="Archived Tickets"
      subheader="Manage your old tickets that have been archived"
      actions={
        <Flex xSpace>
          <FilterMenu
            position={FilterMenu.POSITIONS.LEFT}
            buttonProps={{ loading: query.isLoading }}
            {...filters.filterMenuProps}>
            <TicketFilters {...filters.filterProps} />
          </FilterMenu>
          <Button as={Link} variant="secondary" to={`/tickets`} icon="ticket">
            Tickets
          </Button>
        </Flex>
      }>
      {query.showNoResultsMessage && (
        <Flex fat center>
          <EmptyStatePlaceholder
            type="tickets"
            heading="Nothing to show yet"
            text="When your agents start archiving tickets, they will appear here."
            helpLink="/docs/2151317744/2153382239"
            helpText="Learn more about the ticketing system."
          />
        </Flex>
      )}
      {query.showTable && (
        <StretchPanel>
          <Table loading={query.isLoading} loadingColumns={6}>
            <TableHead>
              <OrderableTableRow
                rows={[
                  { label: 'Summary', sort: 'name' },
                  { label: 'Tags', sort: null },
                  { label: 'Status', sort: 'status' },
                  { label: 'Subscriber', sort: null },
                  { label: 'Date', sort: 'created_at' },
                  null
                ]}
                {...filters.order.props}
              />
            </TableHead>
            {query.isSuccess && (
              <TableBody>
                {query.data.data.map((ticket, key) => (
                  <TableRow key={key}>
                    <TicketNameTableCell>{ticket.name}</TicketNameTableCell>
                    <TicketGroupTableCell>
                      <Flex xSpaceSm xScroll>
                        {ticket.groups.length > 0
                          ? ticket.groups.map(group => (
                              <Tag key={group.id}>{group.name}</Tag>
                            ))
                          : 'N/A'}
                      </Flex>
                    </TicketGroupTableCell>
                    <TableCell>
                      <TicketStatus>{ticket.status}</TicketStatus>
                    </TableCell>
                    <TableCell>
                      <SubscriberLink subscriber={ticket.subscriber} />
                    </TableCell>
                    <TableCell>
                      <TicketCreatedAt date={ticket.created_at} />
                    </TableCell>
                    <TableActions>
                      <PermissionFilter can="edit tickets">
                        <Button
                          variant="secondary"
                          icon="lockOpen"
                          onClick={handleRestore(ticket)}>
                          Restore
                        </Button>
                      </PermissionFilter>
                      <PermissionFilter can="delete tickets">
                        <Button
                          variant="secondary"
                          colour="danger"
                          icon="trash"
                          onClick={handleDelete(ticket)}>
                          Delete
                        </Button>
                      </PermissionFilter>
                    </TableActions>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
          {query.showPagination && (
            <Pagination
              pagination={query.data.meta}
              {...filters.pagination.props}
            />
          )}
        </StretchPanel>
      )}
    </PageWrapper>
  );
};

export default ArchivedTickets;
