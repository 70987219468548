import useQuery from './useQuery';

const formatSettings = settings => {
  const bot = {
    name: settings.name,
    showBanner: settings.banner_enabled,
    title: settings.banner_title,
    subtitle: settings.banner_subtitle,
    showBurgerIcon: settings.has_buttons,
    buttons: settings.buttons,
    showName: settings.name_enabled,
    showLogo: settings.logo_enabled,
    showDateTime: settings.timestamp_enabled,
    logoLink: settings.logo_link,
    logo: settings.logo,
    showlauncher: settings.launcher_logo_enabled,
    launcherLogoUrl: settings.launcher_logo_url,
    showAvatars: settings.avatars_enabled,
    humanAvatarUrl: settings.human_avatar_url,
    botAvatarUrl: settings.bot_avatar_url,
    resetOnClose: settings.reset_on_close,
    initialShowCta: settings.call_to_action_enabled,
    ctaText: settings.call_to_action_text,
    showPoweredByUbisend: settings.styles?.showPoweredByUbisend,
    footerImageUrl: settings.styles?.footerImageUrl,
    footerUrl: settings.styles?.footerUrl,
    showReset: settings.reset_enabled,
    showChangeLanguage: settings.change_subscriber_language_enabled,
    showExportTranscript: settings.export_transcript_enabled,
    showExportSubscriber: settings.export_subscriber_enabled,
    showDeleteSubscriber: settings.delete_subscriber_enabled,
    hasActiveSettings:
      settings.reset_enabled ||
      settings.change_subscriber_language_enabled ||
      settings.export_transcript_enabled ||
      settings.export_subscriber_enabled ||
      settings.delete_subscriber_enabled
        ? true
        : false
  };

  return {
    bot,
    styles: {
      ...settings.styles,
      launcherEnabled: settings.launcher_logo_enabled,
      launcherLogo: settings.launcher_logo_url
    }
  };
};

const useSettings = (query, options = {}) => {
  const { data, ...rest } = useQuery(query, options);

  return {
    ...(rest.isSuccess && formatSettings(data.data)),
    ...rest
  };
};

export { formatSettings };
export default useSettings;
