import { hasPermission } from '@ubisend/pulse-auth';

import { Blocklist } from './Pages/index';

const routes = [
  {
    name: 'Blocklist',
    path: '/blocklist',
    component: Blocklist,
    canAccess: hasPermission('view block list'),
    actions: [
      {
        title: 'Manage your block list',
        description: "Manage your chatbot's handling of profanity.",
        icon: 'xCircle'
      }
    ]
  }
];

export default routes;
