import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';

import { motion } from '@ubisend/framer-motion';
import Icon from '@ubisend/pulse-icons/src';

import useTheme from '../../hooks/useTheme';
import { Span } from '../Text/index';

const Container = styled(motion.div)`
  ${tw`inline-flex items-center rounded-full cursor-pointer relative shadow-inner flex-shrink-0`}
  width: 3.25em;
  height: 1.75rem;
  background: ${props => props.theme.grey};
  font-size: 1rem;
`;

const StyledIcon = styled(Span)`
  ${tw`absolute`}
  height: 1rem;
  top: 4px;
  &:last-of-type {
    right: 0px;
  }
`;

const Dot = styled(motion.div)`
  ${tw`border-gray-500 bg-white rounded-full absolute shadow`}
  height: 1.25rem;
  width: 1.25rem;
  margin: 0.25rem;
`;

const MiniToggle = ({
  handleToggleChange = undefined,
  primary = false,
  on,
  title,
  disabled,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Container
      animate={{
        opacity: disabled ? 0.5 : 1,
        cursor: disabled ? 'not-allowed' : 'pointer',
        backgroundColor: primary
          ? on
            ? theme.primary
            : theme.grey
          : on
          ? theme.primary
          : theme.grey
      }}
      whileHover={!disabled && { opacity: 0.8 }}
      {...props}
      onClick={!disabled ? handleToggleChange : undefined}>
      <StyledIcon aria-hidden="true">
        <Icon type="check" height="1.25rem" colour="#fff" />
      </StyledIcon>
      <StyledIcon aria-hidden="true">
        <Icon type="x" height="1.25rem" colour="#fff" />
      </StyledIcon>
      <Dot
        // layout // This looks weird currently due to a flickering scroll bar
        aria-label={`${title}-toggle-${on ? 'off' : 'on'}`}
        style={on ? { right: 0 } : { left: 0 }}
      />
    </Container>
  );
};

MiniToggle.propTypes = {
  handleToggleChange: PropTypes.func,
  on: PropTypes.bool,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  primary: PropTypes.bool
};

export default MiniToggle;
