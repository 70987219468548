import React from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import { useModal } from '@ubisend/pulse-components';

import { useBotSettings } from '../../hooks';
import TabAdminSettings from './TabAdminSettings';
import { deleteConverseSetting } from '../../api';

const TabAdminDropdown = ({
  setShowUpdateWidget,
  showUpdateWidget,
  setShowDuplicateWidget,
  showDuplicateWidget
}) => {
  const {
    setDefaultSetting,
    currentSetting,
    fetchBotSettings,
    isPrimary,
    setLoadingTabs
  } = useBotSettings();
  const { showModal, hideModal } = useModal();

  const mutationDelete = useMutation(deleteConverseSetting, {
    onSuccess: () => {
      setDefaultSetting();
      fetchBotSettings();
    }
  });

  const handleDelete = () => {
    showModal({
      header: 'Delete Widget',
      body: `Are you sure you want to delete widget?`,
      handleConfirm: () => {
        try {
          mutationDelete.mutate(currentSetting);
          setLoadingTabs(true);
        } finally {
          hideModal();
        }
      }
    });
  };

  const dropDownOptions = () => {
    const options = [
      {
        label: 'Rename widget',
        onClick: () => setShowUpdateWidget(!showUpdateWidget)
      },
      {
        label: 'Duplicate widget',
        onClick: () => setShowDuplicateWidget(!showDuplicateWidget)
      },
      {
        label: 'Delete widget',
        onClick: handleDelete
      }
    ];

    if (isPrimary) {
      return options.filter(option => option.label === 'Duplicate widget');
    }
    return options;
  };

  return (
    <TabAdminSettings
      width="10rem"
      label="Settings"
      buttons={dropDownOptions()}
    />
  );
};

TabAdminDropdown.propTypes = {
  setShowUpdateWidget: PropTypes.func.isRequired,
  showUpdateWidget: PropTypes.bool,
  setShowDuplicateWidget: PropTypes.func.isRequired,
  showDuplicateWidget: PropTypes.bool
};

export default TabAdminDropdown;
