import EndCurrentSessionContent from './endCurrentSessionsContent';

const endCurrentSession = {
  name: 'End current session',
  Content: EndCurrentSessionContent,
  setInitialContent: () => {
    return {};
  },
  valid: () => {
    return true;
  }
};

export default endCurrentSession;
