import tw, { styled } from 'twin.macro';

const Name = styled.h1`
  ${tw`m-0 font-bold font-poppins flex items-center m-0`}
  color: ${props => props.theme.bot.textColour};
  ${props => `font-family: '${props.theme.bot.headerFont}', sans-serif;`}
  ${props => props.size}
`;

export default Name;
