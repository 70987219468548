import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';

import {
  ConverseComposer,
  DeliveryContext,
  ComposerContext,
  BotContext
} from '@ubisend/pulse-volt';

import { useComposer } from '../hooks/index';

const Container = styled.div`
  ${tw`w-full bg-gray-300 p-4`}
  & > div {
    ${tw`w-full`}
  }
`;

const Composer = () => {
  const composer = useComposer();

  const [reply, setReply] = useState('');

  const sendMessage = () => setReply('');

  return (
    <ComposerContext.Provider value={{ reply, setReply }}>
      <DeliveryContext.Provider value={{ sendMessage }}>
        <BotContext.Provider
          value={{
            messages: [{ composer }]
          }}>
          <Container>
            <ConverseComposer />
          </Container>
        </BotContext.Provider>
      </DeliveryContext.Provider>
    </ComposerContext.Provider>
  );
};

export default Composer;
