import React from 'react';
import tw from 'twin.macro';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Markdown } from '@ubisend/pulse-markdown';
import { Flex, Placeholder } from '@ubisend/pulse-components';

import {
  Header,
  CloseWidgetButton,
  BackArrowButton,
  Name
} from '../Components/Header/index';
import { useBot, useQuery } from '../../../hooks/index';
import IframeModalButton from '../Components/IframeModalButton';

const Section = ({ match }) => {
  const { showClose } = useBot();
  const { t } = useTranslation(['bots', 'converse']);
  const history = useHistory();

  const { id } = match.params;

  const { isLoading, data } = useQuery(`sections/${id}`);

  const closeWidget = () => {
    history.push('/');
  };

  const handleBack = () => history.goBack();

  const generateTitle = () => {
    if (isLoading) {
      return 'Loading...';
    }

    if (!data?.data?.title) {
      return 'Something went wrong';
    }

    return data.data.title;
  };

  const generateContent = () => {
    if (isLoading) {
      return 'Loading...';
    }

    if (!data?.data?.content) {
      return "We couldn't load the requested section. Please try again later";
    }

    return data.data.content;
  };

  return (
    <>
      <Header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        <BackArrowButton
          aria-label={t('back_arrow_button_label', { ns: 'bots' })}
          onClick={handleBack}
        />
        <Name size={tw`text-xl`}>{generateTitle()}</Name>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          {showClose && (
            <CloseWidgetButton
              aria-label={t('close_widget_button_label', {
                ns: 'converse'
              })}
              onClick={closeWidget}
            />
          )}
        </div>
      </Header>
      <div style={{ flexGrow: '1', padding: '1rem', overflowY: 'auto' }}>
        <Flex right>
          <IframeModalButton
            data-testid="section_full_screen"
            content={{
              title: generateTitle(),
              body: generateContent()
            }}
          />
        </Flex>
        {!isLoading && <Markdown>{generateContent()}</Markdown>}
        {isLoading && <Placeholder />}
      </div>
    </>
  );
};

export default Section;
