import React from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  ModalClose,
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalBody,
  useNotification
} from '@ubisend/pulse-components';
import { useMutation } from '@ubisend/pulse-hooks';

import { createConverseSetting } from '../../../api/index';
import WidgetDetails from './WidgetDetails';
import { useBotSettings } from '../../../hooks';

const CreateWidget = ({ handleClose }) => {
  const { showSuccess } = useNotification();
  const { fetchBotSettings } = useBotSettings();
  const mutation = useMutation(createConverseSetting, {
    onSuccess: () => {
      showSuccess('Successfully created a new widget.');
      handleClose();
      fetchBotSettings();
    }
  });

  const handleSubmit = form => {
    mutation.mutate({
      dashboard_name: form.name,
      duplicate_id: form.duplicateId
    });
  };

  return (
    <ModalPortal>
      <ModalContainer>
        <ModalContent>
          <ModalHeader>
            Create a new widget
            <Flex between>
              <ModalClose onClick={handleClose} />
            </Flex>
          </ModalHeader>
          <ModalBody>
            <WidgetDetails
              duplicating
              handleSubmit={handleSubmit}
              loading={mutation.isLoading}
            />
          </ModalBody>
        </ModalContent>
      </ModalContainer>
    </ModalPortal>
  );
};

CreateWidget.propTypes = {
  handleClose: PropTypes.func.isRequired,
  refetchSettings: PropTypes.func.isRequired
};

export default CreateWidget;
