import React, { useState } from 'react';

import { useMutation, useQuery } from '@ubisend/pulse-hooks';
import {
  Panel,
  Flex,
  LeftHalfLayout,
  Explainer,
  TextArea,
  Button,
  useNotification,
  Placeholder
} from '@ubisend/pulse-components';

import { SectionActions } from '../Components/index';
import { useImports } from '../hooks/index';
import { updateMetadata } from '../api/index';

const Metadata = () => {
  const { item, section } = useImports();
  const { showSuccess } = useNotification();
  const [meta, setMeta] = useState();

  const { isSuccess } = useQuery(
    `imports/${item.id}/sections/${section.id}/metadata`,
    {
      onSuccess: data => setMeta(data?.data?.metadata)
    }
  );

  const handleMetaChange = e => setMeta(e.target.value);

  const { mutate } = useMutation(updateMetadata, {
    onSuccess: () => {
      showSuccess('Successfully update section metadata');
    }
  });

  const handleSubmit = async () =>
    await mutate({ importId: item.id, sectionId: section.id, meta });

  return (
    <>
      <SectionActions />
      <Flex>
        <LeftHalfLayout>
          <Panel header="Metadata">
            {!isSuccess && <Placeholder items={1} />}
            {isSuccess && (
              <>
                <Flex mb>
                  <Explainer type="info">
                    Providing metadata about a section helps your chatbot to
                    return more accurate search results. E.g. if the section is
                    predominantly images, describe what the images show.
                  </Explainer>
                </Flex>
                <TextArea
                  aria-label="meta"
                  id="meta"
                  placeholder={`Landscape image of a sunset`}
                  value={meta}
                  onChange={handleMetaChange}
                />
                <Flex right mt>
                  <Button onClick={handleSubmit} variant="primary">
                    Save
                  </Button>
                </Flex>
              </>
            )}
          </Panel>
        </LeftHalfLayout>
      </Flex>
    </>
  );
};

export default Metadata;
