import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  Heading2,
  Label,
  LeftHalfLayout,
  FormGroup,
  RightHalfLayout,
  Flex,
  Panel,
  TextInput,
  Button,
  Span,
  FormHeading,
  Divider
} from '@ubisend/pulse-components';
import { TopSection } from '@ubisend/pulse-tickets';
import { PermissionFilter } from '@ubisend/pulse-auth';

import JsonPreview from '../JsonPreview';
import { getName } from '../LogName';
import LogLevel from '../LogLevel';
import LogDescription from '../LogDescription';

const hasResponse = response => {
  return Boolean(response.code);
};

const EndpointContent = ({ created_at, description, content, level, type }) => (
  <Panel>
    <Flex col mb>
      <TopSection center>
        <LogLevel level={level} />
        <Heading2>{getName({ level, type })}</Heading2>
      </TopSection>
      <Label secondary>Logged {dayjs(created_at).from(dayjs())}</Label>
    </Flex>
    <Flex>
      {!content ? (
        <LogDescription description={description} />
      ) : (
        <>
          <LeftHalfLayout>
            <LogDescription description={description} />
          </LeftHalfLayout>
          <RightHalfLayout>
            <FormGroup>
              <Label>Integration</Label>
              <Flex xSpace>
                <TextInput value={content.integration.name} disabled />
                <PermissionFilter can="view integrations">
                  <Button
                    as={Link}
                    variant="secondary"
                    icon="eye"
                    to={{
                      pathname: `/integrations`,
                      state: { integrationId: content.integration.id }
                    }}>
                    View
                  </Button>
                </PermissionFilter>
              </Flex>
            </FormGroup>
            <Flex col mb={hasResponse(content.response)}>
              <Label>Endpoint</Label>
              <Flex xSpace>
                <TextInput
                  value={content.integration.endpoint.endpoint}
                  disabled
                />
                <Button
                  as={Link}
                  variant="secondary"
                  icon="eye"
                  to={{
                    pathname: `/integrations`,
                    state: {
                      integrationId: content.integration.id,
                      showEndpoints: true
                    }
                  }}>
                  View
                </Button>
              </Flex>
            </Flex>
            {hasResponse(content.response) ? (
              <>
                <Divider />
                <FormHeading>Response</FormHeading>
                <FormGroup>
                  <Label>Code</Label>
                  <TextInput value={content.response.code} disabled />
                </FormGroup>
                <div>
                  <Label>Body</Label>
                  {content.response.body ? (
                    <JsonPreview value={content.response.body} />
                  ) : (
                    <Span tinyText>No response body returned</Span>
                  )}
                </div>
              </>
            ) : (
              <Flex>
                <Span tinyText>No response returned</Span>
              </Flex>
            )}
          </RightHalfLayout>
        </>
      )}
    </Flex>
  </Panel>
);

EndpointContent.propTypes = {
  created_at: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  content: PropTypes.shape({
    integration: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      endpoint: PropTypes.shape({
        id: PropTypes.number.isRequired,
        endpoint: PropTypes.string.isRequired
      }).isRequired
    }),
    response: PropTypes.shape({
      code: PropTypes.number,
      body: PropTypes.object
    })
  }),
  level: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default EndpointContent;
