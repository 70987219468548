import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Span } from '@ubisend/pulse-components';
import { TemplatingTextInput } from '@ubisend/pulse-templating';

const RequestPermissionsOnAlexaContent = ({ content, onChange }) => {
  const handlePermissionChange = permissions => {
    onChange({ permissions });
  };

  return (
    <Flex xSpace fat center>
      <Span>using</Span>
      <Flex col fat>
        <TemplatingTextInput
          aria-label="Permissions"
          placeholder="alexa::profile:name:read,alexa::profile:mobile_number:read"
          value={content.permissions}
          onChange={handlePermissionChange}
        />
      </Flex>
    </Flex>
  );
};

RequestPermissionsOnAlexaContent.propTypes = {
  onChange: PropTypes.func.isRequired,
  content: PropTypes.shape({
    permissions: PropTypes.string.isRequired
  }).isRequired
};

export default RequestPermissionsOnAlexaContent;
