import React from 'react';
import tw, { styled } from 'twin.macro';

import { TextArea as BaseTextArea } from '@ubisend/pulse-components';

import { useTemplating } from '../../../hooks/index';

const StyledTextArea = styled(BaseTextArea)`
  ${tw`pr-10`}
`;

const TextArea = props => {
  const templating = useTemplating();

  const handleValueChange = event => {
    const { value, selectionStart } = event.target;

    templating.dispatch({
      type: templating.TYPES.UPDATE_VALUE,
      value,
      cursor: selectionStart
    });
  };

  const handleOnClick = event => {
    const { selectionStart } = event.target;

    templating.dispatch({
      type: templating.TYPES.UPDATE_CURSOR,
      cursor: selectionStart
    });
  };

  const handleOnKeyUp = event => {
    const selectionStart = event.target.selectionStart;

    templating.dispatch({
      type: templating.TYPES.UPDATE_CURSOR,
      cursor: selectionStart
    });
  };

  return (
    <StyledTextArea
      {...props}
      value={templating.value}
      ref={templating.input}
      onChange={handleValueChange}
      onClick={handleOnClick}
      onKeyUp={handleOnKeyUp}
    />
  );
};

export default TextArea;
