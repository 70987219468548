import React from 'react';
import tw, { styled } from 'twin.macro';

import { motion } from '@ubisend/framer-motion';

import { useBot } from '../../../../hooks/index';

const AvatarContainer = styled(motion.span)`
  ${tw`inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-300 self-end mr-2`};
  background: ${props => props.theme.bot.sentMessage.background};

  & span:first-child {
    ${tw`text-sm px-3 py-2 leading-normal`};
    color: ${props => props.theme.bot.sentMessage.text};
  }

  & svg {
    ${tw`w-8 h-8`}
  }

  & img {
    ${tw`w-8 h-8`}
  }
`;

const Avatar = ({ children, ...props }) => {
  const { showAvatars } = useBot();

  if (!showAvatars) {
    return null;
  }

  return (
    <AvatarContainer
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ ease: [0.16, 1, 0.3, 1], duration: 0.6, delay: 0.2 }}
      {...props}>
      {children}
    </AvatarContainer>
  );
};

export default Avatar;
