import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@ubisend/pulse-volt';

import ConnectionLine from '../ConnectionLine';
import { useNewLink, useCanvas } from '../../hooks/index';

const NodeConnector = () => {
  const { snapPoint } = useCanvas();
  const { grey } = useTheme();
  const path = useNewLink();

  return (
    <ConnectionLine
      style={{
        strokeDasharray: 20,
        strokeLinecap: 'round',
        opacity: snapPoint ? 1 : 0.5,
        strokeWidth: '3px',
        stroke: grey
      }}
      fill="none"
      d={path}
    />
  );
};

NodeConnector.propTypes = {
  snapPoint: PropTypes.object
};

export default NodeConnector;
