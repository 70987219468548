import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';

import {
  PageWrapper,
  Flex,
  ThreeQuartersLayout,
  OneThirdLayout,
  Panel
} from '@ubisend/pulse-components';
import { FeatureFilter, GlobalRoleFilter, useAuth } from '@ubisend/pulse-auth';
import { ConditionalTypeProvider } from '@ubisend/pulse-conditionals';
import { BackButton } from '@ubisend/pulse-channels';

import { BotSettingsProvider } from '../Providers/index';
import {
  ConverseGeneralSettings,
  WidgetPreview,
  SaveSettings,
  DeploymentCode,
  ConverseBannerSettings,
  CallToActionSettings,
  ComposerSettings,
  DisplaySettings,
  ConverseColourSettings,
  ConverseWidgetSizeSettings,
  ConverseFooterSettings,
  FontCode,
  AvatarSettings,
  SettingPageSettings
} from '../Components/index';
import { updateConverseLogo, updateConverseSettings } from '../api/index';
import SideMenu from '../Components/Converse/SideMenu';
import {
  UpdateWidget,
  DuplicateWidget,
  CreateWidget
} from './Widget/Components/index';
import AllTabs from '../Components/Converse/AllTabs';
import TabAdminDropdown from '../Components/Converse/TabAdminDropdown';

const StyledContainer = styled.div`
  ${tw` flex flex-auto flex-col-reverse xl:flex-row xl:justify-start`}
`;

const StyledPanel = styled(Panel)`
  border-top-left-radius: 0;
`;

const ConverseSettings = () => {
  const [showUpdateWidget, setShowUpdateWidget] = useState(false);
  const [showDuplicateWidget, setShowDuplicateWidget] = useState(false);
  const [showWidgetCreator, setShowWidgetCreator] = useState(false);
  const [settingSection, setSettingSection] = useState('main');

  const { client } = useAuth();

  const source = `${process.env.REACT_APP_DEPLOYMENT_ROOT_URL}?c=${process.env.REACT_APP_INSTALL_NAME}&i=${client.identifier}`;

  const deployment = {
    script: `<script src="${source}" id="chat-widget-1925"></script>`,
    info:
      'Paste this snippet in the <body> of your website.  Please note, this only needs to be done once.  All subsequent changes are saved and deployed automatically.',
    src: source
  };

  return (
    <PageWrapper
      header={'Converse widgets'}
      prevAction={<BackButton to="/channels" />}
      subheader={`Manage the appearance of your Converse widget(s)`}>
      <Flex col mt>
        <BotSettingsProvider
          type="converse"
          updateBotLogo={updateConverseLogo}
          updateBotSettings={updateConverseSettings}
          deploymentDetails={deployment}>
          <AllTabs
            showWidgetCreator={showWidgetCreator}
            setShowWidgetCreator={setShowWidgetCreator}
          />
          {showWidgetCreator && (
            <CreateWidget
              handleClose={() => setShowWidgetCreator(!showWidgetCreator)}
            />
          )}
          {showUpdateWidget && (
            <UpdateWidget
              handleClose={() => setShowUpdateWidget(!showUpdateWidget)}
            />
          )}
          {showDuplicateWidget && (
            <DuplicateWidget
              handleClose={() => setShowDuplicateWidget(!showDuplicateWidget)}
            />
          )}
          <ThreeQuartersLayout>
            <StyledPanel>
              <Flex>
                <SideMenu
                  settingSection={settingSection}
                  handleClick={setSettingSection}
                />
                <StyledContainer>
                  <Flex col gap growShrink>
                    {settingSection === 'main' && (
                      <>
                        <DeploymentCode hideTitle />
                        <ConverseGeneralSettings titlePosition="top" />
                      </>
                    )}

                    {settingSection === 'engagement' && (
                      <>
                        <ConverseBannerSettings titlePosition="top" />
                        <ConditionalTypeProvider>
                          <CallToActionSettings titlePosition="top" />
                        </ConditionalTypeProvider>
                        <ComposerSettings titlePosition="top" />
                        <ConditionalTypeProvider>
                          <DisplaySettings titlePosition="top" />
                        </ConditionalTypeProvider>
                      </>
                    )}

                    {settingSection === 'design' && (
                      <>
                        <ConverseColourSettings titlePosition="top" />
                        <ConverseWidgetSizeSettings titlePosition="top" />
                        <FeatureFilter feature="whitelabel footer">
                          <GlobalRoleFilter for="owner">
                            <ConverseFooterSettings
                              titlePosition="top"
                              data-testid="converse footer settings"
                            />
                          </GlobalRoleFilter>
                        </FeatureFilter>
                        <FontCode titlePosition="top" />
                        <AvatarSettings titlePosition="top" />
                      </>
                    )}

                    {settingSection === 'advanced' && (
                      <>
                        <SettingPageSettings titlePosition="top" />
                      </>
                    )}

                    <SaveSettings />
                  </Flex>
                  <Flex mlAuto pb>
                    <TabAdminDropdown
                      setShowUpdateWidget={setShowUpdateWidget}
                      showUpdateWidget={showUpdateWidget}
                      setShowDuplicateWidget={setShowDuplicateWidget}
                      showDuplicateWidget={showDuplicateWidget}
                      data-testid="tab-admin-dropdown"
                    />
                  </Flex>
                </StyledContainer>
              </Flex>
            </StyledPanel>
          </ThreeQuartersLayout>
          <OneThirdLayout>
            <WidgetPreview />
          </OneThirdLayout>
        </BotSettingsProvider>
      </Flex>
    </PageWrapper>
  );
};

export default ConverseSettings;
