import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Flex,
  Button,
  FormGroup,
  Label,
  TextInput,
  useNotification
} from '@ubisend/pulse-components';
import { useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import { SheetSelect } from '@ubisend/pulse-files';

import { createFaqTest } from '../../api/index';

const AddFaqTest = ({ handleCancel }) => {
  const queryClient = useQueryClient();
  const { showSuccess } = useNotification();

  const [step, setStep] = useState(1);
  const [name, setName] = useState('');

  const { mutate: addFaqTest } = useMutation(createFaqTest, {
    onSuccess: () => {
      queryClient.invalidateQueries('faqs/regression-testing');
      showSuccess(`Successfully added "${name}"`);
      handleCancel();
    }
  });

  const handleFaqTestNameChange = event => {
    setName(event.target.value);
  };

  const handleNextStep = () => setStep(step + 1);

  const handleSubmit = async ({ id }) => {
    await addFaqTest({
      name,
      fileId: id
    });

    handleCancel();
  };

  return (
    <>
      {step === 1 && (
        <ModalPortal>
          <ModalContainer>
            <ModalContent>
              <ModalHeader>Create an FAQ test</ModalHeader>
              <ModalBody>
                <Flex col>
                  <FormGroup>
                    <Label htmlFor="name">Name</Label>
                    <TextInput
                      id="name"
                      name="name"
                      value={name}
                      placeholder="My FAQ test"
                      aria-label="faq-test-name"
                      onChange={handleFaqTestNameChange}
                    />
                  </FormGroup>
                </Flex>
              </ModalBody>
              <ModalFooter>
                <Flex xSpace>
                  <Button variant="secondary" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    onClick={handleNextStep}
                    disabled={Boolean(!name)}
                    variant="primary">
                    Next
                  </Button>
                </Flex>
              </ModalFooter>
            </ModalContent>
          </ModalContainer>
        </ModalPortal>
      )}
      {step === 2 && (
        <SheetSelect
          handleCancel={handleCancel}
          handleFileSelect={handleSubmit}
        />
      )}
    </>
  );
};

AddFaqTest.propTypes = {
  handleCancel: PropTypes.func.isRequired
};

export default AddFaqTest;
