import React from 'react';
import { Link, Redirect } from 'react-router-dom';

import { Button, useNotification, useModal } from '@ubisend/pulse-components';

import { useImports } from '../hooks/index';
import { approveSectionContent, deleteSectionContent } from '../api/index';
import { ContentDiff, ActionsPortal } from '../Components/index';

const Version = () => {
  const { item, section, version, dispatch } = useImports();
  const { showModal, hideModal } = useModal();
  const { showSuccess } = useNotification();

  const handleApprove = async () => {
    await approveSectionContent(item.id, section.id, version.id);

    dispatch({
      type: 'APPROVE_SECTION_CONTENT',
      import: item,
      section,
      content: version
    });
    showSuccess('Successfully approved content and made current version');
  };

  const handleDelete = (item, section, content) => {
    showModal({
      header: 'Remove Version',
      body: `Are you sure you want to delete the '${content.name}' version?`,
      handleConfirm: async () => {
        try {
          // must put before deleteSectionContent
          dispatch({
            type: 'REMOVE_SECTION_CONTENT',
            import: item,
            section,
            content: content
          });
          await deleteSectionContent(item.id, section.id, content.id);
          showSuccess('Version was deleted successfully.');
        } finally {
          hideModal();
        }
      }
    });
  };

  if (!version) {
    return (
      <Redirect
        to={`/knowledge-bases/${item.id}/sections/${section.id}/content`}
      />
    );
  }

  return (
    <>
      <ContentDiff before={section.content.content} after={version.content} />
      <ActionsPortal>
        <Button
          colour="danger"
          variant="secondary"
          icon="trash"
          data-testid={`kbv-button-delete-${version.id}`}
          onClick={() => handleDelete(item, section, version)}>
          Delete
        </Button>
        <Button onClick={handleApprove}>Approve</Button>
        <Button
          as={Link}
          variant="secondary"
          to={`/knowledge-bases/${item.id}/sections/${section.id}/content`}>
          Edit current
        </Button>
      </ActionsPortal>
    </>
  );
};

export default Version;
