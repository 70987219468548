import React from 'react';

const TeamsSettingContext = React.createContext({
  settings: null,
  refetch: () => {},
  isLoading: null,
  isSuccess: false
});

export default TeamsSettingContext;
