import tw, { styled } from 'twin.macro';

import { position, flex } from '../styles';

const Screen = styled.div`
  ${tw`w-screen h-screen`}
  ${({ center }) => center && tw`flex-col justify-center items-center`}
  ${flex}
  ${position}
`;

export default Screen;
