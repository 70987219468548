import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Explainer,
  Button,
  Flex,
  Checkbox,
  Panel
} from '@ubisend/pulse-components';

const PolicyAccept = ({ policyUrl, handlePolicyAccept }) => {
  const [policy, setPolicy] = useState({ accepted: false, isLoading: false });

  const handleCheckboxChange = () =>
    setPolicy({ ...policy, accepted: !policy.accepted });

  const handleSubmit = () => {
    setPolicy({ ...policy, isLoading: true });
    handlePolicyAccept();
  };

  return (
    <Panel data-testid="policy-checkpoint">
      <Explainer mb>
        The use of this channel involves connecting to a third-party service.
        You must accept their Privacy Policy before you can activate this
        channel.
      </Explainer>

      <Flex center between>
        <Checkbox
          aria-label="policy-accept-checkbox"
          checked={policy.accepted}
          onChange={() => handleCheckboxChange()}
          label={
            <a rel="noreferrer" target="_blank" href={policyUrl}>
              I have read and agree to the privacy policy
            </a>
          }
        />
        <Button
          selfTop
          right
          loading={policy.isLoading}
          disabled={policy.accepted === false}
          onClick={() => handleSubmit()}>
          Next
        </Button>
      </Flex>
    </Panel>
  );
};

PolicyAccept.propTypes = {
  policyUrl: PropTypes.string.isRequired,
  handlePolicyAccept: PropTypes.func.isRequired
};

export default PolicyAccept;
