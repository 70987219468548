import chroma from 'chroma-js';

import roundRect from '../roundRect';
import textBox from '../textBox';
import {
  MESSAGE_PREVIEW_PADDING,
  NODE_WIDTH,
  BUBBLE_BORDER_RADIUS,
  BUBBLE_PADDING_Y,
  PARAGRAPH_LINE_HEIGHT,
  BUBBLE_PADDING_X,
  PARAGRAPH_FONT_SIZE,
  BUBBLE_INNER_WIDTH,
  ACTION_BUTTON_HEIGHT,
  ACTION_BUTTON_WIDTH,
  ACTION_BUTTON_SPACING,
  MAX_BUTTON_TEXT_WIDTH,
  ACTION_BUTTON_LINE_HEIGHT
} from '../constants';
import { getLines } from '../util/index';

const renderActionButtons = (ctx, x, y, fill, response) => {
  ctx.save();
  ctx.font = `${PARAGRAPH_FONT_SIZE}px Poppins`;
  ctx.fillStyle = 'white';
  ctx.textAlign = 'center';

  let height = 0;
  // Draw action buttons
  response.content.responses.forEach(button => {
    height += ACTION_BUTTON_SPACING;
    roundRect(
      ctx,
      x + MESSAGE_PREVIEW_PADDING + BUBBLE_PADDING_X,
      y + height,
      ACTION_BUTTON_WIDTH,
      ACTION_BUTTON_HEIGHT,
      chroma.mix(fill, 'white', 0.33),
      fill,
      BUBBLE_BORDER_RADIUS
    );
    // Setting textAlign means text is render to the left and right of a x and y point
    const xCenter = NODE_WIDTH / 2;
    ctx.fillText(
      button.label,
      x + xCenter,
      y + height + ACTION_BUTTON_LINE_HEIGHT + BUBBLE_PADDING_Y,
      MAX_BUTTON_TEXT_WIDTH
    );
    height += ACTION_BUTTON_HEIGHT;
  });
  ctx.restore();
  return height;
};

/**
 * Function to draw a action message based on on a node object
 *
 * @param   {CanvasRenderingContext2D}  ctx current canvas rendering context
 * @param   {number}  x     x position of node
 * @param   {number}  y     y position of node
 * @param   {string}  fill  hex, rgba, or string preset colour value to fill the background
 * @param   {object}  response  response object
 *
 * @return  {number}        height of resulting draw object
 */
const action = (ctx, x, y, fill, response) => {
  const text = response.content.text;

  const textLines = getLines(ctx, text, BUBBLE_INNER_WIDTH);

  // Calculate height of buttons before so we can render the rectangle at the right height before the buttons
  const buttonsHeight =
    response.content.responses.length *
    (ACTION_BUTTON_HEIGHT + ACTION_BUTTON_SPACING);

  const containerHeight =
    textLines.length * PARAGRAPH_LINE_HEIGHT +
    BUBBLE_PADDING_Y * 3 +
    buttonsHeight;

  roundRect(
    ctx,
    x + MESSAGE_PREVIEW_PADDING,
    y + MESSAGE_PREVIEW_PADDING,
    NODE_WIDTH - MESSAGE_PREVIEW_PADDING * 2,
    containerHeight,
    'transparent',
    fill,
    BUBBLE_BORDER_RADIUS
  );
  ctx.save();
  ctx.clip();
  textBox(
    ctx,
    x + MESSAGE_PREVIEW_PADDING + BUBBLE_PADDING_X,
    y + MESSAGE_PREVIEW_PADDING + ACTION_BUTTON_LINE_HEIGHT + BUBBLE_PADDING_Y,
    textLines
  );
  // Y Position to start rendering buttons from
  const yPosition =
    textLines.length * PARAGRAPH_LINE_HEIGHT + BUBBLE_PADDING_Y * 3;
  renderActionButtons(ctx, x, y + yPosition, fill, response);
  ctx.restore();
  return containerHeight;
};

export default action;
export { renderActionButtons };
