import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

import { motion } from '@ubisend/framer-motion';

import GalleryImage from '../GalleryImage';

const Gallery = styled(motion.div)`
  ${tw`absolute flex items-center justify-center w-screen h-screen`}
  z-index: 1000;
  margin-top: -100%;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  margin-left: calc(384px - 100vw);
`;

const variants = {
  hide: {
    opacity: 0,
    transition: {
      duration: 0.6,
      delay: 0.4
    }
  },
  show: {
    opacity: 1,
    transition: {
      duration: 0.6
    }
  }
};

const Lightbox = ({ content, onClose }) => (
  <Gallery
    onClick={onClose}
    initial="hide"
    animate="show"
    exit="hide"
    variants={variants}>
    <GalleryImage image={content.value} />
  </Gallery>
);

Lightbox.propTypes = {
  content: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};

export default Lightbox;
