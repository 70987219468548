import tw, { styled } from 'twin.macro';

const MarkdownCode = styled.div`
  ${tw`inline text-sm font-normal whitespace-pre-wrap p-2 rounded relative`}
  border: 1px solid ${({ theme }) => theme.warning};

  background-color: rgba(255, 255, 255, 1);
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
`;

export default MarkdownCode;
