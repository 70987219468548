import tw, { styled } from 'twin.macro';

const VerticalConnector = styled.div`
  ${tw`z-10`}
  width: 2px;
  background: #888888;
  height: ${props => (props.small ? '1rem' : props.tall ? '144px' : '110px')};
`;

export default VerticalConnector;
