import React, { useState } from 'react';

import { useQueryClient, useMutation } from '@ubisend/pulse-hooks';
import {
  Flex,
  TextInput,
  Panel,
  Label,
  FormGroup,
  Button,
  Explainer,
  useNotification
} from '@ubisend/pulse-components';

import { updateTelegramSettings } from '../api/index';

const CreateTelegramLink = () => {
  const [settings, setSettings] = useState({
    auth_token: ''
  });

  const { showSuccess, showError } = useNotification();
  const queryClient = useQueryClient();

  const updateSettings = useMutation(updateTelegramSettings, {
    onSuccess: () => {
      showSuccess('Successfully updated Telegram Settings.');
      queryClient.invalidateQueries('telegram/settings');
    },
    onError: () => {
      showError(`Something went wrong whilst linking Telegram`);
    }
  });

  const handleAuthTokenChange = e => {
    const token = e.target.value;

    setSettings(settings => ({
      ...settings,
      auth_token: token
    }));
  };

  const handleSubmit = () =>
    updateSettings.mutate({
      auth_token: settings.auth_token
    });

  const valid = settings?.auth_token;

  return (
    <Panel divider header="Link your Telegram account">
      <FormGroup>
        <Explainer mb>
          Please enter your Telegram auth token. For more information please
          view the documentation.
        </Explainer>
        <Label>Auth Token:</Label>
        <TextInput
          aria-label="auth-token-input"
          placeholder="12345:ABC123_ABC123_ABC123"
          value={settings.auth_token}
          onChange={handleAuthTokenChange}
        />
      </FormGroup>
      <Flex xSpace right>
        <Button
          variant="primary"
          disabled={!valid}
          onClick={handleSubmit}
          loading={updateSettings.isLoading}>
          Save
        </Button>
      </Flex>
    </Panel>
  );
};

export default CreateTelegramLink;
