import axios from 'axios';

const generateAccessToken = params => {
  return axios.post('workplace/settings/generate-token', params);
};

const removeSettings = () => axios.delete('workplace/settings');

const updateSettings = params => axios.put(`workplace/settings`, params);

const acceptPolicy = () => axios.put('workplace/settings/accept');

export { updateSettings, removeSettings, generateAccessToken, acceptPolicy };
