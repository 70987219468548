import createTicket from './createTicket';
import createLiveChatTicket from './createLiveChatTicket';
import deleteSubscriber from './deleteSubscriber';
import exportAllImports from './exportAllImports';
import exportImport from './exportImport';
import exportSection from './exportSection';
import exportSubscriber from './exportSubscriber';
import exportTranscript from './exportTranscript';
import leaveFeedback from './leaveFeedback';
import setLanguage from './setLanguage';
import setLocation from './setLocation';
import setCredentials from './setCredentials';
import login from './login';
import logout from './logout';
import setPassword from './setPassword';
import generatePasswordResetToken from './generatePasswordResetToken';
import listenForResponseOnAlexa from './listenForResponseOnAlexa';
import listenForResponseOnVoip from './listenForResponseOnVoip';
import transferVoipCall from './transferVoipCall';
import endCurrentSession from './endCurrentSession';
import requestPermissionsOnAlexa from './requestPermissionsOnAlexa';
import triggerPostback from './triggerPostback';
import triggerPostbackCancel from './triggerPostbackCancel';

const allActions = {
  createTicket,
  createLiveChatTicket,
  deleteSubscriber,
  exportAllImports,
  exportImport,
  exportSection,
  exportSubscriber,
  exportTranscript,
  leaveFeedback,
  setLanguage,
  setLocation,
  setCredentials,
  login,
  logout,
  setPassword,
  generatePasswordResetToken,
  listenForResponseOnAlexa,
  listenForResponseOnVoip,
  transferVoipCall,
  endCurrentSession,
  requestPermissionsOnAlexa,
  triggerPostback,
  triggerPostbackCancel
};

const actions = {
  ...allActions,
  all: [
    allActions.createTicket,
    allActions.createLiveChatTicket,
    allActions.deleteSubscriber,
    allActions.exportAllImports,
    allActions.exportImport,
    allActions.exportSection,
    allActions.exportSubscriber,
    allActions.exportTranscript,
    allActions.leaveFeedback,
    allActions.setLanguage,
    allActions.setLocation,
    allActions.setCredentials,
    allActions.login,
    allActions.logout,
    allActions.setPassword,
    allActions.generatePasswordResetToken,
    allActions.listenForResponseOnAlexa,
    allActions.listenForResponseOnVoip,
    allActions.transferVoipCall,
    allActions.endCurrentSession,
    allActions.requestPermissionsOnAlexa,
    allActions.triggerPostback,
    allActions.triggerPostbackCancel
  ]
};

export default actions;
