const languages = [
  'en', // English
  'de', // German
  'nl', // Dutch
  'fr', // French
  'vi', // Vietnamese
  'pt', // Portuguese
  'tr', // Turkish
  'es', // Spanish
  'pt-br', // Portuguese Spanish
  'no', // Norwegian
  'cy', // Welsh
  'bn', // Bengali
  'hi', // Hindi
  'ro', // Romanian
  'bg', // Bulgarian,
  'fi' // Finnish
];

export default languages;
