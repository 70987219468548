import axios from 'axios';

const createCallToAction = params =>
  axios.post(`converse/call-to-actions`, params);

const updateCallToAction = params => {
  return axios.put(`converse/call-to-actions/${params.cta_id}`, params);
};

const deleteCallToAction = id => axios.delete(`converse/call-to-actions/${id}`);

export { createCallToAction, updateCallToAction, deleteCallToAction };
