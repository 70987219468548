import React from 'react';

import {
  PageWrapper,
  LoadingContainer,
  useNotification
} from '@ubisend/pulse-components';
import { Drivers } from '@ubisend/pulse-hooks';
import { useAuth } from '@ubisend/pulse-auth';

import { associateChannel, removeChannel } from '../api/index';
import { useChannels } from '../hooks/index';

const Channels = () => {
  const { channels, allChannels, setChannels } = useChannels();
  const { showSuccess } = useNotification();
  const { hasPermission } = useAuth();

  const handleRemoveChannel = async updatedChannel => {
    await associateChannel(updatedChannel.id);
    showSuccess(
      `Successfully activated the ${updatedChannel.name} Message Channel.`
    );
    setChannels(channels => channels.concat(updatedChannel.id));
  };

  const handleAddChannel = async updatedChannel => {
    await removeChannel(updatedChannel.id);
    showSuccess(
      `Successfully deactived the ${updatedChannel.name} Message Channel.`
    );
    setChannels(channels => {
      return channels.filter(channel => channel !== updatedChannel.id);
    });
  };

  const handleChannel = updatedChannel => {
    if (channels.find(channel => channel === updatedChannel.id)) {
      handleAddChannel(updatedChannel);
    } else {
      handleRemoveChannel(updatedChannel);
    }
  };

  const loading = !channels || !allChannels;

  const channelDrivers = allChannels.map(channel => {
    const notAcceptedPolicy = channel.has_accepted_policy === false;

    return {
      is_disabled: notAcceptedPolicy,
      warning: notAcceptedPolicy
        ? 'You must agree to the channel policies before you can activate the channel.'
        : null,
      ...channel
    };
  });

  const copy = {
    available: {
      title: 'Available channels',
      info: "Manage your chatbot's messaging channels."
    },
    other: {
      title: 'Bespoke messaging channels',
      info: 'Contact your account manager to activate a bespoke channel.'
    }
  };

  return (
    <PageWrapper
      header="Channels"
      subheader="Configure your messaging channels">
      {loading && <LoadingContainer />}
      {!loading && (
        <Drivers
          copy={copy}
          type="multi"
          required={false}
          value={channels}
          drivers={channelDrivers}
          handleClick={handleChannel}
          canEditDriver={driver => {
            return hasPermission(`edit ${driver.resource_name}`);
          }}
          canEnableDriver={() => hasPermission(`edit channels`)}
          canDisableDriver={() => hasPermission(`edit channels`)}
        />
      )}
    </PageWrapper>
  );
};

export default Channels;
