import React from 'react';
import tw, { styled } from 'twin.macro';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { Flex, Span, Heading1 } from '@ubisend/pulse-components';

import { useTickets } from '../../../hooks/index';
import Notifications from './Notifications';
import { ScreenContainer } from '../Components/index';

const statusColours = {
  submitted: 'rgba(231, 76, 60,0.25)',
  in_progress: 'rgba(255,126,34,0.25)',
  resolved: 'rgba(46, 204, 113,0.25)'
};

const Indicator = styled.span`
  ${tw`py-2 px-3 rounded-full`}
  background: ${({ status }) => statusColours[status]};
  border: 1px solid ${({ status }) => statusColours[status]};
  &:before {
    content: '';
    ${tw`w-2 h-2 mr-2 rounded-full`}
    background: ${({ status }) => statusColours[status]};
  }
`;

const Tickets = () => {
  const { tickets } = useTickets();

  const { t } = useTranslation(['bots', 'full_page']);

  const statuses = {
    submitted: t('reported_ticket_status', { ns: 'bots' }),
    in_progress: t('in_progress_ticket_status', { ns: 'bots' }),
    resolved: t('resolved_ticket_status', { ns: 'bots' })
  };

  return (
    <ScreenContainer data-testid="tickets">
      <Heading1 initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        {t('tickets_header', { ns: 'full_page' })}
      </Heading1>
      {tickets.map(ticket => (
        <Notifications.Notification
          data-testid="ticket"
          href="#"
          key={ticket.id}>
          <Flex fat between center>
            <Flex col>
              <Span mbSm bold>
                {ticket.name}
              </Span>
              <Flex>
                <Notifications.Clock />
                <Notifications.Date>
                  {dayjs().to(dayjs(ticket.created_at))}
                </Notifications.Date>
              </Flex>
            </Flex>
            <Indicator status={ticket.status}>
              {statuses[ticket.status]}
            </Indicator>
          </Flex>
          <Flex fat>
            <Notifications.Body>{ticket.description}</Notifications.Body>
          </Flex>
        </Notifications.Notification>
      ))}
    </ScreenContainer>
  );
};

export default Tickets;
