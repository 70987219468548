import React from 'react';
import PropTypes from 'prop-types';

import { useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import { useNotification, LoadingContainer } from '@ubisend/pulse-components';
import { useAuth } from '@ubisend/pulse-auth';

import { updateClient } from '../../../api/index';
import ClientDetails from './ClientDetails';

const EditClient = ({ client: clientToEdit }) => {
  const { refetchAuth } = useAuth();
  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const mutation = useMutation(updateClient, {
    onSuccess: ({ data: { data } }) => {
      queryClient.invalidateQueries();

      refetchAuth();
      showSuccess(`Successfully updated client "${data.name}"`);
    }
  });

  const handleSubmit = form => {
    mutation.mutate({ id: clientToEdit.id, ...form });
  };

  if (!clientToEdit) {
    return <LoadingContainer />;
  }

  return (
    <ClientDetails
      initialClient={clientToEdit}
      onSubmit={handleSubmit}
      loading={mutation.isLoading}
    />
  );
};

EditClient.propTypes = {
  client: PropTypes.object.isRequired
};

export default EditClient;
