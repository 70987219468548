import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Paragraph, Label, Divider } from '@ubisend/pulse-components';

import NodeHeader from '../../NodeHeader';
import ResponsesPreview from '../../../ResponsesPreview';

const StepPreview = ({ node }) => {
  return (
    <Flex border col rounded shadow style={{ width: 288 }} background="white">
      <NodeHeader>
        <Flex xSpaceSm center fat>
          <Flex col>
            <Label mb={false}>Workflow Step</Label>
            <Paragraph sm secondary>
              {node.base.title}
            </Paragraph>
          </Flex>
        </Flex>
      </NodeHeader>
      <Divider mtNone mbNone />
      <ResponsesPreview responses={node.base.responses} />
    </Flex>
  );
};

StepPreview.propTypes = {
  node: PropTypes.shape({
    base: PropTypes.shape({
      title: PropTypes.string.isRequired,
      responses: PropTypes.array.isRequired
    }).isRequired
  }).isRequired
};

export default StepPreview;
