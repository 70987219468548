import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Label,
  TwoThirdsLayout,
  PageWrapper,
  Panel,
  TextInput,
  Flex,
  Button,
  useNotification,
  Divider,
  Tooltip,
  Select,
  Span
} from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';
import { Rows, useRows, useQuery, useMutation } from '@ubisend/pulse-hooks';

import { updateOAuthSettings } from '../../../api/index';

const AttributeInput = ({ onChange, ...props }) => {
  const handleInputChange = event => {
    const value = event.target.value;

    onChange({ value, label: value });
  };

  return (
    <TextInput
      placeholder="user_name"
      onChange={handleInputChange}
      {...props}
    />
  );
};

AttributeInput.propTypes = {
  onChange: PropTypes.func
};

const EndpointsSelect = ({ value, ...props }) => {
  const query = useQuery('owner/endpoints');

  const format = endpoint => {
    const name =
      endpoint.name || `${endpoint.method.toUpperCase()} ${endpoint.endpoint}`;

    return {
      value: endpoint.id,
      label: `${name} (${endpoint.integration.name})`
    };
  };

  return (
    <Select
      {...props}
      isLoading={query.isLoading}
      value={
        value && query.isSuccess
          ? format(query.data.data.find(endpoint => endpoint.id === value))
          : null
      }
      options={query.isSuccess ? query.data.data.map(format) : []}
    />
  );
};

EndpointsSelect.propTypes = {
  value: PropTypes.number
};

const OAuthSettings = () => {
  const [settings, setSettings] = useState();
  const [showClientSecret, setShowClientSecret] = useState(true);

  const { showSuccess } = useNotification();
  const parameters = useRows();

  const query = useQuery('owner/auth/oauth2/settings', {
    onSuccess: data => {
      setSettings({
        name: data.data.name || '',
        identifier: data.data.identifier || '',
        auth_url: data.data.auth_url || '',
        client_id: data.data.client_id || '',
        has_not_client_secret: data.data.has_not_client_secret || '',
        scopes: data.data.scopes || '',
        state: data.data.state || '',
        auth_token_endpoint_id: data.data.auth_token_endpoint_id,
        user_endpoint_id: data.data.user_endpoint_id,
        logout_endpoint_id: data.data.logout_endpoint_id
      });
      parameters.setRows(data.data.parameters);
      setShowClientSecret(data.data.has_not_client_secret);
    }
  });
  const mutation = useMutation(updateOAuthSettings, {
    onSuccess: () => {
      showSuccess('Successfully updated OAuth settings');
      setShowClientSecret(false);
    }
  });

  const handleInputChange = key => event => {
    const value = event.target.value;

    setSettings(current => {
      return { ...current, [key]: value };
    });
  };

  const handleAuthTokenEndpointChange = option => {
    setSettings(current => {
      return { ...current, auth_token_endpoint_id: option.value };
    });
  };

  const handleUserEndpointChange = option => {
    setSettings(current => {
      return { ...current, user_endpoint_id: option ? option.value : null };
    });
  };

  const handleLogoutEndpointChange = option => {
    setSettings(current => {
      return { ...current, logout_endpoint_id: option ? option.value : null };
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

    mutation.mutate({ ...settings, parameters: parameters.rows });
  };

  const handleShowClientSecret = () => setShowClientSecret(true);

  const valid = useMemo(() => {
    return (
      Boolean(
        settings &&
          settings.name &&
          settings.auth_url &&
          settings.client_id &&
          settings.client_secret &&
          settings.auth_token_endpoint_id &&
          settings.logout_endpoint_id
      ) && parameters.valid
    );
  }, [settings, parameters.valid]);

  return (
    <PageWrapper
      header="OAuth Settings"
      subheader="Configure your OAuth login provider">
      <TwoThirdsLayout>
        <Panel loading={query.isLoading}>
          {query.isSuccess && settings && (
            <form onSubmit={handleSubmit}>
              <Flex col ySpace top>
                <Label htmlFor="name">Name *</Label>
                <TextInput
                  id="name"
                  onChange={handleInputChange('name')}
                  value={settings.name}
                />
                <Label htmlFor="url">Auth URL *</Label>
                <TextInput
                  id="url"
                  type="url"
                  onChange={handleInputChange('auth_url')}
                  value={settings.auth_url}
                />
                <Label htmlFor="client_id">Client Id *</Label>
                <TextInput
                  id="client_id"
                  onChange={handleInputChange('client_id')}
                  value={settings.client_id}
                />
                <Flex width="100%" between>
                  <Label htmlFor="client_secret">Client secret *</Label>
                  {!showClientSecret && (
                    <Flex top>
                      <Button onClick={handleShowClientSecret}>
                        Change Credentials
                      </Button>
                    </Flex>
                  )}
                </Flex>
                {showClientSecret ? (
                  <TextInput
                    id="client_secret"
                    onChange={handleInputChange('client_secret')}
                  />
                ) : (
                  <Span>Authorization credentials have already been set</Span>
                )}

                <Label htmlFor="state">State</Label>
                <TextInput
                  id="state"
                  onChange={handleInputChange('state')}
                  value={settings.state}
                />
                <Label htmlFor="scopes">Scopes</Label>
                <TextInput
                  id="scopes"
                  onChange={handleInputChange('scopes')}
                  value={settings.scopes}
                />
                <Label>Parameters</Label>
                <Rows {...parameters} KeyInput={AttributeInput} />
                <Divider fullWidth mtNone />
                <Flex fat xSpace>
                  <Flex col grow ySpaceSm>
                    <Flex bottom>
                      <Label htmlFor="auth_token_endpoint" mb={false}>
                        Auth token endpoint *
                      </Label>
                      <Tooltip
                        tooltip={
                          <Tooltip.Content>
                            The endpoint to retrieve the access token required
                            for the user endpoint
                          </Tooltip.Content>
                        }>
                        <Tooltip.Icon>?</Tooltip.Icon>
                      </Tooltip>
                    </Flex>
                    <Flex>
                      <EndpointsSelect
                        id="auth_token_endpoint"
                        value={settings.auth_token_endpoint_id}
                        onChange={handleAuthTokenEndpointChange}
                      />
                    </Flex>
                  </Flex>
                  <Flex bottom pb>
                    <Icon
                      size="1rem"
                      width="1rem"
                      height="1rem"
                      type="arrowNarrowRight"
                      outline
                    />
                  </Flex>
                  <Flex col grow ySpaceSm>
                    <Flex bottom>
                      <Label htmlFor="user_token_endpoint" mb={false}>
                        User endpoint
                      </Label>
                      <Tooltip
                        tooltip={
                          <Tooltip.Content>
                            The endpoint to retrieve an identifier for the user
                            from an external service
                          </Tooltip.Content>
                        }>
                        <Tooltip.Icon>?</Tooltip.Icon>
                      </Tooltip>
                    </Flex>
                    <Flex>
                      <EndpointsSelect
                        id="user_token_endpoint"
                        value={settings.user_endpoint_id}
                        onChange={handleUserEndpointChange}
                        isClearable
                      />
                    </Flex>
                  </Flex>
                </Flex>
                <Flex col ySpaceSm fat>
                  <Flex bottom>
                    <Label htmlFor="logout_endpoint" mb={false}>
                      Logout redirect
                    </Label>
                    <Tooltip
                      tooltip={
                        <Tooltip.Content>
                          The endpoint to send the user to after they have
                          logged out. This can be used to invalidate SSO tokens.
                        </Tooltip.Content>
                      }>
                      <Tooltip.Icon>?</Tooltip.Icon>
                    </Tooltip>
                  </Flex>
                  <Flex>
                    <EndpointsSelect
                      id="logout_endpoint"
                      value={settings.logout_endpoint_id}
                      onChange={handleLogoutEndpointChange}
                      isClearable
                    />
                  </Flex>
                </Flex>
                <Flex col ySpaceSm fat>
                  <Flex bottom>
                    <Label htmlFor="identifier" mb={false}>
                      User identifier *
                    </Label>
                    <Tooltip
                      tooltip={
                        <Tooltip.Content>
                          The object key to store from the user endpoint&apos;s
                          response
                        </Tooltip.Content>
                      }>
                      <Tooltip.Icon>?</Tooltip.Icon>
                    </Tooltip>
                  </Flex>
                  <TextInput
                    id="identifier"
                    onChange={handleInputChange('identifier')}
                    value={settings.identifier}
                    fat
                  />
                </Flex>

                <Button
                  variant="primary"
                  type="submit"
                  selfTop
                  loading={mutation.isLoading}
                  disabled={!valid}>
                  Save
                </Button>
              </Flex>
            </form>
          )}
        </Panel>
      </TwoThirdsLayout>
    </PageWrapper>
  );
};

export default OAuthSettings;
