import { useState } from 'react';

const defaultRequest = {
  endpoint: '',
  method: 'get',
  soapFunction: '',
  is_soap: false
};

const useRequest = ({
  endpoint: defaultEndpoint,
  method: defaultMethod,
  soapFunction: defaultSoapFunction,
  is_soap: defaultIsSoap
} = defaultRequest) => {
  const [method, setMethod] = useState(defaultMethod);
  const [endpoint, setEndpoint] = useState(defaultEndpoint);
  const [soapFunction, setSoapFunction] = useState(defaultSoapFunction);
  const [isSoap, setIsSoap] = useState(defaultIsSoap);

  const resetRequest = () => {
    setMethod(defaultMethod);
    setEndpoint(defaultEndpoint);
    setSoapFunction(defaultSoapFunction);
    setIsSoap(defaultIsSoap);
  };

  const valid = Boolean(method && endpoint && endpoint.length > 0);

  const form = {
    method,
    soapFunction,
    isSoap,
    endpoint
  };

  return {
    method,
    setMethod,
    soapFunction,
    setSoapFunction,
    isSoap,
    setIsSoap,
    endpoint,
    setEndpoint,
    form,
    valid,
    resetRequest
  };
};

export default useRequest;
