import listenForResponseOnVoipContent from './ListenForResponseOnVoipContent';

const listenForResponseOnVoip = {
  name: 'Listen for response on Voip',
  Content: listenForResponseOnVoipContent,
  setInitialContent: () => {
    return {};
  },
  valid: () => {
    return true;
  }
};

export default listenForResponseOnVoip;
