import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@ubisend/pulse-components';
import { useQuery } from '@ubisend/pulse-hooks';

const LogTypeSelect = ({ value, ...props }) => {
  const options = useQuery(['logs/types']);

  return (
    options.isSuccess &&
    !options.isLoading && (
      <Select
        options={options.data.data}
        value={value.map(value =>
          options.data.data.find(option => option.value === value)
        )}
        isClearable
        isMulti
        {...props}
      />
    )
  );
};

LogTypeSelect.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default LogTypeSelect;
