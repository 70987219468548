import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import chroma from 'chroma-js';

import { solidIcons } from '@ubisend/pulse-icons';

import Label from '../Forms/Label';
import { spacing } from '../styles';

const StyledLabel = styled.label`
  ${spacing}

  ${tw`flex border-0 bg-transparent p-0`}
  ${props => props.disabled && tw`opacity-50`}
`;

const Check = solidIcons.check;

const StyledIcon = styled(Check)`
  ${tw`absolute text-white z-10`}

  top: 3px;
  left: 2px;
`;

const PulseCheckbox = ({
  checked,
  onChange,
  label,
  disabled,
  'aria-label': ariaLabel,
  className
}) => {
  return (
    <StyledLabel
      disabled={disabled}
      className={className}
      htmlFor={ariaLabel || label}>
      <StyledIcon width={12} height={12} style={{ pointerEvents: 'none' }} />
      <input
        id={ariaLabel || label}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        aria-label={ariaLabel}
      />
      <Label mb={false} htmlFor={ariaLabel || label}>
        <span>{label}</span>
      </Label>
    </StyledLabel>
  );
};

PulseCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  'aria-label': PropTypes.string,
  className: PropTypes.string
};

const getGradient = (colour, darken = 0) => {
  if (!colour) {
    return;
  }
  return `linear-gradient(${chroma(colour).brighten(0.5 - darken)},${chroma(
    colour
  ).darken(darken)})`;
};

const Checkbox = styled(PulseCheckbox)`
  ${tw`relative cursor-pointer `}
  &:hover {
    & > input {
      background: ${({ theme, colour }) => {
        return chroma(colour || theme.primary).alpha(0.1);
      }};
    }
  }
  & > input {
    appearance: none;
    border: 1px solid
      ${({ theme, colour }) => {
        return chroma(colour || theme.primary).alpha(0.5);
      }};
    ${tw`absolute m-0 top-0 left-0 w-4 h-4 rounded-sm`} & + label {
      ${tw`inline-flex items-center relative cursor-pointer p-0 w-full h-4`}
      & > span {
        ${tw`inline-block pl-3`}
      }
    }

    /* Box */
    & + label:before {
      ${tw`inline-block align-middle align-text-top rounded-sm flex-shrink-0`}
      transition: opacity 0.1s ease-out;
      opacity: ${({ checked }) => (checked ? 1 : 0)};
      background: ${({ theme, colour }) => {
        return getGradient(colour || theme.primary);
      }};
      box-sizing: border-box;
      content: '';
      width: 16px;
      height: 16px;

      &:active {
        background: ${({ theme, colour }) => {
          return getGradient(colour || theme.primary, 0.5);
        }};
      }
      &:hover {
        background: ${({ theme, colour }) => {
          return getGradient(colour || theme.primary, 0.25);
        }};
      }
    }
  }
`;

export default Checkbox;
