import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Select, TextInput, Label } from '@ubisend/pulse-components';

const RoundInput = ({ content, onChange }) => {
  const options = [
    { value: 'floor', label: 'Round Down (Floor)' },
    { value: 'ceil', label: 'Round Up (Ceil)' }
  ];

  const handleDecimal = event => {
    const value = parseInt(event.target.value);
    onChange({ decimal_places: value });
  };

  const handleMethod = option => {
    const value = option.value;
    onChange({ method: value });
  };

  return (
    <Flex col>
      <Flex col mb>
        <Label htmlFor="method">Method</Label>
        <Select
          id="method"
          isClearable
          options={options}
          defaultValue="1"
          onChange={handleMethod}
        />
      </Flex>
      <Flex col mb>
        <Label htmlFor="decimal_places">Decimal places</Label>
        <TextInput
          type="number"
          min={0}
          max={10}
          id="decimal_places"
          placeholder="name"
          value={content.decimal_places}
          onChange={handleDecimal}
        />
      </Flex>
    </Flex>
  );
};

RoundInput.propTypes = {
  content: PropTypes.shape({
    decimal_places: PropTypes.number.isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

export default RoundInput;
