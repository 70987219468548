import React from 'react';
import PropTypes from 'prop-types';

import { HumanAvatar, AvatarName, BotAvatar } from './Avatars/index';
import Timestamp from './Timestamp';
import MessageGroupContainer from './MessageGroupContainer';
import MessageContainer from './MessageContainer';
import { message } from '../../../types/index';
import { systemMessageTypes } from '../../../utilities/messages';
import { useTimestamps, useBot } from '../../../hooks/index';

const MessageGroup = ({ group, previousGroup, children }) => {
  const showTimestamp = useTimestamps(group[0], previousGroup?.[0]);
  const { name, showDateTime, showAvatars } = useBot();

  // Automatically sent by the bot.
  const isBot = group[0].direction === 'toClient' && !group[0].agent;

  // Manually sent by a CMS agent, e.g. Live Chat.
  const isAgentCreated = group[0].direction === 'toClient' && group[0].agent;

  // Message looks like it wasn't sent by anyone.
  const isNotSystemMessage = !systemMessageTypes.includes(group[0].type);

  return (
    <>
      {showTimestamp && showDateTime ? (
        <Timestamp
          data-testid="chatbot_date_time"
          stamp={group[0].sentAt.format('D MMM YYYY - h:mmA')}
        />
      ) : null}
      <MessageGroupContainer hasAvatar={isBot && showAvatars}>
        {isNotSystemMessage && isBot && <BotAvatar />}
        {isNotSystemMessage && isAgentCreated && <HumanAvatar />}
        <div>
          {isNotSystemMessage && isBot && <AvatarName>{name}</AvatarName>}
          {isNotSystemMessage && isAgentCreated && (
            <AvatarName>{group[0].agent.name}</AvatarName>
          )}
          <MessageContainer>{children}</MessageContainer>
        </div>
      </MessageGroupContainer>
    </>
  );
};

MessageGroup.propTypes = {
  group: PropTypes.arrayOf(PropTypes.shape(message)).isRequired,
  previousGroup: PropTypes.arrayOf(PropTypes.shape(message))
};

export default MessageGroup;
