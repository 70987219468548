import ImageInput from './ImageInput';

const image = {
  id: 'image',
  name: 'Image',
  Input: ImageInput,
  setInitialContent: content => {
    return {
      url: content.url || 'http://placehold.it/400x200',
      image_description: content.image_description || '',
      full_size: content.full_size !== undefined ? content.full_size : false,
      text: content.text || ''
    };
  },
  valid: content => {
    return content.url.length > 0;
  },
  snippet: `{
    "type": "image",
    "content": {
      "url": "",
      "image_description": "",
      "full_size": false,
      "text": ""
    }
}`
};

export default image;
