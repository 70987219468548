import React from 'react';
import PropTypes from 'prop-types';

import { Button, Flex, Heading2 } from '@ubisend/pulse-components';

const SubscriberFooter = ({
  selectedSubscribers,
  handleMassDataExport,
  handleMassTranscriptExport,
  handleMassDelete,
  handleClear
}) => {
  const handleData = () => handleMassDataExport(selectedSubscribers);

  const handleTranscript = () =>
    handleMassTranscriptExport(selectedSubscribers);

  const handleDelete = () => handleMassDelete(selectedSubscribers);

  return (
    <Flex between>
      <Heading2>
        {`${selectedSubscribers.length} subscriber${
          selectedSubscribers.length === 1 ? '' : 's'
        } selected.`}
      </Heading2>
      <Flex xSpace>
        <Button
          icon="download"
          onClick={handleData}
          aria-label="footer-data-export">
          Export Data
        </Button>
        <Button
          icon="download"
          onClick={handleTranscript}
          aria-label="footer-transcript-export">
          Export Conversation{`${selectedSubscribers.length === 1 ? '' : 's'}`}
        </Button>
        <Button icon="x" onClick={handleClear}>
          Clear
        </Button>
        <Button
          icon="trash"
          colour="danger"
          onClick={handleDelete}
          aria-label="footer-delete-subscriber">
          Delete {selectedSubscribers.length} Conversation
          {`${selectedSubscribers.length === 1 ? '' : 's'}`}
        </Button>
      </Flex>
    </Flex>
  );
};

SubscriberFooter.propTypes = {
  selectedSubscribers: PropTypes.array.isRequired,
  handleMassDataExport: PropTypes.func.isRequired,
  handleMassTranscriptExport: PropTypes.func.isRequired,
  handleMassDelete: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired
};

export default SubscriberFooter;
