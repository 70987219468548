import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';

import { breakpoints } from '@ubisend/pulse-components';

import { useVideoProvider } from '../../../hooks/index';

const VideoContainer = styled.div`
  @media (min-width: ${breakpoints.md}px) {
    ${tw`shadow-lg`}
    max-width: calc(45vw - 2rem);
    max-height: 50vh;
  }
  @media (max-width: ${breakpoints.md - 1}px) {
    ${tw`h-full object-cover object-center`}
  }
  ${tw`bg-black h-full w-full relative`}
  & > iframe {
    ${tw`w-full h-full absolute inset-0`}
    margin-bottom: -5px;
  }
`;

const Video = ({ content }) => {
  const ProviderFrame = useVideoProvider(content.provider);

  return (
    <VideoContainer>
      <ProviderFrame
        url={content.url}
        aria-label={content.content_description}
      />
    </VideoContainer>
  );
};

Video.propTypes = {
  content: PropTypes.shape({
    url: PropTypes.string.isRequired,
    text: PropTypes.string,
    provider: PropTypes.oneOf(Object.values(useVideoProvider.PROVIDERS))
      .isRequired,
    content_description: PropTypes.string
  }).isRequired
};

export default Video;
