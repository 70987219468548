import { styled } from 'twin.macro';

const Spacer = styled.div`
  ${props =>
    props.my && `margin-top: ${props.my}rem; margin-bottom: ${props.my}rem`};
  ${props =>
    props.mx && `margin-right: ${props.mx}rem; margin-left: ${props.mx}rem`};
  ${props => props.mt && `margin-top: ${props.mt}rem;`};
  ${props => props.mb && `margin-bottom: ${props.mb}rem;`};
  ${props => props.ml && `margin-left: ${props.ml}rem;`};
  ${props => props.mr && `margin-right: ${props.mr}rem;`};
`;

export default Spacer;
