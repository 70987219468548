import React from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Select, formatSelectOption } from '@ubisend/pulse-components';

const AvailableAssigneeSelect = ({ value, groupIds, ...props }) => {
  const query = useQuery([
    'tickets/ubidesk/agents/available',
    { group_ids: groupIds }
  ]);

  const formatAssignees = () => [
    {
      label: 'Available Agents',
      options: query.data.data
        .filter(assignee => {
          if (assignee.is_available) {
            return assignee;
          }
        })
        .map(assignee => formatSelectOption(assignee))
    },
    {
      label: 'Unavailable Agents',
      options: query.data.data
        .filter(assignee => {
          if (!assignee.is_available) {
            return assignee;
          }
        })
        .map(assignee => formatSelectOption(assignee))
    }
  ];

  return (
    <Select
      aria-label="assignee-select"
      isClearable
      isLoading={query.isLoading}
      options={query.isSuccess ? formatAssignees() : []}
      value={
        query.isSuccess &&
        value &&
        formatSelectOption(query.data.data.find(agent => agent.id === value))
      }
      {...props}
    />
  );
};

AvailableAssigneeSelect.propTypes = {
  value: PropTypes.number,
  groupIds: PropTypes.arrayOf(PropTypes.number)
};

export default AvailableAssigneeSelect;
