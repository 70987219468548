import IntegrationBlock from './IntegrationBlock';
import AddIntegrationBlock from './AddIntegrationBlock';
import EditIntegrationBlock from './EditIntegrationBlock';

const integration = {
  id: 'integration',
  name: 'Integration',
  icon: 'chip',
  permission: 'integrations',
  Block: IntegrationBlock,
  AddBlock: AddIntegrationBlock,
  EditBlock: EditIntegrationBlock
};

export default integration;
