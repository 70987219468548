import RoundInput from './RoundInput';

const round = {
  name: 'Round',
  Input: RoundInput,
  content: {
    method: 'common',
    decimal_places: 0
  },
  format: (variable, content) => {
    return `${variable}|round(${content.decimal_places},'${content.method}')`;
  },
  valid: () => true
};

export default round;
