import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import plugins from 'suneditor/src/plugins';

import { useTheme } from '@ubisend/pulse-components';

import {
  tableCellBackgroundColour,
  linkSection as linkSectionPlugin
} from '../plugins/index';
import SectionModal from './SectionModal';

const WysiwygEditor = ({ value, onChange, imports, ...props }) => {
  const [sectionSelect, setSectionSelect] = useState(false);
  const ref = useRef();

  const theme = useTheme();

  const handleChange = value => {
    onChange(value);
  };

  const getSunEditorInstance = sunEditor => {
    ref.current = sunEditor;
  };

  const handleCloseSectionModal = () => setSectionSelect(false);

  const handleInsertSection = ({ section, text }) => {
    const html = `<a href=/window/section/${section.value}>${
      text ? text : section.label
    }</a>`;

    ref.current.insertHTML(html);
    handleCloseSectionModal();
  };

  return (
    <>
      <SunEditor
        defaultValue={value}
        onChange={handleChange}
        getSunEditorInstance={getSunEditorInstance}
        setAllPlugins={false}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={false}
        setOptions={{
          // Plugins
          plugins: {
            // Built in
            font: plugins.font,
            fontSize: plugins.fontSize,
            fontColor: plugins.fontColor,
            formatBlock: plugins.formatBlock,
            hiliteColor: plugins.hiliteColor,
            align: plugins.align,
            list: plugins.list,
            link: plugins.link,
            image: plugins.image,
            table: plugins.table,
            // Custom
            tableCellBackgroundColour,
            linkSection: {
              open: () => setSectionSelect(true),
              ...linkSectionPlugin(theme)
            }
          },
          buttonList: [
            ['undo', 'redo'],
            ['font', 'fontSize', 'formatBlock'],
            ['bold', 'underline', 'italic'],
            ['fontColor', 'hiliteColor', 'tableCellBackgroundColour'],
            ['outdent', 'indent'],
            ['align', 'list'],
            ['table', 'link', 'linkSection', 'image'],
            ['codeView', 'fullScreen']
          ],
          // Plugin settings
          font: ['Arial', 'Courier New,Courier', 'Open Sans', 'Poppins'],
          fontSize: [8, 10, 12, 13, 14, 18, 24, 36],
          defaultStyle: 'font-family: Open Sans; font-size: 13px;',
          formats: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
          colorList: [
            [
              theme.primary,
              theme.secondary,
              theme.positive,
              theme.danger,
              theme.warning,
              theme.grey,
              theme.white
            ]
          ],
          imageFileInput: false,
          // Styles
          height: 'auto',
          resizingBar: false
        }}
        {...props}
      />
      {sectionSelect && (
        <SectionModal
          imports={imports}
          handleClose={handleCloseSectionModal}
          handleSubmit={handleInsertSection}
          currentReference={window.getSelection().toString()}
        />
      )}
    </>
  );
};

WysiwygEditor.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  imports: PropTypes.array
};

export default WysiwygEditor;
