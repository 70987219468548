import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';

import { Flex, Span } from '@ubisend/pulse-components';

import { useVideoProvider } from '../../../../hooks/index';
import Bubble from './Bubble';

const VideoContainer = styled.div`
  ${tw`bg-black max-w-full relative`}
  padding-bottom: 56.25%;
  & > iframe {
    ${tw`w-full h-full absolute inset-0`}
    margin-bottom: -5px;
  }
`;

const Video = ({ content, direction }) => {
  const ProviderFrame = useVideoProvider(content.provider);

  return (
    <Flex fat right={direction === 'recievedMessage'}>
      <Bubble direction={direction} style={{ padding: 0, width: '75%' }}>
        <VideoContainer>
          <ProviderFrame
            url={content.url}
            aria-label={content.content_description}
          />
        </VideoContainer>
        {content.text && (
          <Flex padSm>
            <Span fat>{content.text}</Span>
          </Flex>
        )}
      </Bubble>
    </Flex>
  );
};

Video.propTypes = {
  content: PropTypes.shape({
    url: PropTypes.string.isRequired,
    provider: PropTypes.oneOf(Object.keys(useVideoProvider.PROVIDERS))
      .isRequired,
    text: PropTypes.string,
    content_description: PropTypes.string
  }).isRequired,
  direction: PropTypes.string,
  type: PropTypes.string
};

export default Video;
