const changeLanguage = (axios, { languageId, isDemo }) => {
  if (isDemo) {
    return axios.put('languages', {
      language_id: languageId,
      demo: true
    });
  }
  return axios.put('languages', { language_id: languageId });
};

export { changeLanguage };
