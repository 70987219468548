import React, { useMemo } from 'react';

import { useBot } from '../../../../hooks/index';
import { Lightbox, ContentModal } from './ResponseTypes/index';

const iframeModalContentTypes = {
  image: Lightbox,
  content: ContentModal
};

const IframeModal = () => {
  const { iframeModal, setIframeModal, onIframeModalClose } = useBot();

  const handleClose = () => {
    setIframeModal('');
    onIframeModalClose();
  };

  const Component = useMemo(() => iframeModalContentTypes[iframeModal.type], [
    iframeModal.type
  ]);

  return <Component content={iframeModal} onClose={handleClose} />;
};

export default IframeModal;
