import tw, { styled } from 'twin.macro';

const NodeFooter = styled.div`
  ${tw`flex justify-start w-full p-3 absolute bottom-0`}
  & i {
    ${tw`flex`}
  }
`;

export default NodeFooter;
