import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Flex,
  Label,
  TextInput,
  useNotification,
  Span
} from '@ubisend/pulse-components';
import { useMutation, useQueryClient } from '@ubisend/pulse-hooks';

import { updateSettings as updateSettingsApi } from '../api/index';

const SmsDataForm = ({ number, token, prevBaseUri, noSecret }) => {
  const [phoneNumber, setPhoneNumber] = useState(number ?? '');
  const [apiKey, setApiKey] = useState(token ?? '');
  const [apiSecret, setSecretKey] = useState('');
  const [baseUri, setBaseUri] = useState(prevBaseUri ?? '');
  const [showApiSecret, setShowApiSecret] = useState(noSecret);

  const { showSuccess } = useNotification();
  const queryClient = useQueryClient();

  const { mutate: updateSettings } = useMutation(updateSettingsApi, {
    onSuccess: () => {
      showSuccess('Successfully updated sms settings');
      queryClient.invalidateQueries('sms/settings');
    }
  });

  const handlePhoneNumberChange = event => {
    setPhoneNumber(event.target.value);
  };

  const handleBaseUriChange = event => {
    setBaseUri(event.target.value);
  };

  const handleApiKeyChange = event => {
    setApiKey(event.target.value);
  };

  const handleApiSecretChange = event => {
    setSecretKey(event.target.value);
  };

  const handelSubmit = () => {
    if (showApiSecret) {
      updateSettings({
        phone_number: phoneNumber,
        api_key: apiKey,
        api_secret: apiSecret,
        base_uri: baseUri
      });
    } else {
      updateSettings({
        phone_number: phoneNumber,
        api_key: apiKey,
        base_uri: baseUri
      });
    }
  };

  const handleShowClientSecret = () => setShowApiSecret(true);

  return (
    <Flex col>
      <Flex col mb>
        <Label htmlFor="sms_number_input">Phone Number</Label>
        <TextInput
          id="sms_number_input"
          data-testid="sms-number-input"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          placeholder="447451123456"
        />
      </Flex>
      <Flex col mb>
        <Label htmlFor="sms_base_uri_input">Base Url</Label>
        <TextInput
          id="sms_base_uri_input"
          data-testid="sms-base-uri-input"
          value={baseUri}
          onChange={handleBaseUriChange}
          placeholder="https://uk.sopranodesign.com"
        />
      </Flex>
      <Flex col mb>
        <Label htmlFor="sms_key_input">Api Key</Label>
        <TextInput
          id="sms_key_input"
          data-testid="sms-key-input"
          value={apiKey}
          onChange={handleApiKeyChange}
          placeholder="Your api key"
        />
      </Flex>
      <Flex col mb>
        <Label htmlFor="sms_secret_input">Api Id</Label>
        <Flex between center>
          {showApiSecret ? (
            <TextInput
              id="sms_secret_input"
              data-testid="sms-secret-input"
              value={apiSecret}
              onChange={handleApiSecretChange}
              placeholder="Your api id"
            />
          ) : (
            <Span>Api secret credentials have been set</Span>
          )}
          {!showApiSecret && (
            <Flex top>
              <Button
                onClick={handleShowClientSecret}
                data-testid="change-sms-secret">
                Change Credentials
              </Button>
            </Flex>
          )}
        </Flex>
      </Flex>
      <Flex right mt>
        <Button
          variant="primary"
          onClick={handelSubmit}
          data-testid="submit-sms-data">
          Save
        </Button>
      </Flex>
    </Flex>
  );
};

SmsDataForm.propTypes = {
  number: PropTypes.string,
  token: PropTypes.string,
  noSecret: PropTypes.bool,
  prevBaseUri: PropTypes.string
};

export default SmsDataForm;
