import React from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  Panel,
  OneQuarterLayout,
  InfoSection,
  Divider
} from '@ubisend/pulse-components';

import InitialOpenSettings from './InitialOpenSettings';
import RenderSettings from './RenderSettings';

const DisplaySettings = props => {
  return (
    <Flex top>
      {props.titlePosition === 'left' && (
        <OneQuarterLayout>
          <InfoSection
            title="Display Rules"
            info="Create smart rules to control the visibility of your chat widget."
          />
        </OneQuarterLayout>
      )}
      <Panel>
        {props.titlePosition === 'top' && (
          <>
            <InfoSection
              mtNone
              title="Display Rules"
              info="Create
             smart rules to control the visibility of your chat widget."
            />
            <Divider />
          </>
        )}
        <Flex col ySpace>
          <RenderSettings />
          <InitialOpenSettings />
        </Flex>
      </Panel>
    </Flex>
  );
};

DisplaySettings.propTypes = {
  titlePosition: PropTypes.string
};

DisplaySettings.defaultProps = {
  titlePosition: 'left'
};

export default DisplaySettings;
