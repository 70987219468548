import React from 'react';

import { FormGroup, Label } from '@ubisend/pulse-components';
import {
  TemplatingTextInput,
  TemplatingTextArea
} from '@ubisend/pulse-templating';

import { useComposer } from '../../../../hooks/index';

const EmbedInput = () => {
  const {
    response: { content },
    dispatch
  } = useComposer();

  const handleTextChange = value => {
    dispatch({
      type: 'UPDATE_CONTENT',
      newContent: { ...content, text: value }
    });
  };

  const handleButtonChange = value => {
    dispatch({ type: 'UPDATE_CONTENT', newContent: { button: value } });
  };

  const handleTitleChange = value => {
    dispatch({
      type: 'UPDATE_CONTENT',
      newContent: { ...content, title: value }
    });
  };

  const handleUrlChange = value => {
    dispatch({
      type: 'UPDATE_CONTENT',
      newContent: { ...content, url: value }
    });
  };

  const handleDescriptionChange = value => {
    dispatch({
      type: 'UPDATE_CONTENT',
      newContent: { ...content, content_description: value }
    });
  };

  return (
    <>
      <FormGroup>
        <Label htmlFor="text">Message</Label>
        <TemplatingTextArea
          id="text"
          name="text"
          placeholder="Click below to view the embedded link"
          value={content.text}
          onChange={handleTextChange}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="button-label">Button Label</Label>
        <TemplatingTextInput
          id="button-label"
          name="button-label"
          value={content.button}
          onChange={handleButtonChange}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="title">Title</Label>
        <TemplatingTextInput
          id="title"
          name="title"
          placeholder="My Embed"
          value={content.title}
          onChange={handleTitleChange}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="url">URL</Label>
        <TemplatingTextInput
          id="url"
          name="url"
          placeholder="https://my-embed-link.com"
          value={content.url}
          onChange={handleUrlChange}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="content_description">Content Description</Label>
        <TemplatingTextInput
          id="content_description"
          name="content_description"
          placeholder="Document contains a spreadsheet"
          value={content.content_description}
          onChange={handleDescriptionChange}
        />
      </FormGroup>
    </>
  );
};

export default EmbedInput;
