import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';

import { spacing } from '../styles';
import Paragraph from '../Text/Paragraph';
import { Flex } from '../Layout/index';

const TitleContainer = styled(Flex)`
  ${tw`mr-10`}
  ${props => (props.mtNone ? tw`mt-0` : tw`mt-4`)}
  & > h2 {
    ${tw`my-0 font-semibold`}
  }
  ${spacing}
`;

const InfoContainer = styled(Paragraph)`
  ${tw`pr-4 pb-2 leading-normal`}
`;

const InfoSection = ({ title, info, actions, mtNone }) => (
  <>
    <TitleContainer mtNone={mtNone} between center>
      <h2>{title}</h2>
    </TitleContainer>
    <InfoContainer sm secondary>
      {info}
    </InfoContainer>
    {actions}
  </>
);

InfoSection.propTypes = {
  title: PropTypes.string,
  info: PropTypes.string,
  actions: PropTypes.element,
  mtNone: PropTypes.bool
};

export default InfoSection;
