import tw, { styled } from 'twin.macro';

const PreviewExpandButton = styled.button`
  ${tw`w-10 h-10 bg-gray-300 rounded-full flex items-center justify-center hover:bg-white z-10 ml-4 
  font-poppins font-semibold outline-none mt-2 cursor-pointer`}
  border: 1px solid #493C61;
  box-sizing: border-box;
`;

export default PreviewExpandButton;
