import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Select, Label } from '@ubisend/pulse-components';

import { getPages } from '../api/settings';

const SelectPage = ({ user, handlePageSelect }) => {
  const [pages, setPages] = useState();

  const fetchPages = useCallback(async () => {
    const {
      data: { data: pages }
    } = await getPages(user.accessToken);

    setPages(pages);
  }, [user.accessToken]);

  useEffect(() => {
    if (user) {
      fetchPages();
    }
  }, [user, fetchPages]);

  if (!pages) {
    return null;
  }
  return (
    <>
      <Label>Page to connect</Label>
      <Select
        style={{ flexGrow: 1, flexShrink: 0 }}
        aria-label="facebook-page-select"
        options={pages.map(({ name, id, access_token }) => {
          return { value: { id, access_token }, label: name };
        })}
        onChange={handlePageSelect}
      />
    </>
  );
};

SelectPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.any.isRequired,
  handlePageSelect: PropTypes.func.isRequired
};

export default SelectPage;
