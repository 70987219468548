import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';

import TooltipPopup from './TooltipPopup';
import Flex from '../Layout/Flex';
import useTooltip, { POSITIONS } from '../../hooks/useTooltip';

const TooltipContainer = styled.div`
  ${tw`inline-block relative`}
`;

const TooltipIcon = styled.div`
  ${tw`cursor-default rounded-full w-5 h-5 flex justify-center items-center leading-none font-extrabold text-sm ml-2 mr-2`}
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.white};
`;

const TooltipContent = ({ children }) => (
  <Flex pl pr ptSm pbSm>
    {children}
  </Flex>
);

const Tooltip = ({
  children,
  position = POSITIONS.BOTTOM,
  tooltip: passedTooltip
}) => {
  const [visible, setVisible] = useState();

  const tooltip = useTooltip({
    visible,
    position,
    tooltip: passedTooltip
  });

  return (
    <TooltipContainer
      ref={tooltip.ref}
      onFocus={() => setVisible(true)}
      onBlur={() => setVisible(false)}
      onMouseOver={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      tabIndex="0">
      {children}
      <TooltipPopup {...tooltip.props} />
    </TooltipContainer>
  );
};

Tooltip.POSITIONS = POSITIONS;
Tooltip.Icon = TooltipIcon;
Tooltip.Content = TooltipContent;
Tooltip.propTypes = {
  position: TooltipPopup.propTypes.position,
  tooltip: TooltipPopup.propTypes.tooltip
};

export default Tooltip;
