import React from 'react';
import tw, { styled } from 'twin.macro';
import chroma from 'chroma-js';
import PropTypes from 'prop-types';

import { motion } from '@ubisend/framer-motion';
import { Flex, FormGroup, Heading3, Span } from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';

import Modal from './Modal';

const IconButton = styled(motion.button)`
  ${tw`flex flex-col p-4 items-center justify-center cursor-pointer border-solid  rounded text-base`}
  border-color: #dddbdf;
  flex-grow: 1;
  flex-basis: 0;
  :first-child {
    margin-right: 1rem;
  }
  &:hover {
    background: ${chroma('#F9F9F9').darken(0.2)};
    box-shadow: 0px 4px 8px -4px #908a9e;
  }
  &:focus:not(:focus-visible) {
    border: 2px #908a9e solid;
    outline: none;
  }
  &:focus-visible {
    outline: 2px solid #908a9e;
  }
`;

const ConnectorNewStep = ({ handleClose, submitNewStep }) => {
  return (
    <Modal
      header={'New step'}
      handleClose={handleClose}
      style={{ maxWidth: '100%', width: '30rem' }}>
      <Flex center style={{ padding: '1.5rem' }}>
        <FormGroup
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
          }}>
          <Heading3 mb tinyText semiBold trackingWide>
            SELECT A STEP TYPE:
          </Heading3>
          <Flex>
            <IconButton
              whileHover={{
                scale: 1.05,
                transition: {
                  type: 'spring',
                  stiffness: 2000,
                  damping: 100,
                  restDelta: 0.001
                }
              }}
              onClick={() => {
                submitNewStep('messageStep');
              }}
              data-testid="message-icon-button">
              <Icon height="2rem" type="chatAlt" outline />
              <Span>Message</Span>
            </IconButton>
            <IconButton
              whileHover={{
                scale: 1.05,
                transition: {
                  type: 'spring',
                  stiffness: 2000,
                  damping: 100,
                  restDelta: 0.001
                }
              }}
              onClick={() => {
                submitNewStep('automatedStep');
              }}
              data-testid="robo-icon-button">
              <Icon height="2rem" type="robo" outline />
              <Span>Automated</Span>
            </IconButton>
          </Flex>
        </FormGroup>
      </Flex>
    </Modal>
  );
};

ConnectorNewStep.propTypes = {
  handleClose: PropTypes.func.isRequired,
  submitNewStep: PropTypes.func.isRequired
};

export default ConnectorNewStep;
