import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

import { Flex } from '@ubisend/pulse-components';

import FilePreviewDetails from '../../FilePreviewDetails';

const Image = styled(Flex)`
  ${tw`cursor-pointer h-full w-full`}
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const ImagePreview = ({ file }) => (
  <Flex col tall>
    <Image style={{ backgroundImage: `url(${file.link})` }} />
    <FilePreviewDetails file={file} />
  </Flex>
);

ImagePreview.propTypes = {
  file: PropTypes.shape({
    link: PropTypes.string.isRequired
  }).isRequired
};

export { Image };
export default ImagePreview;
