import axios from 'axios';

const updateConversation = ({ id, conversation }) => {
  return axios.put(`conversations/builder/${id}`, conversation);
};

const createStep = ({ conversationId, step }) => {
  return axios.post(`conversations/builder/${conversationId}/steps`, step);
};

const updateStep = ({ conversationId, stepId, step }) => {
  return axios.put(
    `conversations/builder/${conversationId}/steps/${stepId}`,
    step
  );
};

const updateStepPosition = ({ conversationId, stepId, position }) => {
  return axios.put(
    `conversations/builder/${conversationId}/steps/${stepId}/position`,
    position
  );
};

const deleteStep = ({ conversationId, stepId }) => {
  return axios.delete(
    `conversations/builder/${conversationId}/steps/${stepId}`
  );
};

export {
  updateConversation,
  createStep,
  updateStep,
  updateStepPosition,
  deleteStep,
};
