import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  FormGroup,
  Label,
  TextInput,
  Button,
  Checkbox,
  Select
} from '@ubisend/pulse-components';

import { useBotSettings } from '../../../hooks';

const WidgetDetails = ({
  handleSubmit,
  duplicating,
  initialChecked,
  loading,
  settings = null,
  prefilledDuplicateValue = false
}) => {
  const [name, setName] = useState(
    settings && !duplicating ? settings.dashboard_name : ''
  );
  const [isChecked, setIsChecked] = useState(initialChecked);
  const [duplicateOption, setDuplicateOption] = useState(
    prefilledDuplicateValue && settings.id
  );

  const { allSettings } = useBotSettings();

  const handleNameChange = event => {
    setName(event.target.value);
  };

  const onSubmit = event => {
    event.preventDefault();

    handleSubmit({
      name: name,
      duplicateId: duplicateOption ? duplicateOption : null
    });
  };

  useEffect(() => {
    setIsChecked(initialChecked);
  }, [initialChecked]);

  const handleDuplicateCheckChange = e => {
    const checkValue = e.target.checked;
    if (checkValue) {
      setDuplicateOption(allSettings[0].id);
    } else {
      setDuplicateOption(null);
    }
    setIsChecked(checkValue);
  };

  const handleDuplicateOptionChange = event => {
    setDuplicateOption(event.value);
  };

  const widgetOptions = () => {
    const widgetOptions = [];
    allSettings.map(setting => {
      widgetOptions.push({ label: setting.dashboard_name, value: setting.id });
    });
    return widgetOptions;
  };

  const findValue = id => {
    const setting = allSettings.find(setting => setting.id === id);
    return { value: setting.id, label: setting.dashboard_name };
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <Flex ySpace col mb>
          <FormGroup>
            <Label htmlFor="name">Widget name</Label>
            <Flex xSpace>
              <TextInput
                id="name"
                placeholder="My widget"
                aria-label="new-setting-name"
                value={name}
                onChange={handleNameChange}
              />
            </Flex>
          </FormGroup>
        </Flex>
        {duplicating && (
          <>
            <Flex ySpace col mb>
              <FormGroup>
                <Checkbox
                  checked={isChecked}
                  label="Duplicate existing widget settings"
                  onChange={handleDuplicateCheckChange}
                />
              </FormGroup>
            </Flex>
            <Flex ySpace col mb>
              <FormGroup>
                {isChecked && prefilledDuplicateValue && (
                  <Select
                    value={
                      duplicateOption
                        ? findValue(duplicateOption)
                        : findValue(settings.id)
                    }
                    options={widgetOptions()}
                    onChange={handleDuplicateOptionChange}
                    aria-label="prefilled-duplicate-setting"
                  />
                )}
                {isChecked && !prefilledDuplicateValue && (
                  <Select
                    options={widgetOptions()}
                    onChange={handleDuplicateOptionChange}
                    aria-label="duplicate-setting"
                    value={
                      duplicateOption
                        ? findValue(duplicateOption)
                        : findValue(allSettings[0].id)
                    }
                  />
                )}
              </FormGroup>
            </Flex>
          </>
        )}
        <Flex right>
          <Button
            type="submit"
            variant="primary"
            loading={loading}
            data-testid={'save-new-setting'}
            disabled={name ? false : true}
            icon="save">
            Save
          </Button>
        </Flex>
      </form>
    </>
  );
};

WidgetDetails.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  duplicating: PropTypes.bool,
  initialChecked: PropTypes.bool,
  settings: PropTypes.object,
  widget: PropTypes.shape({
    id: PropTypes.integer,
    name: PropTypes.string
  }),
  prefilledDuplicateValue: PropTypes.bool
};

export default WidgetDetails;
