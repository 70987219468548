import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import chroma from 'chroma-js';

import { Flex, Tooltip } from '@ubisend/pulse-components';

import { useTemplating } from '../../../hooks/index';
import { LINE_HEIGHT } from '../../../constants';

const LineNumberContainer = styled(Flex)`
  height: ${LINE_HEIGHT};
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  ${props => {
    if (props.error) {
      return `
        color: ${props.theme.danger};
        background-color: ${chroma(props.theme.danger).alpha(0.25)}
      `;
    }
  }}
`;

const Container = styled(Flex)`
  ${tw`bg-gray-300`}
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
`;

const LineNumber = ({ number }) => {
  const templating = useTemplating();

  if (templating.error?.line === number) {
    return (
      <Tooltip
        position={Tooltip.POSITIONS.RIGHT}
        tooltip={<Tooltip.Content>{templating.error.message}</Tooltip.Content>}>
        <LineNumberContainer center light error plSm prSm>
          {number}
        </LineNumberContainer>
      </Tooltip>
    );
  }

  return (
    <LineNumberContainer center light plSm prSm>
      {number}
    </LineNumberContainer>
  );
};

LineNumber.propTypes = {
  number: PropTypes.number.isRequired
};

const LineNumbers = () => {
  const templating = useTemplating();

  return (
    <Container col ptSm plSm prSm borderRight>
      {new Array(templating.lineCount).fill(null).map((_, key) => (
        <LineNumber key={key + 1} number={key + 1} />
      ))}
    </Container>
  );
};

export default LineNumbers;
