import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

import {
  SecondarySidebarRoot as RootLink,
  Button
} from '@ubisend/pulse-components';

import SidebarItem, { isActive } from './SidebarItem';
import { useDocLocation } from '../hooks/index';

const hasActiveItem = (pathname, items) => {
  if (!items) {
    return false;
  }

  return Boolean(
    items.find(item => {
      return (
        isActive(pathname, item.url) || hasActiveItem(pathname, item?.children)
      );
    })
  );
};

const LinkContainer = styled.div`
  ${tw`flex p-2 justify-between items-center`}
  & svg,
   & i {
    width: 20px;
    height: 20px;
  }
`;

const noop = () => undefined;

const RootSidebarItem = ({ items, label, to, open, handleOpen }) => {
  const location = useDocLocation();

  const hasItems = items?.length > 0;

  return (
    <>
      <LinkContainer>
        <RootLink
          onClick={hasItems ? handleOpen : noop}
          active={
            isActive(location.pathname, to) ||
            hasActiveItem(location.pathname, items)
          }
          to={`${location.base_url}docs/${to}`}>
          {label}
        </RootLink>
        {hasItems && (
          <Button
            aria-label={open ? 'Close sidebar item' : 'Open sidebar item'}
            variant="inline"
            onClick={handleOpen}
            icon={open ? 'cheveronUp' : 'cheveronDown'}
          />
        )}
      </LinkContainer>
      {hasItems &&
        open &&
        items.map((item, key) => (
          <SidebarItem
            key={key}
            items={item.children}
            label={item.label}
            to={`${location.base_url}docs/${item.url}`}
          />
        ))}
    </>
  );
};

RootSidebarItem.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  items: PropTypes.array,
  label: PropTypes.string,
  to: PropTypes.string
};

export { hasActiveItem };
export default RootSidebarItem;
