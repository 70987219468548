import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import {
  Flex,
  FormGroup,
  Label,
  TextInput,
  Button,
  Checkbox,
  Tooltip,
  useNotification
} from '@ubisend/pulse-components';

import Modal from '../../../Modal';
import { useBuilder } from '../../../../hooks/index';
import { updateStep } from '../../../../api/index';

const EditStepNode = ({ handleClose, node }) => {
  const [title, setTitle] = useState(node.base.title);
  const [sensitive, setSensitive] = useState(node.base.sensitive);

  const { showSuccess } = useNotification();
  const { conversation, dispatch } = useBuilder();

  const { isLoading, mutate } = useMutation(updateStep, {
    onSuccess: ({ data }) => {
      dispatch({
        type: 'UPDATE_STEP',
        stepId: node.id,
        stepDetails: data.data
      });
      showSuccess('Successfully updated workflow step');
      handleClose();
    }
  });

  const valid = useMemo(() => {
    return title.length > 0;
  }, [title]);

  const handleTitleChange = event => {
    setTitle(event.target.value);
  };

  const handleSensitiveChange = () => {
    setSensitive(sensitive => !sensitive);
  };

  const hadleSubmit = event => {
    event.preventDefault();

    if (valid && !isLoading) {
      mutate({
        conversationId: conversation.id,
        stepId: node.id,
        step: { title, sensitive }
      });
    }
  };

  return (
    <Modal header="Edit step" handleClose={handleClose}>
      <form onSubmit={hadleSubmit}>
        <Flex pad fat col>
          <FormGroup>
            <Label htmlFor="name">Name</Label>
            <TextInput
              id="name"
              value={title}
              placeholder="My steps name"
              onChange={handleTitleChange}
            />
          </FormGroup>
          <FormGroup>
            <Flex center>
              <Checkbox
                checked={sensitive}
                onChange={handleSensitiveChange}
                label="Sensitive information at this step"
              />
              <Tooltip
                position={Tooltip.POSITIONS.TOP}
                tooltip={
                  <Tooltip.Content>
                    If a step is marked as sensitive, no responses from the
                    subscriber will be stored.
                  </Tooltip.Content>
                }>
                <Tooltip.Icon>?</Tooltip.Icon>
              </Tooltip>
            </Flex>
          </FormGroup>
          <Flex right>
            <Button
              variant="primary"
              disabled={!valid || isLoading}
              type="submit">
              Save
            </Button>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};

EditStepNode.propTypes = {
  handleClose: PropTypes.func.isRequired,
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
    base: PropTypes.shape({
      title: PropTypes.string.isRequired,
      sensitive: PropTypes.bool.isRequired
    }).isRequired
  }).isRequired
};

export default EditStepNode;
