import {
  HEADER_PADDING_X,
  HEADER_FONT_SIZE,
  HEADER_PADDING_Y
} from './constants';

const nodeHeading = (ctx, x, y, node, botTheme) => {
  const nodeText = node.style === 'automatedStep' ? 'AUTOMATED' : 'MESSAGE';

  ctx.save();
  ctx.fillStyle = 'black';
  ctx.font = '600 12px Poppins';
  ctx.fillText(
    nodeText,
    x + HEADER_PADDING_X,
    y + HEADER_FONT_SIZE + HEADER_PADDING_Y
  );
  ctx.fillStyle = botTheme.grey;
  ctx.font = '400 14px Open Sans';
  ctx.fillText(
    node.base.title.length < 25
      ? node.base.title
      : `${node.base.title.slice(0, 25)}...`,
    x + HEADER_PADDING_X,
    y + (HEADER_FONT_SIZE + HEADER_PADDING_Y) * 2
  );
  ctx.restore();
};

export default nodeHeading;
