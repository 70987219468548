import tw, { styled } from 'twin.macro';

const PreviewConditions = styled.div`
  ${tw`text-gray-500 my-2 flex-col items-center flex`}
  & div p {
    ${tw`m-0`}
  }
  & div > p:not(:last-child) {
    ${tw`mb-2`}
  }
`;

export default PreviewConditions;
