import Alerts from './Alerts';
import Builder from './Builder';
import Conversations from './Conversations';
import CustomMetrics from './CustomMetrics';
import Dashboard from './Dashboard';
import Emails from './Emails';
import Faqs from './Faqs';
import Feedback from './Feedback';
import Files from './Files';
import Generic from './Generic';
import Integrations from './Integrations';
import Knowledgebase from './Knowledgebase';
import Links from './Links';
import LiveChat from './LiveChat';
import Logs from './Logs';
import SemanticSearch from './SemanticSearch';
import Tickets from './Tickets';
import Training from './Training';
import Variables from './Variables';

const emptyStatePlaceholder = {
  alerts: Alerts,
  builder: Builder,
  conversations: Conversations,
  custommetrics: CustomMetrics,
  dashboard: Dashboard,
  emails: Emails,
  faqs: Faqs,
  feedback: Feedback,
  files: Files,
  generic: Generic,
  integrations: Integrations,
  knowledgebase: Knowledgebase,
  links: Links,
  livechat: LiveChat,
  logs: Logs,
  semanticsearch: SemanticSearch,
  tickets: Tickets,
  training: Training,
  variables: Variables
};

export default emptyStatePlaceholder;
