import React from 'react';
import tw from 'twin.macro';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Header,
  CloseWidgetButton,
  BackArrowButton,
  Name
} from '../Header/index';
import { useMessage, useBot } from '../../../../hooks/index';

const Embed = () => {
  const message = useMessage();
  const { showClose } = useBot();

  const { t } = useTranslation(['bots', 'converse']);

  const history = useHistory();

  const closeWidget = () => {
    history.push('/');
  };

  const handleBack = () => history.goBack();

  return (
    <>
      <Header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        <BackArrowButton
          aria-label={t('back_arrow_button_label', { ns: 'bots' })}
          onClick={handleBack}
        />
        <Name size={tw`text-xl`}>{message.content.title}</Name>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          {showClose && (
            <CloseWidgetButton
              aria-label={t('close_widget_button_label', { ns: 'converse' })}
              onClick={closeWidget}
            />
          )}
        </div>
      </Header>
      <div style={{ flexGrow: '1', padding: '1rem', overflowY: 'auto' }}>
        <iframe
          title={message.content.title}
          frameBorder="0"
          src={message.content.url}
          allowFullScreen={true}
          width={'100%'}
          height={'100%'}
        />
      </div>
    </>
  );
};

export default Embed;
