import React, { useState } from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

import {
  Flex,
  Label,
  Button,
  TextInput,
  Span
} from '@ubisend/pulse-components';

const StyledFlex = styled(Flex)`
  ${tw`flex-col w-full mr-4`}
  flex: 0 0 150px;
  margin-top: auto;
`;

const dateTimeTypes = [
  { label: 'Month', value: 'month' },
  { label: 'Day', value: 'day' },
  { label: 'Hour', value: 'hour' },
  { label: 'Minute', value: 'minute' }
];

import DateModifyTypeSelect from './DateModifyTypeSelect';
import DateModifyPeriodSelect from './DateModifyPeriodSelect';
import DateModifyRangeSelect from './DateModifyRangeSelect';

const FlexRows = ({
  content,
  tableRowRemove,
  onValUpdate,
  onDateModifySelectChange,
  onDateModifyRangeChange
}) => {
  return content.map((data, index) => {
    return (
      <Flex key={data.id}>
        <StyledFlex>
          {index === 0 && (
            <Flex top>
              <Label htmlFor="date-modify-type">Type</Label>
            </Flex>
          )}
          <DateModifyTypeSelect
            id="date-modify-type"
            value={data.dateModifyType}
            onChange={event =>
              onDateModifySelectChange(index, event, 'dateModifyType')
            }
          />
        </StyledFlex>
        {content[index].dateModifyType && (
          <>
            <Flex xSpace fat bottom mr>
              <Span noWrap mbSm>
                {content[index].dateModifyType === 'dynamic'
                  ? 'Modify to'
                  : content[index].dateModifyType === ''
                  ? ' '
                  : ' Set to'}
              </Span>
            </Flex>

            <Flex col fat mr>
              {index === 0 && <Label htmlFor="date-modify-value">Value</Label>}
              <TextInput
                id="date-modify-value"
                aria-label={`row-value-${index}`}
                placeholder="Value"
                value={data.dateModifyValue}
                onChange={event => onValUpdate(index, event, 'dateModifyValue')}
              />
            </Flex>

            <Flex col fat mr>
              {index === 0 && (
                <Flex top>
                  <Label htmlFor="date-modify-period">Period</Label>
                </Flex>
              )}
              <DateModifyPeriodSelect
                id="date-modify-period"
                options={
                  content[index]?.dateModifyType === 'dynamic'
                    ? dateTimeTypes.map(item => ({
                        ...item,
                        label: item.label + 's'
                      }))
                    : dateTimeTypes.filter(
                        option =>
                          !content.some(
                            item =>
                              item.dateModifyType !== 'dynamic' &&
                              item.dateModifyPeriod === option.value
                          )
                      )
                }
                value={data.dateModifyPeriod}
                onChange={event =>
                  onDateModifySelectChange(index, event, 'dateModifyPeriod')
                }
              />
            </Flex>

            <StyledFlex>
              {content[index].dateModifyType === 'dynamic' ? (
                <>
                  <Flex hidden>
                    <Label htmlFor="date-modify-range">Range</Label>
                  </Flex>
                  <DateModifyRangeSelect
                    id={`date-modify-range`}
                    value={data.dateModifyRange}
                    onChange={event =>
                      onDateModifyRangeChange(index, event, 'dateModifyRange')
                    }
                  />
                </>
              ) : (
                <Flex col fat></Flex>
              )}
            </StyledFlex>

            <Flex grow bottom>
              <Button
                selfBottom
                icon="trash"
                colour="danger"
                variant="secondary"
                aria-label={`row-delete-${index}`}
                onClick={() => tableRowRemove(index)}
                disabled={content.length <= 1}>
                Delete
              </Button>
            </Flex>
          </>
        )}
      </Flex>
    );
  });
};

const DateModifyInput = ({ content, onChange }) => {
  const [uniqueIdCounter, setUniqueIdCounter] = useState(0);
  const addRowTable = () => {
    const newId = uniqueIdCounter + 1;
    setUniqueIdCounter(newId);
    const data = [
      ...content.data,
      {
        id: newId,
        dateModifyType: '',
        dateModifyValue: '',
        dateModifyPeriod: '',
        dateModifyRange: ''
      }
    ];
    onChange({ data });
  };

  const tableRowRemove = index => {
    const data = [...content.data];
    data.splice(index, 1);
    onChange({ data });
  };

  const onValUpdate = (i, event, id) => {
    const data = [...content.data];
    data[i] = { ...data[i], [id]: event.target.value };
    onChange({ data });
  };

  const onDateModifySelectChange = (i, event, id) => {
    const data = [...content.data];
    data[i] = { ...data[i], [id]: event.value };
    onChange({ data });
  };

  const onDateModifyRangeChange = (i, event, id) => {
    const data = [...content.data];
    data[i] = { ...data[i], [id]: event.value };
    onChange({ data });
  };

  return (
    <>
      <FlexRows
        content={content.data}
        tableRowRemove={tableRowRemove}
        onValUpdate={onValUpdate}
        onDateModifySelectChange={onDateModifySelectChange}
        onDateModifyRangeChange={onDateModifyRangeChange}
      />
      <Flex>
        <Button variant="secondary" onClick={addRowTable}>
          + Add new
        </Button>
      </Flex>
    </>
  );
};

DateModifyInput.propTypes = {
  content: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        dateModifyValue: PropTypes.string,
        dateModifyPeriod: PropTypes.string,
        dateModifyRange: PropTypes.string
      })
    )
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

export default DateModifyInput;
