import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

import Bubble from '../Bubble';
import { useTheme } from '../../../../../hooks/index';

const names = {
  'global-pay': 'Global Pay'
};

const Container = styled.div`
  ${tw`flex justify-between items-center`}
`;

const Card = props => (
  <svg {...props} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
    />
  </svg>
);

const StyledCard = styled(Card)`
  ${tw`w-10 h-10 ml-2`}
  & path {
    fill: ${props => props.colour};
  }
`;

const PaymentPreview = ({ content }) => {
  const name = names[content.processor] || 'Payment';
  const theme = useTheme();

  return (
    <Bubble direction="sentMessage">
      <Container>
        {/* eslint-disable i18next/no-literal-string */}
        {name} Gateway
        {/* eslint-enable i18next/no-literal-string */}
        <StyledCard colour={theme.sentMessage.text} />
      </Container>
    </Bubble>
  );
};

PaymentPreview.propTypes = {
  content: PropTypes.shape({
    processor: PropTypes.oneOf(['global-pay'])
  })
};

export default PaymentPreview;
