import React from 'react';
import PropTypes from 'prop-types';

import {
  FormGroup,
  Button,
  Label,
  TextInput,
  Flex,
  FormHeading,
  Divider,
  InnerPanel
} from '@ubisend/pulse-components';
import { DeactivatedQueryProvider } from '@ubisend/pulse-volt';

import ComposerTypeSelect from './ComposerTypeSelect';
import ComposerContent from './ComposerContent';
import Composer from './Composer';
import { useComposerReducer } from '../reducers/index';
import { ComposerContext } from '../Contexts/index';
import subjects from './Subjects/index';

const ComposerDetails = ({ initialComposer, handleSubmit }) => {
  const composer = useComposerReducer({
    initialComposer,
    subjects: [subjects.integration, subjects.lookup]
  });

  const onSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    if (!composer.valid) {
      return false;
    }

    handleSubmit(composer.form);
  };

  const handleNameChange = event => {
    const name = event.target.value;

    composer.dispatch({ type: composer.TYPES.UPDATE_NAME, name });
  };

  const handleTypeChange = ({ value }) => {
    composer.dispatch({
      type: composer.TYPES.UPDATE_TYPE,
      composer_type: value
    });
  };

  return (
    <ComposerContext.Provider value={composer}>
      {composer.type !== 'dynamic' && (
        <>
          <FormHeading>Composer Preview</FormHeading>
          <Flex mb fat grow>
            <DeactivatedQueryProvider>
              <Composer type={composer.type} content={composer.content} />
            </DeactivatedQueryProvider>
          </Flex>
          <Divider fullWidth />
        </>
      )}
      <form onSubmit={onSubmit}>
        <Flex between top>
          <FormHeading>Settings</FormHeading>
          <ComposerTypeSelect
            aria-label="composer-type"
            value={composer.type}
            onChange={handleTypeChange}
          />
        </Flex>
        <InnerPanel mb>
          <FormGroup>
            <Label htmlFor="name">Name</Label>
            <TextInput
              id="name"
              placeholder="My Custom Composer"
              value={composer.name}
              onChange={handleNameChange}
            />
          </FormGroup>
          <ComposerContent />
        </InnerPanel>
        <Flex right>
          <Button disabled={!composer.valid} type="submit" variant="primary">
            Save
          </Button>
        </Flex>
      </form>
    </ComposerContext.Provider>
  );
};

ComposerDetails.propTypes = {
  initialComposer: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    content: PropTypes.object.isRequired
  }),
  handleSubmit: PropTypes.func.isRequired
};

export default ComposerDetails;
