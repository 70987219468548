import React from 'react';
import PropTypes from 'prop-types';

import {
  ColourPicker,
  Divider,
  Panel,
  Label,
  Flex,
  OneQuarterLayout,
  InfoSection,
  Accordion
} from '@ubisend/pulse-components';

import { useBotSettings } from '../../hooks/index';
import CallToActions from './CallToActions';
import ColourContrast from '../ColourContrast';

const CallToActionSettings = props => {
  const {
    settings,
    setSettings,
    focusLauncher,
    handleColourChange
  } = useBotSettings();

  const handleCallToActionChange = () => {
    focusLauncher();
    setSettings(settings => ({
      ...settings,
      call_to_action_enabled: !settings.call_to_action_enabled
    }));
  };

  return (
    <Flex>
      {props.titlePosition === 'left' && (
        <OneQuarterLayout>
          <InfoSection
            title="Call-to-actions"
            info="Customise the colour and visibility of call-to-actions."
          />
        </OneQuarterLayout>
      )}
      <Panel>
        {props.titlePosition === 'top' && (
          <>
            <InfoSection
              mtNone
              title="Call-to-actions"
              info="Customise the colour and visibility of call-to-actions."
            />
            <Divider />
          </>
        )}
        <Accordion
          header="Show call-to-action"
          primary
          hasToggle
          checked={settings.call_to_action_enabled}
          onChange={handleCallToActionChange}>
          <Accordion
            header="Configured call-to-actions"
            inner
            data-testid="configured-cta-accordion">
            <CallToActions />
          </Accordion>
          <Divider fullWidth />
          <Flex mt col>
            <Flex mbSm between middle>
              <Label mb={false}>Call-to-action background colour</Label>
              <ColourContrast
                backgrounds={[settings.styles.ctaBackgroundColour]}
                foreground={settings.styles.ctaTextColour || 'white'}
              />
            </Flex>
            <ColourPicker
              testId="call-to-action-background-colour-picker"
              onChange={focusLauncher}
              color={settings.styles.ctaBackgroundColour}
              onChangeComplete={handleColourChange('ctaBackgroundColour')}
            />
          </Flex>
          <Flex mt col>
            <Flex mbSm between middle>
              <Label mb={false}>Call-to-action text colour</Label>
              <ColourContrast
                backgrounds={[settings.styles.ctaBackgroundColour]}
                foreground={settings.styles.ctaTextColour || 'white'}
              />
            </Flex>
            <ColourPicker
              testId="call-to-action-text-colour-picker"
              onChange={focusLauncher}
              color={settings.styles.ctaTextColour}
              onChangeComplete={handleColourChange('ctaTextColour')}
            />
          </Flex>
        </Accordion>
      </Panel>
    </Flex>
  );
};

CallToActionSettings.defaultProps = {
  titlePosition: 'left'
};

CallToActionSettings.propTypes = {
  titlePosition: PropTypes.string
};

export default CallToActionSettings;
