import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useQuery, useMutation } from '@ubisend/pulse-hooks';
import {
  Placeholder,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Flex,
  Checkbox,
  Explainer
} from '@ubisend/pulse-components';
import { getTicket } from '@ubisend/pulse-tickets';

import { updateTicket } from '../../../api/index';

const LiveChatFeedback = ({ match }) => {
  const [botCouldAnswer, setBotCouldAnswer] = useState(false);

  const history = useHistory();

  const query = useQuery(`tickets/ubidesk/${match.params.id}`, {
    cacheTime: 0
  });
  const mutation = useMutation(updateTicket);

  const handleBotCouldAnswerChange = () => {
    setBotCouldAnswer(toggle => !toggle);
  };

  const handleSubmit = async event => {
    event.preventDefault();

    if (botCouldAnswer) {
      await mutation.mutateAsync({
        ...getTicket(query.data.data),
        bot_could_answer: botCouldAnswer
      });
    }

    history.push('/live-chat');
  };

  if (query.isLoading) {
    return (
      <Flex fat tall pad>
        <Placeholder />
      </Flex>
    );
  }

  return (
    <ModalContainer>
      <ModalContent>
        <form onSubmit={handleSubmit}>
          <ModalHeader>Ticket feedback</ModalHeader>
          <ModalBody>
            <Flex mb>
              <Explainer>
                Please give some feedback on the live chat ticket to help
                improve future chats.
              </Explainer>
            </Flex>
            <Checkbox
              checked={botCouldAnswer}
              onChange={handleBotCouldAnswerChange}
              label="Could an FAQ have solved this ticket?"
            />
          </ModalBody>
          <ModalFooter>
            <Flex fat xSpace right>
              <Button type="submit" variant="primary">
                Save
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </ModalContainer>
  );
};

export default LiveChatFeedback;
