import { hasFeature, hasPermission } from '@ubisend/pulse-auth';

import {
  ManageSmallTalk,
  EditSmallTalk,
  SmallTalkSettings
} from './Pages/index';

const routes = [
  {
    path: '/smalltalk',
    component: ManageSmallTalk,
    name: 'Small Talk',
    canAccess: hasPermission('view small talk') && hasFeature('small talk')
  },
  {
    path: '/smalltalk/settings',
    component: SmallTalkSettings,
    name: 'Small Talk',
    canAccess:
      hasPermission('view small talk settings') && hasFeature('small talk')
  },
  {
    path: '/smalltalk/:smallTalkId/edit',
    component: EditSmallTalk,
    name: 'Edit Small Talk',
    canAccess: hasPermission('edit small talk') && hasFeature('small talk')
  }
];

export default routes;
