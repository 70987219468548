import transferVoipCallContent from './TransferVoipCallContent';

const transferVoipCall = {
  name: 'Transfer Voip Call',
  Content: transferVoipCallContent,
  setInitialContent: (content = {}) => {
    return {
      transferTarget: content.transferTarget || ''
    };
  },
  valid: () => {
    return true;
  }
};

export default transferVoipCall;
