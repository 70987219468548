import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Flex, Explainer } from '@ubisend/pulse-components';

import LoginButton from './LoginButton';
import { useFacebookApi } from '../hooks/index';
import { scope as fbScope } from '../facebook';

const FacebookLogin = ({ user, handleNewUser, scope = fbScope }) => {
  const [connecting, setConnecting] = useState();

  const FB = useFacebookApi();

  const handleLogin = () => {
    setConnecting(true);
    FB.login(
      response => {
        if (response?.authResponse) {
          const {
            authResponse: { accessToken, userID }
          } = response;

          handleNewUser({
            userID,
            accessToken
          });
        } else {
          // handle error
        }
        setConnecting(false);
      },
      { scope }
    );
  };

  if (user) {
    return null;
  }

  return (
    <Flex col>
      <Explainer>
        Clicking the button below will open a Facebook login page to begin the
        connection process.
      </Explainer>
      <Flex top mt>
        <LoginButton onClick={handleLogin} loading={connecting} />
      </Flex>
    </Flex>
  );
};

FacebookLogin.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.any,
  handleNewUser: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  scope: PropTypes.any
};

export default FacebookLogin;
