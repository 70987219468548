import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

import { AnimateSharedLayout } from '@ubisend/framer-motion';
import Icon from '@ubisend/pulse-icons';

import { Flex } from '../Layout/index';
import { TabButton } from '../Button/index';
import { Span } from '../Text/index';
import NumberFormatter from '../Formatters/NumberFormatter';
import { ButtonSelect } from '../Input/index';

const Container = styled(Flex)`
  ${tw`py-3 pl-1 pr-4`}
`;

/**
 * https://gist.github.com/kottenator/9d936eb3e4e3c3e02598
 */
const getPageButtons = (current, total) => {
  const delta = 2;
  const range = [1];
  const rangeWithDots = [];

  let l;

  if (total <= 1) {
    return range;
  }

  for (let i = current - delta; i <= current + delta; i++) {
    if (i < total && i > 1) {
      range.push(i);
    }
  }

  range.push(total);

  for (const i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push('...');
      }
    }
    rangeWithDots.push(i);
    l = i;
  }

  return rangeWithDots;
};

const Pagination = ({
  pagination,
  perPageOptions,
  handlePageChange,
  handlePerPageChange
}) => {
  const {
    current_page: page,
    last_page: totalPages,
    per_page: perPage
  } = pagination;

  const onFirstPage = page === 1;
  const onLastPage = page === totalPages;

  return (
    <Container center between fat>
      <Flex center>
        <TabButton
          disabled={onFirstPage}
          onClick={() => handlePageChange(page - 1)}>
          <Icon
            type={'cheveronLeft'}
            size="1.25rem"
            height="1.25rem"
            width="1.25rem"
          />
        </TabButton>
        <AnimateSharedLayout>
          {getPageButtons(page, totalPages).map((linkPage, index) => (
            <TabButton
              key={index}
              active={linkPage === page}
              onClick={() => handlePageChange(linkPage)}>
              {linkPage}
            </TabButton>
          ))}
        </AnimateSharedLayout>
        <TabButton
          disabled={onLastPage}
          onClick={() => handlePageChange(page + 1)}>
          <Icon
            type={'cheveronRight'}
            size="1.25rem"
            height="1.25rem"
            width="1.25rem"
          />
        </TabButton>
        {pagination.total > 0 ? (
          <Span light tinyText>
            Showing <NumberFormatter>{pagination.from}</NumberFormatter>-
            <NumberFormatter>{pagination.to}</NumberFormatter> of{' '}
            <NumberFormatter>{pagination.total}</NumberFormatter> result
            {pagination.total > 1 && 's'}
          </Span>
        ) : (
          <Span light tinyText>
            No results found
          </Span>
        )}
      </Flex>
      {perPageOptions && (
        <Flex center xSpaceSm>
          <Span light tinyText>
            Show
          </Span>
          <ButtonSelect
            menuPlacement="top"
            minWidth="4.5rem"
            value={{ label: perPage, value: perPage }}
            onChange={handlePerPageChange}
            options={perPageOptions.map(perPageOption => ({
              label: perPageOption,
              value: perPageOption
            }))}
          />
          <Span light tinyText>
            results per page
          </Span>
        </Flex>
      )}
    </Container>
  );
};

Pagination.propTypes = {
  pagination: PropTypes.shape({
    per_page: PropTypes.number.isRequired,
    current_page: PropTypes.number.isRequired,
    last_page: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    from: PropTypes.number,
    to: PropTypes.number
  }).isRequired,
  handlePageChange: PropTypes.func.isRequired,
  perPageOptions: PropTypes.arrayOf(PropTypes.number),
  handlePerPageChange: PropTypes.func
};

export default Pagination;
export { getPageButtons };
