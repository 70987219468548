import query from '../query';
import LineGraphMultiAxisMetric from './LineGraphMultiAxisMetric';

const lineGraphMultiAxis = {
  name: 'Configurable multiple axis line graph',
  Component: LineGraphMultiAxisMetric,
  columns: 6,
  rows: 3,
  query
};

export default lineGraphMultiAxis;
