import React from 'react';

import { Tag, Flex } from '@ubisend/pulse-components';

const TriggerPostbackCancelContent = () => {
  return (
    <Flex center>
      <Tag background="warning" colour="warning">
        Beta
      </Tag>
    </Flex>
  );
};

export default TriggerPostbackCancelContent;
