import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';

const Flex = styled.div`
  ${tw`flex my-4 w-full justify-center`}
`;

const YoutubeLink = ({ href }) => {
  const getId = url => {
    if (url.includes('?v=')) {
      return url.split('?v=')[1];
    }

    const urlParts = url.split('/');
    return urlParts[urlParts.length - 1];
  };

  return (
    <Flex>
      <iframe
        title="youtube embed"
        style={{ width: 640, maxWidth: '100%', height: 360 }}
        src={`https://www.youtube.com/embed/${getId(href)}`}
        frameBorder="0"
        allowFullScreen
      />
    </Flex>
  );
};

YoutubeLink.propTypes = {
  href: PropTypes.string.isRequired
};

export default YoutubeLink;
