import React from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import { Button, useNotification, useModal } from '@ubisend/pulse-components';

import { deleteSection } from '../api/index';
import { useImports } from '../hooks/index';

const DeleteSection = ({ item, section }) => {
  const { dispatch } = useImports();
  const { showSuccess } = useNotification();
  const { showModal, hideModal } = useModal();

  const mutation = useMutation(deleteSection, {
    onSuccess: () => {
      dispatch({
        type: 'REMOVE_SECTION',
        import: item,
        section,
        content: section.content
      });

      showSuccess('Successfully deleted section');
    }
  });

  const handleDelete = () => {
    showModal({
      header: `Delete content section`,
      body: `Are you sure you want to delete this content section? You will lose all content associated with it.`,
      handleConfirm: async () => {
        try {
          await mutation.mutate({ importId: item.id, sectionId: section.id });
        } finally {
          hideModal();
        }
      }
    });
  };

  return (
    <Button
      variant="secondary"
      colour="danger"
      icon="trash"
      onClick={handleDelete}>
      Delete
    </Button>
  );
};

DeleteSection.propTypes = {
  item: PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired,
  section: PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired
  }).isRequired
};

export default DeleteSection;
