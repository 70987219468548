import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Select, Button, Label, Flex } from '@ubisend/pulse-components';
import { GroupSelect } from '@ubisend/pulse-groups';

const formatIntent = ({ identifier, name, location }) => ({
  value: identifier,
  label: location ? `${name} (${location.name})` : name
});

const defaultGroups = [
  { label: 'FAQs', options: [] },
  { label: 'Small Talk', options: [] }
];

const groupIntents = ([faqGroup, smallTalkGroup], intent) => {
  // If the identifier contains 'small_talk', we know it's small talk.

  if (isNaN(intent.value) && intent.value.includes('small_talk')) {
    return [
      { label: 'FAQs', options: faqGroup.options },
      { label: 'Small Talk', options: smallTalkGroup.options.concat(intent) }
    ];
  }

  return [
    { label: 'FAQs', options: faqGroup.options.concat(intent) },
    { label: 'Small Talk', options: smallTalkGroup.options }
  ];
};

const IntentSearcher = ({
  handleSubmit,
  languageId,
  kind,
  submitText = 'Update',
  ...props
}) => {
  const [groups, setGroups] = useState([]);
  const [chosenIntent, setChosenIntent] = useState();

  const { data, isLoading, isSuccess } = useQuery([
    'intents',
    {
      groups,
      language_id: languageId,
      kind: kind
    }
  ]);

  const handleIntentChange = item => setChosenIntent(item);

  const handleGroupChange = items => {
    setGroups(items ? items.map(item => item.value) : []);
    setChosenIntent(false);
  };

  return (
    <Flex col ySpace>
      <Flex xSpace>
        <Flex col fat>
          <Label htmlFor="tags">Tags</Label>
          <GroupSelect
            id="tags"
            for="faqs"
            value={groups}
            onChange={handleGroupChange}
            sortAlphabetically
          />
        </Flex>
        <Flex col fat>
          <Label>FAQs</Label>
          <Select
            aria-label="intent-searcher"
            options={
              isSuccess
                ? data.data
                    .sort((a, b) =>
                      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                    )
                    .map(formatIntent)
                    .reduce(groupIntents, defaultGroups)
                : []
            }
            isClearable
            isLoading={isLoading}
            onChange={handleIntentChange}
            value={chosenIntent}
            {...props}
          />
        </Flex>
      </Flex>
      <Flex>
        <Button
          variant="secondary"
          disabled={!chosenIntent}
          onClick={() => handleSubmit(chosenIntent)}>
          {submitText}
        </Button>
      </Flex>
    </Flex>
  );
};

IntentSearcher.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  languageId: PropTypes.number,
  submitText: PropTypes.string,
  kind: PropTypes.string
};

export { formatIntent, defaultGroups, groupIntents };
export default IntentSearcher;
