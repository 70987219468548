import React from 'react';
import tw, { styled } from 'twin.macro';
import { useTranslation } from 'react-i18next';

import { Span, Flex } from '@ubisend/pulse-components';

import { useBot } from '../../../hooks/index';

const Image = styled.img`
  height: ${({ footerImageUrl }) => {
    return footerImageUrl ? '1rem' : '0.89em';
  }};

  margin: ${({ footerImageUrl }) => {
    return footerImageUrl ? '0.188rem 0' : '0.37rem 0 0 0.3rem';
  }};
`;

const Link = styled.a`
  ${tw`flex items-center text-black no-underline `};
`;

const logo =
  'https://s3.eu-west-2.amazonaws.com/ubisend.website/assets/static/images/soprano_design_footer_logo.png';

const WidgetFooter = () => {
  const { showPoweredByUbisend, footerImageUrl, footerUrl } = useBot();
  const { t } = useTranslation('bots');

  if (!showPoweredByUbisend) {
    return null;
  }

  const FooterImage = () => (
    <Image
      footerImageUrl={footerImageUrl}
      src={footerImageUrl ?? logo}
      alt={t('bot footer logo')}
      aria-label={t('widget footer image')}
    />
  );
  if (footerImageUrl && !footerUrl) {
    return <FooterImage />;
  }

  return (
    <>
      {footerUrl ? (
        <Link
          rel="nofollow noopener noreferrer"
          target="_blank"
          aria-label={t('widget footer')}
          href={footerUrl}>
          {!footerImageUrl && <Span>{t('powered by')}</Span>}
          <FooterImage />
        </Link>
      ) : (
        <Flex center>
          {!footerImageUrl && <Span>{t('powered by')}</Span>}
          <FooterImage />
        </Flex>
      )}
    </>
  );
};

export default WidgetFooter;
export { logo };
