import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@ubisend/pulse-components';

import { useComposer } from '../../hooks/index';

const ComposerSubmitButton = ({ children, onClick, ...props }) => {
  const { valid, form } = useComposer();

  const handleClick = () => {
    if (onClick) {
      onClick(form);
    }
  };

  return (
    <Button
      variant="primary"
      onClick={handleClick}
      disabled={!valid}
      {...props}>
      {children}
    </Button>
  );
};

ComposerSubmitButton.propTypes = {
  onClick: PropTypes.func
};

export default ComposerSubmitButton;
