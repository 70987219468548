import { useEffect, useState, useRef, useCallback } from 'react';

import {
  useMotionValue,
  useSpring,
  useMotionTemplate,
  useTransform,
  animate
} from '@ubisend/framer-motion';

const ZOOM_INCREMENT = 0.2;
const MAX_ZOOM = 1;
const MIN_ZOOM = 0.2;
const SIDEBAR_WIDTH = 12 * 16;
const PAN_DEBOUNCE_DELAY = 100;
const THROTTLE_DELAY = 8; // Allow a max of 2 event calls a second
const DEFAULT_TRANSITION = {
  type: 'spring',
  stiffness: 800,
  damping: 90,
  restDelta: 0.001
};
const PAN_DISTANCE = 100;

// Converts the local (screen space) mouse position into one relative to zoom and pan values
const localToGlobalMousePosition = ([latestMouse, latestPan, latestZoom]) =>
  latestMouse - latestPan / latestZoom;

/**
 * Logic and handlers for zooming and panning.
 */
const useCanvasTransform = () => {
  const percentageRef = useRef(null);
  const throttleTime = useRef(null);
  const svgRef = useRef();

  const [showPreview, setShowPreview] = useState(false); // Decide whether to use canvas preview render or full render
  const [panningEnabled, setPanningEnabled] = useState(true); // To disable all kinds of panning in some scenarios
  const [panning, setPanning] = useState(false); // Whether the user has clicked to pan, meaning mousemoves will pan the view
  const [linking, setLinking] = useState({ from: null, to: null });

  const panX = useMotionValue(0);
  const panY = useMotionValue(0);
  const zoomAmount = useSpring(1, {
    damping: 40,
    restDelta: 0.0005,
    duration: 0.2
  });
  const scale = useMotionTemplate`clamp(0.01, ${zoomAmount}, 2)`;
  const backgroundStyle = {
    backgroundOrigin: useMotionTemplate`-${panX}px -${panY}px`,
    backgroundPosition: useMotionTemplate`${panX}px ${panY}px`,
    backgroundSize: useMotionTemplate`calc(${scale} * 16px)`
  };
  const localMousePositionX = useMotionValue(0);
  const localMousePositionY = useMotionValue(0);

  const mousePositionX = useTransform(
    [localMousePositionX, panX, zoomAmount],
    localToGlobalMousePosition
  );
  const mousePositionY = useTransform(
    [localMousePositionY, panY, zoomAmount],
    localToGlobalMousePosition
  );

  const zoom = useCallback(
    factor => {
      let nextZoom = zoomAmount.get() + factor;

      // If next zoom event takes you over min or max zoom threshold then do not set.
      if (nextZoom > MAX_ZOOM || nextZoom < MIN_ZOOM) {
        return;
      }

      nextZoom =
        Math.round((nextZoom * 100) / (ZOOM_INCREMENT * 100)) * ZOOM_INCREMENT;

      animate(zoomAmount, nextZoom, DEFAULT_TRANSITION);
    },
    [zoomAmount]
  );

  const handleZoomInClick = useCallback(() => {
    zoom(ZOOM_INCREMENT);
  }, [zoom]);

  const handleZoomOutClick = useCallback(() => {
    zoom(-ZOOM_INCREMENT);
  }, [zoom]);

  const handleMouseDown = () => {
    setPanning(true);
  };

  const handleMouseMove = event => {
    const currentTime = new Date().getTime();
    if (throttleTime.current > currentTime - THROTTLE_DELAY) {
      return;
    }
    throttleTime.current = currentTime;
    if (panning) {
      panX.set(panX.get() + event.movementX);
      panY.set(panY.get() + event.movementY);
    }
  };

  const debounceTimer = useRef(null);

  const handleDebounce = () => {
    setShowPreview(true);
    clearTimeout(debounceTimer.current);
    debounceTimer.current = setTimeout(
      () => setShowPreview(false),
      PAN_DEBOUNCE_DELAY
    );
  };

  const handleMouseUp = () => {
    handleDebounce();
    setPanning(false);
  };

  useEffect(() => {
    panX.onChange(handleDebounce);
    panY.onChange(handleDebounce);
    zoomAmount.onChange(handleDebounce);

    return () => {
      clearTimeout(debounceTimer.current);
    };
  }, [panX, panY, zoomAmount]);

  const handleWheel = useCallback(
    event => {
      const currentTime = new Date().getTime();

      if (throttleTime.current > currentTime - THROTTLE_DELAY) {
        return;
      }

      throttleTime.current = currentTime;

      if (event.metaKey || event.ctrlKey) {
        const newScale = zoomAmount.get() - event.deltaY / 200;
        zoomAmount.set(newScale, false);
        return;
      }

      if (panningEnabled) {
        panX.set(panX.get() - event.deltaX);
        panY.set(panY.get() - event.deltaY);
      }

      event.stopPropagation();
    },
    [panX, panY, zoomAmount, panningEnabled]
  );

  /**
   * Update zoom counter
   */
  useEffect(() => {
    return zoomAmount.onChange(value => {
      if (value > MAX_ZOOM) {
        return zoomAmount.set(MAX_ZOOM);
      }
      if (value < MIN_ZOOM) {
        return zoomAmount.set(MIN_ZOOM);
      }
      if (percentageRef.current) {
        percentageRef.current.textContent = `${Math.round(value * 100)}%`;
      }
    });
  }, [zoomAmount]);

  const handleLinkCancel = useCallback(
    event => {
      if (event.key === 'Escape') {
        setLinking({ from: null, to: null });
      }
    },
    [setLinking]
  );

  const handleArrowPan = useCallback(
    event => {
      // Only move cavas if the body is the target.
      if (event.target !== document.body) {
        return;
      }

      switch (event.key) {
        case 'Down': // IE/Edge specific value
        case 'ArrowDown':
          animate(panY, panY.get() - PAN_DISTANCE, DEFAULT_TRANSITION);
          break;
        case 'Up': // IE/Edge specific value
        case 'ArrowUp':
          animate(panY, panY.get() + PAN_DISTANCE, DEFAULT_TRANSITION);
          break;
        case 'Left': // IE/Edge specific value
        case 'ArrowLeft':
          animate(panX, panX.get() + PAN_DISTANCE, DEFAULT_TRANSITION);
          break;
        case 'Right': // IE/Edge specific value
        case 'ArrowRight':
          animate(panX, panX.get() - PAN_DISTANCE, DEFAULT_TRANSITION);
          break;
        default:
          return;
      }
    },
    [panX, panY]
  );

  useEffect(() => {
    document.addEventListener('keyup', handleLinkCancel, { passive: false });
    document.addEventListener('keydown', handleArrowPan, { passive: false });

    return () => {
      document.removeEventListener('keyup', handleLinkCancel);
      document.removeEventListener('keydown', handleArrowPan);
    };
  }, [handleLinkCancel, handleArrowPan]);

  const updateMousePosition = useCallback(
    event => {
      localMousePositionX.set(
        (event.clientX - SIDEBAR_WIDTH) / zoomAmount.get()
      );
      localMousePositionY.set(event.clientY / zoomAmount.get());
    },
    [localMousePositionX, localMousePositionY, zoomAmount]
  );

  useEffect(() => {
    document.addEventListener('mousemove', updateMousePosition, false);
    document.addEventListener('drop', updateMousePosition, false);

    return () => {
      document.removeEventListener('mousemove', updateMousePosition, false);
      document.addEventListener('drop', updateMousePosition, false);
    };
  }, [updateMousePosition]);

  return {
    // Panning
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
    handleWheel,
    handleCanvasRender: handleDebounce,
    panX,
    panY,
    setPanningEnabled,
    panning,
    mousePositionX,
    mousePositionY,
    // Zooming
    handleZoomInClick,
    handleZoomOutClick,
    scale,
    zoomAmount,
    percentageRef,
    backgroundStyle,
    svgRef,
    // Linking
    linking,
    setLinking,
    // Canvas render toggle
    showPreview
  };
};

export { MAX_ZOOM, MIN_ZOOM };
export default useCanvasTransform;
