import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import {
  Flex,
  Label,
  Button,
  useNotification,
  useModal
} from '@ubisend/pulse-components';
import { EmailSelect } from '@ubisend/pulse-integrations';

import Modal from '../../../Modal';
import { syncStepEmails } from '../../../../api/index';
import { useBuilder } from '../../../../hooks/index';

const EditEmailBlock = ({ handleClose, node, block }) => {
  const [email, setEmail] = useState(block.base.id);

  const { showSuccess } = useNotification();
  const { showModal, hideModal } = useModal();
  const { dispatch } = useBuilder();

  const { mutate, mutateAsync, isLoading } = useMutation(syncStepEmails, {
    onSuccess: ({ data }) => {
      showSuccess('Successfully updated emails');
      dispatch({
        type: 'SYNC_STEP_EMAILS',
        stepId: node.id,
        newEmails: data.data
      });
      handleClose();
    }
  });

  const handleEmailChange = email => {
    setEmail(email ? email.value : null);
  };

  const valid = useMemo(() => {
    return Boolean(email);
  }, [email]);

  const handleSubmit = event => {
    event.preventDefault();

    if (!valid || isLoading) {
      return;
    }

    mutate({
      stepId: node.id,
      emails: node.base.emails.map(({ id }) => {
        if (id === block.base.id) {
          return email;
        }

        return id;
      })
    });
  };

  const handleDelete = () => {
    showModal({
      header: 'Remove email',
      body: `Are you sure you want to remove this email?`,
      handleConfirm: async () => {
        try {
          await mutateAsync({
            stepId: node.id,
            emails: node.base.emails
              .filter(email => email.id !== block.base.id)
              .map(email => email.id)
          });
        } finally {
          hideModal();
        }
      }
    });
  };

  return (
    <Modal
      header="Edit email"
      handleClose={handleClose}
      actions={
        <Button
          variant="secondary"
          colour="danger"
          icon="trash"
          onClick={handleDelete}
          loading={isLoading}>
          Delete
        </Button>
      }>
      <form onSubmit={handleSubmit}>
        <Flex pad col>
          <Flex mb col>
            <Label htmlFor="email">Email</Label>
            <EmailSelect
              id="email"
              value={email}
              onChange={handleEmailChange}
            />
          </Flex>
          <Flex right>
            <Button
              variant="primary"
              type="submit"
              disabled={!valid || isLoading}>
              Save
            </Button>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};

EditEmailBlock.propTypes = {
  handleClose: PropTypes.func.isRequired,
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
    base: PropTypes.shape({
      emails: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired
      ).isRequired
    }).isRequired
  }).isRequired,
  block: PropTypes.shape({
    base: PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired
  }).isRequired
};

export default EditEmailBlock;
