import React from 'react';

import { hasPermission } from '@ubisend/pulse-auth';

import { FacebookLanding } from './Pages/index';
import { FacebookSettingProvider, FacebookProvider } from './Providers';

const routes = [
  {
    name: 'Facebook Messenger',
    path: '/facebook-link',
    component: () => (
      <FacebookSettingProvider>
        <FacebookProvider>
          <FacebookLanding />
        </FacebookProvider>
      </FacebookSettingProvider>
    ),
    canAccess: hasPermission('view facebook messenger settings'),
    actions: [
      {
        title: 'Manage your Messenger channel',
        description:
          'Connect, disconnect, or edit your Facebook Messenger integration.',
        icon: 'lightningBolt'
      }
    ]
  }
];

export default routes;
