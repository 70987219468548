import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { useDrag, useDrop } from 'react-dnd';

import { Flex, Button } from '@ubisend/pulse-components';

import { useComposer } from '../../hooks/index';

const MIN_RESPONSES = 1;

const StyledMessage = styled(Flex)`
  ${tw`opacity-50 cursor-pointer`}
  ${props => props.active && tw`opacity-100`}
  ${props => !props.active && tw`hover:opacity-100`}
`;

const MultiComposerMessageWrapper = ({
  children,
  message,
  index,
  ...props
}) => {
  const containerRef = useRef(null);
  const buttonRef = useRef(null);

  const { responses, current, dispatch } = useComposer();

  const [, drop] = useDrop({
    accept: 'RESPONSE',
    drop(item) {
      if (item.index === index) {
        return;
      }

      dispatch({
        type: 'MOVE_RESPONSE',
        fromIndex: item.index,
        toIndex: index
      });
    }
  });
  const [, drag] = useDrag({
    type: 'RESPONSE',
    item: () => ({ index })
  });

  const canDelete = () => {
    if (responses.length <= MIN_RESPONSES) {
      return false;
    }

    return true;
  };

  const handleDelete = event => {
    event.stopPropagation();
    dispatch({ type: 'DELETE_RESPONSE', id: message.id });
  };

  const handleSelect = () => {
    dispatch({ type: 'SELECT_RESPONSE', id: message.id });
  };

  drag(buttonRef);
  drop(containerRef);

  return (
    <StyledMessage
      border
      padSm
      ref={containerRef}
      fat
      active={message.id === current}
      onClick={handleSelect}
      {...props}>
      <Flex col tall>
        <Button variant="inline" icon="menuAlt4" ref={buttonRef} />
      </Flex>
      <Flex ml mr fat style={{ minWidth: 0 }}>
        {children}
      </Flex>
      <Flex col tall>
        <Button
          variant="secondary"
          colour="danger"
          icon="trash"
          disabled={!canDelete()}
          onClick={handleDelete}
          aria-label="Delete response"
        />
      </Flex>
    </StyledMessage>
  );
};

MultiComposerMessageWrapper.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired,
  index: PropTypes.number.isRequired
};

export default MultiComposerMessageWrapper;
