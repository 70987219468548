import { styled } from 'twin.macro';

const PreviewGroup = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  background: rgba(255, 255, 255, 0.5);
`;

export default PreviewGroup;
