import React, { useState, useMemo } from 'react';
import tw, { styled } from 'twin.macro';
import { useTranslation } from 'react-i18next';

import {
  motion,
  AnimatePresence,
  AnimateSharedLayout
} from '@ubisend/framer-motion';
import { pseudoStyles } from '@ubisend/pulse-components';
import { useSubscriptionContext } from '@ubisend/pulse-subscribe';
import { hexToRgba } from '@ubisend/pulse-component-hooks';

import { ConnectedIcon, DisconnectedIcon } from '../../../Components/index';

const StatusContainer = styled(motion.div)`
  background: ${({ theme }) => hexToRgba(theme.bot.textColour, 0.2)};
  padding: 0.15rem 0.5rem;
  ${tw`flex items-center rounded-full mt-1 overflow-hidden h-4`}
  & > *:first-child:not(:only-child) {
    ${tw`mr-1`}
  }
  & svg {
    ${tw`w-4 h-4`}
  }
  ${pseudoStyles}
`;

const StatusIndicator = styled(motion.div)`
  ${tw`rounded-full w-2 h-2 my-1`}
  border: 1px solid rgba(255,255,255,0.25);
  background: ${props => (props.connected ? '#2ecc71' : '#e74c3c')};
`;

const StatusText = styled(motion.span)`
  ${tw`uppercase tracking-wide font-poppins`}
  font-size: 0.675rem;
  margin-bottom: -1px;
`;

const ConnectionStatus = props => {
  const { connected } = useSubscriptionContext();
  const [hover, setHover] = useState(false);

  const { t } = useTranslation('bots');

  const Icon = useMemo(() => {
    return connected ? ConnectedIcon : DisconnectedIcon;
  }, [connected]);

  return (
    <AnimateSharedLayout exitBeforeEnter>
      <StatusContainer
        layout
        tabIndex="0"
        colour="white"
        aria-label={t(connected ? 'connected' : 'disconnected')}
        initial={{ borderRadius: '16px' }}
        onFocus={() => setHover(true)}
        onBlur={() => setHover(false)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        {...props}>
        <StatusIndicator layout="position" connected={connected} />
        <AnimatePresence exitBeforeEnter>
          {hover ? (
            <StatusText
              initial={{ opacity: 0, y: 16 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 16 }}
              layout="position">
              {t(connected ? 'connected' : 'disconnected')}
            </StatusText>
          ) : (
            <Icon
              layout="position"
              initial={{ opacity: 0, y: 16 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 16 }}
            />
          )}
        </AnimatePresence>
      </StatusContainer>
    </AnimateSharedLayout>
  );
};

export default ConnectionStatus;
export { ConnectedIcon, DisconnectedIcon };
