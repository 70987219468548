import React from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  useNotification,
  Panel,
  Button,
  InnerPanel,
  Label,
  SubHeader
} from '@ubisend/pulse-components';
import { useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import Icon from '@ubisend/pulse-icons';

import { removeSettings } from '../api/index';

const InstagramSettings = ({ settings }) => {
  const { showSuccess, showError } = useNotification();

  const queryClient = useQueryClient();

  const { mutate: handleDelete, isLoading } = useMutation(removeSettings, {
    onSuccess: () => {
      showSuccess(`Successfully unlinked Facebook page`);
      queryClient.invalidateQueries('instagram/settings');
    },
    onError: () => {
      showError(`Something went wrong whilst unlinking a Facebook page`);
    }
  });

  const handleDeleteSettings = () => handleDelete();

  return (
    <Panel header="Update Instagram settings">
      <Flex col ySpace mt>
        <InnerPanel>
          <Flex center>
            <Flex mr>
              <Icon type="instagram" />
            </Flex>

            <Flex col ySpace>
              <Label>Connected to</Label>
              <SubHeader>{settings.page_name}</SubHeader>
            </Flex>
          </Flex>
        </InnerPanel>
        <Flex>
          <Button
            variant="secondary"
            colour="danger"
            onClick={handleDeleteSettings}
            disabled={isLoading}>
            Remove connection
          </Button>
        </Flex>
      </Flex>
    </Panel>
  );
};

InstagramSettings.propTypes = {
  settings: PropTypes.shape({
    page_name: PropTypes.string
  }).isRequired,
  handleUnlinkPage: PropTypes.func.isRequired
};

export default InstagramSettings;
