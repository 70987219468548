import React, { useState } from 'react';

import { CanvasContext } from '../Contexts/index';
import {
  useCanvasTransform,
  useNodePositions,
  useBuilder
} from '../hooks/index';

const CanvasProvider = ({ children }) => {
  const [activeNode, setActiveNode] = useState(null);
  const [hoveredBlock, setHoveredBlock] = useState(null);
  const [canvasActive, setCanvasActive] = useState(false);
  const [snapPoint, setSnapPoint] = useState(null);
  const [selectedNodes, setSelectedNodes] = useState([]);

  const { conversation } = useBuilder();

  const canvas = useCanvasTransform();
  const node = useNodePositions(conversation);

  const compareNodes = (a, b) => {
    if (!a || !b) {
      return false;
    }

    return a.type === b.type && a.id === b.id;
  };

  const context = {
    // State
    activeNode,
    setActiveNode,
    hoveredBlock,
    setHoveredBlock,
    canvasActive,
    setCanvasActive,
    snapPoint,
    setSnapPoint,
    selectedNodes,
    setSelectedNodes,
    // Hooks
    ...canvas,
    ...node,
    // Handlers
    compareNodes
  };

  return (
    <CanvasContext.Provider value={context}>{children}</CanvasContext.Provider>
  );
};

export default CanvasProvider;
