import React from 'react';
import PropTypes from 'prop-types';

import {
  FormGroup,
  Label,
  Select,
  formatSelectOption
} from '@ubisend/pulse-components';
import { useQuery } from '@ubisend/pulse-hooks';

const format = ({ id, name }) => ({ label: name, value: id });

const WebhookActionSelect = ({ handleActionChange, value }) => {
  const query = useQuery('webhooks/actions/types');

  const defaultOption = { label: 'No action', value: null };

  return (
    <FormGroup>
      <Label htmlFor="webhook_action">Webhook Action</Label>
      <Select
        id="webhook_action"
        data-testid="webhook_action"
        placeholder="Select Action..."
        isLoading={query.isLoading}
        value={
          query.isSuccess && value
            ? format(
                query.data.data.find(
                  webhookAction => webhookAction.id === value
                )
              )
            : defaultOption
        }
        options={query.isSuccess ? query.data.data.map(formatSelectOption) : []}
        onChange={handleActionChange}
        for="webhook_action"
        isClearable
      />
    </FormGroup>
  );
};

WebhookActionSelect.propTypes = {
  handleActionChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default WebhookActionSelect;
