import React, { useState, useEffect } from 'react';
import tw, { styled } from 'twin.macro';
import dayjs from 'dayjs';

import { useIsMutating } from '@ubisend/pulse-hooks';
import { Flex, Panel, Heading1, Span } from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { useBuilder } from '../hooks/index';
import EditConversation from './EditConversation';

const Container = styled(Panel)`
  ${tw`flex items-center p-4 shadow-none border-none`}
  box-sizing: border-box;
`;

const LastUpdatedAt = () => {
  const [lastUpdatedAt, setLastUpdatedAt] = useState(null);
  const isMutating = useIsMutating();

  const getText = () => {
    if (isMutating) {
      return 'Saving...';
    }

    if (!lastUpdatedAt) {
      return 'No updates made this session';
    }

    return `Last updated ${lastUpdatedAt.from()}`;
  };

  useEffect(() => {
    if (isMutating) {
      setLastUpdatedAt(dayjs());
    }
  }, [isMutating]);

  return (
    <Span colour="grey" tinyText>
      {getText()}
    </Span>
  );
};

const TopBar = () => {
  const { conversation } = useBuilder();

  return (
    <Container fat>
      <Flex fat top between center xSpace>
        <Flex center xSpaceSm>
          <Flex col>
            <Heading1 xlText semiBold>
              {conversation.title}
            </Heading1>
            <LastUpdatedAt />
          </Flex>
        </Flex>
        <Flex>
          <PermissionFilter can="edit conversations">
            <EditConversation />
          </PermissionFilter>
        </Flex>
      </Flex>
    </Container>
  );
};

export default TopBar;
