import React from 'react';
import PropTypes from 'prop-types';

import {
  Panel,
  InfoSection,
  Flex,
  OneQuarterLayout,
  Accordion,
  Divider
} from '@ubisend/pulse-components';

import { useBotSettings } from '../../hooks/index';

const SettingPageSettings = props => {
  const { settings, focusSettings, setSettings } = useBotSettings();

  const handleResetChange = () => {
    setSettings(settings => ({
      ...settings,
      reset_enabled: !settings.reset_enabled
    }));

    focusSettings();
  };

  const handleLanguageChange = () => {
    setSettings(settings => ({
      ...settings,
      change_subscriber_language_enabled: !settings.change_subscriber_language_enabled
    }));

    focusSettings();
  };

  const handleTranscriptChange = () => {
    setSettings(settings => ({
      ...settings,
      export_transcript_enabled: !settings.export_transcript_enabled
    }));

    focusSettings();
  };

  const handleDataExportChange = () => {
    setSettings(settings => ({
      ...settings,
      export_subscriber_enabled: !settings.export_subscriber_enabled
    }));

    focusSettings();
  };

  const handleDeleteChange = () => {
    setSettings(settings => ({
      ...settings,
      delete_subscriber_enabled: !settings.delete_subscriber_enabled
    }));

    focusSettings();
  };

  return (
    <Flex>
      {props.titlePosition === 'left' && (
        <OneQuarterLayout>
          <InfoSection
            title="Settings"
            info="Choose the options to display inside your chatbot's settings panel."
          />
        </OneQuarterLayout>
      )}
      <Panel>
        {props.titlePosition === 'top' && (
          <>
            <InfoSection
              mtNone
              title="Settings"
              info="Choose the options to display inside your chatbot's settings panel."
            />
            <Divider />
          </>
        )}
        <Flex col ySpace>
          <Accordion
            header="Language"
            primary
            hasToggle
            checked={settings.change_subscriber_language_enabled}
            onChange={handleLanguageChange}
            htmlFor="Language"
          />
          <Accordion
            primary
            hasToggle
            checked={settings.reset_enabled}
            onChange={handleResetChange}
            header="Reset"
            htmlFor="Reset"
          />
          <Accordion
            primary
            hasToggle
            checked={settings.export_transcript_enabled}
            onChange={handleTranscriptChange}
            header="Transcript"
            htmlFor="Transcript"
          />
          <Accordion
            primary
            hasToggle
            checked={settings.export_subscriber_enabled}
            onChange={handleDataExportChange}
            header="Data export"
            htmlFor="Data export"
          />
          <Accordion
            primary
            hasToggle
            checked={settings.delete_subscriber_enabled}
            onChange={handleDeleteChange}
            header="Delete"
            htmlFor="Delete"
          />
        </Flex>
      </Panel>
    </Flex>
  );
};

SettingPageSettings.propTypes = {
  titlePosition: PropTypes.string
};

SettingPageSettings.defaultProps = {
  titlePosition: 'left'
};

export default SettingPageSettings;
