import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useQueryClient, useMutation } from '@ubisend/pulse-hooks';
import {
  Flex,
  ModalClose,
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalBody,
  useNotification,
  Tag,
  Explainer
} from '@ubisend/pulse-components';

import { importFaqs } from '../../api/index';
import FaqDetails from './FaqDetails';

const CreateSheet = ({ handleClose }) => {
  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const mutation = useMutation(importFaqs, {
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries('faqs');
      showSuccess(
        `Your FAQs are being imported. We estimate this will take ${data.totalInMinutes} minutes.`
      );
      handleClose();
    }
  });

  const handleSubmit = form => {
    mutation.mutate(form);
  };

  return (
    <ModalPortal>
      <ModalContainer>
        <ModalContent>
          <ModalHeader>
            <Flex center>
              Import FAQs
              <Tag mlSm background="warning" colour="warning">
                Beta
              </Tag>
            </Flex>
            <Flex between>
              <ModalClose onClick={handleClose} />
            </Flex>
          </ModalHeader>
          <ModalBody>
            <Explainer mbSm>
              Please refer to our documentation to learn how to format and
              import your FAQs:{' '}
              <Link to="/docs/2151317744/2708144186">Import FAQs</Link>.
            </Explainer>
            <FaqDetails
              handleSubmit={handleSubmit}
              loading={mutation.isLoading}
            />
          </ModalBody>
        </ModalContent>
      </ModalContainer>
    </ModalPortal>
  );
};

CreateSheet.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default CreateSheet;
