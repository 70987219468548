import MessageInput from '../Message/MessageInput';

const numeric = {
  id: 'numeric',
  name: 'Numeric',
  Input: MessageInput,
  setInitialContent: content => {
    return {
      type: content.type || 'text',
      placeholder: content.placeholder || 'Enter a number...',
      disabled: content.disabled !== undefined ? content.disabled : false,
      show_file_upload:
        content.show_file_upload !== undefined
          ? content.show_file_upload
          : false
    };
  },
  formatContent: content => {
    return {
      type: content.type,
      placeholder: content.placeholder,
      disabled: content.disabled,
      show_file_upload: content.show_file_upload
    };
  },
  valid: content => {
    return Boolean(content.placeholder);
  },
  snippet: `{
    "type": "numeric",
    "content": {
      "type": "text",
      "placeholder": "Enter a number...",
      "disabled": false,
      "show_file_upload": false
    } 
}`
};

export default numeric;
