import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  PanelSlider,
  Flex,
  LeftHalfLayout,
  RightHalfLayout,
  Placeholder,
  Button,
  useModal,
  useNotification,
  Label
} from '@ubisend/pulse-components';
import { SubscriberDetails } from '@ubisend/pulse-tickets';
import { useQuery, useQueryClient, useMutation } from '@ubisend/pulse-hooks';
import { PermissionFilter, FeatureFilter, useAuth } from '@ubisend/pulse-auth';

import { Messages } from './Components/index';
import {
  authoriseSubscriber as authoriseSubscriberApi,
  deleteSubscriber as deleteSubscriberApi,
  exportSubscriber as exportSubscriberApi,
  exportTranscript as exportTranscriptApi
} from './api/index';

const Subscriber = ({ match }) => {
  const history = useHistory();

  const queryClient = useQueryClient();
  const query = useQuery(`subscribers/${match.params.id}`);

  const { showModal, hideModal } = useModal();
  const { showSuccess } = useNotification();
  const { user: loggedInUser } = useAuth();

  const authoriseSubscriber = useMutation(authoriseSubscriberApi, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('subscribers');
      await queryClient.invalidateQueries(`subscribers/${match.params.id}`);
      showSuccess(`Successfully authorised subscriber`);
    }
  });
  const deleteSubscriber = useMutation(deleteSubscriberApi, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('subscribers');

      history.push('/conversations');
      showSuccess('Successfully deleted subscriber');
    }
  });
  const exportSubscriber = useMutation(exportSubscriberApi, {
    onSuccess: () => {
      showSuccess(
        'You will receive an email shortly with details on accessing the subscribers data'
      );
    }
  });
  const exportTranscript = useMutation(exportTranscriptApi, {
    onSuccess: () => {
      showSuccess(
        'You will receive an email shortly with details on accessing the subscribers transcript'
      );
    }
  });

  const handleSubscriberDelete = () => {
    showModal({
      header: 'Delete Subscriber',
      body:
        'Are you sure you want to delete the subscriber? This will remove all data associated with them, including all of their messages.',
      handleConfirm: async () => {
        try {
          await deleteSubscriber.mutateAsync(query.data.data.id);
        } finally {
          hideModal();
        }
      }
    });
  };

  const handleExportData = () => {
    showModal({
      header: 'Export Subscriber Data',
      body: `Are you sure you want to export this subscribers data? You will receive an email at "${loggedInUser.email}" with a link to download all data stored on them.`,
      handleConfirm: async () => {
        try {
          await exportSubscriber.mutateAsync(query.data.data.id);
        } finally {
          hideModal();
        }
      }
    });
  };

  const handleTranscriptExport = () => {
    showModal({
      header: 'Export Subscriber Transcript',
      body: `Are you sure you want to export this subscribers transcript? You will receive an email at "${loggedInUser.email}" with a link to download their conversation transcript.`,
      handleConfirm: async () => {
        try {
          await exportTranscript.mutateAsync(query.data.data.id);
        } finally {
          hideModal();
        }
      }
    });
  };

  const handleSubscriberAuthorise = () => {
    showModal({
      header: 'Authorise',
      body: `Are you sure you want to authorise this subscriber?`,
      handleConfirm: async () => {
        try {
          await authoriseSubscriber.mutateAsync(query.data.data.id);
        } finally {
          hideModal();
        }
      }
    });
  };

  return (
    <PanelSlider
      header="Conversation"
      handleHide={() => history.push('/conversations')}
      width="60vw">
      <Flex>
        <LeftHalfLayout>
          {query.isLoading && <Placeholder />}
          {query.isSuccess && (
            <SubscriberDetails
              subscriber={query.data.data}
              hideConversation
              actions={
                <Flex col>
                  <Label htmlFor="authorisation">Actions</Label>
                  <Flex xSpaceSm wrap>
                    <PermissionFilter can="export subscribers">
                      <Button
                        mbSm
                        variant="secondary"
                        onClick={handleExportData}
                        icon="download"
                        aria-label="Export data">
                        Data
                      </Button>
                      <Button
                        mbSm
                        variant="secondary"
                        onClick={handleTranscriptExport}
                        icon="download"
                        aria-label="Export transcript">
                        Transcript
                      </Button>
                    </PermissionFilter>
                    <FeatureFilter feature="authorisation">
                      {!query.data.data.authorised_at && (
                        <Button
                          mbSm
                          icon="lockOpen"
                          onClick={handleSubscriberAuthorise}>
                          Authorise
                        </Button>
                      )}
                    </FeatureFilter>
                    <PermissionFilter can="delete subscribers">
                      <Button
                        mbSm
                        colour="danger"
                        variant="secondary"
                        icon="trash"
                        onClick={handleSubscriberDelete}>
                        Delete
                      </Button>
                    </PermissionFilter>
                  </Flex>
                </Flex>
              }
            />
          )}
        </LeftHalfLayout>
        <RightHalfLayout>
          {query.isLoading && <Placeholder />}
          {query.isSuccess && <Messages subscriber={query.data.data} />}
        </RightHalfLayout>
      </Flex>
    </PanelSlider>
  );
};

export default Subscriber;
