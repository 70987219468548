import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';

import { Select } from '@ubisend/pulse-components';

const SectionSelect = ({ value, onChange, ...props }) => {
  const [options, setOptions] = useState(null);

  useQuery('imports', {
    onSuccess: ({ data }) => {
      const options = data.map(imprt => {
        return {
          label: imprt.name,
          options: imprt.sections.map(section => {
            return {
              label: section.content.name,
              value: section.unique_id
            };
          })
        };
      });

      setOptions(options);
    }
  });

  const formattedValue = useMemo(() => {
    if (!options || !value) {
      return null;
    }

    const option = options.find(group => {
      return group.options.find(option => option.value === value);
    });

    if (!option) {
      return null;
    }

    return option.options.find(option => option.value === value);
  }, [options, value]);

  return (
    <Select
      {...props}
      onChange={onChange}
      isLoading={!options}
      value={formattedValue}
      options={options ? options : []}
    />
  );
};

SectionSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default SectionSelect;
