import React, { useState } from 'react';
import { styled } from 'twin.macro';

import TypingIndicator from '../../../../TypingIndicator';

const frameId = 'global-pay-frame';

const Iframe = styled.iframe`
  border: none;
  ${props => props.hide && 'display: none !important'};
  height: 36rem;
  width: 100%;
`;

const GlobalPayFrame = props => {
  const [loaded, setLoaded] = useState(false);

  const handleLoad = () => {
    if (!loaded) {
      setLoaded(true);
    }
  };

  return (
    <>
      {!loaded && <TypingIndicator />}
      <Iframe id={frameId} {...props} hide={!loaded} onLoad={handleLoad} />
    </>
  );
};

export default GlobalPayFrame;
export { frameId };
