import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  Button,
  formatSelectOption,
  Label,
  Select,
  ActionMenu
} from '@ubisend/pulse-components';
import { useQuery, useMutation } from '@ubisend/pulse-hooks';
import {
  ConverseBaseComposer,
  useComposer,
  useDelayedBot,
  isValidMessage,
  formatClientMessage,
  ConverseSendButton
} from '@ubisend/pulse-volt';
import { FileSelect } from '@ubisend/pulse-files';
import { ComposerSelect } from '@ubisend/pulse-composer';
import { PermissionFilter, PermissionsFilter } from '@ubisend/pulse-auth';

import { sendMessage as sendMessageApi } from '../../../../../api/index';
import { useUpdateTicketResponsesCache } from '../../../../../hooks/index';
import ToggleActionsButton from './ToggleActionsButton';

const MAX_MESSAGE_LENGTH = 1000;
const MIN_MESSAGE_LIMIT = 1;

const isValid = message => {
  return isValidMessage(message, {
    min: MIN_MESSAGE_LIMIT,
    max: MAX_MESSAGE_LENGTH
  });
};

const findLiveChatComposer = composer => {
  return !composer.is_custom && composer.type === 'live-chat';
};

const AgentComposerSelect = ({ value, disabled, ...props }) => {
  const query = useQuery('composers');

  const getValue = () => {
    if (!query.isSuccess) {
      return null;
    }

    if (!value) {
      return formatSelectOption(query.data.data.find(findLiveChatComposer));
    }

    return formatSelectOption(query.data.data.find(({ id }) => id === value));
  };

  return (
    <Select
      menuPlacement="top"
      options={
        query.isSuccess
          ? ComposerSelect.groupComposerSelectOptions(
              query.data.data,
              formatSelectOption
            )
          : []
      }
      isLoading={query.isLoading}
      isDisabled={disabled}
      value={getValue()}
      {...props}
    />
  );
};

AgentComposerSelect.propTypes = {
  value: PropTypes.number,
  disabled: PropTypes.bool
};

const AgentComposer = ({ subscriberId, ticketId }) => {
  const [showFileSelect, setShowFileSelect] = useState(false);
  const [composer, setComposer] = useState(null);

  const { reply, setReply } = useComposer();
  const { setMessages } = useDelayedBot();
  const updateTicketResponsesCache = useUpdateTicketResponsesCache();

  const mutation = useMutation(sendMessageApi, {
    onSuccess: ({ data }) => {
      updateTicketResponsesCache({ ticketId, responses: data });
    }
  });

  const textareaRef = useRef(null);

  const handleChange = event => {
    const value = event.target.value;

    if (value.length >= MAX_MESSAGE_LENGTH) {
      return;
    }

    setReply(value);

    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const sendMessage = response => {
    setMessages(messages => {
      return messages.concat(formatClientMessage(response));
    });

    mutation.mutate({
      subscriberId,
      responses: [
        {
          ...response,
          composer_id: composer
        }
      ]
    });

    setComposer(null);
  };

  const handleSubmit = () => {
    const validatedMessage = isValid(reply);

    if (!validatedMessage) {
      return;
    }

    sendMessage({
      type: 'standard',
      content: { text: reply }
    });
    setReply('');

    if (textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.style.height = '';
    }
  };

  const handleShowFileSelect = () => {
    setShowFileSelect(true);
  };

  const handleHideFileSelect = () => {
    setShowFileSelect(false);
  };

  const handleFileSelect = file => {
    sendMessage({
      type: 'file',
      content: {
        url: file.link,
        text: file.name
      }
    });

    setShowFileSelect(false);
  };

  const handleKeyDown = event => {
    // Submit on enter.
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const handleComposerChange = option => {
    setComposer(option.value);
  };

  const disabled = mutation.isLoading;

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.style.height = '';
      const newHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = `${newHeight}px`;

      if (newHeight > 150) {
        textareaRef.current.style.overflow = 'auto';
      } else {
        textareaRef.current.style.overflow = 'hidden';
      }
    }
  }, [reply, disabled]);

  return (
    <>
      {showFileSelect && (
        <FileSelect
          handleFileSelect={handleFileSelect}
          handleCancel={handleHideFileSelect}
        />
      )}
      <ConverseBaseComposer.Container>
        <Flex middle fat center pl pr>
          <Flex ml mr fat>
            <ConverseBaseComposer.TextArea
              aria-label="Message"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              value={reply}
              placeholder="Send a message"
              disabled={disabled}
              ref={textareaRef}
              rows={1}
            />
          </Flex>
          <PermissionFilter can="view composers">
            <Flex mrSm>
              <ActionMenu
                position={ActionMenu.POSITIONS.TOP}
                Button={ToggleActionsButton}
                buttonProps={{
                  'aria-label': 'Toggle composer actions'
                }}>
                <Flex col pad style={{ width: '13rem' }}>
                  <Flex col>
                    <Label htmlFor="composer">Composer</Label>
                    <AgentComposerSelect
                      id="composer"
                      value={composer}
                      disabled={disabled}
                      onChange={handleComposerChange}
                    />
                  </Flex>
                </Flex>
              </ActionMenu>
            </Flex>
          </PermissionFilter>
          <PermissionsFilter can={['view files', 'view file sources']}>
            <Flex mrSm>
              <Button
                fat
                middle
                variant="inline"
                icon="paperClip"
                colour="secondary"
                onClick={handleShowFileSelect}
                disabled={disabled}
                aria-label="Send file"
              />
            </Flex>
          </PermissionsFilter>
          <Flex mr>
            <ConverseSendButton
              aria-label="Send message"
              disabled={disabled}
              onClick={handleSubmit}
            />
          </Flex>
        </Flex>
      </ConverseBaseComposer.Container>
    </>
  );
};

AgentComposer.propTypes = {
  ticketId: PropTypes.number.isRequired,
  subscriberId: PropTypes.number.isRequired
};

export default AgentComposer;
