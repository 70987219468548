import UrlInput from './UrlInput';

const url = {
  id: 'url',
  name: 'Url',
  Input: UrlInput,
  setInitialContent: content => {
    // pre existing customers will not have this value
    // we need to explicitly check whether its been set a boolean
    // this is as we want existing customers will no value to set to `true` and otherwise having no value would return `false`
    const expiresValueExists =
      content.expires === true || content.expires === false;

    return {
      label: content.label || '',
      action: content.action || '',
      linkback: content.linkback || '',
      expires: expiresValueExists ? content.expires : true
    };
  },
  valid: content => {
    return Boolean(content.label && content.action);
  },
  snippet: `{"type": "url", "label": "", "action": "", "linkback": "", expires: true}`
};

export default url;
