import React from 'react';
import tw, { styled } from 'twin.macro';

import Footer, { animations } from './Footer';

const StickyContainer = styled(Footer)`
  ${tw`sticky w-full bottom-0 z-20`}
`;

const StickyFooter = ({ children }) => (
  <StickyContainer {...animations}>{children}</StickyContainer>
);

export default StickyFooter;
