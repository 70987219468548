import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';

import { Button } from '@ubisend/pulse-components';

import { Wizard } from './Wizard/index';

const StyledButton = styled(Button)`
  ${props => props.fill && tw`h-full w-full `};
  justify-content: ${props => props.fill && 'center'};
  border-radius: ${props => props.fill && '0'};
`;

const NewImportButton = ({ children, ...props }) => {
  const [show, setShow] = useState(false);

  const handleWizardStart = () => setShow(true);

  const handleWizardEnd = () => setShow(false);

  return (
    <>
      <StyledButton variant="primary" onClick={handleWizardStart} {...props}>
        {children}
      </StyledButton>
      {show && <Wizard handleClose={handleWizardEnd} />}
    </>
  );
};

export default NewImportButton;
