import Messenger from './Messenger';
import Workplace from './Workplace';
import Teams from './Teams';
import Telegram from './Telegram';
import Pulse from './Pulse';
import Cisco from './Cisco';
import Discord from './Discord';
import Google from './Google';
import Instagram from './Instagram';
import Kakaotalk from './Kakaotalk';
import Apple from './Apple';
import Kik from './Kik';
import Linkedin from './Linkedin';
import Mattermost from './Mattermost';
import Slack from './Slack';
import Viber from './Viber';
import WeChat from './WeChat';
import Whatsapp from './Whatsapp';
import Proprietary from './Proprietary';
import Alexa from './Alexa';
import Voiceai from './Voiceai';
import Sms from './Sms';

const channelIcons = {
  messenger: Messenger,
  workplace: Workplace,
  teams: Teams,
  telegram: Telegram,
  pulse: Pulse,
  cisco: Cisco,
  discord: Discord,
  google: Google,
  instagram: Instagram,
  kakaotalk: Kakaotalk,
  apple: Apple,
  kik: Kik,
  linkedin: Linkedin,
  mattermost: Mattermost,
  slack: Slack,
  viber: Viber,
  wechat: WeChat,
  whatsapp: Whatsapp,
  proprietary: Proprietary,
  alexa: Alexa,
  voiceai: Voiceai,
  sms: Sms
};

export default channelIcons;
