import tw, { styled } from 'twin.macro';

import Panel from './Panel';
import { spacing, flex } from '../styles';

const Card = styled(Panel)`
  ${spacing}
  ${flex}

  ${props => props.active && tw`outline outline-red-300 outline-2`};
  outline-color: ${props => props.theme.primary};
  outline-offset: ${props => props.active && '-2px'};
`;

export default Card;
