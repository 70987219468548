import {
  usePaginationReducer,
  useOrderableTableReducer,
  useFilterReducer
} from '@ubisend/pulse-components';

const defaultFilters = {
  assignee_ids: [],
  ticket_types: [],
  statuses: [],
  group_ids: [],
  keyword_search: []
};

const useTicketFilters = ({ id, initialFilters = defaultFilters }) => {
  const pagination = usePaginationReducer({ id });
  const order = useOrderableTableReducer({ id });
  const filters = useFilterReducer({
    id,
    initialFilters,
    options: { pagination }
  });

  const handleStatusesChange = options => {
    filters.updateFilters({
      statuses: options ? options.map(option => option.value) : []
    });
  };

  const handleTypesChange = options => {
    filters.updateFilters({
      ticket_types: options ? options.map(option => option.value) : []
    });
  };

  const handleAssigneesChange = options => {
    filters.updateFilters({
      assignee_ids: options ? options.map(option => option.value) : []
    });
  };

  const handleGroupsChange = options => {
    filters.updateFilters({
      group_ids: options ? options.map(option => option.value) : []
    });
  };

  const handleKeywordChange = option => {
    filters.updateFilters({
      keyword_search: option?.target.value !== '' ? option.target.value : []
    });
  };

  return {
    form: { ...filters.form, ...pagination.params, ...order.params },
    filterProps: {
      filters: filters.filters,
      handleStatusesChange,
      handleTypesChange,
      handleAssigneesChange,
      handleGroupsChange,
      handleKeywordChange
    },
    filterMenuProps: filters.props,
    pagination,
    order
  };
};

export default useTicketFilters;
