import tw, { styled } from 'twin.macro';

import { motion } from '@ubisend/framer-motion';

import { spacing, text } from '../styles';

const Heading1 = styled(motion.h1)`
  ${tw`text-2xl m-0`}
  ${spacing}
  ${text}
`;

export default Heading1;
