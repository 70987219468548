import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { useTranslation } from 'react-i18next';

import { useBot } from '../../../../hooks/index';

const StyledClose = styled.button`
  ${tw`absolute text-white border-none bg-transparent cursor-pointer z-20 opacity-50 hover:opacity-100 self-start py-2`}
  margin-right: -0.5rem;
  margin-top: -0.5rem;
  top: 0.5rem;
  right: 0.5rem;
  & svg {
    width: 20px;
    height: 20px;
  }
  & svg path {
    stroke: white;
  }
`;

const SvgContainer = styled.div`
  width: 20px;
  height: 20px;
`;

const CloseButton = ({ background, ...props }) => {
  const { setShowCta, onCtaHide } = useBot();

  const { t } = useTranslation('bots');

  const hideCta = event => {
    event.stopPropagation();
    setShowCta(false);
    onCtaHide();
  };

  return (
    <StyledClose
      type="button"
      tabIndex="0"
      id="close-cta"
      aria-label={t('close_cta_button_label')}
      onClick={hideCta}
      {...props}>
      <SvgContainer>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6 18L18 6M6 6L18 18"
            stroke="#4A5568"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </SvgContainer>
    </StyledClose>
  );
};

CloseButton.propTypes = {
  background: PropTypes.string
};

export default CloseButton;
