import React from 'react';
import { styled } from 'twin.macro';
import PropTypes from 'prop-types';

import Avatar from './Avatar';
import { useBot } from '../../../../hooks/index';

const BotSvg = ({ className, ...rest }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    {...rest}>
    <path
      className="a"
      d="M409.24,207.69V257.3l-10.48,9.31L372.1,270l-1.2,15.57-10.2,16.7-17,8.11-62.51,1-.39,37.5H366L380,353.36l8.41,11.75,1.26,1.76.32,57.59-1.58,1.25A222.69,222.69,0,0,1,111.71,425l.7-61.49,14.93-13.44,93.23-1.21-2.29-39.26-63.67,1.76-17.48-12.29L126.48,270l-24.61-3.44L91.66,253V194.8l12.05-6.25,9.1-2.35,0-56.11L104.21,119l.09-13.13,15.87-3.14,6.7,13.15-9,14.31-1.17,13,1.57,45.32,10.55-.53L137,155.64l26.57-12.41H276.88l66,.46L364.22,156l9.54,22v13.28L384.3,188V130l-11-8.43,3.3-16.12,14.08-1.32,12.88,11.72L390.67,130.2l-2.46,4.44.78,53.91,11.72,3.9Z"
    />
    <path
      className="b"
      d="M388.45,363v62.74a222.55,222.55,0,0,1-27,18.25V368.63a14.11,14.11,0,0,0-14.06-14.06h-74a14.06,14.06,0,0,1,11.26-5.66h89.69A14.11,14.11,0,0,1,388.13,360,13.83,13.83,0,0,1,388.45,363Z"
    />
    <path
      className="c"
      d="M111.71,425.05a222.64,222.64,0,0,0,52.69,31.22V368.69c0-7.76,12.72-14.1,28.26-14.1H341.33l0,0c-5.18-3.44-13.38-5.66-22.59-5.66H138.5a46.75,46.75,0,0,0-19.11,3.74c-5.61,2.58-9.14,6.28-9.14,10.36v60.87Z"
    />
    <rect className="c" x="222.09" y="311.41" width="15.53" height="37.5" />
    <path
      className="c"
      d="M117.82,260.72h-9.75A10.24,10.24,0,0,1,97.85,250.5V204.14a10.24,10.24,0,0,1,10.22-10.22h9.75a10.25,10.25,0,0,0-10.22,10.22V250.5A10.25,10.25,0,0,0,117.82,260.72Z"
    />
    <path
      className="c"
      d="M117.07,124.92a9.15,9.15,0,0,1-2.55.35,9.38,9.38,0,1,1,0-18.76,9.61,9.61,0,0,1,2.55.35,9.39,9.39,0,0,0,0,18.06Z"
    />
    <path
      className="c"
      d="M388.45,124.92a9.15,9.15,0,0,1-2.55.35,9.38,9.38,0,1,1,0-18.76,9.61,9.61,0,0,1,2.55.35,9.39,9.39,0,0,0,0,18.06Z"
    />
    <path
      className="b"
      d="M218.57,216.63a20.4,20.4,0,0,1-20.51,20.26,20.75,20.75,0,0,1-3.08-.23,20.23,20.23,0,0,0,0-40.07,20.75,20.75,0,0,1,3.08-.23A20.4,20.4,0,0,1,218.57,216.63Z"
    />
    <path
      className="b"
      d="M332.06,216.63a20.4,20.4,0,0,1-20.51,20.26,20.75,20.75,0,0,1-3.08-.23,20.23,20.23,0,0,0,0-40.07,20.75,20.75,0,0,1,3.08-.23A20.4,20.4,0,0,1,332.06,216.63Z"
    />
    <path
      className="c"
      d="M286.28,260.15c-6.6,15.13-19.95,25.48-35.32,25.48s-28.74-10.35-35.33-25.48c6.6,10.37,19.95,17.47,35.33,17.47S279.68,270.52,286.28,260.15Z"
    />
    <polygon
      className="c"
      points="386.4 188.55 386.4 267.94 376.5 269.48 372.1 188.02 386.4 188.55"
    />
    <path
      className="c"
      d="M189,311.41H162.85c-15.63,0-28.43-16.36-28.43-36.35V179.59c0-20,12.8-36.35,28.43-36.35H189c-19.15,0-34.81,20-34.81,44.53v79.11C154.17,291.37,169.84,311.41,189,311.41Z"
    />
    <circle className="d" cx="114.51" cy="115.89" r="14.31" />
    <circle className="d" cx="386.4" cy="115.89" r="14.31" />
    <circle className="d" cx="307.3" cy="216.38" r="14.31" />
    <circle className="d" cx="193.62" cy="216.38" r="14.31" />
    <line className="d" x1="114.51" y1="130.2" x2="114.51" y2="188.55" />
    <line className="d" x1="386.4" y1="130.2" x2="386.4" y2="188.55" />
    <path
      className="e"
      d="M265.74,143.23h71.58A34.87,34.87,0,0,1,372.1,178v98.64a34.87,34.87,0,0,1-34.77,34.77H163.59a34.87,34.87,0,0,1-34.77-34.77V178a34.87,34.87,0,0,1,34.77-34.77h23.34"
    />
    <line className="f" x1="208.9" y1="143.23" x2="232.64" y2="143.23" />
    <line className="d" x1="220.57" y1="348.91" x2="220.57" y2="311.41" />
    <line className="d" x1="280.78" y1="348.91" x2="280.78" y2="311.41" />
    <path
      className="g"
      d="M110.25,418.25V370a21.11,21.11,0,0,1,9.14-17.35,20.89,20.89,0,0,1,12-3.74H369.57A21.14,21.14,0,0,1,388.13,360a20.85,20.85,0,0,1,2.54,10v49.33"
    />
    <path
      className="d"
      d="M128.83,266.61H107.77a16.16,16.16,0,0,1-16.1-16.11V204.14A16.16,16.16,0,0,1,107.77,188h21.06"
    />
    <path
      className="d"
      d="M372.1,266.61h21a16.17,16.17,0,0,0,16.11-16.11V204.14A16.17,16.17,0,0,0,393.12,188h-21"
    />
    <path
      className="e"
      d="M218.28,257.3c1.85,2,13.78,14.41,32.81,14.06a44.54,44.54,0,0,0,30.08-12.89"
    />
    <circle className="d" cx="307.3" cy="216.38" r="14.31" />
    <circle className="d" cx="193.62" cy="216.38" r="14.31" />
  </svg>
);

BotSvg.propTypes = {
  className: PropTypes.string
};

const StyledBotAvatar = styled(BotSvg)`
  .a {
    fill: #fff;
  }

  .b {
    fill: #e6e6e6;
  }

  .c {
    fill: #ccc;
  }

  .d,
  .e,
  .f,
  .g {
    fill: none;
    stroke: #000;
  }

  .d,
  .e,
  .f {
    stroke-miterlimit: 10;
  }

  .d,
  .e,
  .g {
    stroke-width: 11.72px;
  }

  .e,
  .f,
  .g {
    stroke-linecap: round;
  }

  .f {
    stroke-width: 10.55px;
  }
  .g {
    stroke-linejoin: round;
  }
`;

const BotAvatar = () => {
  const { botAvatarUrl } = useBot();

  return (
    <Avatar data-testid="bot-avatar">
      {botAvatarUrl ? <img src={botAvatarUrl} alt={''} /> : <StyledBotAvatar />}
    </Avatar>
  );
};

export default BotAvatar;
