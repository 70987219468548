import React from 'react';

import { Flex, Button } from '@ubisend/pulse-components';

import { useComposer } from '../../hooks/index';

const MultiComposerGroupWrapper = props => {
  const { dispatch } = useComposer();

  const handleNewResponse = () => {
    dispatch({ type: 'ADD_RESPONSE' });
  };

  return (
    <>
      {props.children}
      <Flex fat right>
        <Button variant="secondary" onClick={handleNewResponse}>
          + New message
        </Button>
      </Flex>
    </>
  );
};

export default MultiComposerGroupWrapper;
