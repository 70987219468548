import tw, { styled } from 'twin.macro';

import { motion } from '@ubisend/framer-motion';

const IconWrapper = styled(motion.button)`
  ${tw`bg-transparent border-0 p-0 outline-none flex items-center justify-center cursor-pointer`}
  &:hover {
    opacity: 0.75;
  }
  &:active {
    opacity: 0.5;
  }
`;

export default IconWrapper;
