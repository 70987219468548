import tw, { styled } from 'twin.macro';

import { size, spacing, text, display, colours, flex } from '../styles';

const Span = styled.span`
  ${size}
  ${spacing}
  ${text}
  ${display}
  ${colours}
  ${flex}

  ${props => props.clickable && tw`cursor-pointer hover:underline`}
`;

export default Span;
