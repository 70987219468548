import React from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  TextInput,
  Label,
  Button,
  Tooltip
} from '@ubisend/pulse-components';

function FlexRows({ content, tableRowRemove, onValUpdate }) {
  return content.map((data, index) => {
    const { search, replace } = data;
    return (
      <Flex key={index}>
        <Flex col fat mr>
          {index === 0 && <Label htmlFor="search">Find...</Label>}
          <TextInput
            id="search"
            aria-label={`row-search-${index}`}
            placeholder="search"
            value={search}
            onChange={event => onValUpdate(index, event)}
          />
        </Flex>
        <Flex col fat mr>
          {index === 0 && (
            <Flex top>
              <Label htmlFor="replace">Replace with...</Label>
              <Tooltip
                tooltip={
                  <Tooltip.Content>
                    Enter a value or leave blank to delete
                  </Tooltip.Content>
                }>
                <Tooltip.Icon>?</Tooltip.Icon>
              </Tooltip>
            </Flex>
          )}
          <TextInput
            id="replace"
            aria-label={`row-replace-${index}`}
            placeholder="replace"
            value={replace}
            onChange={event => onValUpdate(index, event)}
          />
        </Flex>
        <Flex bottom>
          <Button
            selfBottom
            icon="trash"
            colour="danger"
            variant="secondary"
            aria-label={`row-delete-${index}`}
            onClick={() => tableRowRemove(index)}
            disabled={index === 0}>
            Delete
          </Button>
        </Flex>
      </Flex>
    );
  });
}

const ReplaceInput = ({ content, onChange }) => {
  const addRowTable = () => {
    const data = [...content.data, { search: '', replace: '' }];
    onChange({ data });
  };

  const tableRowRemove = index => {
    const data = [...content.data];
    data.splice(index, 1);
    onChange({ data });
  };

  const onValUpdate = (i, event) => {
    const data = [...content.data];
    data[i] = { ...data[i], [event.target.id]: event.target.value };
    onChange({ data });
  };

  return (
    <>
      <FlexRows
        content={content.data}
        tableRowRemove={tableRowRemove}
        onValUpdate={onValUpdate}
      />
      <Flex>
        <Button variant="secondary" onClick={addRowTable}>
          + Add new
        </Button>
      </Flex>
    </>
  );
};

ReplaceInput.propTypes = {
  content: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        search: PropTypes.string,
        replace: PropTypes.string
      })
    )
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

export default ReplaceInput;
