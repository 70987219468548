import React from 'react';

import { ConditionalContext } from '../Contexts/index';
import { useConditionalReducer } from '../hooks/index';

const ConditionalProvider = ({ children, ...config }) => {
  const context = useConditionalReducer({
    ...config,
    conditionals: null,
    messageEquals: true
  });

  return (
    <ConditionalContext.Provider value={context}>
      {children}
    </ConditionalContext.Provider>
  );
};

export default ConditionalProvider;
