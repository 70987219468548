import React from 'react';

import { useComposer } from '../../../../hooks/index';
import CarouselItem from '../Carousel/CarouselItem';

const GeneralInput = () => {
  const {
    response: { content },
    dispatch
  } = useComposer();

  const handleImageChange = image => {
    dispatch({
      type: 'UPDATE_CONTENT',
      newContent: { ...content, image }
    });
  };

  const handleUrlChange = value => {
    dispatch({
      type: 'UPDATE_CONTENT',
      newContent: { ...content, url: value }
    });
  };

  const handleTitleChange = value => {
    dispatch({
      type: 'UPDATE_CONTENT',
      newContent: { ...content, title: value }
    });
  };

  const handleSubtitleChange = value => {
    dispatch({
      type: 'UPDATE_CONTENT',
      newContent: { ...content, subtitle: value }
    });
  };

  const handleImageDescriptionChange = value => {
    dispatch({
      type: 'UPDATE_CONTENT',
      newContent: { ...content, image_description: value }
    });
  };

  const handleSizeChange = () => {
    dispatch({
      type: 'UPDATE_CONTENT',
      newContent: { ...content, full_size: !content.full_size }
    });
  };

  const handleResponsesChange = responses => {
    dispatch({
      type: 'UPDATE_CONTENT',
      newContent: { ...content, responses }
    });
  };

  return (
    <CarouselItem
      {...content}
      handleImageChange={handleImageChange}
      handleUrlChange={handleUrlChange}
      handleTitleChange={handleTitleChange}
      handleSubtitleChange={handleSubtitleChange}
      handleResponsesChange={handleResponsesChange}
      handleImageDescriptionChange={handleImageDescriptionChange}
      handleSizeChange={handleSizeChange}
    />
  );
};

export default GeneralInput;
