import React from 'react';
import PropTypes from 'prop-types';
import { styled } from 'twin.macro';
import chroma from 'chroma-js';

import {
  Panel,
  Flex,
  Button,
  InfoSection,
  OneQuarterLayout,
  Accordion,
  Div
} from '@ubisend/pulse-components';
import { MarkdownCode } from '@ubisend/pulse-markdown';
import { useCopyToClipboard } from '@ubisend/pulse-component-hooks';

import { useBotSettings } from '../../hooks';

const StyledMarkdownCode = styled(MarkdownCode)`
  display: grid;
`;

const StyledPre = styled.pre`
  overflow-x: auto;
  white-space: pre;
  margin: 0;
  font-family: inherit;
`;

const StyledCode = styled.code`
  word-wrap: normal;
  white-space: pre-wrap;
  font-family: inherit;
`;

const StyledButton = styled(Button)`
  top: -1px;
  right: -1px;
  &:not(:disabled):hover {
    background-color: #fff;
  }
  border: 1px solid ${({ theme }) => theme.warning};
`;

const StyledAccordion = styled(Accordion)`
  border: 1px solid ${({ theme }) => theme.warning};
  background-color: ${({ theme }) => chroma(theme.warning).alpha(0.1)};

  hr {
    border: 0 solid ${({ theme }) => theme.warning};
    border-top-width: 1px;
  }

  > button {
    background-color: ${({ theme }) => chroma(theme.warning).alpha(0)};
  }

  && button:not(:disabled):focus {
    border: 1px solid ${({ theme }) => chroma(theme.warning).alpha(0.5).css()};

    box-shadow: 0 0 0 0.2rem ${({ theme }) => chroma(theme.warning).alpha(0.25)};
  }
`;

const StyledDiv = styled(Div)`
  color: ${({ theme }) => theme.grey};
`;

const DeploymentCode = ({ hideTitle }) => {
  const { copy, copied } = useCopyToClipboard();
  const { deploymentScript, deploymentInfo } = useBotSettings();

  return (
    <Flex>
      {hideTitle ? null : (
        <OneQuarterLayout>
          <InfoSection title="Deploy" info={deploymentInfo} />
        </OneQuarterLayout>
      )}
      <Panel>
        <StyledAccordion open header="Deploy">
          <Flex col ySpace>
            <StyledDiv mtNone>{deploymentInfo}</StyledDiv>
            <StyledMarkdownCode relative aria-label="deployment-code-preview">
              <StyledPre>
                <StyledCode>{deploymentScript()}</StyledCode>
              </StyledPre>
              <StyledButton
                absolute
                pinTop
                pinRight
                variant="secondary"
                icon="clipboardCopy"
                aria-label="Copy script snippet to clipboard"
                onClick={() => copy(deploymentScript())}>
                {copied ? 'Copied' : ''}
              </StyledButton>
            </StyledMarkdownCode>
          </Flex>
        </StyledAccordion>
      </Panel>
    </Flex>
  );
};

DeploymentCode.propTypes = {
  hideTitle: PropTypes.bool
};

export default DeploymentCode;
