import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

import { motion } from '@ubisend/framer-motion';

const StyledImage = styled(motion.img)`
  ${tw`w-auto h-auto`}
  max-width: calc(100% - 40px);
  max-height: calc(100vh - 40px);
  margin: 20px;
`;

const variants = {
  show: {
    y: 0,
    x: 0,
    width: 'auto',
    opacity: 1,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      delay: 0.2,
      duration: 0.6
    }
  },
  hide: {
    y: 'calc(50vh - 300px)',
    x: 'calc(50vw - 250px)',
    width: 250,
    opacity: 0,
    transition: {
      ease: [0.32, 0, 0.67, 0],
      duration: 0.6
    }
  }
};

const GalleryImage = ({ image }) => (
  <StyledImage
    data-testid="lightbox-image"
    initial="hide"
    animate="show"
    variants={variants}
    exit="hide"
    src={image}
  />
);

GalleryImage.propTypes = {
  image: PropTypes.string
};

export default GalleryImage;
