import tw, { styled } from 'twin.macro';

import { pseudoStyles } from '../Input/TextInput';

const InputButton = styled.button`
  ${tw`absolute right-0 h-full bg-transparent border-none outline-none cursor-pointer flex px-3 items-center rounded-sm`}
  svg {
    opacity: 0.25;
  }
  &:hover svg {
    opacity: 0.5;
  }
  &:active svg {
    opacity: 0.25;
  }
  ${pseudoStyles}
`;

const InputOverlay = styled.div`
  ${tw`absolute h-full bg-transparent border-none outline-none flex px-3 items-center rounded-sm opacity-25`}
  ${props => props.right && tw`right-0`}
  ${props => props.left && tw`left-0`}
`;

InputButton.defaultProps = {
  type: 'button'
};

export default InputButton;
export { InputOverlay };
