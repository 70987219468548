import tw, { css } from 'twin.macro';

const styles = css`
  ${tw`md:px-4 md:py-3 py-2 text-white tracking-wide font-semibold font-poppins rounded-full text-xs md:text-sm cursor-pointer no-underline text-center`}
  background-color: rgba(255, 255, 255, 0.25);
  border: 2px solid rgba(255, 255, 255, 0.25);
  @media (max-width: 320px) {
    ${tw`py-1`}
  }
`;

export default styles;
