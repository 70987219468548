import React from 'react';

import {
  PageWrapper,
  OneHalfLayout,
  Flex,
  Divider
} from '@ubisend/pulse-components';
import { useAuth } from '@ubisend/pulse-auth';
import { BackButton } from '@ubisend/pulse-channels';

import { BotSettingsProvider } from '../Providers/index';
import {
  FullPageColourSettings,
  FullPageGeneralSettings,
  SettingPageSettings,
  DeploymentCode,
  FullPagePreview,
  FontCode
} from '../Components/index';
import { updateFullPageSettings, updateFullPageLogo } from '../api/index';
import SaveSettings from '../Components/FullPage/SaveSettings';

const FullPageSettings = () => {
  const { client } = useAuth();

  const deployment = {
    script: `
   <html>
     <style>
       body, html {
         padding: 0;
         margin: 0;
       }
       iframe {
         width: 100vw;
         height: 100vh;
         border: none;
       }
     </style>
     <body>
       <iframe src="${process.env.REACT_APP_FULL_PAGE_DEPLOYMENT_ROOT_URL}?c=${process.env.REACT_APP_INSTALL_NAME}&i=${client.identifier}" id="chat-widget-1925"></iframe>
     </body>
   </html>`,
    info: 'Create a webpage with this snippet.'
  };

  return (
    <PageWrapper
      header={'Full Page Widget'}
      prevAction={<BackButton to="/channels" />}
      subheader={`Manage the appearance of your Full Page Widget`}>
      <Flex>
        <BotSettingsProvider
          type="full-page"
          updateBotLogo={updateFullPageLogo}
          updateBotSettings={updateFullPageSettings}
          deploymentDetails={deployment}>
          <OneHalfLayout>
            <Flex mr col ySpace>
              <DeploymentCode />
              <Divider pb />
              <FullPageGeneralSettings />
              <Divider pb />
              <FullPageColourSettings />
              <Divider pb />
              <FontCode />
              <Divider pb />
              <SettingPageSettings />
              <Divider pb />
              <SaveSettings />
            </Flex>
          </OneHalfLayout>
          <OneHalfLayout>
            <FullPagePreview />
          </OneHalfLayout>
        </BotSettingsProvider>
      </Flex>
    </PageWrapper>
  );
};

export default FullPageSettings;
