import file from './File/index';
import postback from './Postback/index';
import callto from './Callto/index';
import mailto from './Mailto/index';
import url from './Url/index';
import contentButton from './Content/index';

const allTypes = {
  file,
  callto,
  mailto,
  postback,
  url,
  contentButton
};

const types = {
  ...allTypes,
  all: [
    allTypes.callto,
    allTypes.mailto,
    allTypes.url,
    allTypes.file,
    allTypes.postback,
    allTypes.contentButton
  ]
};

export default types;
