import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useNotifySubscription } from '@ubisend/pulse-subscribe';

import { useTypingReducer } from '../reducers/index';
import { useBot, useDelayedBot, useDelivery } from '../hooks/index';
import { TypingContext } from '../Contexts/index';

const EVENTS = {
  AGENT_STARTED_TYPING: 'AGENT_STARTED_TYPING',
  AGENT_STOPPED_TYPING: 'AGENT_STOPPED_TYPING',
  MESSAGE_ROUTING_WAS_STARTED: 'MESSAGE_ROUTING_WAS_STARTED',
  FILES_ROUTING_WAS_STARTED: 'FILES_ROUTING_WAS_STARTED'
};

const SubscriberTypingProvider = ({ children }) => {
  const { messages, queuedMessages, name } = useDelayedBot();
  const { showName } = useBot();
  const { subscribed } = useDelivery();
  const typing = useTypingReducer({
    typing: !subscribed,
    message: null
  });

  const { t } = useTranslation('bots');

  const { dispatch, TYPES } = typing;

  const handleAgentStartedTyping = useCallback(
    ({ agent }) => {
      dispatch({
        type: TYPES.START_TYPING,
        message: t('agent_typing_label', { name: agent.name })
      });
    },
    [dispatch, TYPES, t]
  );

  const handleAgentStopedTyping = useCallback(() => {
    dispatch({ type: TYPES.STOP_TYPING });
  }, [dispatch, TYPES]);

  const handleMessageRoutingStarting = useCallback(() => {
    if (showName) {
      dispatch({
        type: TYPES.START_TYPING,
        message: t('chatbot_thinking_label', { name })
      });
    } else {
      dispatch({
        type: TYPES.START_TYPING
      });
    }
  }, [dispatch, TYPES, name, showName, t]);

  const handleFilesRoutingStarting = useCallback(() => {
    dispatch({
      type: TYPES.START_TYPING,
      message: t('processing_files_label')
    });
  }, [dispatch, TYPES, t]);

  useNotifySubscription(EVENTS.AGENT_STARTED_TYPING, handleAgentStartedTyping);
  useNotifySubscription(EVENTS.AGENT_STOPPED_TYPING, handleAgentStopedTyping);

  useNotifySubscription(
    EVENTS.MESSAGE_ROUTING_WAS_STARTED,
    handleMessageRoutingStarting
  );

  useNotifySubscription(
    EVENTS.FILES_ROUTING_WAS_STARTED,
    handleFilesRoutingStarting
  );

  useEffect(() => {
    if (queuedMessages.length > 0) {
      return;
    }

    dispatch({ type: TYPES.STOP_TYPING });
  }, [messages, queuedMessages, dispatch, TYPES, subscribed, name]);

  return (
    <TypingContext.Provider value={typing}>{children}</TypingContext.Provider>
  );
};

export default SubscriberTypingProvider;
