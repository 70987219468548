import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { styled } from 'twin.macro';

import { useQuery } from '@ubisend/pulse-hooks';
import { AnimatePresence, AnimateSharedLayout } from '@ubisend/framer-motion';
import {
  Panel,
  UserIcon,
  Timeline,
  Profile,
  Flex,
  InfiniteScroll,
  Placeholder,
  Label,
  Paragraph,
  EmptyStatePlaceholder
} from '@ubisend/pulse-components';

import { getInitials, CommentContainer } from './Comments';

const StyledInfiniteScroll = styled(InfiniteScroll)`
  max-height: 50rem;
  overflow-y: auto;
`;

const Activity = ({ query, getEvent }) => {
  const [page, setPage] = useState(1);
  const [statuses, setStatuses] = useState([]);

  const { data } = useQuery([query, { page }]);

  useEffect(() => {
    if (data) {
      setStatuses(statuses => statuses.concat(data.data));
    }
  }, [data]);

  const handleBottomReached = () => {
    if (page === data.meta.last_page) {
      return;
    }

    setPage(page + 1);
  };

  if (!data) {
    return <Placeholder />;
  }

  if (data.data.length === 0) {
    return (
      <Flex middle center fat ySpace col>
        <EmptyStatePlaceholder
          customHeight="500px"
          type="tickets"
          heading="No tickets have been worked on yet"
          text="Manage tickets and agent settings."
          helpLink="/docs/2151317744/2153382239"
          helpText="Learn more about the ticketing system."
        />
      </Flex>
    );
  }

  return (
    <AnimateSharedLayout initial={false}>
      <Panel
        transition={{ layoutY: { ease: [0.16, 1, 0.3, 1], duration: 0.6 } }}>
        <StyledInfiniteScroll handleBottomReached={handleBottomReached}>
          <Flex col>
            <AnimatePresence initial={false}>
              {statuses.map((status, key) => (
                <CommentContainer
                  layout
                  key={status.id}
                  initial={{ opacity: 0, scale: 0.75 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.75 }}
                  transition={{ ease: [0.16, 1, 0.3, 1], duration: 0.6 }}>
                  <Flex mrSm col center>
                    <UserIcon>
                      {status.agent ? getInitials(status.agent) : 'N/A'}
                    </UserIcon>
                    {key !== statuses.length - 1 && <Timeline />}
                  </Flex>
                  <Flex col>
                    <Profile col middle>
                      <Label mb={false}>{status.agent?.name || 'N/A'}</Label>
                      <Label mb={false} secondary>
                        {status.created_at
                          ? dayjs(status.created_at).from(dayjs())
                          : 'N/A'}
                      </Label>
                    </Profile>
                    <Flex mb={key !== statuses.length - 1}>
                      <Paragraph secondary>
                        {getEvent(status, statuses[key + 1])}
                      </Paragraph>
                    </Flex>
                  </Flex>
                </CommentContainer>
              ))}
            </AnimatePresence>
          </Flex>
        </StyledInfiniteScroll>
      </Panel>
    </AnimateSharedLayout>
  );
};

Activity.propTypes = {
  query: PropTypes.string.isRequired,
  getEvent: PropTypes.func.isRequired
};

export default Activity;
