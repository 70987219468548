import {
  HEADER_FONT_SIZE,
  BUBBLE_INNER_WIDTH,
  BUBBLE_PADDING_X,
  MESSAGE_PREVIEW_PADDING,
  HEADER_PADDING_X,
  HEADER_PADDING_Y
} from './constants';
import textBox from './textBox';
import { getLines } from './util';

const getSubjectPreview = subject => {
  if (subject.type === 'variable') {
    return subject.target;
  }

  return subject.type;
};

const conditionalPreview = (ctx, x, y, node) => {
  const { conditionals, match } = node.base;

  const text = conditionals.reduce((previous, current, i) => {
    let currentString = '';

    if (i > 0) {
      const prefix = match === 'all' ? 'and' : 'or';
      currentString += `${prefix} `;
    }

    if (current.flipped) {
      currentString += 'not ';
    }

    if (current.subject && current.type.type !== 'event') {
      currentString += `${getSubjectPreview(current.subject)} `;
    }

    currentString += `${current.type.name.toLowerCase()} `;

    if (current.condition) {
      currentString += `${current.condition} `;
    }

    if (i === 0) {
      return previous + currentString;
    }

    return previous + '\n' + currentString;
  }, 'When ');

  ctx.save();
  ctx.fillStyle = 'black';
  ctx.font = '600 12px Poppins';
  ctx.fillText(
    node.type.toUpperCase(),
    x + HEADER_PADDING_X,
    y + HEADER_FONT_SIZE + HEADER_PADDING_Y
  );

  // Return the height of the preview
  return (
    textBox(
      ctx,
      x + BUBBLE_PADDING_X,
      y + HEADER_FONT_SIZE + MESSAGE_PREVIEW_PADDING + HEADER_PADDING_Y * 1.4,
      getLines(ctx, text, BUBBLE_INNER_WIDTH),
      'gray'
    ) +
    MESSAGE_PREVIEW_PADDING * 2
  );
};

export default conditionalPreview;
