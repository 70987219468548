import React, { useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { styled } from 'twin.macro';
import { TwitterPicker } from 'react-color';
import PropTypes from 'prop-types';

const StyledPicker = styled(TwitterPicker)`
  width: 312px !important;
`;

const StyledDiv = styled.div`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const ColourSwatch = styled.div`
  width: 70px;
  height: 30px;
  border-radius: 2px;
  background-color: ${props => props.color};
`;

const SwatchWrapper = styled.div`
  position: relative;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
`;

const Popover = styled.div`
  position: absolute;
  z-index: 100;
  margin-left: 10px;
  left: 100%;
  top: 0;
`;

const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

const ColourPicker = props => {
  const theme = useContext(ThemeContext);
  const [displayColourPicker, setDisplayColourPicker] = useState(false);

  const colours = [
    theme.primary,
    theme.white,
    theme.black,
    theme.grey,
    theme.secondary,
    theme.positive,
    theme.warning,
    theme.danger
  ];

  const handleClick = () => {
    setDisplayColourPicker(!displayColourPicker);
  };

  const handleClose = () => {
    setDisplayColourPicker(false);
  };

  return (
    <StyledDiv data-testid={props.testId ?? ''}>
      <SwatchWrapper>
        <ColourSwatch
          color={props.color}
          onClick={handleClick}
          data-testid={`${props.testId ?? ''}-selected-colour`}
        />
        {displayColourPicker ? (
          <Popover data-testid={`${props.testId}-popover`}>
            <Cover onClick={handleClose} />
            <StyledPicker triangle="hide" colors={colours} {...props} />
          </Popover>
        ) : null}
      </SwatchWrapper>
    </StyledDiv>
  );
};

ColourPicker.propTypes = {
  testId: PropTypes.string,
  color: PropTypes.string
};

export default ColourPicker;
