import React from 'react';
import PropTypes from 'prop-types';

import { Button, Flex, Heading2 } from '@ubisend/pulse-components';

const FaqsFooter = ({ selectedFaqs, handleDelete, handleClear }) => {
  return (
    <Flex between>
      <Heading2>
        {`${selectedFaqs.length} FAQ${
          selectedFaqs.length === 1 ? '' : 's'
        } selected.`}
      </Heading2>
      <Flex xSpace>
        <Button icon="x" onClick={handleClear}>
          Clear
        </Button>
        <Button
          variant="secondary"
          colour="danger"
          icon="trash"
          aria-label="button-delete-all"
          onClick={handleDelete}>
          Delete {selectedFaqs.length} faq
          {selectedFaqs.length === 1 ? '' : 's'}
        </Button>
      </Flex>
    </Flex>
  );
};

FaqsFooter.propTypes = {
  selectedFaqs: PropTypes.array.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired
};

export default FaqsFooter;
