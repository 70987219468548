import React from 'react';
import { useLocation } from 'react-router-dom';

import { Flex, Heading1, Divider } from '@ubisend/pulse-components';
import { useQuery } from '@ubisend/pulse-hooks';

import { useAuth } from '../hooks/index';
import methods from '../Components/LoginMethods/index';
import {
  AnimatedAuthContainer,
  AuthErrorRedirect,
  LoggedInRedirect
} from '../Components/index';

const Login = () => {
  const { authenticated, loginUser } = useAuth();

  const location = useLocation();

  const code = new URLSearchParams(location?.search).get('code');

  const callbackQuery = useQuery(`auth/oauth2/callback${location?.search}`, {
    enabled: Boolean(code && !authenticated),
    onSuccess: ({ data }) => {
      const { token, client, ...user } = data;
      loginUser({ user, token, client });
    }
  });

  const query = useQuery('auth');

  if (authenticated) {
    return <LoggedInRedirect />;
  }

  if (code && callbackQuery.isError) {
    return <AuthErrorRedirect />;
  }

  return (
    <AnimatedAuthContainer>
      {query.isSuccess && (
        <>
          <Heading1 style={{ fontSize: '2rem' }} mb textCenter>
            Login
          </Heading1>
          <Flex col ySpace>
            {query.data.data.map((method, key) => {
              const { Login } = methods[method.name];

              return (
                <React.Fragment key={key}>
                  {key === 1 && <Divider text="Or" />}
                  <Login method={method} />
                </React.Fragment>
              );
            })}
          </Flex>
        </>
      )}
    </AnimatedAuthContainer>
  );
};

export default Login;
