import { hasPermission } from '@ubisend/pulse-auth';

import { VoipLink } from './Pages/index';

const routes = [
  {
    name: 'Voip',
    path: '/voip-link',
    component: VoipLink,
    canAccess: hasPermission('view voip settings'),
    actions: []
  }
];

export default routes;
