import tw, { styled } from 'twin.macro';

const RightUpConnector = styled.div`
  ${tw`h-4 w-3`}
  border-bottom-left-radius: 2rem;
  border-bottom: 2px solid #888888;
  border-left: 2px solid #888888;
`;

export default RightUpConnector;
