import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { motion } from '@ubisend/framer-motion';
import { useAutoTextArea } from '@ubisend/pulse-component-hooks';

import { useComposer, useDelivery } from '../../../../../hooks/index';
import {
  Container,
  LeftSection,
  ComposerButton,
  Airplane,
  Label,
  labelAnimations
} from '../Custom/MessageComposer';

const TextArea = styled(motion.textarea)`
  ${tw`border-0 outline-none text-base mt-2 w-full resize-none`}

  font-family: inherit;
  font-size: inherit;

  &:disabled,
  &[disabled] {
    ${tw`cursor-not-allowed opacity-0`}
  }
`;

const FeedbackComposer = () => {
  const [inputActive, setInputActive] = useState(false);

  const { t } = useTranslation('full_page');

  const { reply, setReply } = useComposer();
  const { sendMessage } = useDelivery();

  const ref = useAutoTextArea(reply, {
    lineHeight: 23,
    initialTextAreaHeight: 23
  });

  const handleOnChange = event => {
    const value = event.target.value;

    setReply(value);
  };

  const handleNewReply = () => {
    if (!reply) {
      return;
    }

    sendMessage(reply);
    setReply('');
  };

  const handleBlur = () => {
    if (inputActive && reply.length === 0) {
      setInputActive(false);
    }
  };

  const handleFocus = () => {
    if (!inputActive) {
      setInputActive(true);
    }
  };

  return (
    <Container>
      <LeftSection htmlFor="feedback-composer">
        <Label
          htmlFor="feedback-composer"
          initial="expanded"
          variants={labelAnimations}
          animate={inputActive ? 'minimised' : 'expanded'}>
          {t('feedback_composer_label')}
        </Label>
        <TextArea
          ref={ref}
          id="feedback-composer"
          value={reply}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleOnChange}
        />
      </LeftSection>
      <ComposerButton
        aria-label={t('send_button_label')}
        onClick={handleNewReply}>
        <Airplane />
      </ComposerButton>
    </Container>
  );
};

FeedbackComposer.propTypes = {
  content: PropTypes.shape({
    placeholder: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired
  })
};

export default FeedbackComposer;
