import date from './Date/index';
import regex from './Regex/index';
import unique from './Unique/index';
import dateModify from './DateModify/index';
import escape from './Escape/index';
import round from './Round/index';
import replace from './Replace/index';
import extract from './Extract/index';
import urlEncode from './UrlEncode/index';
import numberFormat from './NumberFormat/index';
import queryParameter from './QueryParameter/index';
import explodeToJsonArray from './ExplodeToJsonArray/index';

const defaultFilters = [
  date,
  dateModify,
  escape,
  replace,
  explodeToJsonArray,
  extract,
  numberFormat,
  round,
  queryParameter,
  regex,
  unique,
  urlEncode
];

const filters = {
  date,
  dateModify,
  escape,
  replace,
  explodeToJsonArray,
  extract,
  numberFormat,
  round,
  queryParameter,
  regex,
  default: defaultFilters,
  unique,
  urlEncode
};

export default filters;
