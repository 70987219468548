import React from 'react';
import PropTypes from 'prop-types';

import {
  Panel,
  Flex,
  OneQuarterLayout,
  InfoSection,
  TabButton,
  Divider
} from '@ubisend/pulse-components';
import { ButtonComposer } from '@ubisend/pulse-composer';
import { AnimateSharedLayout } from '@ubisend/framer-motion';
import { useLanguages, LanguagesConsumer } from '@ubisend/pulse-hooks';

import { useBotSettings } from '../../hooks/index';

const ComposerSettings = props => {
  const {
    language,
    setLanguage,
    settings,
    focusBot,
    setSettings
  } = useBotSettings();
  const { languages } = useLanguages();

  const handleButtonChange = buttonsInCurrentLanguage => {
    focusBot();

    setSettings(settings => {
      const buttons = settings.buttons
        .filter(button => button.language_id !== language.id)
        .concat(
          buttonsInCurrentLanguage.map(button => ({
            ...button,
            language_id: language.id
          }))
        );

      return { ...settings, buttons };
    });
  };

  return (
    <Flex>
      {props.titlePosition === 'left' && (
        <OneQuarterLayout>
          <InfoSection title="Hamburger" info="Create a persistent menu." />
        </OneQuarterLayout>
      )}

      <Panel>
        {props.titlePosition === 'top' && (
          <>
            <InfoSection
              mtNone
              title="Hamburger"
              info="Create a persistent menu."
            />
            <Divider />
          </>
        )}
        <Flex col ySpace>
          <LanguagesConsumer>
            <Flex>
              <AnimateSharedLayout>
                {languages.map(languageTab => (
                  <TabButton
                    onClick={() => setLanguage(languageTab)}
                    active={languageTab.id === language.id}
                    key={languageTab.id}>
                    {languageTab.name}
                  </TabButton>
                ))}
              </AnimateSharedLayout>
            </Flex>
            <Divider fullWidth mtNone />
          </LanguagesConsumer>
          <ButtonComposer
            only={['postback']}
            value={settings.buttons.filter(
              button => button.language_id === language.id
            )}
            onChange={handleButtonChange}
          />
        </Flex>
      </Panel>
    </Flex>
  );
};

ComposerSettings.propTypes = {
  titlePosition: PropTypes.string
};

ComposerSettings.defaultProps = {
  titlePosition: 'left'
};

export default ComposerSettings;
