import React from 'react';
import PropTypes from 'prop-types';

import { useQueryClient, useMutation } from '@ubisend/pulse-hooks';
import {
  Label,
  Flex,
  Button,
  useNotification
} from '@ubisend/pulse-components';

import { updateUserRoles, updateUserPermissions } from '../api/index';
import Roles from './Roles';
import Permissions from './Permissions';
import { useRolesAndPermissions } from '../hooks/index';

const EditUser = ({ user }) => {
  const { showSuccess } = useNotification();
  const {
    roles,
    setRoles,
    permissions,
    setPermissions,
    formattedPermissions
  } = useRolesAndPermissions({
    initialRoles: user.roles,
    initialPermissions: user.permissions
  });

  const queryClient = useQueryClient();
  const {
    mutateAsync: updateRoles,
    isLoading: roleUpdateLoading
  } = useMutation(updateUserRoles, {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
      showSuccess('Successfully updated user roles');
    }
  });
  const {
    mutateAsync: updatePermissions,
    isLoading: permissionUpdateLoading
  } = useMutation(updateUserPermissions);

  const handleRolesChange = roles => {
    setRoles(roles);
  };

  const handlePermissionsChange = permissions => {
    setPermissions(permissions);
  };

  const handleSubmit = event => {
    event.preventDefault();

    updateRoles({ userId: user.id, ids: roles.map(role => role.id) });
    updatePermissions({
      userId: user.id,
      ids: permissions.map(permission => permission.id)
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Flex col ySpace>
        <div>
          <Label>Roles</Label>
          <Roles value={roles} onChange={handleRolesChange} />
        </div>
        <div>
          <Label>Extra Permissions</Label>
          <Permissions
            value={formattedPermissions}
            onChange={handlePermissionsChange}
          />
        </div>
        <Flex fat right>
          <Button
            variant="primary"
            type="submit"
            loading={roleUpdateLoading || permissionUpdateLoading}>
            Save
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};

const permissionsType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired
  })
).isRequired;

EditUser.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    roles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        permissions: permissionsType
      })
    ).isRequired,
    permissions: permissionsType
  }).isRequired
};
export default EditUser;
