import tw, { styled } from 'twin.macro';

import { motion } from '@ubisend/framer-motion';
import { breakpoints } from '@ubisend/pulse-components';

const ScreenContainer = styled(motion.div)`
  ${tw`flex flex-col mt-16 lg:mt-0 items-stretch max-w-lg mx-auto p-4 overflow-y-auto`}
  height: calc(100% - 70px);

  @media (min-width: ${breakpoints.md}px) {
    height: calc(100% - 8rem);
  }
`;

ScreenContainer.defaultProps = {
  initial: 'initial',
  animate: 'show',
  tabIndex: '0',
  variants: {
    initial: {},
    show: {
      transition: {
        staggerChildren: 0.075
      }
    }
  }
};

export default ScreenContainer;
