import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';

import Icon from '@ubisend/pulse-icons';

const IconWrapper = styled.button`
  ${tw`bg-transparent border-0 p-0 outline-none flex items-center justify-center cursor-pointer`}
  &:hover {
    opacity: 0.75;
  }
  &:active {
    opacity: 0.5;
  }
`;

const ModalClose = ({ onClick, ...props }) => (
  <IconWrapper aria-label="Close modal" onClick={onClick} {...props}>
    <Icon
      colour={'#000'}
      size="1.5rem"
      width="1.5rem"
      height="1.5rem"
      style={{ cursor: 'pointer' }}
      type={'x'}
    />
  </IconWrapper>
);

ModalClose.propTypes = {
  onClick: PropTypes.func
};

export default ModalClose;
