import React from 'react';
import tw, { styled } from 'twin.macro';

import BaseComposer from '../../BaseComposer';

const StyledComposer = styled(BaseComposer)`
  ${tw`cursor-pointer`}
`;

const DateInput = props => (
  <StyledComposer showBurgerIcon={false} readOnly {...props} />
);

export default DateInput;
