import React from 'react';
import PropTypes from 'prop-types';

const IntentUtterance = ({ faq, defaultLanguage }) => {
  const intent = faq.intents.find(
    intent => intent.language.id === defaultLanguage.id
  );

  if (intent && intent.utterances && intent.utterances.length > 0) {
    return <span>{intent.utterances[0].utterance}</span>;
  }

  return <span>No utterances available</span>;
};

IntentUtterance.propTypes = {
  faq: PropTypes.shape({
    intents: PropTypes.arrayOf(PropTypes.object).isRequired
  }).isRequired,
  defaultLanguage: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired
};

export default IntentUtterance;
