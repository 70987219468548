import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';

import {
  Flex,
  Tooltip,
  CreatableSelect as Select,
  Checkbox
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';
import { TemplatingTextInput } from '@ubisend/pulse-templating';

import { useButtons } from '../../../../hooks/index';
import ExpiresTooltip from '../../../ExpiresTooltip';

const formatLink = link => ({
  label: link.name || link.url,
  value: `${link.tracked_url}?subscriber_id={{ subscriber.identifier }}`
});

const UrlSelect = ({ value, onChange, ...props }) => {
  const [options, setOptions] = useState(null);

  useQuery('links/all', {
    onSuccess: ({ data }) => {
      const trackedLinks = data.map(formatLink);

      // If the user has a manual link (not tracked) add it as a new option group.
      const regularLinks =
        value && !trackedLinks.find(option => option.value === value)
          ? [{ label: value, value }]
          : [];

      setOptions([
        { label: 'Tracked links', options: trackedLinks },
        { label: 'Regular links', options: regularLinks }
      ]);
    }
  });

  const formatCreateLabel = input => input;

  const onCreateOption = input => {
    const option = { label: input, value: input };
    setOptions(options => [
      options[0],
      {
        ...options[1],
        options: options[1].options.concat(option)
      }
    ]);
    onChange(option);
  };

  const formattedValue = useMemo(() => {
    if (!options || !value) {
      return null;
    }

    const option = options.find(group => {
      return group.options.find(option => option.value === value);
    });

    if (!option) {
      return null;
    }

    return option.options.find(option => option.value === value);
  }, [options, value]);

  return (
    <Select
      {...props}
      onChange={onChange}
      isLoading={!options}
      value={formattedValue}
      options={options ? options : []}
      isCreatable
      formatCreateLabel={formatCreateLabel}
      onCreateOption={onCreateOption}
    />
  );
};

UrlSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

const UrlInput = ({ content, index }) => {
  const { handleButtonChange } = useButtons();

  return (
    <Flex col ySpace fat>
      <TemplatingTextInput
        aria-label={`url-label-${index + 1}`}
        value={content.label}
        placeholder="Visit Website"
        onChange={value => {
          handleButtonChange(index, 'label', value);
        }}
      />
      <Flex col>
        <PermissionFilter
          can="view links"
          fallback={
            <TemplatingTextInput
              aria-label={`url-action-${index + 1}`}
              value={content.action}
              placeholder="https://example.com"
              onChange={value => {
                handleButtonChange(index, 'action', value);
              }}
            />
          }>
          <UrlSelect
            aria-label={`url-action-${index + 1}`}
            value={content.action}
            placeholder="Type or select a link"
            onChange={({ value }) => {
              handleButtonChange(index, 'action', value);
            }}
          />
        </PermissionFilter>
      </Flex>
      <Flex center>
        <TemplatingTextInput
          aria-label={`url-linkback-${index + 1}`}
          value={content.linkback}
          placeholder="The link was clicked"
          onChange={value => {
            handleButtonChange(index, 'linkback', value);
          }}
        />
        <Tooltip
          position={Tooltip.POSITIONS.TOP}
          tooltip={
            <Tooltip.Content>
              This optional postback text can be used to trigger a new step when
              the user clicks the link defined above.
            </Tooltip.Content>
          }>
          <Tooltip.Icon>?</Tooltip.Icon>
        </Tooltip>
      </Flex>
      <Flex>
        <Checkbox
          aria-label={`url-expires-${index + 1}`}
          checked={content.linkback ? content.expires : false}
          disabled={!content.linkback}
          onChange={() => {
            handleButtonChange(index, 'expires', !content.expires);
          }}
          label="Disable on reply"
        />
        <ExpiresTooltip />
      </Flex>
    </Flex>
  );
};

UrlInput.propTypes = {
  content: {
    label: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    linkback: PropTypes.string,
    expires: PropTypes.bool
  },
  index: PropTypes.number.isRequired
};

export default UrlInput;
