import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import {
  Flex,
  InnerPanel,
  Heading3,
  Select,
  Button,
  Explainer,
  Placeholder,
  useNotification
} from '@ubisend/pulse-components';

import { updateTicketAssigner } from '../api/index';

const AssignmentSelect = ({ currentAssigner }) => {
  const [assigner, setAssigner] = useState({
    label: currentAssigner.algorithm.name,
    value: currentAssigner.algorithm.id
  });

  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const { data: assigners, isLoading } = useQuery(
    'tickets/ubidesk/assign-algorithms'
  );
  const changeAssigner = useMutation(updateTicketAssigner, {
    onSuccess: () => {
      queryClient.invalidateQueries('tickets/ubidesk/settings');
      showSuccess('Successfully updated ticket assignment algorithm');
    }
  });

  const handleSubmit = () => {
    changeAssigner.mutate({ id: assigner.value });
  };

  const handleAssignerChange = value => {
    setAssigner({ label: value.label, value: value.value });
  };

  return (
    <>
      {isLoading && <Placeholder />}
      {!isLoading && (
        <>
          <Flex>
            <Heading3>Assignment algorithm</Heading3>
          </Flex>
          <InnerPanel>
            <Flex mb>
              <Explainer>
                The assignment algorithm determines how tickets are
                automatically assigned to agents.
              </Explainer>
            </Flex>
            <Select
              aria-label={'assigner-select'}
              value={assigner}
              options={assigners.data.map(({ name, id }) => {
                return { value: id, label: name };
              })}
              onChange={handleAssignerChange}
            />
            <Flex right mt>
              <Button variant="primary" onClick={handleSubmit}>
                Save
              </Button>
            </Flex>
          </InnerPanel>
        </>
      )}
    </>
  );
};

AssignmentSelect.propTypes = {
  currentAssigner: PropTypes.object.isRequired
};

export default AssignmentSelect;
