import React from 'react';
import PropTypes from 'prop-types';

import Icon from '@ubisend/pulse-icons';

import BlockContainer from '../../BlockContainer';
import TextPreview from '../../TextPreview';

const EmailBlock = ({ icon, colour, ...props }) => {
  return (
    <BlockContainer colour={colour} {...props} aria-label="Email block">
      <Icon outline type={icon} />
      <TextPreview>{props.base.subject}</TextPreview>
    </BlockContainer>
  );
};

EmailBlock.propTypes = {
  icon: PropTypes.string.isRequired,
  colour: PropTypes.string.isRequired,
  base: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ).isRequired
};

export default EmailBlock;
