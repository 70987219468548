import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Span } from '@ubisend/pulse-components';

import ActionButton from './ActionButton/index';
import Bubble from './Bubble';
import { ImageWrapper } from './Image';

const IndividualGeneralWrapper = ({ children, direction }) => {
  return (
    <Flex fat right={direction === 'recievedMessage'}>
      {children}
    </Flex>
  );
};

IndividualGeneralWrapper.propTypes = {
  direction: Bubble.propTypes.direction
};

const General = ({
  content,
  handleButtonClick,
  direction,
  dataTestid = 'general-image',
  type,
  hasHadReply,
  ...props
}) => {
  const Wrapper =
    type === 'carousel' ? React.Fragment : IndividualGeneralWrapper;

  return (
    <Wrapper direction={direction}>
      <Bubble
        direction={direction}
        fat
        style={{
          padding: '0',
          width: '75%',
          maxWidth: '23rem',
          flexShrink: '0'
        }}
        {...props}>
        <Flex col tall>
          {content.url ? (
            <a
              href={content.url}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={content.title}>
              <ImageWrapper
                full_size={content.full_size}
                data-testid={dataTestid}
                url={content.image}
                aria-label={content.image_description}
              />
            </a>
          ) : (
            <ImageWrapper
              full_size={content.full_size}
              data-testid={dataTestid}
              url={content.image}
              noPointer
              aria-label={content.image_description}
            />
          )}
          <Flex col ySpaceSm padSm grow>
            <Span bold>{content.title}</Span>
            <Span>{content.subtitle}</Span>
          </Flex>
          <Flex col ySpaceSm padSm>
            {content.responses.map((response, key) => (
              <ActionButton
                key={key}
                direction={direction}
                response={response}
                handleButtonClick={handleButtonClick}
                hasHadReply={hasHadReply}
              />
            ))}
          </Flex>
        </Flex>
      </Bubble>
    </Wrapper>
  );
};

General.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    image: PropTypes.string.isRequired,
    image_description: PropTypes.string,
    full_size: PropTypes.bool,
    responses: PropTypes.array.isRequired,
    url: PropTypes.string
  }).isRequired,
  direction: Bubble.propTypes.direction,
  handleButtonClick: PropTypes.func.isRequired,
  dataTestid: PropTypes.string,
  type: PropTypes.oneOf(['carousel']),
  hasHadReply: PropTypes.bool
};

export default General;
