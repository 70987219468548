import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import ContentMessage from './ContentMessage';
import { useHasContentMessage } from '../hooks/index';

const ContentMessageSlideInPortal = ({ children }) => {
  if (process.env.NODE_ENV === 'test') {
    return children;
  }

  const element = document.getElementById('content-message-slide-in');

  if (!element) {
    return null;
  }

  return ReactDOM.createPortal(children, element);
};

const ContentMessageSlideIn = ({ content, sectionFromApi }) => {
  const hasContentMessage = useHasContentMessage(content);

  const showContentFromMessage = !sectionFromApi && hasContentMessage;

  return (
    <ContentMessageSlideInPortal>
      {sectionFromApi && <ContentMessage sectionFromApi={sectionFromApi} />}
      {showContentFromMessage && <ContentMessage content={content} />}
    </ContentMessageSlideInPortal>
  );
};

ContentMessageSlideIn.propTypes = {
  content: PropTypes.object.isRequired,
  sectionFromApi: PropTypes.string
};

export default ContentMessageSlideIn;
export { ContentMessageSlideInPortal };
