import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  Panel,
  WizardProgress,
  useNotification
} from '@ubisend/pulse-components';
import { FacebookLogin } from '@ubisend/pulse-facebook';
import { useQueryClient, useMutation } from '@ubisend/pulse-hooks';

import SelectPage from './SelectPage';
import { scope } from '../instagram';
import { updateSettings as updateSettingsApi } from '../api/settings';

const SetupInstagramComponent = () => {
  const [user, setUser] = useState();
  const { showSuccess, showError } = useNotification();
  const queryClient = useQueryClient();

  const { mutate: updateSettings } = useMutation(updateSettingsApi, {
    onSuccess: () => {
      showSuccess(`Linked Facebook page`);
      queryClient.invalidateQueries('instagram/settings');
    },
    onError: () => {
      showError(`Something went wrong whilst selecting a Facebook page`);
    }
  });

  const handlePageSelect = page => {
    const {
      value: { id, access_token },
      label
    } = page;

    updateSettings({
      page_id: id,
      auth_token: access_token,
      page_name: label
    });
  };

  const handleNewUser = newUser => setUser(newUser);

  return (
    <Panel
      divider
      header="Link your Instagram account through Facebook"
      actions={<WizardProgress steps={3} current={user ? 1 : 0} />}>
      <Flex col ySpace mt>
        {!user && (
          <FacebookLogin
            user={user}
            handleNewUser={handleNewUser}
            scope={scope}
          />
        )}
        {user && <SelectPage user={user} handlePageSelect={handlePageSelect} />}
      </Flex>
    </Panel>
  );
};

SetupInstagramComponent.propTypes = {
  handlePageSelect: PropTypes.func.isRequired
};

export default SetupInstagramComponent;
