import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Explainer, Button } from '@ubisend/pulse-components';

import { useActionReducer } from '../../../../reducers/index';
import ActionSelect from './ActionSelect';
import ActionContent from './ActionContent';
import allActions from './Actions/index';

const ActionDetails = ({ initialAction, loading, handleSubmit }) => {
  const actions = useActionReducer({
    initialAction,
    allActions: allActions.all
  });

  const handleNameChange = ({ value }) => {
    actions.dispatch({ type: actions.TYPES.UPDATE_NAME, name: value });
  };

  const handleContentChange = content => {
    actions.dispatch({ type: actions.TYPES.UPDATE_CONTENT, content });
  };

  const onSubmit = event => {
    event.preventDefault();

    if (!actions.valid || loading) {
      return;
    }

    handleSubmit(actions.form);
  };

  return (
    <form onSubmit={onSubmit}>
      <Flex ySpace col>
        <Explainer>
          Actions will be sent when a user reaches the step in the conversation.
          An action is a built-in piece of functionality that carries out a
          specific task.
        </Explainer>
        <Flex xSpace center fat>
          <Flex fat xSpace>
            <Flex style={{ width: '20rem' }}>
              <ActionSelect
                aria-label="Actions"
                value={actions.action.name}
                onChange={handleNameChange}
              />
            </Flex>
            {actions.action.name && (
              <ActionContent
                name={actions.action.name}
                content={actions.action.content}
                onChange={handleContentChange}
              />
            )}
          </Flex>
        </Flex>
        <Flex right xSpace>
          <Button
            variant="primary"
            disabled={!actions.valid || loading}
            type="submit">
            Save
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};

ActionDetails.propTypes = {
  initialAction: PropTypes.shape({
    name: PropTypes.name,
    content: PropTypes.object
  }),
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default ActionDetails;
