import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import {
  Flex,
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalClose,
  ModalBody,
  useNotification,
  Button,
  Label,
  Explainer
} from '@ubisend/pulse-components';
import { GroupSelect } from '@ubisend/pulse-groups';

import { forwardTicket } from '../api/index';
import AvailableAssigneeSelect from './AvailableAssigneeSelect';

const defaultOnSuccess = () => undefined;

const ForwardTicketModal = ({
  handleClose,
  id,
  onSuccess = defaultOnSuccess
}) => {
  const [agent, setAgent] = useState(null);
  const [groupsIds, setGroupIds] = useState([]);

  const { showSuccess } = useNotification();

  const mutation = useMutation(forwardTicket, {
    onSuccess: () => {
      showSuccess('Successfully forwarded ticket');
      handleClose();
      onSuccess();
    }
  });

  const handleAgentChange = option => {
    setAgent(option ? option.value : null);
  };

  const handelGroupIdsChange = options => {
    setGroupIds(options ? options.map(option => option.value) : []);
  };

  const handleSubmit = () => {
    mutation.mutate({ id, agent_id: agent });
  };

  const valid = Boolean(agent);

  return (
    <ModalPortal>
      <ModalContainer>
        <ModalContent>
          <ModalHeader>
            Forward Ticket
            <ModalClose onClick={handleClose} />
          </ModalHeader>
          <ModalBody>
            <Flex fat col ySpace>
              <Explainer>
                This ticket will be reassigned to the new agent and an email
                will be sent to them containing all of the ticket details.
              </Explainer>
              <Flex fat col>
                <Label htmlFor="tags">Tags</Label>
                <GroupSelect
                  id="tags"
                  value={groupsIds}
                  onChange={handelGroupIdsChange}
                  for="tickets"
                />
              </Flex>
              <Flex fat col>
                <Label htmlFor="agents">Agents</Label>
                <AvailableAssigneeSelect
                  id="agents"
                  value={agent}
                  onChange={handleAgentChange}
                  groupIds={groupsIds}
                />
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Flex xSpace>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button
                loading={mutation.isLoading}
                disabled={!valid}
                onClick={handleSubmit}>
                Forward
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalContainer>
    </ModalPortal>
  );
};

ForwardTicketModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  onSuccess: PropTypes.func
};

export default ForwardTicketModal;
