import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Action from './Action';

const ViewMessage = ({ id, content, ...props }) => {
  const history = useHistory();

  const handleClick = () => {
    if (id) {
      history.push(`/window/messages/${id}`);
    }
  };

  return (
    <Action
      {...props}
      handleButtonClick={handleClick}
      description={content.content_description}
      content={{
        text: content.text,
        responses: [
          {
            type: 'postback',
            label: content.button,
            action: ''
          }
        ]
      }}
    />
  );
};

ViewMessage.propTypes = {
  id: PropTypes.number,
  content: PropTypes.shape({
    text: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    content_description: PropTypes.string
  })
};

export default ViewMessage;
