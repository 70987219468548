import tw, { styled } from 'twin.macro';
import chroma from 'chroma-js';

import { position, spacing } from '../styles';

const background = ({ theme, type = 'client' }) => {
  if (type === 'owner') {
    return 'white';
  }

  return chroma
    .blend(theme.black, theme.primary, 'overlay')
    .brighten(0.5)
    .css();
};

const Sidebar = styled.div`
  ${tw`flex flex-col h-full pt-5 m-0 z-40 flex-shrink-0 overflow-y-auto h-screen`};
  background-color: ${background};
  ${props => (props.width ? `width: ${props.width}` : tw`w-48`)};
  ${props => (props.height ? `height: ${props.height}` : tw`h-screen`)};
  ${position};
  ${spacing}
`;

export default Sidebar;
