import React from 'react';
import PropTypes from 'prop-types';

import {
  RoutingContent,
  NlpContent,
  EndpointContent,
  SheetContent,
  SemanticSearchContent,
  AutomatedContent
} from './Content/index';

const types = {
  routing: RoutingContent,
  automation: AutomatedContent,
  nlp: NlpContent,
  sso: NlpContent,
  email: NlpContent,
  debug: NlpContent,
  endpoint: EndpointContent,
  spreadsheet: SheetContent,
  semantic_search: SemanticSearchContent
};

const LogContent = ({ log }) => {
  if (!log) {
    return null;
  }

  const Content = types[log.type];

  return <Content {...log} />;
};

LogContent.propTypes = {
  log: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.oneOf(Object.keys(types)).isRequired
  })
};

export default LogContent;
