import React from 'react';

import { useQuery } from '@ubisend/pulse-hooks';

import { FacebookSettingContext } from '../Contexts/index';

const FacebookSettingProvider = ({ children }) => {
  const { data, isSuccess, isLoading, refetch } = useQuery('facebook/settings');

  const context = {
    settings: isSuccess ? data.data : null,
    refetch,
    isLoading
  };

  return (
    <FacebookSettingContext.Provider value={context}>
      {children}
    </FacebookSettingContext.Provider>
  );
};

export default FacebookSettingProvider;
