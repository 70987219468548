import React from 'react';

import { hasPermission } from '@ubisend/pulse-auth';

import WhatsAppLink from './Pages/index';

const routes = [
  {
    name: 'WhatsApp',
    path: '/whatsapp-link',
    component: () => <WhatsAppLink />,
    canAccess: hasPermission('view whatsapp settings'),
    actions: [
      {
        title: 'Manage your WhatsApp channel',
        description: 'Connect, disconnect, or edit your whatsApp integration.',
        icon: 'lightningBolt'
      }
    ]
  }
];

export default routes;
