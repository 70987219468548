import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { useAuth } from '@ubisend/pulse-auth';
import { Span, Flex } from '@ubisend/pulse-components';

import routes from '../routes';

const Container = styled.div`
  ${tw`flex px-10 pt-6 text-xs uppercase tracking-wide font-semibold`}
`;

const StyledLink = styled(Link)`
  ${tw`no-underline text-black opacity-75 hover:underline font-poppins`}
`;

const StyledSpan = styled(Span)`
  ${tw`font-poppins`}
`;

const Breadcrumbs = ({ match }) => {
  const auth = useAuth();

  const crumbs = useMemo(() => {
    return routes
      .filter(({ path, name }) => {
        return path !== '/' && name && match.path.includes(path);
      })
      .filter(({ canAccess = () => true }) => canAccess(auth))
      .sort((a, b) => a.path.length - b.path.length)
      .map(({ path: originalPath, name }) => {
        // If the path is dynamic, e.g. "faqs/:faqId/responses/:responseId", replace
        // each dynamic param with the param value.
        const isDynamicRoute = Object.keys(match.params).length > 0;
        const path = isDynamicRoute
          ? Object.keys(match.params).reduce((replacedPath, param) => {
              return replacedPath.replace(`:${param}`, match.params[param]);
            }, originalPath)
          : originalPath;

        return {
          path,
          name,
          active: path === match.url
        };
      });
  }, [match, auth]);

  // Bail if on an index page.
  if (crumbs.length <= 1) {
    return null;
  }

  return (
    <Container>
      <Flex xSpaceSm>
        {crumbs.map(({ path, name, active }, key) => (
          <React.Fragment key={key}>
            {active ? (
              <StyledSpan>{name}</StyledSpan>
            ) : (
              <StyledLink to={path}>{name}</StyledLink>
            )}
            {key + 1 !== crumbs.length && <span>/</span>}
          </React.Fragment>
        ))}
      </Flex>
    </Container>
  );
};

export default Breadcrumbs;
