import React from 'react';
import tw, { styled } from 'twin.macro';

import { Embed, DeactivatedQueryProvider } from '@ubisend/pulse-volt';

import { BotProvider } from '../../Providers/index';

const Sticky = styled.div`
  ${tw`sticky top-0 border border-gray-300 border-solid`}
  height: calc(100vh - 12rem);
`;

const EmbedPreview = () => (
  <DeactivatedQueryProvider>
    <BotProvider>
      <Sticky>
        <Embed />
      </Sticky>
    </BotProvider>
  </DeactivatedQueryProvider>
);

export default EmbedPreview;
