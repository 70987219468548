import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Flex,
  Label,
  TextInput,
  useNotification,
  Span
} from '@ubisend/pulse-components';
import { useMutation, useQueryClient } from '@ubisend/pulse-hooks';

import { updateSettings as updateSettingsApi } from '../api/index';

const WhatsAppDataForm = ({ number, token, prevBaseUri, noSecret }) => {
  const [phoneNumber, setPhoneNumber] = useState(number ?? '');
  const [apiId, setApiId] = useState(token ?? '');
  const [apiKey, setApiKey] = useState('');
  const [baseUri, setBaseUri] = useState(prevBaseUri ?? '');
  const [showApiKey, setShowApiKey] = useState(noSecret);

  const { showSuccess } = useNotification();
  const queryClient = useQueryClient();

  const { mutate: updateSettings } = useMutation(updateSettingsApi, {
    onSuccess: () => {
      showSuccess('Successfully updated whatsApp settings');
      queryClient.invalidateQueries('whatsapp/settings');
    }
  });

  const handlePhoneNumberChange = event => {
    setPhoneNumber(event.target.value);
  };

  const handleBaseUriChange = event => {
    setBaseUri(event.target.value);
  };

  const handleApiIdChange = event => {
    setApiId(event.target.value);
  };

  const handleApiKeyChange = event => {
    setApiKey(event.target.value);
  };

  const handelSubmit = () => {
    if (showApiKey) {
      updateSettings({
        phone_number: phoneNumber,
        api_id: apiId,
        api_key: apiKey,
        base_uri: baseUri
      });
    } else {
      updateSettings({
        phone_number: phoneNumber,
        api_id: apiId,
        base_uri: baseUri
      });
    }
  };

  const handleShowClientKey = () => setShowApiKey(true);

  return (
    <Flex col>
      <Flex col mb>
        <Label htmlFor="whatsapp_number_input">Phone Number</Label>
        <TextInput
          id="whatsapp_number_input"
          data-testid="whatsapp-number-input"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          placeholder="447451123456"
        />
      </Flex>
      <Flex col mb>
        <Label htmlFor="whatsapp_base_uri_input">Base Url</Label>
        <TextInput
          id="whatsapp_base_uri_input"
          data-testid="whatsapp-base-uri-input"
          value={baseUri}
          onChange={handleBaseUriChange}
          placeholder="https://uk.sopranodesign.com"
        />
      </Flex>
      <Flex col mb>
        <Label htmlFor="whatsapp_id_input">Api Id</Label>
        <TextInput
          id="whatsapp_id_input"
          data-testid="whatsapp-id-input"
          value={apiId}
          onChange={handleApiIdChange}
          placeholder="Your api id"
        />
      </Flex>
      <Flex col mb>
        <Label htmlFor="whatsapp_key_input">Api Key</Label>
        <Flex between center>
          {showApiKey ? (
            <TextInput
              id="whatsapp_key_input"
              data-testid="whatsapp-key-input"
              value={apiKey}
              onChange={handleApiKeyChange}
              placeholder="Your api key"
            />
          ) : (
            <Span>Api key credentials have been set</Span>
          )}
          {!showApiKey && (
            <Flex top>
              <Button
                onClick={handleShowClientKey}
                data-testid="change-whatsapp-key">
                Change Credentials
              </Button>
            </Flex>
          )}
        </Flex>
      </Flex>
      <Flex right mt>
        <Button
          variant="primary"
          onClick={handelSubmit}
          data-testid="submit-whatsapp-data">
          Save
        </Button>
      </Flex>
    </Flex>
  );
};

WhatsAppDataForm.propTypes = {
  number: PropTypes.string,
  token: PropTypes.string,
  noSecret: PropTypes.bool,
  prevBaseUri: PropTypes.string
};

export default WhatsAppDataForm;
