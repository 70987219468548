import React, { useState } from 'react';

import {
  PageWrapper,
  OneHalfLayout,
  Flex,
  Panel,
  Placeholder
} from '@ubisend/pulse-components';
import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import { PolicyAccept, BackButton } from '@ubisend/pulse-channels';

import {
  UpdateTelegramSettings,
  CreateTelegramLink
} from '../Components/index';
import { acceptPolicy as acceptPolicyApi } from '../api/settings';

const TelegramSettings = () => {
  const [existingSettings, setExistingSettings] = useState(false);

  const { isLoading, isSuccess, data: initialSettings } = useQuery(
    'telegram/settings',
    {
      onSuccess: data => {
        setExistingSettings(data.data.has_auth_token);
      }
    }
  );

  const queryClient = useQueryClient();

  const { mutate: acceptPolicy } = useMutation(acceptPolicyApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('telegram/settings');
    }
  });

  const PolicyCheck = () => {
    if (!initialSettings.data.has_given_data_consent) {
      return (
        <PolicyAccept
          handlePolicyAccept={acceptPolicy}
          policyUrl="https://core.telegram.org/api/terms"
        />
      );
    }

    if (!existingSettings) {
      return <CreateTelegramLink />;
    }

    return <UpdateTelegramSettings />;
  };

  return (
    <PageWrapper
      center
      half
      header="Telegram"
      prevAction={<BackButton to="/channels" />}>
      <Flex col center>
        <OneHalfLayout>
          {isLoading && (
            <Panel>
              <Placeholder items={1} subitems={3} center />
            </Panel>
          )}
          {isSuccess && <PolicyCheck />}
        </OneHalfLayout>
      </Flex>
    </PageWrapper>
  );
};

export default TelegramSettings;
