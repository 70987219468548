import tw, { styled } from 'twin.macro';
import React from 'react';
import PropTypes from 'prop-types';

import { spacing } from '../styles';
import Label from '../Forms/Label';
import Flex from '../Layout/Flex';

const Hr = styled.hr`
  ${tw`border-gray-200 border-0 border-t w-full border-solid`}
  ${props =>
    props.fullWidth &&
    `
    margin-left: -1rem;
    margin-right: -1rem;
    width: calc(100% + 2rem);
  `}
  ${props =>
    props.fullWidthSm &&
    `
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    width: calc(100% + 1rem);
  `}
  ${props => (props.tall ? tw`my-8` : tw`my-4`)}
  ${spacing}
`;

const Text = styled(Label)`
  margin: 0 1rem;
`;

const Divider = ({ text, ...props }) =>
  text ? (
    <Flex center {...props}>
      <Hr mbNone mtNone />
      {text && <Text secondary>{text}</Text>}
      <Hr mbNone mtNone />
    </Flex>
  ) : (
    <Hr {...props} />
  );

Divider.propTypes = {
  text: PropTypes.string
};

export default Divider;
