import axios from 'axios';

const getSettings = () => axios.get('facebook/settings');

const removeSettings = () => axios.delete('facebook/settings');

const updateSettings = params => axios.put(`facebook/settings`, params);

const acceptPolicy = () => axios.put('facebook/settings/accept');

const getPages = accessToken =>
  axios.get(`facebook/pages`, { params: { access_token: accessToken } });

export { getSettings, updateSettings, removeSettings, getPages, acceptPolicy };
