import axios from 'axios';

const massExportData = subscriberIds => {
  return axios.put(`subscribers/export`, {
    subscriber_ids: subscriberIds
  });
};

const massExportTranscript = subscriberIds => {
  return axios.put(`subscribers/export/transcript`, {
    subscriber_ids: subscriberIds
  });
};

const massDelete = subscriberIds => {
  return axios.put(`subscribers/delete`, {
    subscriber_ids: subscriberIds
  });
};

export { massExportData, massExportTranscript, massDelete };
