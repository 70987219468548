import React from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import {
  Flex,
  ModalClose,
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalBody,
  useNotification
} from '@ubisend/pulse-components';

import { createConverseSetting } from '../../../api/index';
import WidgetDetails from './WidgetDetails';
import { useBotSettings } from '../../../hooks';

const DuplicateWidget = ({ widget, handleClose }) => {
  const { showSuccess } = useNotification();
  const { fetchBotSettings, settings } = useBotSettings();
  const mutation = useMutation(createConverseSetting, {
    onSuccess: () => {
      fetchBotSettings();
      showSuccess('Successfully created a new widget.');
      handleClose();
    }
  });

  const handleSubmit = form => {
    mutation.mutate({
      dashboard_name: form.name,
      duplicate_id: form.duplicateId
    });
  };

  return (
    <ModalPortal>
      <ModalContainer>
        <ModalContent>
          <ModalHeader>
            Duplicate Widget
            <Flex between>
              <ModalClose onClick={handleClose} />
            </Flex>
          </ModalHeader>
          <ModalBody>
            <WidgetDetails
              duplicating
              initialChecked={true}
              widget={widget}
              handleSubmit={handleSubmit}
              loading={mutation.isLoading}
              prefilledDuplicateValue={true}
              settings={settings}
            />
          </ModalBody>
        </ModalContent>
      </ModalContainer>
    </ModalPortal>
  );
};

DuplicateWidget.propTypes = {
  widget: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    isPrimary: PropTypes.bool.isRequired
  }),
  handleClose: PropTypes.func.isRequired
};

export default DuplicateWidget;
