import TriggerPostbackContent from './TriggerPostbackContent';

const triggerPostback = {
  name: 'Trigger postback',
  Content: TriggerPostbackContent,
  setInitialContent: (content = {}) => {
    const defaultContent = {
      rows: [
        {
          id: Math.random(),
          triggerPostbackDateTime: '',
          triggerPostbackCallback: ''
        }
      ]
    };
    return Object.keys(content).length !== 0 && content.rows.length > 0
      ? content
      : defaultContent;
  },
  valid: content => {
    return Boolean(
      Object.keys(content).length !== 0 &&
        content.rows.length > 0 &&
        content.rows.every(row => {
          return Boolean(
            row.triggerPostbackDateTime && row.triggerPostbackCallback
          );
        })
    );
  }
};

export default triggerPostback;
