import RequestPermissionsOnAlexaContent from './RequestPermissionsOnAlexaContent';

const requestPermissionsOnAlexa = {
  name: 'Request permissions on Alexa',
  Content: RequestPermissionsOnAlexaContent,
  setInitialContent: (content = {}) => {
    return {
      permissions: content.permissions || ''
    };
  },
  valid: content => {
    return Boolean(content.permissions);
  }
};

export default requestPermissionsOnAlexa;
