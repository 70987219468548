import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useQuery } from '@ubisend/pulse-hooks';
import {
  Flex,
  LoadingContainer,
  NoResults,
  Button
} from '@ubisend/pulse-components';
import {
  Markdown,
  tags as defaultTags,
  MarkdownLink
} from '@ubisend/pulse-markdown';

import { useDocLocation } from '../hooks/index';

const DocLink = props => {
  const location = useDocLocation();

  if (props.href[0] === '/') {
    return (
      <Button
        as={Link}
        to={`${location.base_url}docs${props.href}`}
        style={{ display: 'inline' }}>
        {props.children}
      </Button>
    );
  }

  return <MarkdownLink {...props} />;
};

DocLink.propTypes = {
  href: PropTypes.string.isRequired
};

const WELCOME_DOC =
  process.env.REACT_APP_WELCOME_DOC || '2151317744/2152202241';

const tags = {
  ...defaultTags,
  a: DocLink
};

const Doc = ({ path }) => {
  const { data, isSuccess, isLoading } = useQuery([
    'docs/show',
    { path: path || WELCOME_DOC }
  ]);

  return (
    <Flex pad fat yScroll mb>
      <Flex mxAuto fat style={{ maxWidth: '48rem' }}>
        {isLoading && (
          <Flex middle fat>
            <LoadingContainer />
          </Flex>
        )}
        {isSuccess && !data.content && (
          <Flex fat middle>
            <NoResults
              text="The document was not found"
              subtitle="Why not try another link?"
            />
          </Flex>
        )}
        {isSuccess && data.content && (
          <Markdown components={tags}>{data.content}</Markdown>
        )}
      </Flex>
    </Flex>
  );
};

Doc.propTypes = {
  path: PropTypes.string
};

export default Doc;
