import React from 'react';
import PropTypes from 'prop-types';

import {
  FormGroup,
  Label,
  Select,
  formatSelectOption
} from '@ubisend/pulse-components';
import { useQuery } from '@ubisend/pulse-hooks';

const format = ({ id, name }) => ({ label: name, value: id });

const WebhookVariableSelect = ({ handleVariableChange, value }) => {
  const query = useQuery('variables/all');

  return (
    <>
      <FormGroup>
        <Label htmlFor="variable_key">Variable Key</Label>
        <Select
          id="variable_key"
          placeholder="Select Variable..."
          onChange={handleVariableChange}
          value={
            query.isSuccess &&
            value &&
            format(
              query.data.data.find(
                webhookVariable => webhookVariable.id === value
              )
            )
          }
          options={
            query.isSuccess ? query.data.data.map(formatSelectOption) : []
          }
          for="variable_key"
          isClearable
        />
      </FormGroup>
    </>
  );
};

WebhookVariableSelect.propTypes = {
  handleVariableChange: PropTypes.func.isRequired,
  value: PropTypes.func.isRequired
};

export default WebhookVariableSelect;
