import React, { useState } from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

import { AnimatePresence, motion } from '@ubisend/framer-motion';
import { breakpoints } from '@ubisend/pulse-components';

const Lightbox = styled(motion.div)`
  ${tw`fixed flex w-screen h-screen flex-grow left-0 top-0 items-center justify-center z-20`}
  background: rgba(0, 0, 0, 0.5);
  & > img {
    border-radius: 1rem;
    max-height: 90vh;
    max-width: 90vw;
  }
`;

const StyledImage = styled(motion.img)`
  @media (min-width: ${breakpoints.md}px) {
    ${tw`block shadow-lg cursor-pointer`}
    max-width: calc(45vw - 2rem);
    max-height: 50vh;
    border-radius: 1rem;
  }

  @media (max-width: ${breakpoints.md - 1}px) {
    ${tw`flex-shrink-0`}
    object-fit: cover;
  }
`;

const Image = ({ lightbox = true, ...props }) => {
  const [showLightbox, setShowLightbox] = useState(false);

  return (
    <>
      <StyledImage onClick={() => setShowLightbox(true)} {...props} />
      <AnimatePresence>
        {showLightbox && lightbox && (
          <Lightbox
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setShowLightbox(false)}>
            <Image
              initial={{ scale: 0.95 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.95 }}
              transition={{ ease: 'circOut' }}
              onClick={() => setShowLightbox(false)}
              {...props}
            />
          </Lightbox>
        )}
      </AnimatePresence>
    </>
  );
};

Image.propTypes = {
  lightbox: PropTypes.bool
};

export default Image;
