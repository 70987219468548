import tw, { styled } from 'twin.macro';

import { hexToRgba } from '@ubisend/pulse-component-hooks';

import { spacing } from '../styles';

const Tag = styled.span`
  ${tw`px-2 py-1 rounded text-xs leading-tight uppercase font-poppins whitespace-nowrap`}
  color: ${({ theme, colour = 'primary' }) => theme[colour]};
  background-color: ${({ theme, background = 'primary' }) => {
    return hexToRgba(theme[background], 0.15);
  }};
  ${spacing}
`;

export default Tag;
