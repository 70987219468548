import tw, { styled } from 'twin.macro';

const BuilderStepContainer = styled.div`
  ${tw`flex flex-grow relative flex-col`}
  width: 24rem;
  ${props =>
    props.right &&
    `margin-left: -1px;
    width: calc(24rem + 1px);`}
  ${props =>
    props.left &&
    `margin-left: 1px;
    width: calc(24rem - 1px);`}
`;

export default BuilderStepContainer;
