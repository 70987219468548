import React from 'react';
import PropTypes from 'prop-types';
import chroma from 'chroma-js';
import { keyframes } from 'styled-components';
import { styled } from 'twin.macro';

import Flex from '../Layout/Flex';

const loading = keyframes`
  0%{
    margin-left: -100%;
  }
  100%{
    margin-left: 100%;
  }
`;

const Item = styled.div`
  width: 100%;
  height: 1.5rem;
  background: ${props => chroma(props.colour || 'black').alpha(0.75)};
  opacity: 0.33;
  overflow: hidden;
  border-radius: 0.25rem;
  &:nth-child(odd) {
    width: 70%;
  }
  &:nth-child(even) {
    width: 80%;
  }
  &:nth-child(3n) {
    width: 90%;
  }
  &:nth-child(4n) {
    width: 60%;
  }
`;

const InnerItem = styled.div`
  height: 100%;
  width: 100%;
  background: linear-gradient(
    90deg,
    transparent 0%,
    ${props => props.colour || 'black'} 33%,
    ${props => props.colour || 'black'} 66%,
    transparent 100%
  );
  opacity: 1;
  animation: ${loading} infinite cubic-bezier(0.76, 0, 0.24, 1) 1s;
`;

const DefaultWrapper = ({ children }) => (
  <Flex col fat>
    {children}
  </Flex>
);

const Placeholder = ({
  items = 5,
  subitems = 3,
  Wrapper = DefaultWrapper,
  colour = 'black',
  ...props
}) => (
  <Flex fat col ySpace {...props}>
    {[...new Array(items)].map((_, key) => (
      <Wrapper key={key} style={{ marginBottom: '5rem' }}>
        <Flex fat col ySpace>
          {[...new Array(subitems)].map((_, key) => (
            <Item colour={colour} key={key}>
              <InnerItem colour={colour} />
            </Item>
          ))}
        </Flex>
      </Wrapper>
    ))}
  </Flex>
);

Placeholder.propTypes = {
  items: PropTypes.number,
  subitems: PropTypes.number,
  colour: PropTypes.string,
  Wrapper: PropTypes.elementType
};

export { Item };

export default Placeholder;
