import tw, { styled } from 'twin.macro';

const TableImage = styled.div`
  > img {
    ${tw`h-full`}
    max-width: 10rem;
    max-height: 10rem;
  }
`;

export default TableImage;
