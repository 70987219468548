import React from 'react';
import PropTypes from 'prop-types';

import { ErrorContainer } from '@ubisend/pulse-components';
import {
  BotProvider,
  DeactivatedQueryProvider,
  ThemeProvider
} from '@ubisend/pulse-volt';

import { conversation } from '../replies';
import ErrorWidget from './ErrorWidget';

const ErrorView = ({ initialMessages = conversation, code, message }) => (
  <ErrorContainer code={code} message={message}>
    <DeactivatedQueryProvider>
      <ThemeProvider>
        <BotProvider
          showPoweredByUbisend={false}
          showLogo={false}
          initialMessages={initialMessages}
          hasSeenBanner={true}
          initialOpen
          showClose={false}
          name={'Support bot'}>
          <ErrorWidget />
        </BotProvider>
      </ThemeProvider>
    </DeactivatedQueryProvider>
  </ErrorContainer>
);

ErrorView.propTypes = {
  initialMessages: PropTypes.array,
  code: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired
};

export default ErrorView;
